import Navbar from 'react-bootstrap/Navbar'
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
// import 'components/RollingText.js'
import RollingText from './RollingText.js';

import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";

const dropdownItems = [
    {href: '#', name: 'Overview'},
    {href: '#', name: 'Setup'},
    {href: '#', name: 'Usage'},
];


const MenuNavbar = () => {
      return (
        <TestRunAndTickerRepoConsumer>
        {context => { 
            return(
            <Navbar bg="light" expand="lg" 
                    style={{ maxHeight: '4vh' }}>
              <Container fluid >
                <div className="BrandMenuTitle">
                  <Navbar.Brand href="#">Neural Trading Network</Navbar.Brand>
                </div>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                      <Nav
                        className="me-auto my-2 my-lg-0"
                        navbarScroll
                      >
                      <div className="HomeMenuBarOptions">
                        <Nav.Link href="#action1">Home</Nav.Link>
                      </div> 
                      <div className="MyStrategyMenuBarOptions">
                        <Nav.Link href="#action2">My Strategy</Nav.Link>
                      </div>
                      {/* <div className="TopPerformersMenuBarOptions">  
                        <NavDropdown title="Top Performers" id="navbarScrollingDropdown">
                          <NavDropdown.Item href="#action3">Highest Total Profits</NavDropdown.Item>
                          <NavDropdown.Item href="#action4">Highest Wins/Loss Ratio</NavDropdown.Item>
                          <NavDropdown.Item href="#action5">Highest Profit/Order</NavDropdown.Item>
                          <NavDropdown.Divider />
                        </NavDropdown>
                        </div>
                      <div className="TutorialMenuBarOptions">  
                        <NavDropdown title="Tutorial (Coming Soon)" id="navbarScrollingDropdown">
                          <NavDropdown.Item href="#action6" >Quick Tutorial (90 Seconds)</NavDropdown.Item>
                          <NavDropdown.Item href="#action7" >Regular Tutorial (5 Minutes)</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="#action8" >Navigation Bar</NavDropdown.Item>
                          <NavDropdown.Item href="#action9" >Constellation Chart</NavDropdown.Item>
                          <NavDropdown.Item href="#action10" >Chart Viewer</NavDropdown.Item>
                        </NavDropdown>   
                        </div>   */}
                      </Nav>  
                    <div className="RollingTextBar" >
                        <RollingText />
                    </div>
                    
                    <div className="SearchMenuBar" >
                      <Form className="d-flex">
                        <FormControl
                          type="search"
                          placeholder="Search Stock or Crypto"
                          className="me-2"
                          aria-label="Search"
                        />
                        <Button variant="outline-success">Search</Button>
                      </Form>
                    </div>
                  <Navbar.Text className="FundsComponent">                    
                    {context.StateOfUserProfilePopup.client_equity != 0.0
                      ? <a href="#funds">Funds: ${context.StateOfUserProfilePopup.client_equity}</a> 
                      : <a href="#funds">Funds: $0.00</a>
                    }  
                  </Navbar.Text>
                  <Navbar.Text className="LoginComponent" onClick={() => { context.setUserProfilePopupDisplaying()}}>
                    {context.StateOfUserProfilePopup.nameOfUser != '' 
                      ? <a href="#Profile">{context.StateOfUserProfilePopup.nameOfUser}</a> 
                      : <a href="#Profile">Sign in</a>
                    }                    
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>)
                        }
                      }
            </TestRunAndTickerRepoConsumer>
        );
    };

    
export default MenuNavbar