
import React, { useRef, useEffect } from 'react'
import  useCanvas from '../Hooks/NeuronHook'


const Neurons = props => {

  
  const { animate, ...rest } = props
  const canvasRef = useCanvas(animate)
  
  return <canvas ref={canvasRef} {...rest}  width={window.innerWidth - 20} height={window.innerHeight} />
}

export default Neurons