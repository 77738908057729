import React from 'react'
import {   Checkbox,
  Grid,
  Button,
  Header,
  Icon,
  Input,
  Menu,
  Rating,
  Search,
  Segment,
  Sidebar,
  Table,
  Dropdown } from 'semantic-ui-react'
  import 'semantic-ui-css/semantic.min.css'
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import AssetsTable from './AssetsTable.js';
import IndicatorsTable from './IndicatorsTable.js';


class TableExampleApprove extends React.Component {    

static contextType = TestRunAndTickerRepoContext;


GetConsumer = () => {
    return (
      <TestRunAndTickerRepoConsumer>
        {context => {     
    
               
        }}
      </TestRunAndTickerRepoConsumer>
    ); 
  }


  renderTableData(prop, context) {      
    return prop.map((row, index) => {
      const { userStrategyRowName, userStrategyStartedInvestingDate, userStrategyEndedInvestingDate, userStrategyInvested, userStrategyProfit, userStrategyWinRate, userStrategyStrategyType, userStrategyPercentageOfWholeStrategy, userStrategyIndicators } = row //destructuring
      let indicatorsString = userStrategyIndicators.replaceAll(",", "\n");
      return (
        // <Table.Row onClick={(event) => context.SetStateCurrentSelectedStrategy(event, userStrategyRowName)}>
        <Table.Row onClick={(event) => this.handledRowClicked(event, userStrategyRowName, context)}>
          <Table.Cell>{userStrategyRowName}</Table.Cell> 
          <Table.Cell>{userStrategyStartedInvestingDate}</Table.Cell> 
          <Table.Cell>{userStrategyEndedInvestingDate}</Table.Cell> 
          <Table.Cell>{'$' + userStrategyInvested}</Table.Cell>  
          {
              userStrategyProfit > 0 ? 
              <Table.Cell positive>{'$' + userStrategyProfit}</Table.Cell>
              :
              userStrategyProfit < 0 ? 
              <Table.Cell negative>{'$' + userStrategyProfit}</Table.Cell>
              :                  
              <Table.Cell>{'$' + userStrategyProfit}</Table.Cell>
          }
          <Table.Cell>{userStrategyWinRate}</Table.Cell>
          <Table.Cell>{userStrategyStrategyType}</Table.Cell>
          <Table.Cell>{userStrategyPercentageOfWholeStrategy}</Table.Cell> 
          <Table.Cell>{indicatorsString}</Table.Cell> 
          <Table.Cell collapsing>          
          <Button inverted compact size='small'>
                                                  View
                                              </Button>
          </Table.Cell>
        </Table.Row>                  
      )                
    })
  }
  

  renderOrdersData(prop, context)
  {
    return prop.map((row, index) => {
      const { OrderName, OrderTestRunName, OrderTime, OrderType, OrderSymbol, OrderQuantity, OrderAveragePrice, OrderRequestedPrice, OrderTags, 
          OrderProfit, OrderProfitPercentage, OrderDesiredProfit, OrderStopLoss } = row //destructuring
        if(OrderProfit != undefined)
        {
          let x = 0
        }
        return (
        // <Table.Row onClick={(event) => this.handledUserOrderRowClicked(event, TestRunId)}>
        <Table.Row> 
          <Table.Cell>{OrderName}</Table.Cell> 
          <Table.Cell>{OrderTestRunName}</Table.Cell> 
          <Table.Cell>{OrderTime}</Table.Cell> 
          <Table.Cell>{OrderType}</Table.Cell> 
          <Table.Cell>{OrderSymbol}</Table.Cell> 
          <Table.Cell>{OrderQuantity}</Table.Cell> 
          <Table.Cell>{OrderAveragePrice}</Table.Cell> 
          <Table.Cell>{OrderRequestedPrice}</Table.Cell> 
          <Table.Cell>{OrderTags}</Table.Cell> 
          <Table.Cell>{OrderProfit}</Table.Cell> 
          <Table.Cell>{OrderProfitPercentage}</Table.Cell> 
          <Table.Cell>{OrderDesiredProfit}</Table.Cell> 
          <Table.Cell>{OrderStopLoss}</Table.Cell> 
        </Table.Row>                  
      ) 
    })
  }


StrategyTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortStrategyTestRunName} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptTestRunName'})}
  >Strategy Name</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortStartedInvestingDate} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptStartedInvestingDate'})}
  >Start Investing Data</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortEndedInvestingDate} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptEndedInvestingDate'})}
  >End Investing Data</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortInvested} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortProfit} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptProfit'})}
  >Profits</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortWinRate} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortStrategyType} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptStrategyType'})}
  >Strategy Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortPercentageOfWholeStrategy} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptPercentageOfWholeStrategy'})}
  >Percentage Of Whole Strategy</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicators} 
      onClick={() => context.SortUserPromptStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicators'})}
  >Indicators</Table.HeaderCell>
  <Table.HeaderCell>Display Strategy</Table.HeaderCell>
</Table.Row>
}



handledRowClicked = (event, userStrategyTestRunId, context) => {
  if(context.StateOfUserProfilePopup.SelectedStrategy == null || context.StateOfUserProfilePopup.SelectedStrategy != userStrategyTestRunId)
  {
    context.SetStateCurrentSelectedStrategy(event, userStrategyTestRunId)
  }
  else
  {
    context.setStateExtendedRowUserStrategyTestRunId(userStrategyTestRunId)
  }
};



renderAssetTable = () =>
{
  return (<AssetsTable/>)
}

renderIndicatorTable = () =>
{
  return (<IndicatorsTable/>)
}

StrategyTableDataRows (context, conditionalOptions, userStrategies)
{   return (
      <Table.Body>
      <Table.Row height='100px'>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown              
                placeholder='Equals'
                fluid
                selection
                options={conditionalOptions}
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxUserStrategyTestRunName} onChange={context.UserPromptInputDataListOnChange} list='userPromptTestRunNameList' placeholder='(Strategy Name)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForStartedInvestingDate}     
                placeholder='(All)'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptStartedInvestingDateList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input id="StrategyStartTimeInput" value={context.StateOfUserProfilePopup.searchBoxStartedInvestingDate} onChange={context.UserPromptInputDataListOnChange} list='userPromptStartedInvestingDateList' placeholder='(Start Investing)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown      
                value={context.StateOfUserProfilePopup.operationForEndedInvestingDate}            
                placeholder='(All)'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptEndedInvestingDateList'
            />
        </div>
        <div className='InputWithDataSets'>                                
          <Input id="StrategyEndTimeInput" value={context.StateOfUserProfilePopup.searchBoxEndedInvestingDate} onChange={context.UserPromptInputDataListOnChange} list='userPromptEndedInvestingDateList' placeholder='(End Investing)'/>
          </div>  
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown        
                value={context.StateOfUserProfilePopup.operationForInvested}        
                placeholder='(All)'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptInvestedList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxInvested} onChange={context.UserPromptInputDataListOnChange} list='userPromptInvestedList' placeholder='(Amount Invested)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForProfit}
                placeholder='(All)'                                         
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptProfitList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxProfit} onChange={context.UserPromptInputDataListOnChange} list='userPromptProfitList' placeholder='(Profit)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown              
                value={context.StateOfUserProfilePopup.operationForWinRate}
                placeholder='(All)'                                      
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptWinRateList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxWinRate} onChange={context.UserPromptInputDataListOnChange} list='userPromptWinRateList' placeholder='(Win Rate)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={'Equals'}                                        
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptStrategyTypeList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxStrategyType} onChange={context.UserPromptInputDataListOnChange} list='userPromptStrategyTypeList' placeholder='(Strategy Type)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value={context.StateOfUserProfilePopup.operationForPercentageOfWholeStrategy}       
                placeholder='(All)'                             
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptPercentageOfWholeStrategyList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxPercentageOfWholeStrategy} onChange={context.UserPromptInputDataListOnChange} list='userPromptPercentageOfWholeStrategyList' placeholder='(Percentage Owned)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value= {'Equals'}     
                placeholder={'Equals'}      
                disabled={true}                        
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOperatorOnChange} list='userPromptIndicatorsList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxIndicators} onChange={context.UserPromptInputDataListOnChange} list='userPromptIndicatorsList' placeholder='(Indicators)'/>
          </div>
        </Table.Cell>
        <Table.Cell />
    </Table.Row>
    {userStrategies}
    </Table.Body>
  )
}


OrderTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortOrderName} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderName'})}
  >Order Name</Table.HeaderCell>  
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderTestRunName} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderTestRunName'})}
  >Strategy Name</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderTime} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderTime'})}
  >Time</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderType} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderType'})}
  >Order Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderSymbol} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderSymbol'})}
  >Symbol</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderQuantity} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderQuantity'})}
  >Quantity</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderAveragePrice} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderAveragePrice'})}
  >Average Price</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderRequestedPrice} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderRequestedPrice'})}
  >Requested Price</Table.HeaderCell>
  <Table.HeaderCell>Notes</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderProfit} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderProfitPercentage} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderProfitPercentage'})}
  >Profit Percentage</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderDesiredProfit} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderDesiredProfit'})}
  >Desired Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortOrderStopLoss} 
      onClick={() => context.SortUserPromptOrdersOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptOrderStopLoss'})}
  >Stop Loss</Table.HeaderCell>
</Table.Row>
}



AssetTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortAssetName} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetName'})}
  >Asset Name</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetType} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetType'})}
  >Asset Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetInvested} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetProfit} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetWinRate} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetWins} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWins'})}
  >Wins</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetLosses} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetLosses'})}
  >Losses</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetNumberOfTrades} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetNumberOfTrades'})}
  >Number of Trades</Table.HeaderCell>
</Table.Row>
}


IndicatorTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortIndicatorName} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorName'})}
  >Indicator Name</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorType} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorType'})}
  >Indicator Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorInvested} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorProfit} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWinRate} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWins} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWins'})}
  >Wins</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorLosses} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorLosses'})}
  >Losses</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorNumberOfTrades'})}
  >Number of Trades</Table.HeaderCell>
</Table.Row>
}


OrderTableDataRows (context, conditionalOptions, userOrders)
{   return (
      <Table.Body>
       <Table.Row height='100px'>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet' >                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderName} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderNameList' placeholder='(Order Name)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet' >                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderTestRunName} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderTestRunNameList' placeholder='(Order Strategy Name)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForOrderTime}      
                placeholder='(All)'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderTimeList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderTime} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderTimeList' placeholder='(Order Time)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderTypeList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderType} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderTypeList' placeholder='(Order Type)'/>
          </div>  
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderSymbolList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderSymbol} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderSymbolList' placeholder='(Asset)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForOrderQuantity}
                placeholder='(All)'                                         
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderQuantityList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderQuantity} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderQuantityList' placeholder='(Quantity)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown         
                value={context.StateOfUserProfilePopup.operationForOrderAveragePrice}     
                placeholder='(All)'                                      
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderAveragePriceList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderAveragePrice} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderAveragePriceList' placeholder='(Avg Price)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForOrderRequestedPrice}  
                placeholder='(All)'                                         
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderRequestedPriceList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderRequestedPrice} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderRequestedPriceList' placeholder='(Requested Price)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value='Equals'                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderTagList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderTag} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderTagList' placeholder='(Order Notes)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForOrderProfit}                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderProfitList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderProfit} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderProfitList' placeholder='(Profit)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForOrderProfitPercentage}                             
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderProfitPercentageList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderProfitPercentage} onChange={context.UserPromptInputOrderDataListOnChange} 
                    list='userPromptOrderProfitPercentageList' placeholder='(Profit Percentage)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForOrderDesiredProfit}                              
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderDesiredProfitList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderDesiredProfit} onChange={context.UserPromptInputOrderDataListOnChange} 
                    list='userPromptOrderDesiredProfitList' placeholder='(Desired Profit)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForStopLoss}                              
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputOrderOperatorOnChange} list='userPromptOrderStopLossList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input value={context.StateOfUserProfilePopup.searchBoxOrderStopLoss} onChange={context.UserPromptInputOrderDataListOnChange} list='userPromptOrderStopLossList' placeholder='(Stop Loss)'/>
          </div>
        </Table.Cell>
    </Table.Row>
    {userOrders}
    </Table.Body>
  )
}


SetUserStrategiesProp (context)
{
  let userPromptFilterStrategies = []    
  let props =  []
  let testRunValue = 'Back-Again-Mustard'
  let testRunProfit = '0'
  let testRunProfitRaw = 0
  let totalNumberOfOrders = 0
  let totalLifeTime = 0
  let removeDecimalWinLossFormat = ''
  let durationString = ''
  let testRunWinLossRate = ''
  let rating = 2
              
  let userPromptTestRunIdsString = '<userPromptTestRunNameList id=\'UserPromptTestRunOptionsElement\'>'
  let userPromptStartedInvestingDateString = '<userPromptStartedInvestingDateList id=\'UserPromptStartedInvestingDateOptionsElement\'>'
  let userPromptEndedInvestingDateString = '<userPromptEndedInvestingDateList id=\'UserPromptEndedInvestingDateOptionsElement\'>'
  let userPromptInvestedString = '<userPromptInvestedList id=\'UserPromptInvestedOptionsElement\'>'
  let userPromptProfitString = '<userPromptProfitList id=\'UserPromptProfitOptionsElement\'>'
  let userPromptWinRateString = '<userPromptWinRateList id=\'UserPromptWinRateOptionsElement\'>'
  let userPromptStrategyTypeString = '<userPromptStrategyTypeList id=\'UserPromptStrategyTypeOptionsElement\'>'
  let userPromptPercentageOfWholeStrategyString = '<userPromptPercentageOfWholeStrategyList id=\'UserPromptPercentageOfWholeStrategyOptionsElement\'>'
  let userPromptIndicatorsString = '<userPromptIndicatorsList id=\'UserPromptIndicatorsyOptionsElement\'>'

  let userPromptTestRunNameListDataList = document.getElementById('userPromptTestRunNameList');
  let userPromptStartedInvestingDateDataList = document.getElementById('userPromptStartedInvestingDateList');
  let userPromptEndedInvestingDateDataList = document.getElementById('userPromptEndedInvestingDateList');
  let userPromptInvestedDataList = document.getElementById('userPromptInvestedList');
  let userPromptProfitDataList = document.getElementById('userPromptProfitList');
  let userPromptWinRateDataList = document.getElementById('userPromptWinRateList');
  let userPromptStrategyTypeDataList = document.getElementById('userPromptStrategyTypeList');
  let userPromptPercentageOfWholeStrategyDataList = document.getElementById('userPromptPercentageOfWholeStrategyList');    
  let userPromptIndicatorsDataList = document.getElementById('userPromptIndicatorsList');    

  for (let key in context.StateOfUserProfilePopup.userStrategies) 
    {
      let userStrategy = context.StateOfUserProfilePopup.userStrategies[key]
      
      if(context.StateOfUserProfilePopup.searchBoxUserStrategyTestRunName != '' && !userStrategy.TestRunId.lastIndexOf(context.StateOfUserProfilePopup.searchBoxUserStrategyTestRunName, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxStartedInvestingDate != '') //context.StateOfUserProfilePopup.searchBoxStartedInvestingDate != '' && 
      {
        let currentDateFilter = Date.parse(context.StateOfUserProfilePopup.searchBoxStartedInvestingDate)
        let valueToCheck = Date.parse(userStrategy.StartedInvestingDate)        
        if(!context.CheckStrategyValidPerOperation(valueToCheck, currentDateFilter, context.StateOfUserProfilePopup.operationForStartedInvestingDate))
        {
          continue;           
        }
      }
      if(context.StateOfUserProfilePopup.searchBoxEndedInvestingDate != '')
      {
        let currentDateFilter = Date.parse(context.StateOfUserProfilePopup.searchBoxEndedInvestingDate)
        let valueToCheck = Date.parse(userStrategy.EndedInvestingDate)        
        if(!context.CheckStrategyValidPerOperation(valueToCheck, currentDateFilter, context.StateOfUserProfilePopup.operationForEndedInvestingDate))
        {
          continue;           
        }
      }
      if(context.StateOfUserProfilePopup.searchBoxInvested != '' && !context.CheckStrategyValidPerOperation(userStrategy.Invested, context.StateOfUserProfilePopup.searchBoxInvested, context.StateOfUserProfilePopup.operationForInvested))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxProfit != '' && !context.CheckStrategyValidPerOperation(userStrategy.Profit, context.StateOfUserProfilePopup.searchBoxProfit, context.StateOfUserProfilePopup.operationForProfit))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxWinRate != '' && !context.CheckStrategyValidPerOperation(userStrategy.WinRate, context.StateOfUserProfilePopup.searchBoxWinRate, context.StateOfUserProfilePopup.operationForWinRate))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxStrategyType != ''  && !userStrategy.StrategyType.lastIndexOf(context.StateOfUserProfilePopup.searchBoxStrategyType, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxPercentageOfWholeStrategy != '' && !context.CheckStrategyValidPerOperation(userStrategy.PercentageOfWholeStrategy, context.StateOfUserProfilePopup.searchBoxPercentageOfWholeStrategy, context.StateOfUserProfilePopup.operationForPercentageOfWholeStrategy))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicators != '' && !context.CheckStrategyValidPerOperation(userStrategy.ActiveIndicators, context.StateOfUserProfilePopup.searchBoxIndicators, context.StateOfUserProfilePopup.operationForIndicators))
      {
        continue;
      }
                     
      
      let prop = {
        userStrategyRowName: userStrategy.TestRunId, 
        userStrategyStartedInvestingDate: userStrategy.StartedInvestingDate, 
        userStrategyEndedInvestingDate: userStrategy.EndedInvestingDate, 
        userStrategyInvested: userStrategy.Invested, 
        userStrategyProfit: userStrategy.Profit, 
        userStrategyWinRate: userStrategy.WinRate, 
        userStrategyStrategyType: userStrategy.StrategyType, 
        userStrategyPercentageOfWholeStrategy: userStrategy.PercentageOfWholeStrategy,
        userStrategyIndicators: userStrategy.ActiveIndicators
      }
      props.push(prop);
      userPromptFilterStrategies.push(context.StateOfUserProfilePopup.userStrategies[key])
    }
    
    let sortStrategy = ''
    let sortDirection = ''
    if(props.length > 0)
    {
        props.sort(function(a, b){
            if(context.StateOfUserProfilePopup.sortStrategyTestRunName != null)
            {
              sortStrategy = 'sortStrategyTestRunName';
              sortDirection = context.StateOfUserProfilePopup.sortStrategyTestRunName;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortStrategyTestRunName, a.userStrategyRowName, b.userStrategyRowName);
            }
            else if(context.StateOfUserProfilePopup.sortStartedInvestingDate != null)
            {     
              sortStrategy = 'sortStartedInvestingDate';
              sortDirection = context.StateOfUserProfilePopup.sortStartedInvestingDate;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortStartedInvestingDate, a.userStrategyStartedInvestingDate, b.userStrategyStartedInvestingDate);       
            }
            else if(context.StateOfUserProfilePopup.sortEndedInvestingDate != null)
            {
              sortStrategy = 'sortEndedInvestingDate';
              sortDirection = context.StateOfUserProfilePopup.sortEndedInvestingDate;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortEndedInvestingDate, a.userStrategyEndedInvestingDate, b.userStrategyEndedInvestingDate);   
            }
            else if(context.StateOfUserProfilePopup.sortInvested != null)
            {
              sortStrategy = 'sortInvested';
              sortDirection = context.StateOfUserProfilePopup.sortInvested;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortInvested, a.userStrategyInvested, b.userStrategyInvested);   
            }
            else if(context.StateOfUserProfilePopup.sortProfit != null)
            {
              sortStrategy = 'sortProfit';
              sortDirection = context.StateOfUserProfilePopup.sortProfit;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortProfit, a.userStrategyProfit, b.userStrategyProfit);   
            }
            else if(context.StateOfUserProfilePopup.sortWinRate != null)
            {
              sortStrategy = 'sortWinRate';
              sortDirection = context.StateOfUserProfilePopup.sortWinRate;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortWinRate, a.userStrategyWinRate, b.userStrategyWinRate);   
            }
            else if(context.StateOfUserProfilePopup.sortStrategyType != null)
            {
              sortStrategy = 'sortStrategyType';
              sortDirection = context.StateOfUserProfilePopup.sortStrategyType;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortStrategyType, a.userStrategyStrategyType, b.userStrategyStrategyType);   
            }
            else if(context.StateOfUserProfilePopup.sortPercentageOfWholeStrategy != null)
            {
              sortStrategy = 'sortPercentageOfWholeStrategy';
              sortDirection = context.StateOfUserProfilePopup.sortPercentageOfWholeStrategy;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortPercentageOfWholeStrategy, a.userStrategyPercentageOfWholeStrategy, b.userStrategyPercentageOfWholeStrategy);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicators != null)
            {
              sortStrategy = 'sortIndicators';
              sortDirection = context.StateOfUserProfilePopup.sortIndicators;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicators, a.userStrategyIndicators, b.userStrategyIndicators);   
            }
            else
                return 0;
        });
          
        if(context != null && context.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId != '' && context.StateOfUserProfilePopup.currentStrategyAssetMetrics.length > 0)
        {
            let userStrategyParentData = context.StateOfUserProfilePopup.userStrategies[context.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId]
            let userStrategyExtendedTickerData = context.StateOfUserProfilePopup.currentStrategyAssetMetrics
              
              userStrategyExtendedTickerData.sort(function(a, b){
              if(sortStrategy == 'sortStrategyTestRunName')
              {
                return context.GetOrderedValues(sortDirection, a.Ticker, b.Ticker);
              }
              // else if(sortStrategy == 'sortStartedInvestingDate')
              // {
              //   return context.GetOrderedValues(sortDirection, a.TickerStartedInvestingDate, b.TickerStartedInvestingDate);   
              // }
              // else if(sortStrategy == 'sortEndedInvestingDate')
              // {
              //   return context.GetOrderedValues(sortDirection, a.TickerEndedInvestingDate, b.TickerEndedInvestingDate);   
              // } 
              else if(sortStrategy == 'sortInvested')
              {
                return context.GetOrderedValues(sortDirection, a.TickerInvested, b.TickerInvested);
              }
              else if(sortStrategy == 'sortProfit')
              {
                return context.GetOrderedValues(sortDirection, a.TickerProfit, b.TickerProfit);   
              }
              else if(sortStrategy == 'sortWinRate')
              {
                return context.GetOrderedValues(sortDirection, a.TickerWinRate, b.TickerWinRate);   
              }
              // else if(sortStrategy == 'sortStrategyType')
              // {
              //   return context.GetOrderedValues(sortDirection, a.userStrategyParentData.userStrategyStrategyType, b.userStrategyParentData.userStrategyStrategyType);   
              // }
              // else if(sortStrategy == 'sortPercentageOfWholeStrategy')
              // {
              //   return context.GetOrderedValues(sortDirection, a.userStrategyParentData.userStrategyPercentageOfWholeStrategy,  a.userStrategyParentData.userStrategyPercentageOfWholeStrategy);   
              // }
              // else if(sortStrategy == 'sortIndicators')
              // {
              //   return context.GetOrderedValues(sortDirection, a.userStrategyParentData.userStrategyIndicators,  a.userStrategyParentData.userStrategyIndicators);   
              // }
              else
                  return 0;
              });

            var indexOfExtendedData = props.findIndex(item => item.userStrategyRowName === context.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId); 
            
            if(indexOfExtendedData >= 0)
            {
              userStrategyExtendedTickerData.map( x => {
                x.userStrategyStartedInvestingDate = userStrategyParentData.StartedInvestingDate;
                x.userStrategyEndedInvestingDate = userStrategyParentData.EndedInvestingDate;
                x.userStrategyStrategyType = userStrategyParentData.StrategyType;
                x.userStrategyPercentageOfWholeStrategy = userStrategyParentData.PercentageOfWholeStrategy;
                x.userStrategyIndicators = userStrategyParentData.ActiveIndicators;
                return x;
                })
  
              props.splice.apply(props, [indexOfExtendedData +1, 0].concat(userStrategyExtendedTickerData));
            }
          } 
          
          //return props
    } 

  let userPromptTestRunIdSort = []
  let userPromptStartedInvestingDateSort = []
  let userPromptEndedInvestingDateSort = []
  let userPromptInvestedSort = []
  let userPromptProfitSort = []
  let userPromptWinRateSort = []
  let userPromptStrategyTypeSort = []
  let userPromptPercentageOfWholeStrategySort = []
  let userPromptIndicatorsSort = []
  
  let userPromptStartedInvestingDateGroupByCheck= []
  let userPromptEndedInvestingDateGroupByCheck = []
  let userPromptInvestedGroupByCheck = []
  let userPromptProfitGroupByCheck = []
  let userPromptWinRateGroupByCheck = []
  let userPromptStrategyTypeGroupByCheck = []
  let userPromptPercentageOfWholeStrategyGroupByCheck = []
  let userPromptIndicatorsGroupByCheck = []
  
  for (var index in userPromptFilterStrategies){
    let userPromptStrategyData = userPromptFilterStrategies[index]
    userPromptTestRunIdSort.push(userPromptStrategyData.TestRunId);

    let startDate = new Date(userPromptStrategyData.StartedInvestingDate)
    let endDate = new Date(userPromptStrategyData.EndedInvestingDate)
    userPromptStartedInvestingDateSort.push(startDate);
    userPromptEndedInvestingDateSort.push(endDate);
    userPromptInvestedSort.push(userPromptStrategyData.Invested);
    userPromptProfitSort.push(userPromptStrategyData.Profit);
    userPromptWinRateSort.push(userPromptStrategyData.WinRate);
    userPromptStrategyTypeSort.push(userPromptStrategyData.StrategyType);
    userPromptPercentageOfWholeStrategySort.push(userPromptStrategyData.PercentageOfWholeStrategy);
    userPromptIndicatorsSort.push(userPromptStrategyData.ActiveIndicators);
  };

  userPromptTestRunIdSort.sort();
  userPromptStartedInvestingDateSort.sort(function(a, b) {
    return b - a;
  });
  userPromptEndedInvestingDateSort.sort(function(a, b) {
    return b - a;
  });
  userPromptInvestedSort.sort(function(a, b) {
    return b - a;
  });
  userPromptProfitSort.sort(function(a, b) {
    return b - a;
  });
  userPromptWinRateSort.sort(function(a, b) {
    return b - a;
  });
  userPromptStrategyTypeSort.sort(function(a, b) {
    return b - a;
  });
  userPromptPercentageOfWholeStrategySort.sort(function(a, b) {
    return b - a;
  });
  userPromptIndicatorsSort.sort(function(a, b) {
    return b - a;
  });

  for(var index in userPromptStartedInvestingDateSort)
  {                  
    let orderedStartedInvestingDate = userPromptStartedInvestingDateSort[index];
    if(!userPromptStartedInvestingDateGroupByCheck.includes(orderedStartedInvestingDate))
    {
      userPromptStartedInvestingDateGroupByCheck.push(orderedStartedInvestingDate);
    }
  }
  for(var index in userPromptEndedInvestingDateSort)
  {
    let orderedEndedInvestingDate = userPromptEndedInvestingDateSort[index];
    if(!userPromptEndedInvestingDateGroupByCheck.includes(orderedEndedInvestingDate))
    {
      userPromptEndedInvestingDateGroupByCheck.push(orderedEndedInvestingDate);
    }
  }
  for(var index in userPromptInvestedSort)
  {
    let orderedInvested = userPromptInvestedSort[index];
    if(!userPromptInvestedGroupByCheck.includes(orderedInvested))
    {
      userPromptInvestedGroupByCheck.push(orderedInvested);
    }
  }
  for(var index in userPromptProfitSort)
  {
    let orderedProfit = userPromptProfitSort[index];
    if(!userPromptProfitGroupByCheck.includes(orderedProfit))
    {
      userPromptProfitGroupByCheck.push(orderedProfit);
    }
  }
  for(var index in userPromptWinRateSort)
  {
    let orderedWinRate = userPromptWinRateSort[index];
    if(!userPromptWinRateGroupByCheck.includes(orderedWinRate))
    {
      userPromptWinRateGroupByCheck.push(orderedWinRate);
    }
  }
  for(var index in userPromptStrategyTypeSort)
  {
    let orderedStrategyType = userPromptStrategyTypeSort[index];
    if(!userPromptStrategyTypeGroupByCheck.includes(orderedStrategyType))
    {
      userPromptStrategyTypeGroupByCheck.push(orderedStrategyType);
    }
  }
  for(var index in userPromptPercentageOfWholeStrategySort)
  {
    let orderedPercentageOfWholeStrategy = userPromptPercentageOfWholeStrategySort[index];
    if(!userPromptPercentageOfWholeStrategyGroupByCheck.includes(orderedPercentageOfWholeStrategy))
    {
      userPromptPercentageOfWholeStrategyGroupByCheck.push(orderedPercentageOfWholeStrategy);
    }
  }
  for(var index in userPromptIndicatorsSort)
  {
    let orderedIndicators = userPromptIndicatorsSort[index];
    if(!userPromptIndicatorsGroupByCheck.includes(orderedIndicators))
    {
      userPromptIndicatorsGroupByCheck.push(orderedIndicators);
    }
  }
  
  for(var userPromptTestRunIdSortedIndex in userPromptTestRunIdSort)
  {
    let userPromptTestRunIdSorted = userPromptTestRunIdSort[userPromptTestRunIdSortedIndex];
    userPromptTestRunIdsString += '<option value=\'' + userPromptTestRunIdSorted + '\'>' + userPromptTestRunIdSorted + '</option>';
  }
  for(var userPromptStartedKey in userPromptStartedInvestingDateGroupByCheck)
  {
    let userPromptStartedString = userPromptStartedInvestingDateGroupByCheck[userPromptStartedKey];
    userPromptStartedInvestingDateString += '<option value=\'' + userPromptStartedString + '\'>' + userPromptStartedString + '</option>';
  }
  for(var userPromptEndedKey in userPromptEndedInvestingDateGroupByCheck)
  {
    let userPromptEndedString = userPromptEndedInvestingDateGroupByCheck[userPromptEndedKey];
    userPromptEndedInvestingDateString += '<option value=\'' + userPromptEndedString + '\'>' + userPromptEndedString + '</option>';
  }
  for(var userPromptInvestedIndex in userPromptInvestedGroupByCheck)
  {
    let investedString = userPromptInvestedGroupByCheck[userPromptInvestedIndex];
    userPromptInvestedString += '<option value=\'' + Math.round(investedString * 100) / 100 + '\'>' + Math.round(investedString * 100) / 100 + '</option>';
  }
  for(var profitIndex in userPromptProfitGroupByCheck)
  {
    let profitString = userPromptProfitGroupByCheck[profitIndex];
    userPromptProfitString += '<option value=\'' + Math.round(profitString * 100) / 100 + '\'>' + Math.round(profitString * 100) / 100 + '</option>';
  }
  
  for(var winRateIndex in userPromptWinRateGroupByCheck)
  {
    let winRateString = userPromptWinRateGroupByCheck[winRateIndex];
    userPromptWinRateString += '<option value=\'' + winRateString + '\'>' + winRateString + '</option>';
  }
  for(var strategyTypeKey in userPromptStrategyTypeGroupByCheck)
  {
    let strategyTypeString = userPromptStrategyTypeGroupByCheck[strategyTypeKey];
    userPromptStrategyTypeString += '<option value=\'' + strategyTypeString + '\'>' + strategyTypeString + '</option>';
  }
  for(var percentageOwnedKey in userPromptPercentageOfWholeStrategyGroupByCheck)
  {
    let percentageOwnedString = userPromptPercentageOfWholeStrategyGroupByCheck[percentageOwnedKey];
    userPromptPercentageOfWholeStrategyString += '<option value=\'' + percentageOwnedString + '\'>' + percentageOwnedString + '</option>';
  }
  for(var indicatorsKey in userPromptIndicatorsGroupByCheck)
  {
    let indicatorsString = userPromptIndicatorsGroupByCheck[indicatorsKey];
    userPromptIndicatorsString += '<option value=\'' + indicatorsString + '\'>' + indicatorsString + '</option>';
  }

  userPromptTestRunIdsString += '</userPromptTestRunNameList>';
  userPromptStartedInvestingDateString += '</userPromptStartedInvestingDateList>';
  userPromptEndedInvestingDateString += '</userPromptEndedInvestingDateList>';
  userPromptInvestedString += '</userPromptInvestedList>';
  userPromptProfitString += '</userPromptProfitList>';
  userPromptWinRateString += '</userPromptWinRateList>';
  userPromptStrategyTypeString += '</userPromptStrategyTypeList>';
  userPromptPercentageOfWholeStrategyString += '</userPromptPercentageOfWholeStrategyList>';
  userPromptIndicatorsString += '</userPromptIndicatorsList>';

  userPromptTestRunNameListDataList.innerHTML = userPromptTestRunIdsString;
  userPromptStartedInvestingDateDataList.innerHTML = userPromptStartedInvestingDateString;
  userPromptEndedInvestingDateDataList.innerHTML = userPromptEndedInvestingDateString;
  userPromptInvestedDataList.innerHTML = userPromptInvestedString;
  userPromptProfitDataList.innerHTML = userPromptProfitString;
  userPromptWinRateDataList.innerHTML = userPromptWinRateString;
  userPromptStrategyTypeDataList.innerHTML = userPromptStrategyTypeString;
  userPromptPercentageOfWholeStrategyDataList.innerHTML = userPromptPercentageOfWholeStrategyString;
  userPromptIndicatorsDataList.innerHTML = userPromptIndicatorsString;
  return {userStrategiesProp: props, sortStrategy: sortStrategy, sortDirection: sortDirection}
}


SetUserOrdersProp (context)
{
  let userPromptFilterOrders = []
  let props =  []

  let userPromptOrderNamesString = '<userPromptOrderNameList id=\'UserPromptOrderNameOptionsElement\'>'
  let userPromptOrderTestRunNameString = '<userPromptOrderTestRunNameList id=\'UserPromptOrderTestRunNameOptionsElement\'>'
  let userPromptOrderTimeString = '<userPromptOrderTimeList id=\'UserPromptOrderTimeOptionsElement\'>'
  let userPromptOrderTypeString = '<userPromptOrderTypeList id=\'UserPromptOrderTypeOptionsElement\'>'
  let userPromptOrderSymbolString = '<userPromptOrderSymbolList id=\'UserPromptOrderSymbolOptionsElement\'>'
  let userPromptOrderQuantityString = '<userPromptOrderQuantityList id=\'UserPromptOrderQuantityOptionsElement\'>'
  let userPromptOrderAveragePriceString = '<userPromptOrderAveragePriceList id=\'UserPromptOrderAveragePriceOptionsElement\'>'
  let userPromptOrderRequestedPriceString = '<userPromptOrderRequestedPriceList id=\'UserPromptOrderRequestedPriceOptionsElement\'>'
  let userPromptOrderTagString = '<userPromptOrderTagList id=\'UserPromptOrderTagOptionsElement\'>'
  let userPromptOrderProfitString = '<userPromptOrderProfitList id=\'UserPromptOrderProfitOptionsElement\'>'
  let userPromptOrderProfitPercentageString = '<userPromptOrderProfitPercentageList id=\'UserPromptOrderProfitPercentageOptionsElement\'>'
  let userPromptOrderDesiredProfitString = '<userPromptOrderDesiredProfitList id=\'UserPromptOrderDesiredProfitOptionsElement\'>'
  let userPromptOrderStopLossString = '<userPromptOrderStopLossList id=\'UserPromptOrderStopLossOptionsElement\'>'

  let userPromptOrderNameListDataList = document.getElementById('userPromptOrderNameList');
  let userPromptOrderTestRunNameListDataList = document.getElementById('userPromptOrderTestRunNameList');
  let userPromptOrderTimeListDataList = document.getElementById('userPromptOrderTimeList');
  let userPromptOrderTypeListDataList = document.getElementById('userPromptOrderTypeList');
  let userPromptOrderSymbolDataList = document.getElementById('userPromptOrderSymbolList');
  let userPromptOrderQuantityDataList = document.getElementById('userPromptOrderQuantityList');
  let userPromptOrderAveragePriceListDataList = document.getElementById('userPromptOrderAveragePriceList');
  let userPromptOrderRequestedPriceListDataList = document.getElementById('userPromptOrderRequestedPriceList');  
  let userPromptuserPromptOrderTagListDataList = document.getElementById('userPromptOrderTagList');   
  let userPromptuserPromptOrderProfitListDataList = document.getElementById('userPromptOrderProfitList');   
  let userPromptuserPromptOrderProfitPercentageListDataList = document.getElementById('userPromptOrderProfitPercentageList');   
  let userPromptuserPromptOrderDesiredProfitListDataList = document.getElementById('userPromptOrderDesiredProfitList');   
  let userPromptuserPromptOrderStopLossListDataList = document.getElementById('userPromptOrderStopLossList');   



  for (let key in context.StateOfUserProfilePopup.userOrders) 
    {
      let userOrder = context.StateOfUserProfilePopup.userOrders[key]
      
      if(context.StateOfUserProfilePopup.searchBoxOrderName != '' && !userOrder.OrderName.lastIndexOf(context.StateOfUserProfilePopup.searchBoxOrderName, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderTestRunName != '' && !userOrder.TestRunId.lastIndexOf(context.StateOfUserProfilePopup.searchBoxOrderTestRunName, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderTime != '')
      {
        let currentDateFilter = Date.parse(context.StateOfUserProfilePopup.searchBoxOrderTime)
        let valueToCheck = Date.parse(userOrder.EventTime)
        if(!context.CheckStrategyValidPerOperation(valueToCheck, currentDateFilter, context.StateOfUserProfilePopup.operationForOrderTime))
        {
          continue;
        }
      }      
      if(context.StateOfUserProfilePopup.searchBoxOrderType != '' && !userOrder.OrderType.lastIndexOf(context.StateOfUserProfilePopup.searchBoxOrderType, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderSymbol != '' && !userOrder.Symbol.lastIndexOf(context.StateOfUserProfilePopup.searchBoxOrderSymbol, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderQuantity != '' && !context.CheckStrategyValidPerOperation(userOrder.Quantity, context.StateOfUserProfilePopup.searchBoxOrderQuantity, context.StateOfUserProfilePopup.operationForOrderQuantity))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderAveragePrice != '' && !context.CheckStrategyValidPerOperation(userOrder.AverageFillPrice, context.StateOfUserProfilePopup.searchBoxOrderAveragePrice, context.StateOfUserProfilePopup.operationForOrderAveragePrice))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderRequestedPrice != '' && !context.CheckStrategyValidPerOperation(userOrder.RequestedPrice, context.StateOfUserProfilePopup.searchBoxOrderRequestedPrice, context.StateOfUserProfilePopup.operationForOrderRequestedPrice))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderTag != '' && !userOrder.OrderTag.lastIndexOf(context.StateOfUserProfilePopup.searchBoxOrderTag, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderProfit != '' && (userOrder.OrderProfit == null || !context.CheckStrategyValidPerOperation(userOrder.OrderProfit, context.StateOfUserProfilePopup.searchBoxOrderProfit, context.StateOfUserProfilePopup.operationForOrderProfit)))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderProfitPercentage != '' && (userOrder.OrderProfitPercentage == null || !context.CheckStrategyValidPerOperation(userOrder.OrderProfitPercentage, context.StateOfUserProfilePopup.searchBoxOrderProfitPercentage, context.StateOfUserProfilePopup.operationForOrderProfitPercentage)))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderDesiredProfit != '' && (userOrder.OrderDesiredProfit == null || !context.CheckStrategyValidPerOperation(userOrder.OrderDesiredProfit, context.StateOfUserProfilePopup.searchBoxOrderDesiredProfit, context.StateOfUserProfilePopup.operationForOrderDesiredProfit)))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxOrderStopLoss != '' && (userOrder.OrderStopLoss == null || !context.CheckStrategyValidPerOperation(userOrder.OrderStopLoss, context.StateOfUserProfilePopup.searchBoxOrderStopLoss, context.StateOfUserProfilePopup.operationForOrderStopLoss)))
      {
        continue;
      }
                     
      
      let orderProp = {
        OrderName: userOrder.OrderName, 
        OrderTestRunName: userOrder.TestRunId, 
        OrderTime: userOrder.EventTime, 
        OrderType: userOrder.OrderType, 
        OrderSymbol: userOrder.Symbol, 
        OrderQuantity: userOrder.Quantity, 
        OrderAveragePrice: userOrder.AverageFillPrice, 
        OrderRequestedPrice: userOrder.RequestedPrice,
        OrderTags: userOrder.OrderTag,
        OrderProfit: userOrder.OrderProfit,
        OrderProfitPercentage: userOrder.OrderProfitPercentage,
        OrderDesiredProfit: userOrder.OrderDesiredProfit,
        OrderStopLoss: userOrder.OrderStopLoss,
      }
      props.push(orderProp);
      userPromptFilterOrders.push(context.StateOfUserProfilePopup.userOrders[key])
    } 

    let sortStrategy = ''
    let sortDirection = ''
    if(props.length > 0)
    {
        props.sort(function(a, b){
            if(context.StateOfUserProfilePopup.sortOrderName != null)
            {
              sortStrategy = 'sortOrderName';
              sortDirection = context.StateOfUserProfilePopup.sortOrderName;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderName, a.OrderName, b.OrderName);
            }
            else if(context.StateOfUserProfilePopup.sortOrderTestRunName != null)
            {     
              sortStrategy = 'sortOrderTestRunName';
              sortDirection = context.StateOfUserProfilePopup.sortOrderTestRunName;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderTestRunName, a.OrderTestRunName, b.OrderTestRunName);       
            }
            else if(context.StateOfUserProfilePopup.sortOrderTime != null)
            {
              sortStrategy = 'sortOrderTime';
              sortDirection = context.StateOfUserProfilePopup.sortOrderTime;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderTime, a.OrderTime, b.OrderTime);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderType != null)
            {
              sortStrategy = 'sortOrderType';
              sortDirection = context.StateOfUserProfilePopup.sortOrderType;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderType, a.OrderType, b.OrderType);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderSymbol != null)
            {
              sortStrategy = 'sortOrderSymbol';
              sortDirection = context.StateOfUserProfilePopup.sortOrderSymbol;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderSymbol, a.OrderSymbol, b.OrderSymbol);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderQuantity != null)
            {
              sortStrategy = 'sortOrderQuantity';
              sortDirection = context.StateOfUserProfilePopup.sortOrderQuantity;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderQuantity, a.OrderQuantity, b.OrderQuantity);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderAveragePrice != null)
            {
              sortStrategy = 'sortOrderAveragePrice';
              sortDirection = context.StateOfUserProfilePopup.sortOrderAveragePrice;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderAveragePrice, a.OrderAveragePrice, b.OrderAveragePrice);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderRequestedPrice != null)
            {
              sortStrategy = 'sortOrderRequestedPrice';
              sortDirection = context.StateOfUserProfilePopup.sortOrderRequestedPrice;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderRequestedPrice, a.OrderRequestedPrice, b.OrderRequestedPrice);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderTags != null)
            {
              sortStrategy = 'sortOrderTags';
              sortDirection = context.StateOfUserProfilePopup.sortOrderTags;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderTags, a.OrderTags, b.OrderTags);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderProfit != null)
            {
              sortStrategy = 'sortOrderProfit';
              sortDirection = context.StateOfUserProfilePopup.sortOrderProfit;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderProfit, a.OrderProfit, b.OrderProfit);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderProfitPercentage != null)
            {
              sortStrategy = 'sortOrderProfitPercentage';
              sortDirection = context.StateOfUserProfilePopup.sortOrderProfitPercentage;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderProfitPercentage, a.OrderProfitPercentage, b.OrderProfitPercentage);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderDesiredProfit != null)
            {
              sortStrategy = 'sortOrderDesiredProfit';
              sortDirection = context.StateOfUserProfilePopup.sortOrderDesiredProfit;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderDesiredProfit, a.OrderDesiredProfit, b.OrderDesiredProfit);   
            }
            else if(context.StateOfUserProfilePopup.sortOrderStopLoss != null)
            {
              sortStrategy = 'sortOrderStopLoss';
              sortDirection = context.StateOfUserProfilePopup.sortOrderStopLoss;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortOrderStopLoss, a.OrderStopLoss, b.OrderStopLoss);   
            }
            else
                return 0;
        });
          
        // if(context != null && context.StateOfUserProfilePopup.ExtendedRowUserOrdersName != '')
        // {
        //     let userStrategyParentData = context.StateOfUserProfilePopup.userStrategies[context.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId]
        //     let userStrategyExtendedTickerData = 
        //       Object.values(userStrategyParentData.UserStrategyTickerDataDictionary);      
              
        //       userStrategyExtendedTickerData.sort(function(a, b){
        //       if(sortStrategy == 'sortStrategyTestRunName')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.Ticker, b.Ticker);
        //       }
        //       else if(sortStrategy == 'sortStartedInvestingDate')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.TickerStartedInvestingDate, b.TickerStartedInvestingDate);   
        //       }
        //       else if(sortStrategy == 'sortEndedInvestingDate')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.TickerEndedInvestingDate, b.TickerEndedInvestingDate);   
        //       } 
        //       else if(sortStrategy == 'sortInvested')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.TickerInvested, b.TickerInvested);
        //       }
        //       else if(sortStrategy == 'sortProfit')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.TickerProfit, b.TickerProfit);   
        //       }
        //       else if(sortStrategy == 'sortWinRate')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.TickerWinRate, b.TickerWinRate);   
        //       }
        //       else if(sortStrategy == 'sortStrategyType')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.userStrategyParentData.userStrategyStrategyType, b.userStrategyParentData.userStrategyStrategyType);   
        //       }
        //       else if(sortStrategy == 'sortEndedInvestingDate')
        //       {
        //         return context.GetOrderedValues(sortDirection, a.userStrategyParentData.userStrategyPercentageOfWholeStrategy,  a.userStrategyParentData.userStrategyPercentageOfWholeStrategy);   
        //       }
        //       else
        //           return 0;
        //       });

        //     var indexOfExtendedData = props.findIndex(item => item.testRunValue === context.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId); 

        //     userStrategyExtendedTickerData.map( x => {
        //       x.tickerTotalLifeTime = durationString;
        //       x.tickerRawTotalLifeTime = totalLifeTime;
        //       return x;
        //       })

        //     props.splice.apply(props, [indexOfExtendedData +1, 0].concat(userStrategyExtendedTickerData));
        //   } 
        
        //  return props
    } 


    let userPromptOrderNameSort = []
    let userPromptOrderTestRunNameSort = []
    let userPromptOrderTimeSort = []
    let userPromptOrderTypeSort = []
    let userPromptOrderSymbolSort = []
    let userPromptOrderQuantitySort = []
    let userPromptOrderAveragePriceSort = []
    let userPromptOrderRequestedPriceSort = []
    let userPromptOrderTagsSort = []
    let userPromptOrderProfitSort = []
    let userPromptOrderProfitPercentageSort = []
    let userPromptOrderDesiredProfitSort = []
    let userPromptOrderStopLossSort = []
    
    let userPromptOrderTimeGroupByCheck= []
    let userPromptOrderTypeGroupByCheck = []
    let userPromptOrderSymbolGroupByCheck = []
    let userPromptOrderQuantityGroupByCheck = []
    let userPromptOrderAveragePriceGroupByCheck = []
    let userPromptOrderRequestedPriceGroupByCheck = []
    let userPromptOrderTagsGroupByCheck = []
    let userPromptOrderProfitGroupByCheck = []
    let userPromptOrderProfitPercentageGroupByCheck = []
    let userPromptOrderDesiredProfitGroupByCheck = []
    let userPromptOrderStopLossGroupByCheck = []
    
    for (var index in userPromptFilterOrders){
      let userPromptStrategyData = userPromptFilterOrders[index]
      userPromptOrderNameSort.push(userPromptStrategyData.OrderName);
      userPromptOrderTestRunNameSort.push(userPromptStrategyData.TestRunId);
      let orderTime = new Date(userPromptStrategyData.EventTime)
      userPromptOrderTimeSort.push(orderTime);
      userPromptOrderTypeSort.push(userPromptStrategyData.OrderType);
      userPromptOrderSymbolSort.push(userPromptStrategyData.Symbol);
      userPromptOrderQuantitySort.push(userPromptStrategyData.Quantity);
      userPromptOrderAveragePriceSort.push(userPromptStrategyData.AverageFillPrice);
      userPromptOrderRequestedPriceSort.push(userPromptStrategyData.RequestedPrice);
      userPromptOrderTagsSort.push(userPromptStrategyData.OrderTag);
      userPromptOrderProfitSort.push(userPromptStrategyData.OrderProfit);
      userPromptOrderProfitPercentageSort.push(userPromptStrategyData.OrderProfitPercentage);
      userPromptOrderDesiredProfitSort.push(userPromptStrategyData.OrderDesiredProfit);
      userPromptOrderStopLossSort.push(userPromptStrategyData.OrderStopLoss);
    };

    userPromptOrderNameSort.sort();
    userPromptOrderTestRunNameSort.sort();
    userPromptOrderTimeSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderTypeSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderSymbolSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderSymbolSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderQuantitySort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderAveragePriceSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderRequestedPriceSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderTagsSort.sort();    
    userPromptOrderProfitSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderProfitPercentageSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderDesiredProfitSort.sort(function(a, b) {
      return b - a;
    });
    userPromptOrderStopLossSort.sort(function(a, b) {
      return b - a;
    });


    for(var index in userPromptOrderTimeSort)
    {                  
      let order1 = userPromptOrderTimeSort[0]
      let order2 = userPromptOrderTimeSort[1]
      if(order1 < order2)
      {
        let x = 0
      }
      if(order1 > order2)
      {
        let x = 0
      }
      let orderedOrderTime = userPromptOrderTimeSort[index];
      if(!userPromptOrderTimeGroupByCheck.includes(orderedOrderTime))
      {
        userPromptOrderTimeGroupByCheck.push(orderedOrderTime);
      }
    }
    for(var index in userPromptOrderTypeSort)
    {
      let orderedOrderType = userPromptOrderTypeSort[index];
      if(!userPromptOrderTypeGroupByCheck.includes(orderedOrderType))
      {
        userPromptOrderTypeGroupByCheck.push(orderedOrderType);
      }
    }
    for(var index in userPromptOrderSymbolSort)
    {
      let orderedOrderSymbol = userPromptOrderSymbolSort[index];
      if(!userPromptOrderSymbolGroupByCheck.includes(orderedOrderSymbol))
      {
        userPromptOrderSymbolGroupByCheck.push(orderedOrderSymbol);
      }
    }
    for(var index in userPromptOrderQuantitySort)
    {
      let orderedOrderQuantity = userPromptOrderQuantitySort[index];
      if(!userPromptOrderQuantityGroupByCheck.includes(orderedOrderQuantity))
      {
        userPromptOrderQuantityGroupByCheck.push(orderedOrderQuantity);
      }
    }
    for(var index in userPromptOrderAveragePriceSort)
    {
      let orderedAveragePrice = userPromptOrderAveragePriceSort[index];
      if(!userPromptOrderAveragePriceGroupByCheck.includes(orderedAveragePrice))
      {
        userPromptOrderAveragePriceGroupByCheck.push(orderedAveragePrice);
      }
    }
    for(var index in userPromptOrderRequestedPriceSort)
    {
      let orderedOrderRequestedPrice = userPromptOrderRequestedPriceSort[index];
      if(!userPromptOrderRequestedPriceGroupByCheck.includes(orderedOrderRequestedPrice))
      {
        userPromptOrderRequestedPriceGroupByCheck.push(orderedOrderRequestedPrice);
      }
    }
    for(var index in userPromptOrderProfitSort)
    {
      let orderedOrderProfit = userPromptOrderProfitSort[index];
      if(!userPromptOrderProfitGroupByCheck.includes(orderedOrderProfit))
      {
        userPromptOrderProfitGroupByCheck.push(orderedOrderProfit);
      }
    }
    for(var index in userPromptOrderProfitPercentageSort)
    {
      let orderedOrderProfitPercentage = userPromptOrderProfitPercentageSort[index];
      if(!userPromptOrderProfitPercentageGroupByCheck.includes(orderedOrderProfitPercentage))
      {
        userPromptOrderProfitPercentageGroupByCheck.push(orderedOrderProfitPercentage);
      }
    }
    for(var index in userPromptOrderDesiredProfitSort)
    {
      let orderedOrderDesiredProfit = userPromptOrderDesiredProfitSort[index];
      if(!userPromptOrderDesiredProfitGroupByCheck.includes(orderedOrderDesiredProfit))
      {
        userPromptOrderDesiredProfitGroupByCheck.push(orderedOrderDesiredProfit);
      }
    }
    for(var index in userPromptOrderStopLossSort)
    {
      let orderedOrderStopLoss = userPromptOrderStopLossSort[index];
      if(!userPromptOrderStopLossGroupByCheck.includes(orderedOrderStopLoss))
      {
        userPromptOrderStopLossGroupByCheck.push(orderedOrderStopLoss);
      }
    }


    for(var userPromptOrderNameSortedIndex in userPromptOrderNameSort)
    {
      let orderNameSorted = userPromptOrderNameSort[userPromptOrderNameSortedIndex];
      userPromptOrderNamesString += '<option value=\'' + orderNameSorted + '\'>' + orderNameSorted + '</option>';
    }
    for(var userPromptOrderTestRunNameSortedIndex in userPromptOrderTestRunNameSort)
    {
      let orderTestRunNameSort = userPromptOrderTestRunNameSort[userPromptOrderTestRunNameSortedIndex];
      userPromptOrderTestRunNameString += '<option value=\'' + orderTestRunNameSort + '\'>' + orderTestRunNameSort + '</option>';
    }
    for(var userPromptOrderTimeKey in userPromptOrderTimeGroupByCheck)
    {
      let orderTimeString = userPromptOrderTimeGroupByCheck[userPromptOrderTimeKey];
      userPromptOrderTimeString += '<option value=\'' + orderTimeString + '\'>' + orderTimeString + '</option>';
    }
    for(var userPromptOrderTypeKey in userPromptOrderTypeGroupByCheck)
    {
      let orderTypeString = userPromptOrderTypeGroupByCheck[userPromptOrderTypeKey];
      userPromptOrderTypeString += '<option value=\'' + orderTypeString + '\'>' + orderTypeString + '</option>';
    }
    for(var userPromptOrderSymbolIndex in userPromptOrderSymbolGroupByCheck)
    {
      let orderSymbol = userPromptOrderSymbolGroupByCheck[userPromptOrderSymbolIndex];
      userPromptOrderSymbolString += '<option value=\'' + orderSymbol + '\'>' + orderSymbol + '</option>';
    }
    for(var orderQuantityIndex in userPromptOrderQuantityGroupByCheck)
    {
      let orderQuantityString = userPromptOrderQuantityGroupByCheck[orderQuantityIndex];
      userPromptOrderQuantityString += '<option value=\'' + orderQuantityString + '\'>' + orderQuantityString + '</option>';
    }    
    for(var orderAveragePriceIndex in userPromptOrderAveragePriceGroupByCheck)
    {
      let orderAveragePrice = userPromptOrderAveragePriceGroupByCheck[orderAveragePriceIndex];
      userPromptOrderAveragePriceString += '<option value=\'' + Math.round(orderAveragePrice * 100) / 100 + '\'>' + Math.round(orderAveragePrice * 100) / 100 + '</option>';
    }
    for(var orderRequestedPriceKey in userPromptOrderRequestedPriceGroupByCheck)
    {
      let orderRequestedPrice = userPromptOrderRequestedPriceGroupByCheck[orderRequestedPriceKey];
      userPromptOrderRequestedPriceString += '<option value=\'' + Math.round(orderRequestedPrice * 100) / 100 + '\'>' + Math.round(orderRequestedPrice * 100) / 100 + '</option>';
    }
    for(var userPromptOrderTagsSortedIndex in userPromptOrderTagsSort)
    {
      let orderTagsSort = userPromptOrderTagsSort[userPromptOrderTagsSortedIndex];
      userPromptOrderTagString += '<option value=\'' + orderTagsSort + '\'>' + orderTagsSort + '</option>';
    }
    for(var userPromptOrderProfitSortedIndex in userPromptOrderProfitSort)
    {
      let orderProfitSort = userPromptOrderProfitSort[userPromptOrderProfitSortedIndex];
      userPromptOrderProfitString += '<option value=\'' + orderProfitSort + '\'>' + orderProfitSort + '</option>';
    }
    for(var userPromptOrderProfitPercentageSortedIndex in userPromptOrderProfitPercentageSort)
    {
      let orderProfitPercentageSort = userPromptOrderProfitPercentageSort[userPromptOrderProfitPercentageSortedIndex];
      userPromptOrderProfitPercentageString += '<option value=\'' + orderProfitPercentageSort + '\'>' + orderProfitPercentageSort + '</option>';
    }
    for(var userPromptOrderDesiredProfitSortedIndex in userPromptOrderDesiredProfitSort)
    {
      let orderDesiredProfitSort = userPromptOrderDesiredProfitSort[userPromptOrderDesiredProfitSortedIndex];
      userPromptOrderDesiredProfitString += '<option value=\'' + orderDesiredProfitSort + '\'>' + orderDesiredProfitSort + '</option>';
    }
    for(var userPromptOrderStopLossSortedIndex in userPromptOrderStopLossSort)
    {
      let orderStopLossSort = userPromptOrderStopLossSort[userPromptOrderStopLossSortedIndex];
      userPromptOrderStopLossString += '<option value=\'' + orderStopLossSort + '\'>' + orderStopLossSort + '</option>';
    }

    userPromptOrderNamesString += '</userPromptOrderNameList>';
    userPromptOrderTestRunNameString += '</userPromptOrderTestRunNameList>';
    userPromptOrderTimeString += '</userPromptOrderTimeList>';
    userPromptOrderTypeString += '</userPromptOrderTypeList>';
    userPromptOrderSymbolString += '</userPromptOrderSymbolList>';
    userPromptOrderQuantityString += '</userPromptOrderQuantityList>';
    userPromptOrderAveragePriceString += '</userPromptOrderAveragePriceList>';
    userPromptOrderRequestedPriceString += '</userPromptOrderRequestedPriceList>';    
    userPromptOrderTagString += '</userPromptOrderTagList>';    
    userPromptOrderProfitString += '</userPromptOrderProfitList>';    
    userPromptOrderProfitPercentageString += '</userPromptOrderProfitPercentageList>';    
    userPromptOrderDesiredProfitString += '</userPromptOrderDesiredProfitList>';    
    userPromptOrderStopLossString += '</userPromptOrderStopLossList>';    

    userPromptOrderNameListDataList.innerHTML = userPromptOrderNamesString;
    userPromptOrderTestRunNameListDataList.innerHTML = userPromptOrderTestRunNameString;
    userPromptOrderTimeListDataList.innerHTML = userPromptOrderTimeString;
    userPromptOrderTypeListDataList.innerHTML = userPromptOrderTypeString;
    userPromptOrderSymbolDataList.innerHTML = userPromptOrderSymbolString;
    userPromptOrderQuantityDataList.innerHTML = userPromptOrderQuantityString;
    userPromptOrderAveragePriceListDataList.innerHTML = userPromptOrderAveragePriceString;
    userPromptOrderRequestedPriceListDataList.innerHTML = userPromptOrderRequestedPriceString;
    userPromptuserPromptOrderTagListDataList.innerHTML = userPromptOrderTagString;  
    userPromptuserPromptOrderProfitListDataList.innerHTML = userPromptOrderProfitString;  
    userPromptuserPromptOrderProfitPercentageListDataList.innerHTML = userPromptOrderProfitPercentageString;  
    userPromptuserPromptOrderDesiredProfitListDataList.innerHTML = userPromptOrderDesiredProfitString;  
    userPromptuserPromptOrderStopLossListDataList.innerHTML = userPromptOrderStopLossString;  
    return {userOrdersProp: props, sortStrategy: sortStrategy, sortDirection: sortDirection}
}


render(){ 
    
    let userStrategies = ''
    let userSortStrategy = ''
    let userSortDirection = ''
    let userStrategiesProp =  []
    let userOrders = ''
    let userSortOrders = ''
    let userSortOrdersDirection = ''
    let userOrdersProp =  []
        
    let userSortAssets = ''
    let userSortAssetsDirection = ''
    let userAssetsProp = []
    let userSortIndicators = ''
    let userSortIndicatorsDirection = ''
    let userIndicatorsProp = []

    let conditionalOptions = [
      {
        key: '(All)',
        text: '(All)',
        value: '(All)',
      },
      {
        key: 'Equals',
        text: 'Equals',
        value: 'Equals',
      },
      {
        key: 'Not Equals',
        text: 'Not Equals',
        value: 'Not Equals',   
      },
      {
        key: 'Greater Than',
        text: 'Greater Than',
        value: 'Greater Than',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: 'Less Than',
        text: 'Less Than',
        value: 'Less Than',
      },
      {
        key: 'Greater Than or Equal',
        text: 'Greater Than or Equal',
        value: 'Greater Than or Equal',
      },
      {
        key: 'Less Than or Equal',
        text: 'Less Than or Equal',
        value: 'Less Than or Equal',
      }
    ];


    let strategyTypesDropDownOptions = [
      {
        key: 'My Strategies',
        text: 'My Strategies',
        value: 'My Strategies',
      },
      {
        key: 'Always Buy 1',
        text: '(View) Always Buy 1',
        value: 'Always Buy 1',
      },
      {
        key: '$1 Investor',
        text: '(View) $1 Investor',
        value: '$1 Investor',   
      },
      {
        key: '$10 Investor',
        text: '(View) $10 Investor',
        value: '$10 Investor',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: '$100 Investor',
        text: '(View) $100 Investor',
        value: '$100 Investor',
      },
      {
        key: '$1000 Investor',
        text: '(View) $1000 Investor',
        value: '$1000 Investor',
      },
      {
        key: '$10000 Investor',
        text: '(View) $10000 Investor',
        value: '$10000 Investor',
      }
    ];


    return (
        <TestRunAndTickerRepoConsumer>
          {
          context => {      
            let headerColumnSpan = 9
            
            if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table')
            {
              headerColumnSpan = 10
            }
            else if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Orders Table')
            {
              headerColumnSpan = 13
            }
            else if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Assets Table')
            {
              headerColumnSpan = 13
            }
            else if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Indicators Table')
            {
              headerColumnSpan = 13
            }
            
            if(context != undefined && context.StateOfUserProfilePopup.userStrategies != undefined && Object.keys(context.StateOfUserProfilePopup.userStrategies).length > 0)
            {
              let userStrategyDict = this.SetUserStrategiesProp(context)
              userSortStrategy = userStrategyDict.sortStrategy
              userSortDirection = userStrategyDict.sortDirection
              userStrategiesProp = userStrategyDict.userStrategiesProp
              userStrategies = this.renderTableData(userStrategiesProp, context)
            } 
            if(context != undefined && context.StateOfUserProfilePopup.userOrders != undefined && Object.keys(context.StateOfUserProfilePopup.userOrders).length > 0)
            {
              let userOrderDict = this.SetUserOrdersProp(context)
              userSortOrders = userOrderDict.sortStrategy
              userSortOrdersDirection = userOrderDict.sortDirection
              userOrdersProp = userOrderDict.userOrdersProp
              userOrders = this.renderOrdersData(userOrdersProp, context)
            } 
            

              return (
                <div className='UserStrategyTableInner'>
                  <Table celled inverted selectable striped>
                                          <Table.Header>
                                          {
                                          context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table' 
                                            ? 
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='1' font-size='1.9em' lineColor={'black'} textAlign={'left'}>                                                
                                                  <Dropdown    
                                                        floated='left'      
                                                        labelPosition='left'      
                                                        placeholder='Invest Strategy'
                                                        selection
                                                        options={strategyTypesDropDownOptions}
                                                        onChange={context.UserPromptStrategyTypeChanged}
                                                    />                                  
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign={'center'} colSpan={headerColumnSpan - 1}>Current Invested Strategies </Table.HeaderCell>   
                                            </Table.Row>
                                            : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Orders Table'
                                            ?
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='1' font-size='1.9em' lineColor={'black'} textAlign={'left'}>                                                
                                                  <Dropdown    
                                                        floated='left'      
                                                        labelPosition='left'      
                                                        placeholder='Invest Strategy'
                                                        selection
                                                        options={strategyTypesDropDownOptions}
                                                        onChange={context.UserPromptStrategyTypeChanged}
                                                    />                                  
                                                </Table.HeaderCell>
                                              <Table.HeaderCell textAlign={'center'} colSpan={headerColumnSpan - 1}>Order History</Table.HeaderCell>   
                                            </Table.Row>
                                            : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Assets Table'
                                            ?
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='1' font-size='1.9em' lineColor={'black'} textAlign={'left'}>                                                
                                                  <Dropdown    
                                                        floated='left'      
                                                        labelPosition='left'      
                                                        placeholder='Invest Strategy'
                                                        selection
                                                        options={strategyTypesDropDownOptions}
                                                        onChange={context.UserPromptStrategyTypeChanged}
                                                    />                                  
                                                </Table.HeaderCell>
                                              <Table.HeaderCell textAlign={'center'} colSpan={headerColumnSpan - 1}>Asset Metrics</Table.HeaderCell>   
                                            </Table.Row>
                                            : 
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='1' font-size='1.9em' lineColor={'black'} textAlign={'left'}>                                                
                                                  <Dropdown    
                                                        floated='left'      
                                                        labelPosition='left'      
                                                        placeholder='Invest Strategy'
                                                        selection
                                                        options={strategyTypesDropDownOptions}
                                                        onChange={context.UserPromptStrategyTypeChanged}
                                                    />                                  
                                                </Table.HeaderCell>
                                              <Table.HeaderCell textAlign={'center'} colSpan={headerColumnSpan - 1}>Indicator Metrics</Table.HeaderCell>   
                                            </Table.Row>
                                          }    

                                          
                                          
                                          <Table.Row >
                                              <Table.HeaderCell colSpan={headerColumnSpan}>    
                                              <Button size='small' color='instagram' inverted compact onClick={() => context.setStateUserSelectedTableButtonClick('Strategies Table')}>Strategies Table</Button>
                                              <Button inverted compact size='small' onClick={() => context.setStateUserSelectedTableButtonClick('Orders Table')}>
                                                  Orders Table
                                              </Button>
                                              <Button inverted compact size='small' onClick={() => context.setStateUserSelectedTableButtonClick('Assets Table')}>
                                                  Assets Table
                                              </Button>
                                              <Button inverted compact size='small' onClick={() => context.setStateUserSelectedTableButtonClick('Indicators Table')}>
                                                  Indicators Table
                                              </Button>
                                              <Button inverted compact size='small'>
                                                  User Report
                                              </Button>
                                              {
                                              context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table' 
                                                ? 
                                                <Button
                                                    // floated='left'
                                                    icon
                                                    labelPosition='left'                                                  
                                                    inverted
                                                    compact
                                                    color='red'
                                                    size='small'
                                                >
                                                    <Icon name='user' /> Exit All Strategies
                                                </Button>
                                                : null
                                              } 
                                              </Table.HeaderCell>
                                          </Table.Row>
                                          {
                                          context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table' 
                                            ? this.StrategyTableRows(context) 
                                            : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Orders Table'
                                            ? this.OrderTableRows(context)
                                            : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Assets Table'
                                            ? this.AssetTableRows(context)
                                            : this.IndicatorTableRows(context)
                                          }
                                          </Table.Header>   
                                          {
                                            context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Assets Table'
                                            ? this.renderAssetTable()
                                            :
                                            context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table' 
                                            ? 
                                              this.StrategyTableDataRows(context, conditionalOptions, userStrategies)
                                            : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Orders Table'
                                            ? 
                                              this.OrderTableDataRows(context, conditionalOptions, userOrders)
                                              : context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Indicators Table'
                                              ? 
                                                this.renderIndicatorTable(context, conditionalOptions, userOrders)
                                                : null
                                          }       
                              
                                          
                                          {/* <Table.Footer fullWidth>
                                          </Table.Footer> */}
                                      </Table>
                </div>
              );     
          }
        }  
        </TestRunAndTickerRepoConsumer>
      ); 
}
}
export default TableExampleApprove