import {Chart, Line} from 'react-chartjs-2'
import React from 'react'
// import * as Hammer from 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository";
// import { registerables } from 'chart.js';
// import LargeDatasetsPlugin from 'chartjs-plugin-largedatasets';

class LineChart extends React.Component {    

    xLabels = ['00:00:00 3-17-2021', '04:00:00 3-17-2021', '08:00:00 3-17-2021', 
    '12:00:00 3-17-2021', '16:00:00 3-17-2021', '20:00:00 3-17-2021'];
  

    priceDataList = [{
      label: 'SlowEma',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'orange',
      borderColor: 'orange',
      borderWidth: 2
      },
      {
        label: 'FastEma',
        data: [11, 22, 1, 3, 2, 5],
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2
      },
      {
          label: 'Price',
          data: [47, 52, 67, 59, 80, 50],
          backgroundColor: 'blue',
          borderColor: 'green',
          borderWidth: 2
      }
      ];
      
    state = {
        componentName: ''
    };
    
    
    constructor(props) {
    super(props);
    this.state = {
    count: 0,
    componentName: props.labelValue,
    GridLineColor: props.GridLineColor
    };
    // this.state.componentName = props.labelValue;
    
  }

  static contextType = TestRunAndTickerRepoContext;

    GetConsumer = () => {
      return (
        <TestRunAndTickerRepoConsumer>
          {context => {     
      
                 
          }}
        </TestRunAndTickerRepoConsumer>
      ); 
    }
    
    onZoomChange(){
      // console.log('min: ' ,this.chart.scales['x-axis-0'].min);
      // console.log('max: ' ,this.chart.scales['x-axis-0'].max);
      }


    componentWillMount() {
      Chart.register(zoomPlugin);
      Chart.register(annotationPlugin);
      // Chart.register(LargeDatasetsPlugin);           
    }
    
    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll, true);
    }


    componentDidUpdate(prevProps, prevState) {
      if(prevState.count == this.state.count)
      {
        this.setState({ count: this.state.count + 1 }) 
        // this.forceUpdate()
        // this.props.refreshScreen.ProfileControllerProp('Strategy_Selected')
      }
    }

    handleScroll = (evt) => {
      let scrollChart = evt.target.className
      if (scrollChart == 'FullLineChart')
      {
        this.findChartsToUpdateScroll(evt)
      }
    }

    
    findChartsToUpdateScroll(evt)
    {
      let targetComponent = evt.target
      let parentComponent = targetComponent.parentNode.parentNode.parentNode
      let scrollIndex = targetComponent.scrollLeft
      let chartName = targetComponent.parentNode.innerText
      parentComponent.childNodes.forEach(element => {
        if (element.className == 'AlgorithmChartWraper')
        {
          let algorithmChartElement = Object.values(element.childNodes).find(element => element.className == 'AlgorithmChart')
          if(algorithmChartElement.textContent != chartName)
          {
            let fullLineChart = Object.values(algorithmChartElement.childNodes).find(element => element.className == 'FullLineChart')
            fullLineChart.scrollLeft = scrollIndex
          }
          // this.context.onScrollHandler(scrollIndex)   
        }     
      });
    }


    updateScales(chart) {
      const xScale = chart.scales.x;
      const yScale = chart.scales.y;
      chart.options.scales = {
          newId: {
              display: true
          },
          y: {
              display: true,
          }
      };
      chart.update();
      // need to update the reference
      xScale = chart.scales.newId;
      yScale = chart.scales.y;
    }   
  


    render() {  
        if(this.state.componentName == 'Evaluation Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                  if(context.StateOfCurrentStrategy.testRunExecutionTracking.data.length > 0)
                  {
                    let m = context.StateOfCurrentStrategy.testRunExecutionTracking.data[0]
                    let maxM = -1;
                    let maxKey = -1;
                    for(const [key, value] of Object.entries(m.data)) {
                      if(value > maxM) {
                        maxM = value;
                        maxKey = key;
                      }
                    }

                    let zeroValue = 0
                  }
                  
                  let p = (
                    <div class="FullLineChart" >        
                        <div class="LineGraph" onScroll={(a, b, c) => {
                                console.log(a, b, c);
                              }}>
                            <Line
                            height={0}
                            onScroll={(a, b, c) => {
                              console.log(a, b, c);
                            }}
                            id='EvalChart'
                            data = {{
                                labels: context.newLabelValues,
                                // datasets: context.StateOfPopup.testRunExecutionTracking.data,
                                datasets: context.StateOfCurrentStrategy.filteredTestRunExecutionTracking.data,
                            }}
                            options={{
                              normalized: true,
                              animation: false,
                              elements: {
                                  point: {
                                      radius: 0 
                                  }
                              },
                              scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true
                                            },                                            
                                        }
                                    ],
                                    x: {
                                      parsing : false,
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      onload: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      },
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },                                    
                                  },                                  
                                }
                            }}
                        />
                        </div>
                    </div>
                ); 
                return p;            
              }}
            </TestRunAndTickerRepoConsumer>
          );      
        }
        else if(this.state.componentName == 'Evaluations Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                  let p = (
                    <div className='UserPromptLineGraph'>
                            <Line
                            height={0}
                            onScroll={(a, b, c) => {
                              console.log(a, b, c);
                            }}
                            id='EvalChart'
                            data = {{
                                labels: context.StateOfCurrentStrategy.testRunExecutionTracking.xLabelExecutionTime,
                                // datasets: context.StateOfPopup.testRunExecutionTracking.data,
                                datasets: context.StateOfCurrentStrategy.filteredTestRunExecutionTracking.data,
                                yAxisID: 'userPromptYAxis',
                                // onClick: function(evt, element) {
                                //   if(element.length > 0)
                                //   {
                                //     var ind = element[0]._index;
                                //     // if(confirm('Do you want to remove this point?')){
                                //     //   data.datasets[0].data.splice(ind, 1);
                                //     //   data.labels.splice(ind, 1);
                                //     //   myLineChart.update(data);
                                //     //   }
                                //     }
                                // },
                            }}
                            options={{
                              normalized: true,
                              animation: false,
                              elements: {
                                  point: {
                                      radius: 0 
                                  }
                              },
                              scales: {
                                yAxes: [
                                    {
                                        id: 'userPromptYAxis',
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }
                                ],
                                    x: {
                                      parsing : false,
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      onload: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      },
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },                                    
                                  },                                  
                                }
                            }}
                        />
                        </div>
                );                 
                return p;            
              }}
            </TestRunAndTickerRepoConsumer>
          );      
        }
        else if(this.state.componentName == 'RSI Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                return (
                    <div class="FullLineChart">        
                        <div class="LineGraph">
                            <Line
                            height={0}
                            data = {{
                                labels: context.newLabelValues,
                                datasets: context.rsiDataList,
                            }}
                            options={{
                                normalized: true,
                                elements: {
                                    point: {
                                        radius: 0 
                                    }
                                },
                                animation: false,
                                scales: {                                 
                                    y: {
                                      title: {
                                        display: true,
                                        text: 'Rsi Value',
                                      },                                      
                                      suggestedMin: 0,
                                      suggestedMax: 100
                                    },
                                    x: {
                                      parsing: false,   
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      speed: 25,
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },
                                  },                                  
                                  annotation: {
                                    annotations: context.StateOfPopup.RsiAnnotations 
                                  }
                                }
                            }}
                        />
                        </div>
                    </div>
                );              
              }}
            </TestRunAndTickerRepoConsumer>
          );      
        }
        else if(this.state.componentName == 'Price Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                return (
                    <div class="FullLineChart">        
                        <div class="LineGraph">
                            <Line
                            height={0}
                            data = {{
                                labels: context.newLabelValues,
                                datasets: context.priceDataList,
                            }}
                            options={{
                                normalized: true,
                                elements: {
                                    point: {
                                        radius: 0 
                                    }
                                },
                                animation: false,
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }
                                    ],
                                    x: {
                                      parsing: false,
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      speed: 25,
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },
                                  },
                                  annotation: {
                                    annotations: context.StateOfPopup.BuyOrderAnnotations 
                                  }
                                }
                            }}
                        />
                        </div>
                    </div>
                );              
              }}
            </TestRunAndTickerRepoConsumer>
          );      
        } 
        else if(this.state.componentName == 'Histogram Macd Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                let histogramMacdSignalDifferenceAnnotations = context.StateOfPopup.HistogramMacdSignalDifferenceAnnotations;
                let histogramMacdAnnotations = context.StateOfPopup.HistogramMacdAnnotations;
                return (
                    <div class="FullLineChart">        
                        <div class="LineGraph">
                            <Line
                            height={0}
                            data = {{
                                labels: context.newLabelValues,
                                datasets: context.histogramMacdDataList
                            }}
                            options={{
                                normalized: true,
                                elements: {
                                    point: {
                                        radius: 0
                                    }
                                },
                                animation: false,
                                scales: {
                                    x: {
                                      parsing: false,
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      speed: 25,
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },
                                  },                                     
                                  annotation: {
                                    annotations: histogramMacdSignalDifferenceAnnotations.concat(histogramMacdAnnotations)
                                  }
                                }
                            }}
                        />
                        </div>
                    </div>
                );           
              }}
            </TestRunAndTickerRepoConsumer>
          ); 
        }
        else if(this.state.componentName == 'Single Trend RSI Chart')
        {        
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {
                return (
                    <div class="FullLineChart">        
                        <div class="LineGraph">
                            <Line
                            height={0}
                            data = {{
                                labels: context.newLabelValues,
                                datasets: context.singleTrendRsiDataList,
                            }}
                            options={{
                                normalized: true,
                                elements: {
                                    point: {
                                        radius: 0
                                    }
                                },
                                animation: false,
                                scales: {                          
                                    y: {
                                      title: {
                                        display: true,
                                        text: 'Rsi Value',
                                      },                                      
                                      suggestedMin: 0,
                                      suggestedMax: 100
                                    },
                                    x: {
                                      parsing: false,
                                      min: context.currentXMin,
                                      max: context.currentXMax,
                                    }
                                },
                                plugins: {
                                  zoom: {
                                    zoom: {
                                      wheel: {
                                        enabled: true,                                         
                                        modifierKey:'ctrl'
                                      },
                                      pinch: {
                                        enabled: true,         
                                        mode: 'x',
                                      },
                                      drag: {
                                        enabled: true,
                                        borderColor: 'rgb(54, 162, 235)',
                                        borderWidth: 2,
                                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                      },
                                      mode: 'x',
                                      speed: 25,
                                      onZoom: function(chart){
                                        let currentXAxesValues = {
                                          xMin: chart.chart.options.scales.x.min,
                                          xMax: chart.chart.options.scales.x.max,
                                        };
                                        context.UpdateChartHiddenDataSets(chart)
                                        context.setStateXAxis(currentXAxesValues);
                                      },                                      
                                      onZoomStart: function(chart)
                                      {
                                        context.filterData(chart.chart, 80, context);
                                      }
                                    },
                                  },                                  
                                  annotation: {
                                    annotations: context.StateOfPopup.SingleTrendRsiAnnotations 
                                  }
                                }
                            }}
                        />
                        </div>
                    </div>
                );              
              }}
            </TestRunAndTickerRepoConsumer>
          );      
        }
        else
        {
            return (
                <TestRunAndTickerRepoConsumer>
                  {context => {
                    return (
                        <div class="FullLineChart">        
                            <div class="LineGraph">
                                <Line
                                height={0}
                                data = {{
                                    labels: context.newLabelValues,
                                    datasets: context.macdDataList
                                }}
                                options={{
                                    normalized: true,
                                    elements: {
                                        point: {
                                            radius: 0
                                        }
                                    },
                                    animation: false,
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }
                                        ],
                                        x: {
                                          parsing: false,
                                          min: context.currentXMin,
                                          max: context.currentXMax,
                                        }
                                    },
                                    plugins: {
                                      zoom: {
                                        zoom: {
                                          wheel: {
                                            enabled: true,                                         
                                            modifierKey:'ctrl'
                                          },
                                          pinch: {
                                            enabled: true,         
                                            mode: 'x',
                                          },
                                          drag: {
                                            enabled: true,
                                            borderColor: 'rgb(54, 162, 235)',
                                            borderWidth: 2,
                                            backgroundColor: 'rgba(54, 162, 235, 0.3)'
                                          },
                                          mode: 'x',
                                          speed: 25,
                                          onZoom: function(chart){
                                            let currentXAxesValues = {
                                              xMin: chart.chart.options.scales.x.min,
                                              xMax: chart.chart.options.scales.x.max,
                                            };
                                            context.UpdateChartHiddenDataSets(chart)
                                            context.setStateXAxis(currentXAxesValues);
                                          },                                      
                                          onZoomStart: function(chart)
                                          {
                                            context.filterData(chart.chart, 80, context);
                                          }
                                        },
                                      },                                      
                                      annotation: {                                    
                                        annotations: context.StateOfPopup.MacdAnnotations 
                                      }
                                    }
                                }}
                            />
                            </div>
                        </div>
                    );           
                  }}
                </TestRunAndTickerRepoConsumer>
              );  
        }
    }
}

export default LineChart