import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import { Button, Spinner } from 'react-bootstrap'
import { MDBContainer, MDBCard, MDBCardText, MDBCardFooter, MDBCardTitle, MDBCardBody, MDBCardHeader} from "mdbreact";

const AsyncConfirmationPrompt = (props) =>
{
    return (     
        <div className="AsyncConfirmButtonRow">         
            <MDBContainer >
                <MDBCard border="success" className="m-3" >
                    <div className="AsyncConfirmMessage">                        
                        <MDBCardText >{props.firstMessage}</MDBCardText>         
                        <MDBCardText >{props.secondMessage}</MDBCardText>
                    </div>          
                    <div className="AsyncConfirmButton">
                        <Button onClick=
                        {
                            () => props.onConfirm()
                        }>
                            Connect To Alpaca
                        </Button>              
                    </div>
                    <div className="AsyncConfirmButton">
                        <Button onClick={() => props.onCancel()}>
                            Cancel
                        </Button>                
                    </div>  
                </MDBCard>
            </MDBContainer>        
        </div>   
    );  
};
    
export default AsyncConfirmationPrompt;