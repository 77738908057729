import React from 'react';
import '../styles/style.css';
// import LineChart from 'components/LineChart.js';
import LineChart from './LineChart.js';
import { Button } from '@material-ui/core'

import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import ComboDropDown from './ComboDropDown';
import StrategyConfigurationPrompt from './StrategyConfigurationPrompt'
// import ComboDropDown from './components/ComboDropDown.js';
// import SlideBar from 'components/SlideBar';
import PanalPage from './PanalPage.js';
// import NeuronAnimation from 'images/NeuronPresentation.gif'
// import TextField from '@material-ui/core/TextField';

const axios = require('axios');
  // axios.defaults.baseURL = '//localhost:51728';
axios.defaults.baseURL = '//neuraltradingnetwork.com';
class Popup extends React.Component {

    static contextType = TestRunAndTickerRepoContext;

    xLabels = ['00:00:00 3-17-2021', '04:00:00 3-17-2021', '08:00:00 3-17-2021', 
    '12:00:00 3-17-2021', '16:00:00 3-17-2021', '20:00:00 3-17-2021'];
  

    priceDataList = [{
      label: 'SlowEma',
      data: [],
      backgroundColor: 'orange',
      borderColor: 'orange',
      borderWidth: 2
      },
      {
        label: 'FastEma',
        data: [],
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2
      },
      {
          label: 'Price',
          data: [],
          backgroundColor: 'blue',
          borderColor: 'green',
          borderWidth: 2
      }
      ];

     macdDataList = [{
        label: 'Macd',
        data: [],
        backgroundColor: 'red',
        borderColor: 'red',
        borderWidth: 2
        },
        {
          label: 'Signal',
          data: [],
          backgroundColor: 'blue',
          borderColor: 'blue',
          borderWidth: 2
        }] 


      rsiDataList = [{
          label: 'RSI',
          data: [],
          backgroundColor: 'blue',
          borderColor: 'blue',
          borderWidth: 2
        }
      ]

        GetConsumer = () => {
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {     
          
                     
              }}
            </TestRunAndTickerRepoConsumer>
          ); 
        }
        
        componentDidMount() {
          let contextConsumer = this.context;

          //let GetTestRunAndTickerData = 'http://localhost:51722/api/values/6/' + contextConsumer.StateOfPopup.currentTestRunName + '/' + contextConsumer.StateOfPopup.currentTickerName;
          //let GetAllTestRunAndTickerOrderData = 'http://localhost:51722/api/values/7/' + contextConsumer.StateOfPopup.currentTestRunName + '/' + contextConsumer.StateOfPopup.currentTickerName;
          //let GetTestRunExecutionData = 'http://localhost:51722/api/values/8/' + contextConsumer.StateOfPopup.currentTestRunName;
          let urlString = ''
          if(contextConsumer.connectionType == "Test")
          {
            urlString = 'api/values/'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/'
          }
          let GetTestRunAndTickerData = urlString + '6/' + contextConsumer.StateOfPopup.currentTestRunName + '/' + contextConsumer.StateOfPopup.currentTickerName;
          // let GetAllTestRunAndTickerOrderData = urlString + '7/' + contextConsumer.StateOfPopup.currentTestRunName + '/' + contextConsumer.StateOfPopup.currentTickerName;
          let GetTestRunExecutionData = urlString + '8/' + contextConsumer.StateOfPopup.currentTestRunName;
          
          contextConsumer.setOrderDataList([]);      
          axios({
            method:'get',
            url: GetTestRunAndTickerData,
            //baseURL: 'https://archives.neuraltradingnetwork.com/',
           })
                .then(res => {
                  this.newLabelValues = [] 
                  contextConsumer.macdBuySellSignals = []
                  contextConsumer.MacdAnnotations = []

                  this.priceDataList = [
                    {
                      label: 'SlowEma',
                      data: [],
                      backgroundColor: 'orange',
                      borderColor: 'orange',
                      borderWidth: 2
                    },
                    {
                      label: 'FastEma',
                      data: [],
                      backgroundColor: 'purple',
                      borderColor: 'purple',
                      borderWidth: 2
                    },
                    {
                      label: 'Price',
                      data: [],
                      backgroundColor: 'blue',
                      borderColor: 'green',
                      borderWidth: 2
                    }
                  ]

                  this.macdDataList = [
                    {
                      label: 'Macd',
                      data: [],
                      backgroundColor: 'red',
                      borderColor: 'red',
                      borderWidth: 2
                    },
                    {
                      label: 'Signal',
                      data: [],
                      backgroundColor: 'blue',
                      borderColor: 'blue',
                      borderWidth: 2
                    }
                  ]
                  
                  this.rsiDataList = [                    
                    {
                      label: 'RSI',
                      data: [],
                      backgroundColor: 'blue',
                      borderColor: 'blue',
                      borderWidth: 2
                    }
                  ]

                  let tempLabelValues = []       
                  let tempShortEmaValues = []    
                  let tempLongEmaValues = []    
                  let tempPriceValues = []    
                  let tempMacdValues = []    
                  let tempMacdFastValues = []    
                  let tempMacdSlowValues = []    
                  let tempSignalValues = []    
                  
                  let tempHistogramMacdValues = []    
                  let tempHistogramMacdFastValues = []    
                  let tempHistogramMacdSlowValues = []    
                  let tempHistogramSignalValues = []   
                  let tempHistogramMacdSignalDifference = []   

                  let tempMacdBuySellSignalValues = []    
                  let tempHistogramMacdBuySellSignalValues = []    
                  let tempRsiValues = []
                  let tempRsiBuySellSignalValues = []    
                  let index = 0
                  let rsiAttributeObject = {
                    rsiOverBoughtThreshold: 0, 
                    rsiOverSoldThreshold: 0}

                    let tempSingleTrendRsiValues = []    
                    let tempSingleTrendRsiBuySellSignalValues = []
                    let singleTrendRsiAttributeObject = {
                                                    OverBoughtThreshold: 0, 
                                                    OverSoldThreshold: 0};   

                  contextConsumer.setStateOfNewLabelValues(tempLabelValues);
                  contextConsumer.setStateOfNewShortEmaValues(tempShortEmaValues);
                  contextConsumer.setStateOfNewLongEmaValues(tempLongEmaValues);
                  contextConsumer.setStateOfNewPriceValues(tempPriceValues);
                  contextConsumer.setStateOfNewMacdValues(tempMacdValues);
                  contextConsumer.setStateOfNewMacdSignalValues(tempSignalValues);
                  contextConsumer.setStateOfNewRsiValues(tempRsiValues);
                  contextConsumer.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);
                  contextConsumer.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);
                  contextConsumer.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);
                  contextConsumer.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);
                  

                  for (let key in res.data) {     
                    let testRunAndTickerValue = res.data[key];       
                    let testRunAndTickerActiveIndicatorList = testRunAndTickerValue.ActiveIndicatorList;           
                    tempLabelValues.push(testRunAndTickerValue.Modified);
                    tempShortEmaValues.push(testRunAndTickerValue.ShortEmaValue);
                    tempLongEmaValues.push(testRunAndTickerValue.LongEmaValue);
                    tempPriceValues.push(testRunAndTickerValue.Price);

                    if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'MacdIndicator'))
                    {
                        let macdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'MacdIndicator');
                        tempMacdValues.push(macdElement.MacdValue);
                        tempMacdFastValues.push(macdElement.Fast.CurrentValue);
                        tempMacdSlowValues.push(macdElement.Slow.CurrentValue);
                        tempSignalValues.push(macdElement.SignalValue.CurrentValue);
                        
                        if(macdElement.IsDesiredBuy)
                        {
                          tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'})
                        }
                        else if(macdElement.IsDesiredSell)
                        {
                          tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                        }
                    }
                    if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'RsiIndicator'))
                    {
                        let rsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'RsiIndicator')
                        tempRsiValues.push(rsiElement.RSI);       

                        rsiAttributeObject = {
                          OverBoughtThreshold: rsiElement.OverBoughtThreshold, 
                          OverSoldThreshold: rsiElement.OverSoldThreshold};          

                        if(rsiElement.IsDesiredBuy)
                        {
                          tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                        }
                        else if(rsiElement.IsDesiredSell)
                        {
                          tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                        }
                    }
                    if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'HistogramMacdIndicator'))
                    {
                        let histogramMacdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'HistogramMacdIndicator');                  
                        tempHistogramMacdValues.push(histogramMacdElement.MacdValue);
                        tempHistogramMacdFastValues.push(histogramMacdElement.Fast.CurrentValue);
                        tempHistogramMacdSlowValues.push(histogramMacdElement.Slow.CurrentValue);
                        tempHistogramSignalValues.push(histogramMacdElement.SignalValue.CurrentValue);    
                        tempHistogramMacdSignalDifference.push(histogramMacdElement.DifferenceOfMacdAndSignal);        
                        
                        if(histogramMacdElement.IsDesiredBuy)
                        {
                          tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                        }
                        else if(histogramMacdElement.IsDesiredSell)
                        {
                          tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                        }
                    }
                    if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'SingleTrendRsiIndicator'))
                    {
                        let singleTrendRsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'SingleTrendRsiIndicator')
                        tempSingleTrendRsiValues.push(singleTrendRsiElement.RSI);       
    
                        singleTrendRsiAttributeObject = {
                          OverBoughtThreshold: singleTrendRsiElement.OverBoughtThreshold, 
                          OverSoldThreshold: singleTrendRsiElement.OverSoldThreshold};          
    
                        if(singleTrendRsiElement.IsDesiredBuy)
                        {
                          tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                        }
                        else if(singleTrendRsiElement.IsDesiredSell)
                        {
                          tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                        }
                    }
                    
                    index++;
                  }
                  
                  
                  contextConsumer.setStateOfNewSingleTrendRsiAttributesValues(singleTrendRsiAttributeObject);
                  contextConsumer.setStateOfNewRsiValues(tempRsiValues);
                  contextConsumer.setStateOfNewRsiAttributesValues(rsiAttributeObject);
                  contextConsumer.setStateOfNewLabelValues(tempLabelValues);
                  contextConsumer.setStateOfNewShortEmaValues(tempShortEmaValues);
                  contextConsumer.setStateOfNewLongEmaValues(tempLongEmaValues);
                  contextConsumer.setStateOfNewPriceValues(tempPriceValues);
                  contextConsumer.setStateOfNewMacdValues(tempMacdValues);
                  contextConsumer.setStateOfNewMacdSignalValues(tempSignalValues);
                  contextConsumer.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);
                  contextConsumer.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);
                  contextConsumer.setStateOfMacdBuySellSignals(tempMacdBuySellSignalValues);
                  contextConsumer.setStateOfRsiBuySellSignals(tempRsiBuySellSignalValues);
                  contextConsumer.setStateOfHistogramMacdBuySellSignals(tempHistogramMacdBuySellSignalValues);
                  contextConsumer.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);
                  contextConsumer.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);
                  contextConsumer.setStateOfSingleTrendRsiBuySellSignals(tempSingleTrendRsiBuySellSignalValues);
                  
                  
                  // axios({
                  //   method:'get',
                  //   url: GetAllTestRunAndTickerOrderData,
                  //   //baseURL: 'https://archives.neuraltradingnetwork.com/',
                  //   })
                  //   .then(res => { 
                  //     contextConsumer.setOrderDataList(res.data);
                  //   });
            
                    axios({
                      method:'get',
                      url: GetTestRunExecutionData,
                      //baseURL: 'https://archives.neuraltradingnetwork.com/',
                      })
                    .then(res => {                         
                      contextConsumer.setTestRunExecutionTracking(res.data);
                    });
                });
        }
      


        render() {
          
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {          
                let winningSummaryTestRunString = "Wins: " + context.StateOfPopup.currentTestRunWins + " Losses: " + context.StateOfPopup.currentTestRunLosses;
                let winLossTestRunString =  "Win Percentage: " + context.StateOfPopup.currentTestRunWinPercentage + "%";
                let winningSummaryTickerString = "Wins: " + context.StateOfPopup.currentTickerWins + " Losses: " + context.StateOfPopup.currentTickerLosses;
                let winLossTickerString =  "Win Percentage: " + context.StateOfPopup.currentTickerWinPercentage + "%";
                let evaluationPeriodString =  "Evaluation Period " + context.StateOfPopup.currentDuration;
                let tickerProfitString = '';
                let testRunProfitString = '';
                let summaryTickerPanel = '';
                let summaryTestRunPanel = context.StateOfPopup.currentTestRunProfit <= 0 ? 'ReportNonProfitablePanel' : 'ReportProfitablePanel'
                if(context.StateOfPopup.currentTickerProfit >= 0)
                {
                  tickerProfitString = "+ $" + context.StateOfPopup.currentTickerProfit;
                  summaryTickerPanel = 'ReportProfitablePanel';
                }
                else
                { 
                  tickerProfitString = "- $" + Math.abs(context.StateOfPopup.currentTickerProfit);
                  summaryTickerPanel = 'ReportNonProfitablePanel';
                }

                if(context.StateOfPopup.currentTestRunProfit >= 0)
                {                  
                  testRunProfitString = "+ $" + context.StateOfPopup.currentTestRunProfit;
                  summaryTestRunPanel = 'ReportProfitablePanel';
                }
                else
                {
                  testRunProfitString = "- $" + Math.abs(context.StateOfPopup.currentTestRunProfit);
                  summaryTestRunPanel = 'ReportNonProfitablePanel';
                }

                return (
                  <div className='popup'>
                    <div className='popup_inner'>
                        <div className='popup_exit_button'>
                          <button onClick={this.props.closePopup}>X</button>    
                        </div>  
                        <div className='OptionsRow'>    
                          {
                            <div >
                              <div className='SummaryProfitPanel'>                                
                                <div className={summaryTestRunPanel}> 
                                  <PanalPage panelType={'Strategy'} panelTitle={context.StateOfPopup.currentTestRunName} duration={evaluationPeriodString} profit={testRunProfitString} profitPercent={testRunProfitString} winLossText={winLossTestRunString} summaryText={winningSummaryTestRunString} />                                 
                                </div>   
                                <div className={summaryTickerPanel}>
                                  <PanalPage panelType={'Ticker'} panelTitle={context.StateOfPopup.currentTickerName} duration={evaluationPeriodString} profit={tickerProfitString} profitPercent={tickerProfitString} winLossText={winLossTickerString} summaryText={winningSummaryTickerString} />   
                                </div>
                                <div className='StockResultData'>
                                  <ComboDropDown dataValues= {[]} comboBoxHeader={''} labelValue='Order Test Run By'/>
                                </div>
                                <div className='StockResultData'>
                                  <ComboDropDown dataValues={[]} comboBoxHeader={''} labelValue='Test Run Id'/>
                                </div>
                                <div className='StockResultData'>
                                  <ComboDropDown dataValues={[]} comboBoxHeader={''} labelValue='Stock Result Data'/>
                                </div>               
                                <div className='StockResultData' >
                                  <Button color='Black' onClick={() => { context.handleGetTestRunAndTickerButtonClick() }}>Load Data</Button>
                                  <Button color='Black' onClick={() => { context.HandleLiveTradeStrategyButtonClick() }}>Trade Strategy</Button>
                                </div>  
                              </div>  
                            </div>
                          }
                        </div>   

                        {                          
                            context.StateOfUserProfilePopup.IsDecidingToInvest ?   
                              <StrategyConfigurationPrompt />     
                              :
                              null  
                        }       
                        {
                            <div className='AlgorithmChartWraper'>
                              <div className='AlgorithmChart'>
                                  <h3 className='ChartHeader'>Strategy Overview</h3>
                                  <LineChart            
                                      xAxisValues={context.StateOfCurrentStrategy.testRunExecutionTracking.xLabelExecutionTime}
                                      dataValuesList={context.StateOfCurrentStrategy.filteredTestRunExecutionTracking.data}                
                                      labelValue='Evaluation Chart'
                                  />
                              </div>
                            </div>
                        }      
                        <div className='AlgorithmChartWraper'>
                          <div className='AlgorithmChart'>
                              <h3 className='ChartHeader'>Price Data Chart</h3>
                              <LineChart
                                  xAxisValues={context.newLabelValues}
                                  dataValuesList={context.priceDataList}                
                                  labelValue='Price Chart'
                              />
                          </div>
                        </div>
                        { 
                            context.macdDataList[0].data?.length > 0 ?                            
                            <div className='AlgorithmChartWraper'>
                              <div className='AlgorithmChart'>
                                  <h3 className='ChartHeader'>Macd Chart</h3>
                                  <LineChart
                                      xAxisValues={context.newLabelValues}
                                      dataValuesList={context.macdDataList}        
                                      labelValue='Macd Chart'
                                  />
                              </div>    
                            </div>
                            :
                            null
                        }
                        { 
                            context.rsiDataList[0].data?.length > 0 ?                            
                            <div className='AlgorithmChartWraper'>
                              <div className='AlgorithmChart'>
                                  <h3 className='ChartHeader'>RSI Chart</h3>
                                  <LineChart
                                      xAxisValues={context.newLabelValues}
                                      dataValuesList={context.rsiDataList}        
                                      labelValue='RSI Chart'
                                  />
                              </div>    
                            </div>
                            :
                            null
                        } 
                        { 
                            context.histogramMacdDataList[0].data?.length > 0 ?                            
                            <div className='AlgorithmChartWraper'>
                              <div className='AlgorithmChart'>
                                  <h3 className='ChartHeader'>Histogram Macd Chart</h3>
                                  <LineChart
                                      xAxisValues={context.newLabelValues}
                                      dataValuesList={context.histogramMacdDataList}        
                                      labelValue='Histogram Macd Chart'
                                  />
                              </div>    
                            </div>
                            :
                            null
                        }
                        { 
                            context.singleTrendRsiDataList[0].data?.length > 0 ?
                            <div className='AlgorithmChartWraper'>
                              <div className='AlgorithmChart'>
                                  <h3 className='ChartHeader'>Single Trend RSI Chart</h3>
                                  <LineChart
                                      xAxisValues={context.newLabelValues}
                                      dataValuesList={context.singleTrendRsiDataList}        
                                      labelValue='Single Trend RSI Chart'
                                  />
                              </div>    
                            </div>
                            :
                            null
                        }                  
                    </div>
                  </div>
                );        
              }}
            </TestRunAndTickerRepoConsumer>
          );
        }
}

export default Popup;