import React from 'react'
import {   Checkbox,
  Grid,
  Button,
  Header,
  Icon,
  Input,
  Menu,
  Rating,
  Search,
  Segment,
  Sidebar,
  Table,
  Dropdown } from 'semantic-ui-react'
  import 'semantic-ui-css/semantic.min.css'
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";


class AssetsTable extends React.Component {    

static contextType = TestRunAndTickerRepoContext;


GetConsumer = () => {
    return (
      <TestRunAndTickerRepoConsumer>
        {context => {     
    
               
        }}
      </TestRunAndTickerRepoConsumer>
    ); 
  }

  
  renderAssetsData(prop, context)
  {
    let hasAddedExtendedHeader = false
    return prop.map((row, index) => {
      const { AssetName, AssetType, AssetInvested, AssetProfit, AssetWinRate, AssetWins, AssetLosses, AssetNumberOfTrades} = row //destructuring
      const { TestRunName, userStrategyInvested, userStrategyProfit, userStrategyWinRate, Wins, Losses, NumberOfTrades} = row //destructuring
       
      if(AssetName != undefined)
      {
        return (
        // <Table.Row onClick={(event) => this.handledUserAssetRowClicked(event, TestRunId)}>
        <Table.Row onClick={(event) => this.handledAssetRowClicked(event, AssetName, context)}>
          <Table.Cell>{AssetName}</Table.Cell> 
          <Table.Cell>{AssetType}</Table.Cell> 
          <Table.Cell>{AssetInvested}</Table.Cell> 
          <Table.Cell>{AssetProfit}</Table.Cell> 
          <Table.Cell>{AssetWinRate}</Table.Cell> 
          <Table.Cell>{AssetWins}</Table.Cell> 
          <Table.Cell>{AssetLosses}</Table.Cell> 
          <Table.Cell>{AssetNumberOfTrades}</Table.Cell> 
        </Table.Row>                  
      )
      }
     else
     {
      
      let firstHyphenIndexOffset = TestRunName.indexOf("-") + 1
      let indexOfStrategyTypeEnd = TestRunName.indexOf("-", firstHyphenIndexOffset) //Finds the second occurance of the hypthen (-)
      let strategyTypeUnmodified = TestRunName.substring(0, indexOfStrategyTypeEnd)
      let strategyType = strategyTypeUnmodified.replace("-", " ")

      if(hasAddedExtendedHeader)
      {       
        return (
        <Table.Row active={true} onClick={(event) => this.handledAssetStrategyRowClicked(event, TestRunName, context)}>
            <Table.Cell>{TestRunName}</Table.Cell> 
            <Table.Cell>{strategyType}</Table.Cell> 
            <Table.Cell>{userStrategyInvested}</Table.Cell> 
            <Table.Cell>{userStrategyProfit}</Table.Cell> 
            <Table.Cell>{userStrategyWinRate}</Table.Cell> 
            <Table.Cell>{Wins}</Table.Cell> 
            <Table.Cell>{Losses}</Table.Cell> 
            <Table.Cell>{NumberOfTrades}</Table.Cell> 
          </Table.Row>
        )
      }
      else{        
        hasAddedExtendedHeader = true
        return [this.AssetStrategyTableRows(context),
          <Table.Row active={true} onClick={(event) => this.handledAssetStrategyRowClicked(event, TestRunName, context)}>
            <Table.Cell>{TestRunName}</Table.Cell> 
            <Table.Cell>{strategyType}</Table.Cell> 
            <Table.Cell>{userStrategyInvested}</Table.Cell> 
            <Table.Cell>{userStrategyProfit}</Table.Cell> 
            <Table.Cell>{userStrategyWinRate}</Table.Cell> 
            <Table.Cell>{Wins}</Table.Cell> 
            <Table.Cell>{Losses}</Table.Cell> 
            <Table.Cell>{NumberOfTrades}</Table.Cell> 
          </Table.Row>]  
      }
     } 
    
    })    
  }


  handledAssetRowClicked = (event, userStrategyAsset, context) => {
    if(context.StateOfUserProfilePopup.SelectedAsset == null || context.StateOfUserProfilePopup.SelectedAsset != userStrategyAsset)
    {
      context.SetStateCurrentSelectedAsset(event, userStrategyAsset)
    }
    else
    {
      context.setStateExtendedRowUserAsset(userStrategyAsset)
    }
  };
  

  handledAssetStrategyRowClicked = (event, userStrategyAsset, context) => {
      let x = 0
  };

  AssetStrategyTableRows (context) {
    return <Table.Row >
    <Table.HeaderCell     
        sorted={context.StateOfUserProfilePopup.sortAssetName} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetName'})}
    >Strategy Name</Table.HeaderCell>  
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetType} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetType'})}
    >Strategy Update Period</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetInvested} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetInvested'})}
    >Invested</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetProfit} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetProfit'})}
    >Profit</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetWinRate} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWinRate'})}
    >Win Rate</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetWins} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWins'})}
    >Wins</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetLosses} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetLosses'})}
    >Losses</Table.HeaderCell>
    <Table.HeaderCell
        sorted={context.StateOfUserProfilePopup.sortAssetNumberOfTrades} 
        onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetNumberOfTrades'})}
    >Number Of Trades</Table.HeaderCell>
  </Table.Row>
  }

  
AssetTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortAssetName} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetName'})}
  >Asset Name</Table.HeaderCell>  
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetType} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetType'})}
  >Asset Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetInvested} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetProfit} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetWinRate} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetWins} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWins'})}
  >Wins</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetLosses} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetLosses'})}
  >Losses</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortAssetNumberOfTrades} 
      onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetNumberOfTrades'})}
  >Number Of Trades</Table.HeaderCell>
</Table.Row>
}


AssetTableDataRows (context, conditionalOptions, userAssets)
{   
    return (
      <Table.Body>
       <Table.Row height='100px'>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown      
                value='Equals'          
                fluid
                selection
                options={conditionalOptions}
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet' >                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetName} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetNameList' placeholder='(Asset Name)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetTypeList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetType} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetTypeList' placeholder='(Asset Type)'/>
          </div>  
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value={context.StateOfUserProfilePopup.operationForAssetInvested}  
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetInvestedList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetInvested} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetInvestedList' placeholder='(Invested)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForAssetProfit}                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetProfitList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetProfit} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetProfitList' placeholder='(Profit)'/>
          </div>
        </Table.Cell>

        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown         
                value={context.StateOfUserProfilePopup.operationForAssetWinRate}                
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetWinRateList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetWinRate} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetWinRateList' placeholder='(Win Rate)'/>
          </div>
        </Table.Cell>

        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForAssetWins}                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetWinsList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetWins} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetWinsList' placeholder='(Wins)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForAssetLosses}                          
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetLossesList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetLosses} onChange={context.UserPromptInputAssetDataListOnChange} list='userPromptAssetLossesList' placeholder='(Losses)'/>
          </div>
        </Table.Cell>
        
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForAssetNumberOfTrades}                  
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputAssetOperatorOnChange} list='userPromptAssetNumberOfTradesList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxAssetNumberOfTrades} onChange={context.UserPromptInputAssetDataListOnChange} 
                list='userPromptAssetNumberOfTradesList' placeholder='(Number Of Trades)'/>
          </div>
        </Table.Cell>

    </Table.Row>
    {userAssets}
    </Table.Body>
  )
}



SetUserAssetsProp (context)
{
  let userPromptFilterAssets = []
  let props =  []

  let userPromptAssetNamesString = '<userPromptAssetNameList id=\'UserPromptAssetNameOptionsElement\'>'
  let userPromptAssetTypeString = '<userPromptAssetTypeList id=\'UserPromptAssetTypeOptionsElement\'>'
  let userPromptAssetProfitString = '<userPromptAssetProfitList id=\'UserPromptAssetProfitOptionsElement\'>'
  let userPromptAssetInvestedString = '<userPromptAssetInvestedList id=\'userPromptAssetInvestedOptionsElement\'>'
  let userPromptAssetWinRateString = '<userPromptAssetWinRateList id=\'userPromptAssetWinRateOptionsElement\'>'
  let userPromptAssetWinsString = '<userPromptAssetWinsList id=\'userPromptAssetWinsOptionsElement\'>'
  let userPromptAssetLossestring = '<userPromptAssetLossesList id=\'UserPromptAssetLossesOptionsElement\'>'
  let userPromptAssetNumberOfTradesString = '<userPromptAssetNumberOfTradesList id=\'userPromptAssetNumberOfTradesOptionsElement\'>'

  let userPromptAssetNameListDataList = document.getElementById('userPromptAssetNameList');
  let userPromptAssetTypeListDataList = document.getElementById('userPromptAssetTypeList');
  let userPromptAssetInvestedDataList = document.getElementById('userPromptAssetInvestedList');
  let userPromptAssetProfitDataList = document.getElementById('userPromptAssetProfitList');
  let userPromptAssetWinRateListDataList = document.getElementById('userPromptAssetWinRateList');
  let userPromptAssetWinsDataList = document.getElementById('userPromptAssetWinsList');  
  let userPromptAssetLossesDataList = document.getElementById('userPromptAssetLossesList');   
  let userPromptAssetNumberOfTradesDataList = document.getElementById('userPromptAssetNumberOfTradesList');   



  for (let key in context.StateOfUserProfilePopup.userAssets) 
    {
      let userAsset = context.StateOfUserProfilePopup.userAssets[key]
      
      if(context.StateOfUserProfilePopup.searchBoxAssetName != '' && !userAsset.userStrategyRowName.lastIndexOf(context.StateOfUserProfilePopup.searchBoxAssetName, 0) == 0)
      {
        continue;
      }   
      if(context.StateOfUserProfilePopup.searchBoxAssetType != '' && !userAsset.AssetType.lastIndexOf(context.StateOfUserProfilePopup.searchBoxAssetType, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetInvested != '' && !context.CheckStrategyValidPerOperation(userAsset.userStrategyInvested, context.StateOfUserProfilePopup.searchBoxAssetInvested, context.StateOfUserProfilePopup.operationForAssetInvested))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetProfit != '' && !context.CheckStrategyValidPerOperation(userAsset.userStrategyProfit, context.StateOfUserProfilePopup.searchBoxAssetProfit, context.StateOfUserProfilePopup.operationForAssetProfit))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetWinRate != '' && !context.CheckStrategyValidPerOperation(userAsset.userStrategyWinRate, context.StateOfUserProfilePopup.searchBoxAssetWinRate, context.StateOfUserProfilePopup.operationForAssetWinRate))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetWins != '' && !context.CheckStrategyValidPerOperation(userAsset.Wins, context.StateOfUserProfilePopup.searchBoxAssetWins, context.StateOfUserProfilePopup.operationForAssetWins))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetLosses != '' && !context.CheckStrategyValidPerOperation(userAsset.Losses, context.StateOfUserProfilePopup.searchBoxAssetLosses, context.StateOfUserProfilePopup.operationForAssetLosses))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxAssetNumberOfTrades != '' && (userAsset.NumberOfTrades == null || !context.CheckStrategyValidPerOperation(userAsset.NumberOfTrades, context.StateOfUserProfilePopup.searchBoxAssetNumberOfTrades, context.StateOfUserProfilePopup.operationForAssetNumberOfTrades)))
      {
        continue;
      }
                     
      
      let AssetProp = {
        AssetName: userAsset.userStrategyRowName, 
        AssetType: 'Crypto',
        AssetInvested: userAsset.userStrategyInvested, 
        AssetProfit: userAsset.userStrategyProfit,
        AssetWinRate: userAsset.userStrategyWinRate,
        AssetWins: userAsset.Wins,
        AssetLosses: userAsset.Losses,
        AssetNumberOfTrades: userAsset.NumberOfTrades, 
      }
      props.push(AssetProp);
      userPromptFilterAssets.push(context.StateOfUserProfilePopup.userAssets[key])
    } 

    let sortStrategy = ''
    let sortDirection = ''
    if(props.length > 0)
    {
        props.sort(function(a, b){
            if(context.StateOfUserProfilePopup.sortAssetName != null)
            {
              sortStrategy = 'sortAssetName';
              sortDirection = context.StateOfUserProfilePopup.sortAssetName;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetName, a.AssetName, b.AssetName);
            }
            else if(context.StateOfUserProfilePopup.sortAssetType != null)
            {
              sortStrategy = 'sortAssetType';
              sortDirection = context.StateOfUserProfilePopup.sortAssetType;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetType, a.AssetType, b.AssetType);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetInvested != null)
            {
              sortStrategy = 'sortAssetInvested';
              sortDirection = context.StateOfUserProfilePopup.sortAssetInvested;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetInvested, a.AssetInvested, b.AssetInvested);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetProfit != null)
            {
              sortStrategy = 'sortAssetProfit';
              sortDirection = context.StateOfUserProfilePopup.sortAssetProfit;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetProfit, a.AssetProfit, b.AssetProfit);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetWinRate != null)
            {
              sortStrategy = 'sortAssetWinRate';
              sortDirection = context.StateOfUserProfilePopup.sortAssetWinRate;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetWinRate, a.AssetWinRate, b.AssetWinRate);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetWins != null)
            {
              sortStrategy = 'sortAssetWins';
              sortDirection = context.StateOfUserProfilePopup.sortAssetWins;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetWins, a.AssetWins, b.AssetWins);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetLosses != null)
            {
              sortStrategy = 'sortAssetLosses';
              sortDirection = context.StateOfUserProfilePopup.sortAssetLosses;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetLosses, a.AssetLosses, b.AssetLosses);   
            }
            else if(context.StateOfUserProfilePopup.sortAssetNumberOfTrades != null)
            {
              sortStrategy = 'sortAssetNumberOfTrades';
              sortDirection = context.StateOfUserProfilePopup.sortAssetNumberOfTrades;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortAssetNumberOfTrades, a.AssetNumberOfTrades, b.AssetNumberOfTrades);   
            }
            else
                return 0;
        });

        if(context != null && context.StateOfUserProfilePopup.ExtendedRowUserAsset != '' && context.StateOfUserProfilePopup.currentAssetMetricsByStrategy.length > 0)
        {
            let userStrategyParentData = context.StateOfUserProfilePopup.userStrategies[context.StateOfUserProfilePopup.ExtendedRowUserAsset]
            let extendedData = context.StateOfUserProfilePopup.currentAssetMetricsByStrategy              
            
            extendedData.sort(function(a, b){
              if(sortStrategy == 'sortAssetName')
              {
                return context.GetOrderedValues(sortDirection, a.TestRunName, b.TestRunName);
              }
              // else if(sortStrategy == 'sortAssetType')
              // {
              //   return context.GetOrderedValues(sortDirection, a.AssetType, b.AssetType);
              // }
              else if(sortStrategy == 'sortAssetInvested')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyInvested, b.userStrategyInvested);   
              }
              else if(sortStrategy == 'sortAssetProfit')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyProfit, b.userStrategyProfit);   
              }
              else if(sortStrategy == 'sortAssetWinRate')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyWinRate, b.userStrategyWinRate);   
              }
              else if(sortStrategy == 'sortAssetWins')
              {
                return context.GetOrderedValues(sortDirection, a.Wins, b.Wins);   
              }
              else if(sortStrategy == 'sortAssetLosses')
              {
                return context.GetOrderedValues(sortDirection, a.Losses, b.Losses);   
              }
              else if(sortStrategy == 'sortAssetNumberOfTrades')
              {
                return context.GetOrderedValues(sortDirection, a.NumberOfTrades, b.NumberOfTrades);   
              }
              else
                  return 0;
              });

            var indexOfExtendedData = props.findIndex(item => item.AssetName === context.StateOfUserProfilePopup.ExtendedRowUserAsset); 
            
            if(indexOfExtendedData >= 0)
            {
              // extendedData.map( x => {
              //     x.AssetName = x.TestRunName; 
              //     x.AssetType = 'Crypto';
              //     x.AssetInvested = x.userStrategyInvested; 
              //     x.AssetProfit = x.userStrategyProfit;
              //     x.AssetWinRate = x.userStrategyWinRate;
              //     x.AssetWins = x.Wins;
              //     x.AssetLosses = x.Losses;
              //     x.AssetNumberOfTrades = x.NumberOfTrades;
              //   return x;
              //   })  
              props.splice.apply(props, [indexOfExtendedData +1, 0].concat(extendedData));
            }
          } 
    } 

    let userPromptAssetNameSort = []
    let userPromptAssetTypeSort = []
    let userPromptAssetInvestedSort = []
    let userPromptAssetProfitSort = []
    let userPromptAssetWinRateSort = []
    let userPromptAssetWinsSort = []
    let userPromptAssetLossesSort = []
    let userPromptAssetNumberOfTradesSort = []
    
    let userPromptAssetTimeGroupByCheck= []
    let userPromptAssetTypeGroupByCheck = []
    let userPromptAssetInvestedGroupByCheck = []
    let userPromptAssetProfitGroupByCheck = []
    let userPromptAssetWinRateGroupByCheck = []
    let userPromptAssetWinsGroupByCheck = []
    let userPromptAssetLossesGroupByCheck = []
    let userPromptAssetNumberOfTradesGroupByCheck = []
    
    for (var index in userPromptFilterAssets){
      let userPromptAssetData = userPromptFilterAssets[index]
      userPromptAssetNameSort.push(userPromptAssetData.userStrategyRowName);
      userPromptAssetTypeSort.push('Crypto');
      //userPromptAssetTypeSort.push(userPromptAssetData.AssetType);
      userPromptAssetInvestedSort.push(userPromptAssetData.userStrategyInvested);
      userPromptAssetProfitSort.push(userPromptAssetData.userStrategyProfit);
      userPromptAssetWinRateSort.push(userPromptAssetData.userStrategyWinRate);
      userPromptAssetWinsSort.push(userPromptAssetData.Wins);
      userPromptAssetLossesSort.push(userPromptAssetData.Losses);
      userPromptAssetNumberOfTradesSort.push(userPromptAssetData.NumberOfTrades);
    };

    userPromptAssetNameSort.sort();
    userPromptAssetTypeSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetInvestedSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetProfitSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetWinRateSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetWinsSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetLossesSort.sort(function(a, b) {
      return b - a;
    });
    userPromptAssetNumberOfTradesSort.sort(function(a, b) {
      return b - a;
    });

    for(var index in userPromptAssetTypeSort)
    {
      let AssetedAssetType = userPromptAssetTypeSort[index];
      if(!userPromptAssetTypeGroupByCheck.includes(AssetedAssetType))
      {
        userPromptAssetTypeGroupByCheck.push(AssetedAssetType);
      }
    }
    for(var index in userPromptAssetInvestedSort)
    {
      let AssetInvested = userPromptAssetInvestedSort[index];
      if(!userPromptAssetInvestedGroupByCheck.includes(AssetInvested))
      {
        userPromptAssetInvestedGroupByCheck.push(AssetInvested);
      }
    }
    for(var index in userPromptAssetProfitSort)
    {
      let AssetedAssetProfit = userPromptAssetProfitSort[index];
      if(!userPromptAssetProfitGroupByCheck.includes(AssetedAssetProfit))
      {
        userPromptAssetProfitGroupByCheck.push(AssetedAssetProfit);
      }
    }
    for(var index in userPromptAssetWinRateSort)
    {
      let AssetedAveragePrice = userPromptAssetWinRateSort[index];
      if(!userPromptAssetWinRateGroupByCheck.includes(AssetedAveragePrice))
      {
        userPromptAssetWinRateGroupByCheck.push(AssetedAveragePrice);
      }
    }
    for(var index in userPromptAssetWinsSort)
    {
      let AssetedAssetRequestedPrice = userPromptAssetWinsSort[index];
      if(!userPromptAssetWinsGroupByCheck.includes(AssetedAssetRequestedPrice))
      {
        userPromptAssetWinsGroupByCheck.push(AssetedAssetRequestedPrice);
      }
    }
    for(var index in userPromptAssetLossesSort)
    {
      let assetLosses = userPromptAssetLossesSort[index];
      if(!userPromptAssetLossesGroupByCheck.includes(assetLosses))
      {
        userPromptAssetLossesGroupByCheck.push(assetLosses);
      }
    }
    for(var index in userPromptAssetNumberOfTradesSort)
    {
      let numberOfTrades = userPromptAssetNumberOfTradesSort[index];
      if(!userPromptAssetNumberOfTradesGroupByCheck.includes(numberOfTrades))
      {
        userPromptAssetNumberOfTradesGroupByCheck.push(numberOfTrades);
      }
    }


    for(var userPromptAssetNameSortedIndex in userPromptAssetNameSort)
    {
      let AssetNameSorted = userPromptAssetNameSort[userPromptAssetNameSortedIndex];
      userPromptAssetNamesString += '<option value=\'' + AssetNameSorted + '\'>' + AssetNameSorted + '</option>';
    }
    for(var userPromptAssetTypeKey in userPromptAssetTypeGroupByCheck)
    {
      let AssetTypeString = userPromptAssetTypeGroupByCheck[userPromptAssetTypeKey];
      userPromptAssetTypeString += '<option value=\'' + AssetTypeString + '\'>' + AssetTypeString + '</option>';
    }
    for(var userPromptAssetInvestedIndex in userPromptAssetInvestedGroupByCheck)
    {
      let AssetInvested = userPromptAssetInvestedGroupByCheck[userPromptAssetInvestedIndex];
      userPromptAssetInvestedString += '<option value=\'' + AssetInvested + '\'>' + AssetInvested + '</option>';
    }
    for(var userPromptAssetProfitSortedIndex in userPromptAssetProfitGroupByCheck)
    {
      let assetProfit = userPromptAssetProfitGroupByCheck[userPromptAssetProfitSortedIndex];
      userPromptAssetProfitString += '<option value=\'' + assetProfit + '\'>' + assetProfit + '</option>';
    }   
    for(var AssetWinRateIndex in userPromptAssetWinRateGroupByCheck)
    {
      let AssetWinRate = userPromptAssetWinRateGroupByCheck[AssetWinRateIndex];
      userPromptAssetWinRateString += '<option value=\'' + Math.round(AssetWinRate * 100) / 100 + '\'>' + Math.round(AssetWinRate * 100) / 100 + '</option>';
    }
    for(var AssetWinsKey in userPromptAssetWinsGroupByCheck)
    {
      let AssetWinsSort = userPromptAssetWinsGroupByCheck[AssetWinsKey];
      userPromptAssetWinsString += '<option value=\'' + AssetWinsSort + '\'>' + AssetWinsSort + '</option>';
    }
    for(var userPromptAssetLossesSortedIndex in userPromptAssetLossesGroupByCheck)
    {
      let AssetLossessSort = userPromptAssetLossesGroupByCheck[userPromptAssetLossesSortedIndex];
      userPromptAssetLossestring += '<option value=\'' + AssetLossessSort + '\'>' + AssetLossessSort + '</option>';
    }
    for(var userPromptAssetNumberOfTradesSortedIndex in userPromptAssetNumberOfTradesGroupByCheck)
    {
      let AssetNumberOfTradesSort = userPromptAssetNumberOfTradesSort[userPromptAssetNumberOfTradesSortedIndex];
      userPromptAssetNumberOfTradesString += '<option value=\'' + AssetNumberOfTradesSort + '\'>' + AssetNumberOfTradesSort + '</option>';
    }

    userPromptAssetNamesString += '</userPromptAssetNameList>';
    userPromptAssetTypeString += '</userPromptAssetTypeList>';
    userPromptAssetInvestedString += '</userPromptAssetInvestedList>';
    userPromptAssetProfitString += '</userPromptAssetProfitList>';    
    userPromptAssetWinRateString += '</userPromptAssetWinRateList>';
    userPromptAssetWinsString += '</userPromptAssetWinsList>';    
    userPromptAssetLossestring += '</userPromptAssetLossesList>';    
    userPromptAssetNumberOfTradesString += '</userPromptAssetNumberOfTradesList>';     

    userPromptAssetNameListDataList.innerHTML = userPromptAssetNamesString;
    userPromptAssetTypeListDataList.innerHTML = userPromptAssetTypeString;
    userPromptAssetInvestedDataList.innerHTML = userPromptAssetInvestedString;
    userPromptAssetProfitDataList.innerHTML = userPromptAssetProfitString;  
    userPromptAssetWinRateListDataList.innerHTML = userPromptAssetWinRateString;
    userPromptAssetWinsDataList.innerHTML = userPromptAssetWinsString;
    userPromptAssetLossesDataList.innerHTML = userPromptAssetLossestring;  
    userPromptAssetNumberOfTradesDataList.innerHTML = userPromptAssetNumberOfTradesString;  
    return {userAssetsProp: props, sortStrategy: sortStrategy, sortDirection: sortDirection}
}


render(){ 
    
    let userAssets = ''
    let userSortAssets = ''
    let userSortAssetsDirection = ''
    let userAssetsProp =  []
        
    let conditionalOptions = [
      {
        key: '(All)',
        text: '(All)',
        value: '(All)',
      },
      {
        key: 'Equals',
        text: 'Equals',
        value: 'Equals',
      },
      {
        key: 'Not Equals',
        text: 'Not Equals',
        value: 'Not Equals',   
      },
      {
        key: 'Greater Than',
        text: 'Greater Than',
        value: 'Greater Than',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: 'Less Than',
        text: 'Less Than',
        value: 'Less Than',
      },
      {
        key: 'Greater Than or Equal',
        text: 'Greater Than or Equal',
        value: 'Greater Than or Equal',
      },
      {
        key: 'Less Than or Equal',
        text: 'Less Than or Equal',
        value: 'Less Than or Equal',
      }
    ];


    let strategyTypesDropDownOptions = [
      {
        key: 'My Strategies',
        text: 'My Strategies',
        value: 'My Strategies',
      },
      {
        key: 'Always Buy 1',
        text: '(View) Always Buy 1',
        value: 'Always Buy 1',
      },
      {
        key: '$1 Investor',
        text: '(View) $1 Investor',
        value: '$1 Investor',   
      },
      {
        key: '$10 Investor',
        text: '(View) $10 Investor',
        value: '$10 Investor',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: '$100 Investor',
        text: '(View) $100 Investor',
        value: '$100 Investor',
      },
      {
        key: '$1000 Investor',
        text: '(View) $1000 Investor',
        value: '$1000 Investor',
      },
      {
        key: '$10000 Investor',
        text: '(View) $10000 Investor',
        value: '$10000 Investor',
      }
    ];


    return (
        <TestRunAndTickerRepoConsumer>
          {
          context => {      
            let headerColumnSpan = 9
            
            if(context != undefined && context.StateOfUserProfilePopup.userAssets != undefined && Object.keys(context.StateOfUserProfilePopup.userAssets).length > 0)
            {
              let userAssetDict = this.SetUserAssetsProp(context)
              userSortAssets = userAssetDict.sortStrategy
              userSortAssetsDirection = userAssetDict.sortDirection
              userAssetsProp = userAssetDict.userAssetsProp
            } 
            if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table')
            {
              headerColumnSpan = 10
            }
            else if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Assets Table')
            {
              headerColumnSpan = 13
            }
            
            userAssets = this.renderAssetsData(userAssetsProp, context)

              return (     this.AssetTableDataRows(context, conditionalOptions, userAssets)
              );     
          }
        }  
        </TestRunAndTickerRepoConsumer>
      ); 
}
}
export default AssetsTable