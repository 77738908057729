import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import  {  BrowserRouter, Link, Route, Routes  }  from  'react-router-dom'
import App from './App.js';
// import cube from 'images/cube.png'; // with import
import MenuNavbar from "./components/NavBar.js";
import './styles/launchPage.sass'
import './styles/ConstellationButton.css'
import './styles/TutorialButton.css'
import './styles/SignInButton.css'

import { TestRunAndTickerRepoConsumer } from "./components/TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./components/TestRunAndTickerRepository.js";
//TODO: Change TestRunAndTickerRepo to TestRunAndTickerContext
import { TestRunAndTickerRepoContextProvider } from './components/TestRunAndTickerRepository.js'
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

var contextType = TestRunAndTickerRepoContext;
//const orderTestRunIdsBy = ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average']

ReactDOM.render(  
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <TestRunAndTickerRepoContextProvider>
    <BrowserRouter basename={"/var/www/html/build"}>
      <Auth0Provider
        domain="dev-3d529kxe.us.auth0.com"
        clientId="Z7wEyERjA8JLIb3JCzWCtewTFxXI5MgP"
        redirectUri={window.location.origin}
        audience="https://dev-3d529kxe.us.auth0.com/api/v2/"
        scope="read:current_user update:current_user_metadata"
      >
        <ShowLandingPage context={TestRunAndTickerRepoContextProvider} />
      </Auth0Provider>
    </BrowserRouter>
  </TestRunAndTickerRepoContextProvider>,
  document.getElementById('root')
);



function ShowLandingPage(context) {  
  let testRunAndTickerConsumerContext = context.context.contextType._currentValue;
  const [landingPageShowingStart, setLandingPageShowingStart] = useState(true)
  const [landingPageShowingTutorial, setlandingPageShowingTutorial] = useState(false)
  const [landingPageShowingConstellation, setlandingPageShowingConstellation] = useState(false)
  const [landingPageShowingSignIn, setlandingPageShowingSignIn] = useState(false)

  const HandleConstellationButtonMouseClick = (event) => {       
    setLandingPageShowingStart(landingPageShowingStart => landingPageShowingStart = false)
    setlandingPageShowingTutorial(landingPageShowingTutorial => landingPageShowingTutorial = false)
    setlandingPageShowingSignIn(landingPageShowingSignIn => landingPageShowingSignIn = false)
    setlandingPageShowingConstellation(landingPageShowingConstellation => landingPageShowingConstellation = true)
  }  

  const HandleTutorialMouseClick = (event) => {      
    setLandingPageShowingStart(landingPageShowingStart => landingPageShowingStart = false)
    setlandingPageShowingConstellation(landingPageShowingConstellation => landingPageShowingConstellation = false)
    setlandingPageShowingSignIn(landingPageShowingSignIn => landingPageShowingSignIn = false)
    setlandingPageShowingTutorial(landingPageShowingTutorial => landingPageShowingTutorial = true)
    testRunAndTickerConsumerContext.setStateLandingPageShowingTutorial(true)
  } 

  const HandleSignInMouseClick = (event) => {       
    setLandingPageShowingStart(landingPageShowingStart => landingPageShowingStart = false)
    setlandingPageShowingTutorial(landingPageShowingTutorial => landingPageShowingTutorial = false)
    setlandingPageShowingConstellation(landingPageShowingConstellation => landingPageShowingConstellation = false)
    setlandingPageShowingSignIn(landingPageShowingSignIn => landingPageShowingSignIn = true)
  } 

  const { loginWithRedirect, user, isAuthenticated, isLoading  } = useAuth0();  


//  document.onmouseup = HandleLandingPageMouseClick;

  if (landingPageShowingStart && !isAuthenticated) {
    return    ( 
    <div className='LandingPageStyle'>
    <link href='https://fonts.googleapis.com/css?family=Lato:300,400,700' rel='stylesheet' type='text/css'/>
    <div id="stars"></div>
    <div id="stars2"></div> 
    <div id="stars3"></div>
    <div id="introShootingStars"></div>
    <div id="randomShootingStarBottomLeft"></div> 
    <div id="randomShootingStarBottomRight"></div>
    <div id="randomShootingStarTopRight"></div>
    <div id="randomShootingStarTopLeft"></div> 
    <div id="title">
        <div class="title-fade-in">
          <span>
              Neural Trading Network
          </span>
        </div>
      <br />
        <div class="subtitle-fade-in">
          <subTitleText>
            Automatic Stock and Crypto Trading
          </subTitleText>
        </div>
        <div class="sub-intro-message-fade-in">
          <subIntroMessage>
             Click To Continue
          </subIntroMessage>
        </div>
        <div className='Constellation-Button'>
          <div class="ConstellationButton" onClick={() => { HandleConstellationButtonMouseClick() }}>  
              <span2 >
                  Constellation
                </span2>
                  <em> </em> 
                  <span1>                  
                    (Main Page)
                  </span1>              
          </div>    
          <div class="TutorialButton" onClick={() => { HandleTutorialMouseClick(context) }}>  
              <span2 >
                  Tutorial
                </span2>
                  <em> </em> 
                  <span1>                  
                    (90 Seconds)
                  </span1>  
          </div>
          <div class="SignInButton" onClick={() => 
              {
                loginWithRedirect()
                console.log('Successfully Logged In')
                //RedirectToAlpacaAuthorization()
                console.log('Successfully redirected')
              }
              }>  
              <span2>Sign In / Sign Up</span2>
                  <em> </em> 
                  <span1>                  
                    (Requires Access token)
                  </span1>  
          </div>
          </div>      
      <br />
    </div>
    </div>);
  }
  else if(landingPageShowingTutorial)
  {
    return    ( 
      <div className='LandingPageStyle'>
      <link href='https://fonts.googleapis.com/css?family=Lato:300,400,700' rel='stylesheet' type='text/css'/>
      <div id="clear_stars1"></div>
      <div id="clear_stars2"></div> 
      <div id="clear_stars3"></div>
      <div id="title">
          <div class="title-fade-out">
            <span>
                Neural Trading Network
            </span>
          </div>
        <br />
          <div class="subtitle-fade-out">
            <subTitleText>
              Automatic Stock and Crypto Trading
            </subTitleText>
          </div>
          <div class="sub-intro-message-fade-out">
            <subIntroMessage>
               
            </subIntroMessage>
          </div>
        <br />
      </div>      
      <div className='TutorialBackground'>
        <App />
      </div>
      </div>);
  }
  return (
  <div className='Constellation'>
      <div className='TopMenuBar'>
        <MenuNavbar  />
      </div>
      <div id="clear_stars1"></div>
      <div id="clear_stars2"></div> 
      <div id="clear_stars3"></div>
      <div id="starStoppedSize1"></div>
      <div id="starStoppedSize2"></div>
      <div id="starStoppedSize3"></div>
      <div className='ConstellationBackground'>
        <App user={user} />
      </div>
  </div>);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
