import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import React, { useState } from "react";
import { MDBContainer, MDBCard, MDBCardText, MDBCardFooter, MDBCardTitle, MDBCardBody, MDBCardHeader} from "mdbreact";
import TableExampleApprove from './TableExampleApprove';
import TextBoxInput from './TextBoxInput';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import UserChartsPrompt from './UserChartsPrompt';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Slider } from '@material-ui/core';
import { Item } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from '@date-io/dayjs';




const StrategyConfigurationPrompt = (props) =>
{
    const [selectedDate, handleDateChange] = useState(new Date("2018-01-01T00:00:00.000Z"));

    const useStyles = makeStyles({
        root: {
          "& .MuiInputBase-root": {
            display: 'block',
            "& .MuiButtonBase-root": {
              display: 'block'
            },
            "& .MuiInputBase-input": {
              display: 'block',
            }
          }
        }
      });

      const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Must have Alpaca Brokerage account linked to live trade
        </Tooltip>
      );

      const classes = useStyles();

    return (
        <TestRunAndTickerRepoConsumer>
        {context => { 
                  return (     
                    <div className="StrategyConfigurationPrompt" width='90%'>         
                        <MDBContainer >
                            <MDBCard border="success" className="m-3" >
                                    <div className="StrategyConfigurationPromptText" width='90%'>       
                                        <div className="ConfigureStrategyInputFields">
                                            <Grid container spacing={2} direction="column" alignItems="center">
                                                <Grid item xs container direction="column" spacing={2} alignItems="flex-start">
                                                    <Grid item xs={5} >
                                                        <Paper >Available Funds: ${context.StateOfUserProfilePopup.client_free_cash}</Paper>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={5} >
                                                    <div className="StrategyConfigurationPromptTextTitle">
                                                        <label htmlFor="formGroupExampleInput">Configure Strategy</label>
                                                    </div>
                                                </Grid>
                                                <Grid item xs direction="row" alignItems="center" >    
                                                    <Grid >                                              
                                                        <label id="Amount To Invest" for="FreeCashInput"> Amount To Invest: $ </label>
                                                        <input id="FreeCashInput" value={context.StateOfUserProfilePopup.ConfiguredInvestAmountValue} onChange={context.ConfiguredInputInvestValueChanged}></input>
                                                    </Grid>    
                                                </Grid>
                                                <Grid item container>                                                    
                                                    <Slider min={0} max={context.StateOfUserProfilePopup.client_free_cash} value={context.StateOfUserProfilePopup.ConfiguredInvestAmountValue} valueLabelDisplay="auto" onChange={context.ConfiguredSliderInvestAmountValueChanged}>Something</Slider>
                                                </Grid>
                                            </Grid> 
                                            <Grid container direction="row" alignItems="center" justifyContent="center" >
                                                <Grid md={4}>
                                                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                                                        <DateTimePicker className={classes.root}
                                                            label="Start Time"
                                                            value={context.StateOfUserProfilePopup.ConfiguredStartDateValue}
                                                            onChange={context.ConfiguredStartDateValueChanged}
                                                            // onChange={(date) => {
                                                            //     const dateString = Date(date);
                                                            //     const dateActual = new Date(date);
                                                            //     console.log(d);
                                                            //     // setDate(d);
                                                            //   }}
                                                            dateFormat="DDD D MMM, YYYY"
                                                            timeFormat="H:mm"
                                                            dateWheels="|DDD D MMM, YYYY|"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid >                                                    
                                                    <MuiPickersUtilsProvider utils={DayJsUtils}>
                                                        <DateTimePicker className={classes.root}
                                                            label="End Time"
                                                            value={context.StateOfUserProfilePopup.ConfiguredEndDateValue}
                                                            onChange={context.ConfiguredEndDateValueChanged}
                                                            dateFormat="DDD D MMM, YYYY"
                                                            timeFormat="H:mm"
                                                            dateWheels="|DDD D MMM, YYYY|"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid  >   
                                                    <div className='StockResultData'>
                                                                <Button color='Black' onClick={() => { context.HandleSimulateStrategyButtonClick() }}>Simulate</Button>
                                                    </div> 
                                                    <div className='StockResultData'>
                                                    <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                        >
                                                            <div>
                                                                <Button disabled color='Black' onClick={() => { context.HandleLiveTradeButtonClick() }}>Live Trade</Button>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>          
                                                </Grid>      
                                            </Grid>                                       
                                        </div>
                                    </div>
                            </MDBCard>
                        </MDBContainer>        
                    </div>   
                );  
              }
            }
            </TestRunAndTickerRepoConsumer>);
    
};
    
export default StrategyConfigurationPrompt;