import { dateTimePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import React from "react";

// Provider and Consumer are connected through their "parent" context
const TestRunAndTickerRepoContext = React.createContext();
const axios = require('axios');
// axios.defaults.baseURL = '//localhost:51728';  
axios.defaults.baseURL = '//neuraltradingnetwork.com';

class TestRunAndTickerRepoContextProvider extends React.Component {
    
    static orderTestRunIdsBy = ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average']

    static contextType = TestRunAndTickerRepoContext;
    
    // pointsPerDataset = 2000;
    maxRenderedPointsX = 800; 

    GetConsumer = () => {
      return (
        <TestRunAndTickerRepoConsumer>
          {context => {     
      
                 
          }}
        </TestRunAndTickerRepoConsumer>
      ); 
    }

    state =     
      {
        statePriority:
        {
          dataValues:  ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average'],
          comboBoxHeader: '',
          labelValue: 'Order Test Run By'
        },
        stateTestRun:
        {
          dataValues: [],
          comboBoxHeader: '',
          labelValue: 'Test Run Id'
        },
        stateTicker:
        {
          dataValues: [],
          comboBoxHeader: '',
          labelValue: 'Stock Result Data'
        },
        connectionType: "Prod",
        pointsArray: [],
        particalArray: [],        
        mousePoint: {x: 0, y: 0},
        showModalPopup: false,
        StateOfUserProfilePopup:
        {
          isPropDisplaying: false,
          alpaca_authorized: false,
          nameOfUser: "",
          userId: "",
          client_accrued_fees: 0.0,
          client_free_cash: 0.0,
          client_equity: 0.0,
          userStrategies: { },   
          userOrders: { },   
          currentStrategyAssetMetrics: [],                 
          StrategiesFilterVisible: false,
          ExtendedRowUserStrategyTestRunId: '',
          IsDecidingToInvest: false,
          ConfiguredInvestAmountValue: 0,
          ConfiguredStartDateValue:  new Date(),          
          ConfiguredEndDateValue:  new Date(),
          CurrentUserSelectedTable: 'Strategies Table',
          sortStrategyTestRunName: null,
          sortStartedInvestingDate: null,
          sortEndedInvestingDate: null,
          sortInvested: null,
          sortProfit: null,
          sortWinRate: null,
          sortStrategyType: null,
          sortPercentageOfWholeStrategy: null,
          sortIndicators: null,
          searchBoxUserStrategyTestRunName: '',
          searchBoxStartedInvestingDate: '',
          searchBoxEndedInvestingDate: '',
          searchBoxInvested: '',
          searchBoxProfit: '',
          searchBoxWinRate: '',
          searchBoxStrategyType: '',
          searchBoxPercentageOfWholeStrategy: '',
          searchBoxIndicators: '',
          operationForStartedInvestingDate: 'Greater Than or Equal',
          operationForEndedInvestingDate: 'Less Than or Equal',
          operationForInvested: 'Greater Than or Equal',
          operationForProfit: 'Greater Than or Equal',
          operationForWinRate: 'Greater Than or Equal',
          operationForStrategyType: 'Greater Than',
          operationForPercentageOfWholeStrategy: 'Greater Than or Equal',    
          operationForIndicators: 'Equals',    
          sortOrderName: null,
          sortOrderTestRunName: null,
          sortOrderTime: null,
          sortOrderType: null,
          sortOrderSymbol: null,
          sortOrderQuantity: null,
          sortOrderAveragePrice: null,
          sortOrderRequestedPrice: null,      
          sortOrderOrderTag: null,      
          sortOrderOrderProfit: null,      
          sortOrderOrderProfitPercentage: null,      
          sortOrderOrderDesiredProfit: null,      
          sortOrderOrderStopLoss: null,      
          searchBoxOrderName: '',
          searchBoxOrderTestRunName: '',
          searchBoxOrderTime: '',
          searchBoxOrderType: '',
          searchBoxOrderSymbol: '',
          searchBoxOrderQuantity: '',
          searchBoxOrderAveragePrice: '',
          searchBoxOrderRequestedPrice: '',
          searchBoxOrderTag: '',
          searchBoxOrderProfit: '',
          searchBoxOrderProfitPercentage: '',
          searchBoxOrderDesiredProfit: '',
          searchBoxOrderStopLoss: '',
          operationForOrderName: null,
          operationForOrderTestRunName: null,
          operationForOrderTime: null,
          operationForOrderType: null,
          operationForOrderSymbol: null,
          operationForOrderQuantity: null,
          operationForOrderAveragePrice: null,
          operationForOrderRequestedPrice: null,
          operationForOrderTag: null,
          operationForOrderProfit: null,
          operationForOrderProfitPercentage: null,
          operationForOrderDesiredProfit: null,
          operationForOrderStopLoss: null,
          searchBoxAssetName: '',
          searchBoxAssetType: '',
          searchBoxAssetInvested: '',
          searchBoxAssetProfit: '',
          searchBoxAssetWinRate: '',
          searchBoxAssetWins: '',
          searchBoxAssetLosses: '',
          searchBoxAssetNumberOfTrades: '',
          ExtendedRowUserAsset: '',
          operationForAssetName: 'Equals',
          operationForAssetType: 'Equals',
          operationForAssetInvested: 'Greater Than or Equal',
          operationForAssetProfit: 'Greater Than or Equal',
          operationForAssetWinRate: 'Greater Than or Equal',
          operationForAssetWins: 'Greater Than or Equal',
          operationForAssetLosses: 'Greater Than or Equal',
          operationForAssetNumberOfTrades: 'Greater Than or Equal',
          SelectedAsset: null,   
          userAssets: { },   
          currentAssetMetricsByStrategy: [],      
          searchBoxIndicatorName: '',
          searchBoxIndicatorType: '',
          searchBoxIndicatorInvested: '',
          searchBoxIndicatorProfit: '',
          searchBoxIndicatorWinRate: '',
          searchBoxIndicatorWins: '',
          searchBoxIndicatorLosses: '',
          searchBoxIndicatorNumberOfTrades: '',
          ExtendedRowUserIndicator: '',    
          operationForIndicatorName: 'Equals',
          operationForIndicatorType: 'Equals',
          operationForIndicatorInvested: 'Greater Than or Equal',
          operationForIndicatorProfit: 'Greater Than or Equal',
          operationForIndicatorWinRate: 'Greater Than or Equal',
          operationForIndicatorWins: 'Greater Than or Equal',
          operationForIndicatorLosses: 'Greater Than or Equal',
          operationForIndicatorNumberOfTrades: 'Greater Than or Equal',
          SelectedIndicator: null,
          userIndicators: { },       
          currentIndicatorMetricsByStrategy: [],  
          StrategyType: 'My Strategies',
          SelectedStrategy: null
        },
        StateOfCurrentStrategy: {
          testRunExecutionTracking: {
            data:[],
            xLabelExecutionTime:[]
          },
          filteredTestRunExecutionTracking: {
            data:[]
          },
        },
        StateOfPopup: { 
          isPropDisplaying: false,
          nameOfNode: "",
          currentPriority: 'Most Profitable',
          currentTestRunName: "",
          currentTickerName: "",
          mainPageHasRerendered: false,
          isCurrentTestRunAndTickerProfitable: true,
          currentProfit: "",
          currentTestRunProfit: "",
          currentTickerProfit: "",
          currentTestAverageProfit: "",
          currentTickerAverageProfit: "",
          currentTestWins: "",
          currentTestLosses: "",
          currentTickerWins: "",
          currentTickerLosses: "",
          currentTickerWinPercentage: "",
          currentAmountOfWins: "",
          currentAmountOfLosses: "",
          currentDuration: "",          
          // testRunExecutionTracking: {
          //   doesExist: false,
          //   data: [],
          // },            
          macdBuySellSignals: [],
          rsiBuySellSignals: [],
          histogramMacdBuySellSignals: [],
          histogramMacdSignalDifference: [],
          buyOrderSignals: [],
          MacdAnnotations: [],          
          RsiAnnotations: [],
          HistogramMacdAnnotations: [],
          HistogramMacdSignalDifferenceAnnotations: [],
          SingleTrendRsiAnnotations: [],
          BuyOrderAnnotations: [],
          scrollX: 0,
        },
        needToInitialize: true,
        userMetaData:  {
          UserId: '', 
          alpaca_authorized: false, 
          alpaca_client_id: '', 
          client_available_funds: 0, 
          client_total_invested: 0, 
          client_total_profit: 0, 
          client_total_wins: 0, 
          client_total_losses: 0, 
          client_total_winrate: 0, 
          investing_active: false
        },
        showLanding: true,
        TestRunAndTickerDictionary: {},
        CurrentValues: [],
        SelectedTestRun: "",
        SelectedTicker: "",
        newLabelValues: [],
        priceDataList: [
          {
            label: 'SlowEma',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'orange',
            borderColor: 'orange',
            borderWidth: 2,
			hidden: true
          },
          {
            label: 'FastEma',
            data: [11, 22, 1, 3, 2, 5],
            backgroundColor: 'purple',
            borderColor: 'purple',
            borderWidth: 2,
			hidden: true
          },
          {
            label: 'Price',
            data: [47, 52, 67, 59, 80, 50],
            backgroundColor: 'blue',
            borderColor: 'green',
            borderWidth: 2
          }
        ],
        macdDataList: [
          {
            label: 'Macd',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 2
          },
          {
            label: 'Signal',
            data: [9, 16, 6, 3, 2, 5],
            backgroundColor: 'blue',
            borderColor: 'blue',
            borderWidth: 2
          }
        ],
        histogramMacdDataList: [
          {
            label: 'Macd',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: 'red',
            borderColor: 'red',
            borderWidth: 2
          },
          {
            label: 'Signal',
            data: [9, 16, 6, 3, 2, 5],
            backgroundColor: 'blue',
            borderColor: 'blue',
            borderWidth: 2
          }
        ],
        rsiDataList: [
          {
            label: 'RSI',
            data: [9, 16, 6, 3, 2, 5],
            backgroundColor: 'blue',
            borderColor: 'blue',
            borderWidth: 2
          }
        ],
        rsiAttributes: {
          overBoughtThreshold: 0,
          overSoldThreshold: 0,
        },
        singleTrendRsiDataList: [
          {
            label: 'RSI',
            data: [9, 16, 6, 3, 2, 5],
            backgroundColor: 'blue',
            borderColor: 'blue',
            borderWidth: 2
          }
        ],
        singleTrendRsiAttributes: {
          overBoughtThreshold: 0,
          overSoldThreshold: 0,
        },
        orderDataList: [{
            ticker: '',
            testRunId: '',
            orderType: '',
            orderFilledTime: '',
            orderFillPrice: '',
            OrderTag: 1
          }          
        ],
        currentXMin: 0,
        currentXMax: 0,    
        StateOfStrategyDataFilterTable: { 
          StrategiesFilterVisible: false,
          extendedRowTestRunData: ''
        },
        searchBoxTestRunName: '',
        searchBoxRating: '',
        searchBoxLifeTime: '',
        searchBoxProfit: '',
        searchBoxWinLossRate: '',
        searchBoxNumberOfTrades: 5,        
        operationForRating: 'Greater Than',
        operationForLifeTime: 'Greater Than',
        operationForProfit: 'Greater Than',
        operationForWinLossRate: 'Greater Than',
        operationForNumberOfTrades: 'Greater Than',
        sortTestRunName: null,
        sortRating: null,
        sortLifeTime: null,
        sortProfit: null,
        sortWinLossRate: null,
        sortNumberOfTrades: null, 
        landingPageShowingTutorial: false
    }

    async GetTickerData(currentTestRun, CurrentTicker) {
      //let getRequestTickerData = 'http://localhost:51722/api/values/6/' + currentTestRun + '/' + CurrentTicker;
      //let GetAllTestRunAndTickerOrderData = 'http://localhost:51722/api/values/7/' + currentTestRun + '/' + CurrentTicker;
      //let GetTestRunExecutionData = 'http://localhost:51722/api/values/8/' + currentTestRun;

      let urlString = ''
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      }

      let getRequestTickerData = urlString + '6/' + currentTestRun + '/' + CurrentTicker;
      // let GetAllTestRunAndTickerOrderData = urlString + '7/' + currentTestRun + '/' + CurrentTicker;
      let GetTestRunExecutionData = urlString + '8/' + currentTestRun;

         
      axios({
        method:'get',
        url: getRequestTickerData,
        //baseURL: 'https://archives.neuraltradingnetwork.com/',
      }) 
            .then(res => {
              this.state.newLabelValues = [] 
              this.state.macdBuySellSignals = []
              this.state.MacdAnnotations = []
              this.state.RsiAnnotations = []
              this.state.SingleTrendRsiAnnotations = []
              this.state.BuyOrderAnnotations = []

              this.state.priceDataList = [
                {
                  label: 'SlowEma',
                  data: [],
                  backgroundColor: 'orange',
                  borderColor: 'orange',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'FastEma',
                  data: [],
                  backgroundColor: 'purple',
                  borderColor: 'purple',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'Price',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'green',
                  borderWidth: 2
                }
              ]

              this.state.macdDataList = [
                {
                  label: 'Macd',
                  data: [],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  borderWidth: 2
                },
                {
                  label: 'Signal',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              this.state.rsiDataList = [                    
                {
                  label: 'RSI',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              this.state.histogramMacdDataList = [
                {
                  label: 'Macd',
                  data: [],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  borderWidth: 2
                },
                {
                  label: 'Signal',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              let tempLabelValues = []       
              let tempShortEmaValues = []    
              let tempLongEmaValues = []    
              let tempPriceValues = []    
              let tempMacdValues = []    
              let tempMacdFastValues = []    
              let tempMacdSlowValues = []    
              let tempSignalValues = []    

              let tempHistogramMacdValues = []    
              let tempHistogramMacdFastValues = []    
              let tempHistogramMacdSlowValues = []    
              let tempHistogramSignalValues = []  
              let tempHistogramMacdSignalDifference = []                
              let tempRsiValues = []      
              let rsiAttributeObject = {
                OverBoughtThreshold: 0, 
                OverSoldThreshold: 0};
              let tempBuySellSignalValues = []    
              let tempMacdBuySellSignalValues = []    
              let tempRsiBuySellSignalValues = []   
              let tempHistogramMacdBuySellSignalValues = []            
              let tempSingleTrendRsiValues = []    
              let tempSingleTrendRsiBuySellSignalValues = []
              let singleTrendRsiAttributeObject = {
                                              OverBoughtThreshold: 0, 
                                              OverSoldThreshold: 0};   

              let index = 0               

              for (let key in res.data) {     
                let testRunAndTickerValue = res.data[key];       
                let testRunAndTickerActiveIndicatorList = testRunAndTickerValue.ActiveIndicatorList;           
                tempLabelValues.push(testRunAndTickerValue.Modified);
                tempShortEmaValues.push(testRunAndTickerValue.ShortEmaValue);
                tempLongEmaValues.push(testRunAndTickerValue.LongEmaValue);
                tempPriceValues.push(testRunAndTickerValue.Price);

                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'MacdIndicator'))
                {
                    let macdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'MacdIndicator');
                    tempMacdValues.push(macdElement.MacdValue);
                    tempMacdFastValues.push(macdElement.Fast.CurrentValue);
                    tempMacdSlowValues.push(macdElement.Slow.CurrentValue);
                    tempSignalValues.push(macdElement.SignalValue.CurrentValue);
                    
                    if(macdElement.IsDesiredBuy)
                    {
                      tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'})
                    }
                    else if(macdElement.IsDesiredSell)
                    {
                      tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                    }
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'RsiIndicator'))
                {
                    let rsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'RsiIndicator')
                    tempRsiValues.push(rsiElement.RSI);       

                    rsiAttributeObject = {
                      OverBoughtThreshold: rsiElement.OverBoughtThreshold, 
                      OverSoldThreshold: rsiElement.OverSoldThreshold};          

                    if(rsiElement.IsDesiredBuy)
                    {
                      tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                    }
                    else if(rsiElement.IsDesiredSell)
                    {
                      tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                    }
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'HistogramMacdIndicator'))
                {
                    let histogramMacdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'HistogramMacdIndicator');                
                    tempHistogramMacdValues.push(histogramMacdElement.MacdValue);
                    tempHistogramMacdFastValues.push(histogramMacdElement.Fast.CurrentValue);
                    tempHistogramMacdSlowValues.push(histogramMacdElement.Slow.CurrentValue);
                    tempHistogramSignalValues.push(histogramMacdElement.SignalValue.CurrentValue);   
                    tempHistogramMacdSignalDifference.push(histogramMacdElement.DifferenceOfMacdAndSignal);     
                    
                    if(histogramMacdElement.IsDesiredBuy)
                    {
                      tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                    }
                    else if(histogramMacdElement.IsDesiredSell)
                    {
                      tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                    }
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'SingleTrendRsiIndicator'))
                {
                    let singleTrendRsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'SingleTrendRsiIndicator')
                    tempSingleTrendRsiValues.push(singleTrendRsiElement.RSI);       

                    singleTrendRsiAttributeObject = {
                      OverBoughtThreshold: singleTrendRsiElement.OverBoughtThreshold, 
                      OverSoldThreshold: singleTrendRsiElement.OverSoldThreshold};          

                    if(singleTrendRsiElement.IsDesiredBuy)
                    {
                      tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
                    }
                    else if(singleTrendRsiElement.IsDesiredSell)
                    {
                      tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
                    }
                }

                index++;
              }

              this.setStateOfNewSingleTrendRsiAttributesValues(singleTrendRsiAttributeObject);
              this.setStateOfNewRsiValues(tempRsiValues);
              this.setStateOfNewRsiAttributesValues(rsiAttributeObject);
              this.setStateOfNewLabelValues(tempLabelValues);
              this.setStateOfNewShortEmaValues(tempShortEmaValues);
              this.setStateOfNewLongEmaValues(tempLongEmaValues);
              this.setStateOfNewPriceValues(tempPriceValues);
              this.setStateOfNewMacdValues(tempMacdValues);    
              this.setStateOfNewMacdSignalValues(tempSignalValues);        
              this.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);    
              this.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);          
              this.setStateOfMacdBuySellSignals(tempMacdBuySellSignalValues);
              this.setStateOfHistogramMacdBuySellSignals(tempHistogramMacdBuySellSignalValues);            
              this.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);  
              this.setStateOfRsiBuySellSignals(tempRsiBuySellSignalValues);    
              this.setStateOfSingleTrendRsiBuySellSignals(tempSingleTrendRsiBuySellSignalValues);    
              this.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);    
        
              axios({
                method:'get',
                url: GetTestRunExecutionData,
                //baseURL: 'https://archives.neuraltradingnetwork.com/',
              }) 
              .then(res => {                         
                this.setTestRunExecutionTracking(res.data);
              });
            })
    }


    async GetOrderData(currentTestRun, CurrentTicker) {
          //let GetAllTestRunAndTickerOrderData = 'http://localhost:51722/api/values/7/' + currentTestRun + '/' + CurrentTicker;
          let urlString = ''
          if(this.state.connectionType == "Test")
          {
            urlString = 'api/values'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values'
          }
          // let GetAllTestRunAndTickerOrderData = urlString + '/7/' + currentTestRun + '/' + CurrentTicker;
          // axios({
          //   method:'get',
          //   url: GetAllTestRunAndTickerOrderData,
          //   //baseURL: 'https://archives.neuraltradingnetwork.com/',
          // }) 
          // .then(res => { 
          //   this.setOrderDataList(res.data);
          // });
    }

    ModifyUserIdBeforeSending(userId)
    {
      if(this.state.StateOfUserProfilePopup.StrategyType == 'My Strategies')
      {
        userId = 'G' + this.state.StateOfUserProfilePopup.userId
      }
      else if(this.state.StateOfUserProfilePopup.StrategyType != 'MainUser')
      {
        userId = this.state.StateOfUserProfilePopup.StrategyType
      }
      return userId
    }

    GetUrlPrefix()
    {
      let urlString = ''
      let userId = ''
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      }
      return urlString
    }

    GetAllTestRunAndTickerOrderDataUrl(currentTestRun)
    {
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending()
      
      let GetAllTestRunAndTickerOrderData = ''      
      if(userId == '')
      {
        GetAllTestRunAndTickerOrderData = urlString + '8/' + currentTestRun;
      }
      else
      {
        GetAllTestRunAndTickerOrderData = urlString + 'Users/AdjustEquityProfitData/' + userId + '/' + currentTestRun;
      }
      return GetAllTestRunAndTickerOrderData
    }

    async GetTestRunExecutionData(currentTestRun) {
      let GetAllTestRunAndTickerOrderData = this.GetAllTestRunAndTickerOrderDataUrl(currentTestRun)
      const {data} = await axios.get(GetAllTestRunAndTickerOrderData);
      this.setTestRunExecutionTracking(data);
    }
    
    async GetAssetMetricsExecutionData(currentAsset) {
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending()
      let GetAssetMetricsData = ''      
      if(userId == '')
      {
        GetAssetMetricsData = urlString + '8/' + currentAsset;
      }
      else
      {
        GetAssetMetricsData = urlString + 'Users/AssetMetrics/' + userId + '/' + currentAsset;
      }
      const {data} = await axios.get(GetAssetMetricsData);
      this.setAssetMetricsExecutionTracking(data);
    }
    
    async GetIndicatorMetricsExecutionData(currentIndicator) {
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending()
      let GetIndicatorMetricsData = ''      
      if(userId == '')
      {
        GetIndicatorMetricsData = urlString + '8/' + currentIndicator;
      }
      else
      {
        GetIndicatorMetricsData = urlString + 'Users/IndicatorMetrics/' + userId + '/' + currentIndicator;
      }
      const {data} = await axios.get(GetIndicatorMetricsData);
      this.setIndicatorMetricsExecutionTracking(data);
    }

    async GetAllTestRunData() {
      let urlString = ''
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      }
      //let GetAllTestRunAndTickerData = 'http://localhost:51722/api/values/4/';
      let GetAllTestRunAndTickerData = urlString + '4/';
      axios({
        method:'get',
        url: GetAllTestRunAndTickerData,
        //baseURL: 'https://archives.neuraltradingnetwork.com/',
      })
            .then(res => {
              this.state.newLabelValues = [] 
              this.state.macdBuySellSignals = []
              this.state.buyOrderSignals = []
              this.state.StateOfPopup.MacdAnnotations = []
              this.state.StateOfPopup.RsiAnnotations = []
              this.state.StateOfPopup.SingleTrendRsiAnnotations = []
              this.state.StateOfPopup.BuyOrderAnnotations = []
              
              this.state.priceDataList = [
                {
                  label: 'SlowEma',
                  data: [],
                  backgroundColor: 'orange',
                  borderColor: 'orange',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'FastEma',
                  data: [],
                  backgroundColor: 'purple',
                  borderColor: 'purple',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'Price',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'green',
                  borderWidth: 2
                }
              ]

              this.state.macdDataList = [
                {
                  label: 'Macd',
                  data: [],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  borderWidth: 2
                },
                {
                  label: 'Signal',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              this.state.rsiDataList = [                    
                {
                  label: 'RSI',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              let tempLabelValues = []       
              let tempShortEmaValues = []    
              let tempLongEmaValues = []    
              let tempPriceValues = []    

              let tempMacdValues = []    
              let tempMacdFastValues = []    
              let tempMacdSlowValues = []    
              let tempSignalValues = []    

              let tempRsiValues = []    

              let tempHistogramMacdValues = []
              let tempHistogramMacdFastValues = []
              let tempHistogramMacdSlowValues = []
              let tempHistogramSignalValues = []
              let tempHistogramMacdSignalDifference = []   

              let tempSingleTrendRsiValues = []    


              for (let key in res.data) {     
                let testRunAndTickerValue = res.data[key];       
                let testRunAndTickerActiveIndicatorList = testRunAndTickerValue.ActiveIndicatorList;      
                
                tempLabelValues.push(testRunAndTickerValue.Modified);
                tempShortEmaValues.push(testRunAndTickerValue.ShortEmaValue);
                tempLongEmaValues.push(testRunAndTickerValue.LongEmaValue);
                tempPriceValues.push(testRunAndTickerValue.Price);

                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'MacdIndicator'))
                {
                    let macdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'MacdIndicator');
                    tempMacdValues.push(macdElement.MacdValue);
                    tempMacdFastValues.push(macdElement.Fast.CurrentValue);
                    tempMacdSlowValues.push(macdElement.Slow.CurrentValue);
                    tempSignalValues.push(macdElement.SignalValue.CurrentValue);
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'RsiIndicator'))
                {
                    let rsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'RsiIndicator')
                    tempRsiValues.push(rsiElement.RSI);          
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'HistogramMacdIndicator'))
                {
                    let histogramMacdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'HistogramMacdIndicator');                  
                    tempHistogramMacdValues.push(histogramMacdElement.MacdValue);
                    tempHistogramMacdFastValues.push(histogramMacdElement.Fast.CurrentValue);
                    tempHistogramMacdSlowValues.push(histogramMacdElement.Slow.CurrentValue);
                    tempHistogramSignalValues.push(histogramMacdElement.SignalValue.CurrentValue);       
                    tempHistogramMacdSignalDifference.push(histogramMacdElement.DifferenceOfMacdAndSignal);      
                }
                if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'SingleTrendRsiIndicator'))
                {
                    let rsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'SingleTrendRsiIndicator')
                    tempSingleTrendRsiValues.push(rsiElement.RSI);          
                }
              }

              this.setStateOfNewLabelValues(tempLabelValues);
              this.setStateOfNewShortEmaValues(tempShortEmaValues);
              this.setStateOfNewLongEmaValues(tempLongEmaValues);
              this.setStateOfNewPriceValues(tempPriceValues);
              this.setStateOfNewMacdValues(tempMacdValues);
              this.setStateOfNewMacdSignalValues(tempSignalValues);
              this.setStateOfNewRsiValues(tempRsiValues);
              this.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);
              this.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);
              this.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);  
              this.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);
            })
    }


    CheckStrategyValidPerOperation (valueToCheck, thresholdValue, operation){      
      if(operation == 'Equals')
      {
        return valueToCheck == thresholdValue
      }
      if(operation == 'Not Equals')
      {
        return valueToCheck != thresholdValue
      }
      if(operation == 'Greater Than')
      {
        return valueToCheck > thresholdValue
      }
      if(operation == 'Less Than')
      {
        return valueToCheck < thresholdValue
      }
      if(operation == 'Greater Than or Equal')
      {
        return valueToCheck >= thresholdValue
      }
      if(operation == 'Less Than or Equal')
      {
        return valueToCheck <= thresholdValue
      }
      return true; //Default To return the row if no operations match
    }

    async GetTestRunData(testRunName, tickerName) {
      //let GetAllTestRunAndTickerData = 'http://localhost:51722/api/values/6/' + testRunName + '/' + tickerName;
      let urlString = ''
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      }
      let GetAllTestRunAndTickerData = urlString + '6/' + testRunName + '/' + tickerName;
      axios({
        method:'get',
        url: GetAllTestRunAndTickerData,
        //baseURL: 'https://archives.neuraltradingnetwork.com/',
      })
            .then(res => {
              this.newLabelValues = [] 

              this.priceDataList = [
                {
                  label: 'SlowEma',
                  data: [],
                  backgroundColor: 'orange',
                  borderColor: 'orange',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'FastEma',
                  data: [],
                  backgroundColor: 'purple',
                  borderColor: 'purple',
                  borderWidth: 2,
				  hidden: true
                },
                {
                  label: 'Price',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'green',
                  borderWidth: 2
                }
              ]

              this.macdDataList = [
                {
                  label: 'Macd',
                  data: [],
                  backgroundColor: 'red',
                  borderColor: 'red',
                  borderWidth: 2
                },
                {
                  label: 'Signal',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              
              this.rsiDataList = [                    
                {
                  label: 'RSI',
                  data: [],
                  backgroundColor: 'blue',
                  borderColor: 'blue',
                  borderWidth: 2
                }
              ]

              let tempLabelValues = []       
              let tempShortEmaValues = []    
              let tempLongEmaValues = []    
              let tempPriceValues = []    
              let tempMacdValues = []    
              let tempMacdFastValues = []    
              let tempMacdSlowValues = []    
              let tempSignalValues = []    
              let tempBuySellSignalValues = []
              let tempRsiValues = []    
              let index = 0


              for (let key in res.data) {     
                let testRunAndTickerValue = res.data[key];       
                let testRunAndTickerActiveIndicatorList = testRunAndTickerValue.ActiveIndicatorList;           
                tempLabelValues.push(testRunAndTickerValue.Modified);
                tempShortEmaValues.push(testRunAndTickerValue.ShortEmaValue);
                tempLongEmaValues.push(testRunAndTickerValue.LongEmaValue);
                tempPriceValues.push(testRunAndTickerValue.Price);
                tempMacdValues.push(testRunAndTickerActiveIndicatorList[0].MacdValue);
                tempMacdFastValues.push(testRunAndTickerActiveIndicatorList[0].Fast.CurrentValue);
                tempMacdSlowValues.push(testRunAndTickerActiveIndicatorList[0].Slow.CurrentValue);
                tempSignalValues.push(testRunAndTickerActiveIndicatorList[0].SignalValue.CurrentValue);
                tempRsiValues.push(testRunAndTickerActiveIndicatorList[1].RSI);
                

                for(let indicatorKey in testRunAndTickerValue.ActiveIndicatorList)
                {
                  let indicator = testRunAndTickerValue.ActiveIndicatorList[indicatorKey];
                  if(indicator.IsDesiredBuy)
                  {
                    tempBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'})
                  }
                  else if(indicator.IsDesiredSell)
                  {
                    tempBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified,  IndexOfSignal: index, SignalType: 'Sell'})
                  }
                }

                index++;
              }

              this.setStateOfNewLabelValues(tempLabelValues);
              this.setStateOfNewShortEmaValues(tempShortEmaValues);
              this.setStateOfNewLongEmaValues(tempLongEmaValues);
              this.setStateOfNewPriceValues(tempPriceValues);
              this.setStateOfNewMacdValues(tempMacdValues);
              this.setStateOfNewMacdSignalValues(tempSignalValues);
              this.setStateOfMacdBuySellSignals(tempBuySellSignalValues);
              this.setStateOfNewRsiValues(tempRsiValues);
            })
    }


    UpdateTestRunAndTickerDictionary (testRunAndTicker) {
      if(testRunAndTicker != '')
      {
        this.TestRunAndTickerDictionary = {};
        this.CurrentValues = [];

        if(this.landingPageShowingTutorial)
        {            
          let tempTutorialList = {};
          let tempFilteredTutorialList = {};
          for (let key in testRunAndTicker) { 
            if(testRunAndTicker[key].TestRunProfit > 0 && testRunAndTicker[key].TestRunWins > 10)
            {
              tempFilteredTutorialList[key] = testRunAndTicker[key];
            }

            for(let tickerDataInfo in testRunAndTicker[key].TickerDataDictionary) {     
              let tickerValue = testRunAndTicker[key].TickerDataDictionary[tickerDataInfo];
              // var tickerData = JSON.stringify(tickerValue);
              // console.log('UpdateTestRunAndTickerDictionary TickerData: ' + tickerData)
              if(!this.CurrentValues.includes(tickerValue))
              {
                this.CurrentValues = [...this.CurrentValues, tickerValue]  
              }        
            }
          }

          if(Object.keys(tempFilteredTutorialList).length > 5)
          {
            for (var i = 0; i <= 5; i++)
            {
              let testRunName = Object.keys(tempFilteredTutorialList)[Math.floor(Math.random() * Object.keys(tempFilteredTutorialList).length)]
              let testRunValue = tempFilteredTutorialList[testRunName]
              tempTutorialList[testRunName] = testRunValue
            }
          }
          testRunAndTicker = tempTutorialList
        }
        for (let key in testRunAndTicker) {         
          this.TestRunAndTickerDictionary[key] = testRunAndTicker[key];
          for(let tickerDataInfo in testRunAndTicker[key].TickerDataDictionary) {     

            let tickerValue = testRunAndTicker[key].TickerDataDictionary[tickerDataInfo];

            // var tickerData = JSON.stringify(tickerValue);
            // console.log('UpdateTestRunAndTickerDictionary TickerData: ' + tickerData)

            if(!this.CurrentValues.includes(tickerValue))
            {
              this.CurrentValues = [...this.CurrentValues, tickerValue]  
            }        
          }
        }
      
        this.setStateOfComponentTestRun( {dataValues: this.TestRunAndTickerDictionary} );
        this.setStateOfComponentTicker(Object.values(this.CurrentValues)); 
        this.setStateOfTestRunDictionary( testRunAndTicker );
      }
    }


    ResetUserPromptChartValues()
    {
      this.state.StateOfUserProfilePopup.sortStrategyTestRunName = null
      this.state.StateOfUserProfilePopup.sortStartedInvestingDate = null
      this.state.StateOfUserProfilePopup.sortEndedInvestingDate = null
      this.state.StateOfUserProfilePopup.sortInvested = null
      this.state.StateOfUserProfilePopup.sortProfit = null
      this.state.StateOfUserProfilePopup.sortWinRate = null
      this.state.StateOfUserProfilePopup.sortStrategyType = null
      this.state.StateOfUserProfilePopup.sortPercentageOfWholeStrategy = null
      this.state.StateOfUserProfilePopup.sortIndicators = null
      this.state.StateOfUserProfilePopup.searchBoxUserStrategyTestRunName = ''
      this.state.StateOfUserProfilePopup.searchBoxStartedInvestingDate = ''
      this.state.StateOfUserProfilePopup.searchBoxEndedInvestingDate = ''
      this.state.StateOfUserProfilePopup.searchBoxInvested = ''
      this.state.StateOfUserProfilePopup.searchBoxProfit = ''
      this.state.StateOfUserProfilePopup.searchBoxWinRate = ''
      this.state.StateOfUserProfilePopup.searchBoxStrategyType = ''
      this.state.StateOfUserProfilePopup.searchBoxPercentageOfWholeStrategy = ''
      this.state.StateOfUserProfilePopup.searchBoxIndicators = ''
      this.state.StateOfUserProfilePopup.operationForStartedInvestingDate = 'Greater Than or Equal'
      this.state.StateOfUserProfilePopup.operationForEndedInvestingDate = 'Less Than or Equal'
      this.state.StateOfUserProfilePopup.operationForInvested = 'Greater Than or Equal'
      this.state.StateOfUserProfilePopup.operationForProfit = 'Greater Than or Equal'
      this.state.StateOfUserProfilePopup.operationForWinRate = 'Greater Than or Equal'
      this.state.StateOfUserProfilePopup.operationForPercentageOfWholeStrategy = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForIndicators = 'Equals'   
      this.state.StateOfUserProfilePopup.sortOrderName = null
      this.state.StateOfUserProfilePopup.sortOrderTestRunName = null
      this.state.StateOfUserProfilePopup.sortOrderTime = null
      this.state.StateOfUserProfilePopup.sortOrderType = null
      this.state.StateOfUserProfilePopup.sortOrderSymbol = null
      this.state.StateOfUserProfilePopup.sortOrderQuantity = null
      this.state.StateOfUserProfilePopup.sortOrderAveragePrice = null
      this.state.StateOfUserProfilePopup.sortOrderRequestedPrice = null     
      this.state.StateOfUserProfilePopup.sortOrderOrderTag = null    
      this.state.StateOfUserProfilePopup.sortOrderOrderProfit = null    
      this.state.StateOfUserProfilePopup.sortOrderOrderProfitPercentage = null    
      this.state.StateOfUserProfilePopup.sortOrderOrderDesiredProfit = null    
      this.state.StateOfUserProfilePopup.sortOrderOrderStopLoss = null    
      this.state.StateOfUserProfilePopup.searchBoxOrderName = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderTestRunName = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderTime = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderType = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderSymbol = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderQuantity = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderAveragePrice = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderRequestedPrice = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderTag = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderProfit = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderProfitPercentage = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderDesiredProfit = ''
      this.state.StateOfUserProfilePopup.searchBoxOrderStopLoss = ''
      this.state.StateOfUserProfilePopup.operationForOrderName = null
      this.state.StateOfUserProfilePopup.operationForOrderTestRunName = null
      this.state.StateOfUserProfilePopup.operationForOrderTime = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderType = null
      this.state.StateOfUserProfilePopup.operationForOrderSymbol = null
      this.state.StateOfUserProfilePopup.operationForOrderQuantity = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderAveragePrice = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderRequestedPrice = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderTag = null
      this.state.StateOfUserProfilePopup.operationForOrderProfit = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderProfitPercentage = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderDesiredProfit = 'Greater Than or Equal'   
      this.state.StateOfUserProfilePopup.operationForOrderStopLoss = 'Greater Than or Equal'   
      this.setState(this.state.StateOfUserProfilePopup)
    }


    UpdateCurrentValues = (key) => {
      //Remove current values from call
      if(key.length === 0)
      {
        this.UpdateTestRunAndTickerDictionary(this.state.TestRunAndTickerDictionary)
      }
      else
      {
        this.CurrentValues = this.state.TestRunAndTickerDictionary[key].TickerDataDictionary;
        this.setStateOfComponentTicker(Object.values(this.CurrentValues));
      }
    }

    GetTestRunAndTickerDictionary () {
      return this.state.TestRunAndTickerDictionary;
    }

    GetCurrentValues() {
      return this.state.CurrentValues;
    }

    setStateOfComponentPriority  = (valueOfComponent) => {    
      this.setState({ statePriority: { ...this.state.statePriority, dataValues: valueOfComponent.dataValues}});
    }

    setStateOfComponentTestRun = (valueOfComponent) =>  {    
      let fianlTestRunName = [];
      for( var tickerKeyData in valueOfComponent.dataValues)
      {
        if(this.state.StateOfPopup.currentPriority == 'Most Profitable' || this.state.StateOfPopup.currentPriority == 'Least Profitable')
        {
          let tickerDetailedData = valueOfComponent.dataValues[tickerKeyData];
          let modifiedProfitFormat = Math.abs((Math.round(tickerDetailedData.TestRunProfit * 100) / 100).toFixed(2))
  
          if(tickerDetailedData.TestRunProfit > 0)
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' +$' + modifiedProfitFormat);
          }
          else
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' -$' + modifiedProfitFormat);
          }
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Win Rate' || this.state.StateOfPopup.currentPriority == 'Lowest Win Rate')
        {
          let tickerDetailedData = valueOfComponent.dataValues[tickerKeyData];
          let modifiedWinLossFormatFormat = 0;
          let removeDecimalWinLossFormat = '';
          modifiedWinLossFormatFormat = Math.abs((Math.round(tickerDetailedData.WinLossRate * 100) / 100)).toFixed(2);
          if(tickerDetailedData.WinLossRate == 1)
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('.', '');
          }
          else if(tickerDetailedData.WinLossRate < .1)
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.0', '');
          }
          else
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.', '');
          }
          
          if(tickerDetailedData.WinLossRate > .5)
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' : ' + removeDecimalWinLossFormat + '%');
          }
          else
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' : ' + removeDecimalWinLossFormat + '%');
          }
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Winning Average' || this.state.StateOfPopup.currentPriority == 'Highest Losing Average')
        {
          let tickerDetailedData = valueOfComponent.dataValues[tickerKeyData];
          let modifiedProfitFormat = Math.abs((Math.round(tickerDetailedData.TestRunAverageProfit * 100) / 100).toFixed(2))
  
          if(tickerDetailedData.TestRunAverageProfit > 0)
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' +$' + modifiedProfitFormat);
          }
          else
          {
            fianlTestRunName.push(tickerDetailedData.TestRunId + ' -$' + modifiedProfitFormat);
          }
        }
      }
      
      let mostPriorityTestData = '';
      let durationString = '';
      if(this.state.StateOfPopup.currentTestRunName == '')
      {
          mostPriorityTestData = Object.values(valueOfComponent.dataValues)[0];
      }
      else
      {
          //Do a key look up vs a list search
          mostPriorityTestData = this.state.TestRunAndTickerDictionary[this.state.StateOfPopup.currentTestRunName];
      }

      if(mostPriorityTestData != undefined)
      {
      this.state.StateOfPopup.currentTestRunProfit = mostPriorityTestData.TestRunProfit.toFixed(2);
      this.state.StateOfPopup.currentTestRunWins = mostPriorityTestData.TestRunWins;
      this.state.StateOfPopup.currentTestRunLosses = mostPriorityTestData.TestRunLosses;
      this.state.StateOfPopup.currentTestRunWinPercentage = mostPriorityTestData.WinLossRate.toFixed(3);

      if(mostPriorityTestData.TotalExecutionTime <= 60){
          durationString = (mostPriorityTestData.TotalExecutionTime).toFixed(0) + ' seconds'             
        } else if(mostPriorityTestData.TotalExecutionTime <= 3600){
          durationString = (mostPriorityTestData.TotalExecutionTime  / 60).toFixed(0) + ' minutes'        
        } else if(mostPriorityTestData.TotalExecutionTime <= 86400){
          durationString = (mostPriorityTestData.TotalExecutionTime / 60 / 60).toFixed(0) + ' hours'
        } else{
          durationString = (mostPriorityTestData.TotalExecutionTime  / 60 / 60 / 24).toFixed(0) + ' days'        
        }
      }
      
      this.state.StateOfPopup.currentDuration = durationString;

      this.setState(this.state.StateOfPopup);      
      this.setState({ stateTestRun: { ...this.state.stateTestRun, dataValues: fianlTestRunName}});      
    }


    setStateOfComponentTicker = (valueOfComponent, calledFrom) =>  {    
      var tickerList = [];
      for (var tickerDataKey in valueOfComponent)
      {        
        
        if(this.state.StateOfPopup.currentPriority == 'Most Profitable' || this.state.StateOfPopup.currentPriority == 'Least Profitable')
        {
          let testRunAndTickerValue = valueOfComponent[tickerDataKey];  
          let modifiedProfitFormat = Math.abs((Math.round(testRunAndTickerValue.TickerProfits * 100) / 100)).toFixed(2)

          if(testRunAndTickerValue.TickerProfits > 0)
          {
            if(!tickerList.includes(testRunAndTickerValue.Ticker))
            {
              tickerList.push(testRunAndTickerValue.Ticker + ' +$' + modifiedProfitFormat + ' /stock');
            }  
          }
          else
          {
            if(!tickerList.includes(testRunAndTickerValue.Ticker))
            {
              tickerList.push(testRunAndTickerValue.Ticker + ' -$' + modifiedProfitFormat + ' /stock');
            }  
          }
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Win Rate' || this.state.StateOfPopup.currentPriority == 'Lowest Win Rate')
        {
          let tickerDetailedData = valueOfComponent[tickerDataKey];
          let modifiedWinLossFormatFormat = 0;
          let removeDecimalWinLossFormat = '';
          modifiedWinLossFormatFormat = Math.abs((Math.round(tickerDetailedData.TickerWinLossRatio * 100) / 100)).toFixed(2);
          if(tickerDetailedData.TickerWinLossRatio == 1)
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('.', '');
          }
          else if(tickerDetailedData.TickerWinLossRatio < .1)
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.0', '');
          }
          else
          {
            removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.', '');
          }
          
          if(tickerDetailedData.TickerWinLossRatio > .5)
          {
            tickerList.push(tickerDetailedData.Ticker + ' : ' + removeDecimalWinLossFormat + '%');
          }
          else
          {
            tickerList.push(tickerDetailedData.Ticker + ' : ' + removeDecimalWinLossFormat + '%');
          }
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Winning Average' || this.state.StateOfPopup.currentPriority == 'Highest Losing Average')
        {
          let tickerDetailedData = valueOfComponent[tickerDataKey];
          let modifiedProfitFormat = Math.abs((Math.round(tickerDetailedData.TickerAverageProfit * 100) / 100).toFixed(2))
  
          if(tickerDetailedData.TickerAverageProfit > 0)
          {
            tickerList.push(tickerDetailedData.Ticker + ' +$' + modifiedProfitFormat  + ' /Stock Avg.');
          }
          else
          {
            tickerList.push(tickerDetailedData.Ticker + ' -$' + modifiedProfitFormat + ' /Stock Avg.');
          }
        }
      }

      if(calledFrom === undefined && valueOfComponent.length > 0)
      {
        this.state.StateOfPopup.currentTickerProfit = valueOfComponent[0].TickerProfits.toFixed(2);
        this.state.StateOfPopup.currentTickerWins = valueOfComponent[0].TickerWins;
        this.state.StateOfPopup.currentTickerLosses = valueOfComponent[0].TickerLosses;
        this.state.StateOfPopup.currentTickerWinPercentage = valueOfComponent[0].TickerWinLossRatio.toFixed(3);
      }
      else
      {
        this.state.StateOfPopup.currentTickerName = '';
        this.state.StateOfPopup.currentTickerProfit = '';
        this.state.StateOfPopup.currentTickerWins = '';
        this.state.StateOfPopup.currentTickerLosses = '';
        this.state.StateOfPopup.currentTickerWinPercentage = '';
      }

        
      this.setState(this.state.StateOfPopup);      
      //console.log('tickerList.length: ' + tickerList.length);
      this.setState({ stateTicker: { ...this.state.stateTicker, dataValues: tickerList}});      
    }

    setStateOfTestRunDictionary = (valueOfComponent) =>  {    
      this.setState({ TestRunAndTickerDictionary: valueOfComponent});    
    }
    
    setStateOfNewLabelValues = (valueOfComponent) =>  {    
      this.setState({ newLabelValues: valueOfComponent});

      this.setStateXAxis(valueOfComponent);  
    }
    
    setStateOfNewShortEmaValues = (valueOfComponent) =>  {    
      let priceDataList = [...this.state.priceDataList];
      let shortEmaData = {...priceDataList[0]};
      shortEmaData.data = valueOfComponent;
      priceDataList[0] = shortEmaData;
      this.setState({priceDataList});
    }


    setStateOfNewLongEmaValues = (valueOfComponent) =>  {    
      let priceDataList = [...this.state.priceDataList];
      let longEmaData = {...priceDataList[1]};
      longEmaData.data = valueOfComponent;
      priceDataList[1] = longEmaData;
      this.setState({priceDataList});
    }

    setStateOfNewPriceValues = (valueOfComponent) =>  {    
      let priceDataList = [...this.state.priceDataList];
      let priceData = {...priceDataList[2]};
      priceData.data = valueOfComponent;
      priceDataList[2] = priceData;
      this.setState({priceDataList});
    }

    setStateOfNewMacdValues = (valueOfComponent) =>  {    
      let macdDataList = [...this.state.macdDataList];
      let macdData = {...macdDataList[0]};
      macdData.data = valueOfComponent;
      macdDataList[0] = macdData;
      this.setState({macdDataList});
    }

    setStateOfNewMacdSignalValues = (valueOfComponent) =>  {    
      let macdDataList = [...this.state.macdDataList];
      let signalData = {...macdDataList[1]};
      signalData.data = valueOfComponent;
      macdDataList[1] = signalData;
      this.setState({macdDataList});
    }

    setStateOfNewMacdSlowValues = (valueOfComponent) =>  {    
      this.setState({ newMacdSlowValues: valueOfComponent});
    }

    setStateOfPriceDataList = (valueOfComponent) =>  {    
      this.setState({ priceDataList: valueOfComponent});
    }

    setStateOfNewHistogramMacdValues = (valueOfComponent) =>  {    
      let histogramMacdDataList = [...this.state.histogramMacdDataList];
      let histogramMacdData = {...histogramMacdDataList[0]};
      histogramMacdData.data = valueOfComponent;
      histogramMacdDataList[0] = histogramMacdData;
      this.setState({histogramMacdDataList});
    }

    setStateOfNewHistogramMacdSignalValues = (valueOfComponent) =>  {    
      let histogramMacdDataList = [...this.state.histogramMacdDataList];
      let histogramMacdData = {...histogramMacdDataList[1]};
      histogramMacdData.data = valueOfComponent;
      histogramMacdDataList[1] = histogramMacdData;
      this.setState({histogramMacdDataList});
    }

    setStateOfNewRsiValues = (valueOfComponent) => {
      let rsiDataList = [...this.state.rsiDataList];
      let rsiData = {...rsiDataList[0]};
      rsiData.data = valueOfComponent;
      rsiDataList[0] = rsiData;
      this.setState({rsiDataList});
    }


    setStateOfNewSingleTrendRsiValues = (valueOfComponent) => {
      let singleTrendRsiDataList = [...this.state.singleTrendRsiDataList];
      let rsiData = {...singleTrendRsiDataList[0]};
      rsiData.data = valueOfComponent;
      singleTrendRsiDataList[0] = rsiData;
      this.setState({singleTrendRsiDataList});
    }

    
    setStateOfNewRsiAttributesValues = (valueOfComponent) => 
    {
      this.state.rsiAttributes.overBoughtThreshold = valueOfComponent.OverBoughtThreshold;
      this.state.rsiAttributes.overSoldThreshold = valueOfComponent.OverSoldThreshold;
      this.setState(this.state.rsiAttributes);
    }
    
    setStateOfNewSingleTrendRsiAttributesValues = (valueOfComponent) => 
    {
      this.state.singleTrendRsiAttributes.overBoughtThreshold = valueOfComponent.OverBoughtThreshold;
      this.state.singleTrendRsiAttributes.overSoldThreshold = valueOfComponent.OverSoldThreshold;
      this.setState(this.state.singleTrendRsiAttributes);
    }
    

    handlePriorityInputChange  = (event) => {
      var updateTickerBoard = false;
      if(event == null)
      {        
        this.state.StateOfPopup.currentPriority = 'Most Profitable';
      }
      else
      {
        this.state.StateOfPopup.currentPriority = event.target.textContent;
        updateTickerBoard = true;
      }
      this.setState(this.state.StateOfPopup);     

      let testRunData = Object.values(this.state.TestRunAndTickerDictionary);
      let sortedTestRunData = this.sortTestRunByCurrentPriority(Object.values(testRunData));      
      this.setStateOfComponentTestRun({dataValues: sortedTestRunData}); 

      let currentTickers = Object.values(this.state.TestRunAndTickerDictionary[this.state.StateOfPopup.currentTestRunName].TickerDataDictionary);
      let sortedCurrentTickers = this.sortTickersInTestRunByCurrentPriority(currentTickers);

      if(updateTickerBoard)
      {
        this.setStateOfComponentTicker(sortedCurrentTickers, 'PriorityChanged');
      }
      else
      {        
        this.setStateOfComponentTicker(sortedCurrentTickers);
      }
    }


    sortTickersInTestRunByCurrentPriority = (tickerData) => {
        if(tickerData.length >= 2)
        {
          if(this.state.StateOfPopup.currentPriority == 'Most Profitable')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return b.TickerProfits - a.TickerProfits});
              return sortedByMostProfitable;
          }
          else if(this.state.StateOfPopup.currentPriority == 'Least Profitable')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return a.TickerProfits - b.TickerProfits});
              return sortedByMostProfitable;
          }
          else if(this.state.StateOfPopup.currentPriority == 'Highest Win Rate')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return b.TickerWinLossRatio - a.TickerWinLossRatio});
              return sortedByMostProfitable;
          }
          else if(this.state.StateOfPopup.currentPriority == 'Lowest Win Rate')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return a.TickerWinLossRatio - b.TickerWinLossRatio});
              return sortedByMostProfitable;
          }
          else if(this.state.StateOfPopup.currentPriority == 'Highest Winning Average')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return b.TickerAverageProfit - a.TickerAverageProfit});
              return sortedByMostProfitable;
          }
          else if(this.state.StateOfPopup.currentPriority == 'Highest Losing Average')
          {
              let sortedByMostProfitable = tickerData.sort(function(a, b){return a.TickerAverageProfit - b.TickerAverageProfit});
              return sortedByMostProfitable;
          }
        }
        
        return tickerData;
    } 


    sortTestRunByCurrentPriority = (testRunData) => {
      if(testRunData.length >= 2)
      {
        if(this.state.StateOfPopup.currentPriority == 'Most Profitable')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return b.TestRunProfit - a.TestRunProfit});
            return sortedByMostProfitable;
        }
        else if(this.state.StateOfPopup.currentPriority == 'Least Profitable')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return a.TestRunProfit - b.TestRunProfit});
            return sortedByMostProfitable;
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Win Rate')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return b.WinLossRate - a.WinLossRate});
            return sortedByMostProfitable;
        }
        else if(this.state.StateOfPopup.currentPriority == 'Lowest Win Rate')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return a.WinLossRate - b.WinLossRate});
            return sortedByMostProfitable;
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Winning Average')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return b.TestRunAverageProfit - a.TestRunAverageProfit});
            return sortedByMostProfitable;
        }
        else if(this.state.StateOfPopup.currentPriority == 'Highest Losing Average')
        {
            let sortedByMostProfitable = testRunData.sort(function(a, b){return a.TestRunAverageProfit - b.TestRunAverageProfit});
            return sortedByMostProfitable;
        }
      }
      
      return testRunData;
  } 
    

    handleTestRunInputChange = (event) => {
      //console.log('handleTestRunInputChange');
      let currentTickers = []
      let currentTestRun = ''
      let currentTestRunDuration = '';
      if(event == undefined)
      {        
        currentTestRun = this.state.StateOfPopup.currentTestRunName;
        if(currentTestRun !== undefined)
        {
          currentTickers = Object.values(this.state.TestRunAndTickerDictionary[currentTestRun].TickerDataDictionary);
          currentTestRunDuration = this.state.TestRunAndTickerDictionary[currentTestRun].TotalExecutionTime;
          this.setState({currentDuration: currentTestRunDuration})
          this.sortTestRunByCurrentPriority(this.state.TestRunAndTickerDictionary);
          this.sortTickersInTestRunByCurrentPriority(currentTickers);
          //console.log('handleTestRunInputChange undefined currentTestRun: ' + this.state.StateOfPopup.currentTestRunName + ' currentTickers: ' + this.state.StateOfPopup.currentTickerName);
          if(this.state.StateOfPopup.currentTickerWins != '')
          {
            this.setStateOfComponentTicker(Object.values(currentTickers));
          }
          else
          {
            this.setStateOfComponentTicker(Object.values(currentTickers), 'TestRunChanged');
          }
          this.setState({ SelectedTestRun: this.state.StateOfPopup.currentTestRunName});
          this.setTestRunAndTicker(this.state.StateOfPopup.currentTestRunName,  this.state.StateOfPopup.currentTickerName);
        }
        else
        {

          return;
        }
      }
      else if(event.target.value != undefined && event.target.textContent != '')
      {
        if(event.target.textContent.includes('$') || event.target.textContent.includes('%'))
        {
          let indexOfSpace =  event.target.textContent.indexOf(" ");
          currentTestRun = event.target.textContent.substring(0, indexOfSpace);
        }
        else
        {
          currentTestRun = event.target.textContent;
        }
        currentTickers = this.state.TestRunAndTickerDictionary[currentTestRun].TickerDataDictionary;
        currentTestRunDuration = this.state.TestRunAndTickerDictionary[currentTestRun].TotalExecutionTime;
        this.sortTestRunByCurrentPriority(this.state.TestRunAndTickerDictionary);
        this.setStateOfComponentTicker(Object.values(currentTickers), 'TestRunChanged');
        this.setState({ SelectedTestRun: currentTestRun});
        this.setState({currentDuration: currentTestRunDuration})
        this.setTestRunAndTicker(currentTestRun, '');
      }
      else
      {        
        if(event.target.value == undefined)
        {
          //console.log('handleTestRunInputChange event.target.textContent: Nothing ');
          this.sortTestRunByCurrentPriority(this.state.TestRunAndTickerDictionary);
          this.setStateOfComponentTicker(Object.values(currentTickers));
          this.setState({ SelectedTestRun: currentTestRun});
          this.setState({ SelectedTicker: ''});
          this.setState({currentDuration: ''})
          this.setTestRunAndTicker('', '');
        }

      }

      let testRunValues = this.state.TestRunAndTickerDictionary[currentTestRun];
      this.state.StateOfPopup.currentTestRunName = currentTestRun;
      this.state.StateOfPopup.currentTestRunProfit = testRunValues !== undefined ? testRunValues.TestRunProfit.toFixed(2) : 0;
      this.state.StateOfPopup.currentTestRunWins =  testRunValues !== undefined ? testRunValues.TestRunWins : 0;
      this.state.StateOfPopup.currentTestRunLosses =  testRunValues !== undefined ? testRunValues.TestRunLosses: 0;
      this.state.StateOfPopup.currentTestRunWinPercentage =  testRunValues !== undefined ? testRunValues.WinLossRate.toFixed(3) : 0;
      //console.log('handleTestRunInputChange ' + currentTestRun);  
    }


    handleTickerInputChange  = (event) => { 
      if(event == undefined)
      {      
        if(this.state.currentTestRunName != '')
        {
            let tickerDataValues = Object.values(this.state.TestRunAndTickerDictionary[this.state.currentTestRunName].TickerDataDictionary);
            let sortedTickerList = this.sortTickersInTestRunByCurrentPriority(tickerDataValues)
            let currentTickerDataValues = sortedTickerList[0];
            if(this.state.currentTickerName == '')
            {
              if (this.state.currentTestRunName != '')
              {

                //console.log('handleTickerInputChange ' + event + ' this.state.currentTestRunName: ' + this.state.currentTestRunName + ' event.target.textContent: ' + this.state.currentTickerName); 
                
                this.state.StateOfPopup.currentTickerName = '';//currentTickerDataValues.Ticker;
                this.state.StateOfPopup.currentTickerProfit = '';//currentTickerDataValues.TickerProfits.toFixed(2);
                this.state.StateOfPopup.currentTickerWins = '';//currentTickerDataValues.TickerWins;
                this.state.StateOfPopup.currentTickerLosses = '';//currentTickerDataValues.TickerLosses;
                this.state.StateOfPopup.currentTickerWinPercentage = '';//currentTickerDataValues.TickerWinLossRatio.toFixed(3);

                this.setState({ SelectedTicker: this.state.currentTickerName});
                this.setTestRunAndTicker(this.state.currentTestRunName, this.state.currentTickerName);  
                this.setState(this.state.StateOfPopup);
              } 
            }
            else
            {
              // this.state.StateOfPopup.currentTickerName = currentTickerDataValues.Ticker;
              // this.state.StateOfPopup.currentTickerProfit = currentTickerDataValues.TickerProfits.toFixed(2);
              // this.state.StateOfPopup.currentTickerWins = currentTickerDataValues.TickerWins;
              // this.state.StateOfPopup.currentTickerLosses = currentTickerDataValues.TickerLosses;
              // this.state.StateOfPopup.currentTickerWinPercentage = currentTickerDataValues.TickerWinLossRatio.toFixed(3);
              this.setState({ SelectedTicker: this.state.currentTickerName});
              this.setTestRunAndTicker(this.state.currentTestRunName, this.state.currentTickerName);
              //this.setState(this.state.StateOfPopup);
            }   
        }
      }
      else
      {      
            //console.log('handleTickerInputChange ' + event + ' this.state.currentTestRunName: ' + this.state.currentTestRunName + ' event.target.textContent: ' + event.target.textContent); 
            let finalCurrentTickerName = '';
            if(event.target.textContent.includes('$') || event.target.textContent.includes('%'))
            {
              let indexOfSpace =  event.target.textContent.indexOf(" ");
              finalCurrentTickerName = event.target.textContent.substring(0, indexOfSpace);
            }
            else
            {
              finalCurrentTickerName = event.target.textContent;
            }

            let tickerDataValues = this.state.TestRunAndTickerDictionary[this.state.currentTestRunName].TickerDataDictionary;

            if(finalCurrentTickerName != '')
            {
              let currentTickerDataValues = tickerDataValues[finalCurrentTickerName];
              this.state.StateOfPopup.currentTickerName = finalCurrentTickerName;
              this.state.StateOfPopup.currentTickerProfit = currentTickerDataValues.TickerProfits.toFixed(2);
              this.state.StateOfPopup.currentTickerWins = currentTickerDataValues.TickerWins;
              this.state.StateOfPopup.currentTickerLosses = currentTickerDataValues.TickerLosses;
              this.state.StateOfPopup.currentTickerWinPercentage = currentTickerDataValues.TickerWinLossRatio.toFixed(3);
            }
            else
            {
              this.state.StateOfPopup.currentTickerName = '';
              this.state.StateOfPopup.currentTickerProfit = '';
              this.state.StateOfPopup.currentTickerWins = '';
              this.state.StateOfPopup.currentTickerLosses = '';
              this.state.StateOfPopup.currentTickerWinPercentage = '';
            }


            this.setState({ SelectedTicker: finalCurrentTickerName});
            this.setTestRunAndTicker(this.state.currentTestRunName, finalCurrentTickerName);
            this.setState(this.state.StateOfPopup);
      }  
    }

    handleGetTestRunAndTickerButtonClick = (event) => {      
      if (this.state.SelectedTestRun.length != 0 && this.state.SelectedTicker.length != 0)
      {
        //console.log('handleGetTestRunAndTickerButtonClick this.state.SelectedTestRun: ' + this.state.SelectedTestRun)
        this.GetTestRunExecutionData(this.state.SelectedTestRun);
        this.GetTickerData(this.state.SelectedTestRun, this.state.SelectedTicker) ;

        this.state.StateOfPopup.nameOfNode = this.state.SelectedTestRun;
        this.setState(this.state.StateOfPopup);
      }
    }

    SetStateCurrentSelectedStrategy = (event, testRunid) => {
        this.GetTestRunExecutionData(testRunid)
        this.state.StateOfUserProfilePopup.SelectedStrategy = testRunid
        this.setState(this.state.StateOfUserProfilePopup);
    }

    SetStateCurrentSelectedAsset = (event, asset) => {
        //TODO: For Later Charting
        //this.GetAssetMetricsExecutionData(asset) 
        this.state.StateOfUserProfilePopup.SelectedAsset = asset
        this.setState(this.state.StateOfUserProfilePopup);
    }

    SetStateCurrentSelectedIndicator = (event, indicator) => {
      //TODO: For Later Charting
      //this.GetIndicatorMetricsExecutionData(asset) 
        this.state.StateOfUserProfilePopup.SelectedIndicator = indicator
        this.setState(this.state.StateOfUserProfilePopup);
    }

    handlePointArrayUpdated = (valueOfComponent) => {       
      this.state.pointsArray.push(valueOfComponent);
      this.setState(this.state.pointsArray);      
    }

    
    handleMousePointUpdated = (x, y) => {       
      this.state.mousePoint.x = x;
      this.state.mousePoint.y = y;
      //this.setState(this.state.mousePoint);      
    }


    setStateOfMacdDataList = (valueOfComponent) =>  {    
      this.setState({ macdDataList: valueOfComponent});
    }

    setStateOfRsiDataList = (valueOfComponent) =>  {    
      this.setState({ rsiDataList: valueOfComponent});
    }

    setStateOfSingleTrendRsiDataList = (valueOfComponent) =>  {    
      this.setState({ singleTrendRsiDataList: valueOfComponent});
    }


    showReport = () =>  {    
      let popupDisplay = {...this.state.StateOfPopup};                 
      this.state.StateOfPopup.isPropDisplaying = !popupDisplay.isPropDisplaying;
      this.setState(this.state.StateOfPopup);  

      //console.log('showReport2 ' + this.state.StateOfPopup.isPropDisplaying);  
    }


    setIsInitializing  = () => {
      this.setState({ needToInitialize: false});  
    }

    setShowLanding  = () => {
      this.setState({ showLanding: false});  
    }


    setCurrentNodeName = (nameOfNode) => {              
      this.state.StateOfPopup.nameOfNode = nameOfNode;
      this.setState(this.state.StateOfPopup);  

      //console.log('setCurrentNodeName ' + this.state.StateOfPopup.nameOfNode);  
    }


    setTestRunAndTicker = (testRun, ticker) => {          
      //console.log('setTestRunAndTicker testRun: ' + testRun + ' ticker: ' + ticker);  
      this.state.StateOfPopup.currentTestRunName = testRun;
      this.state.StateOfPopup.currentTickerName = ticker;
      this.setState(this.state.StateOfPopup);  

    }


    setMainPageHasRerendered = () => {          
      this.state.StateOfPopup.mainPageHasRerendered = !this.state.StateOfPopup.mainPageHasRerendered;
      this.setState(this.state.StateOfPopup);  

      //console.log('setRerenderRequired ' + this.state.StateOfPopup.mainPageHasRerendered);  
    }

    setIsCurrentTestRunAndTickerProfitable = () => {          
      this.state.StateOfPopup.isCurrentTestRunAndTickerProfitable = !this.state.StateOfPopup.isCurrentTestRunAndTickerProfitable;
      this.setState(this.state.StateOfPopup);  

      //console.log('setIsCurrentTestRunAndTickerProfitable ' + this.state.StateOfPopup.isCurrentTestRunAndTickerProfitable);  
    }

    setUserFunds = (valueOfComponenet) => {
      this.state.StateOfUserProfilePopup.client_accrued_fees = valueOfComponenet.client_accrued_fees;
      this.state.StateOfUserProfilePopup.client_equity = valueOfComponenet.client_equity;
      this.state.StateOfUserProfilePopup.client_free_cash = valueOfComponenet.client_free_cash;
      this.setState(this.state.StateOfUserProfilePopup); 
    }

    setUserProfilePopupDisplaying = () => {
      this.state.StateOfUserProfilePopup.isPropDisplaying = !this.state.StateOfUserProfilePopup.isPropDisplaying;
      this.setState(this.state.StateOfUserProfilePopup)
    }
    
    setUserName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.nameOfUser = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }
        
    setUserId = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.userId = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setUserAlpacaAuthorized = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.alpaca_authorized = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserStrategies = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.userStrategies = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserOrders = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.userOrders = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserAssets = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.userAssets = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }
    
    setStateUserIndicators = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.userIndicators = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup)
    }
    
    setOrderDataList = (updatedOrderDataList) => {
      this.setState({ orderDataList: updatedOrderDataList});     
      this.setStateBuyOrderSignals(updatedOrderDataList.AllRecordsList);
      // if(updatedOrderDataList.DurationOfTestRun != undefined)
      // {
      //   let durationArray = updatedOrderDataList.DurationOfTestRun.split(':');
      //   let durationString = '';
      //   if(durationArray.length == 0){
  
      //   } else if(durationArray.length == 1){
      //     durationString = durationArray[0] + ' seconds'             
      //   } else if(durationArray.length == 2){
      //     durationString = durationArray[0] + ' minutes'        
      //   } else if(durationArray.length == 3){
      //     durationString = durationArray[0] + ' hours'
      //   } else if(durationArray.length == 4){
      //     durationString = durationArray[0] + ' days'        
      //   }


      //   //this.state.StateOfPopup.currentProfit = updatedOrderDataList.profit.toFixed(7);
      //   //this.state.StateOfPopup.currentWinPercentage = updatedOrderDataList.winPercentage.toFixed(3);
      //   // this.state.StateOfPopup.currentTickerWins = updatedOrderDataList.amountOfWins;
      //   // this.state.StateOfPopup.currentTickerLosses = updatedOrderDataList.amountOfLosses;
      //   // this.state.StateOfPopup.currentDuration = durationString;
      //   this.setState(this.state.StateOfPopup);  

      // }


      //console.log('setOrderDataList ' + this.state.orderDataList);        
    }


    setTestRunExecutionTracking = (updatedTestRunExecutionTracking) => {

      let tempEquityList = []
      let tempProfitList = []
      // let tempProfitChangeList = []
      // let tempRateOfProfitChangeList = []
      let tempWinsList = []
      let tempLossesList= []
      let tempWinLossRateList = []
      

      let tempXLabels = []

      if(updatedTestRunExecutionTracking != undefined && updatedTestRunExecutionTracking.length > 0)
      {        
        // this.state.StateOfPopup.testRunExecutionTracking.doesExist = true;
        for (let key in updatedTestRunExecutionTracking) 
        {       
          let testRunExecutionValue = updatedTestRunExecutionTracking[key];         
                
          if(testRunExecutionValue.Profit > 10000)
          {
            let x = 0
          }      
          tempXLabels.push(testRunExecutionValue.ExecutionTime);        
          tempEquityList.push(testRunExecutionValue.Equity);   
          tempProfitList.push(testRunExecutionValue.Profit);   
          // tempProfitChangeList.push(testRunExecutionValue.ProfitChange);   
          // tempRateOfProfitChangeList.push(testRunExecutionValue.RateOfProfitChange);   
          tempWinsList.push(testRunExecutionValue.Wins);   
          tempLossesList.push(testRunExecutionValue.Losses);   
          tempWinLossRateList.push(testRunExecutionValue.WinLossRate);
        }
        
        // this.state.StateOfPopup.currentProfit =  (((tempEquityList[tempEquityList.length - 1] - tempEquityList[0]) / tempEquityList[0]) * 100).toFixed(3);
        // this.state.StateOfPopup.currentWinPercentage =  updatedTestRunExecutionTracking[updatedTestRunExecutionTracking.length - 1].WinLossRate.toFixed(3);        

      } 

      this.state.StateOfCurrentStrategy.testRunExecutionTracking.data =  [
      {
        label: 'Equity',
        data: tempEquityList,
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2,    
        hidden: false
      },
      {
        label: 'Profit',
        data: tempProfitList,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
        hidden: true
      },
      {
        label: 'Wins',
        data: tempWinsList,
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2,        
        hidden: true
      },
      {
        label: 'Losses',
        data: tempLossesList,
        backgroundColor: 'orange',
        borderColor: 'orange',
        borderWidth: 2,        
        hidden: true
      },
      {
        label: 'WinLossRate',
        data: tempWinLossRateList,
        backgroundColor: 'black',
        borderColor: 'black',
        borderWidth: 2,        
        hidden: true
      },
    ];

      this.state.StateOfCurrentStrategy.testRunExecutionTracking.xLabelExecutionTime = tempXLabels;
      
      this.setStateMaxMinYValues(this.state.StateOfCurrentStrategy.testRunExecutionTracking.data)
    }

    setStateMaxMinYValues = (updatedTestRunExecutionTracking) =>
    {
      if(updatedTestRunExecutionTracking != undefined && updatedTestRunExecutionTracking.length >= 5)
      {        
        this.state.StateOfCurrentStrategy.filteredTestRunExecutionTracking.data = updatedTestRunExecutionTracking;
      } 
      this.setState(this.state.StateOfCurrentStrategy);  
      this.setState(this.state.StateOfPopup);  
    }
    


    setStateOfMacdBuySellSignals = (buySellSignalValues) => 
    {
      this.state.StateOfPopup.macdBuySellSignals = buySellSignalValues;
      this.setState(this.state.StateOfPopup);  

      let annotationList = [];

      let tempDifferenceValues = [...buySellSignalValues];
      tempDifferenceValues.sort(function(a, b){return a - b});
      let minSignalValue = tempDifferenceValues[0];
      let maxSignalValue = tempDifferenceValues[tempDifferenceValues.length - 1];
      for(let entrySignalKey in buySellSignalValues)
      {
        let entrySignal = buySellSignalValues[entrySignalKey];

        if(entrySignal.SignalType == 'Buy')
        {
          annotationList.push(
            {
              // Indicates the type of annotation
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: minSignalValue,
              yMax: maxSignalValue,
              backgroundColor: 'rgba(102, 170, 78, 0.3)',
              borderWidth: 0
            });
        }
        else if(entrySignal.SignalType == 'Sell')
        {          
          annotationList.push(
            {
              // Indicates the type of annotation
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: minSignalValue,
              yMax: maxSignalValue,
              backgroundColor: 'rgba(215, 60, 100, 0.3)',
              borderWidth: 0
            });
        }
      }
      this.state.StateOfPopup.MacdAnnotations = annotationList;
      this.setState(this.state.StateOfPopup);  
    }

    UpdateChartHiddenDataSets = (chart) =>
    {
      // this.setState({currentXMin: valueOfXAxes.xMin});
      let x = 0
      for(let dataSetKey in this.state.StateOfCurrentStrategy.testRunExecutionTracking.data)
      {
        let dataSet = this.state.StateOfCurrentStrategy.testRunExecutionTracking.data[dataSetKey]
        let foundDataSet = chart.chart._metasets.find((currentChartData) => {
          return dataSet.label == currentChartData.label
        })
        if(foundDataSet != null)
        {
          dataSet.hidden = !foundDataSet.visible
        }
      }
    }
    

    setStateXAxis = (valueOfXAxes) =>
    {
      // this.state.currentXMin = valueOfXAxes.xMin;
      // this.state.currentXMax = valueOfXAxes.xMax;
      // this.setState(this.state.currentXMin); 
      // this.setState(this.state.currentXMax); 
      this.setState({currentXMin: valueOfXAxes.xMin});
      this.setState({currentXMax: valueOfXAxes.xMax});
    }

    setStateOfRsiBuySellSignals = (buySellSignalValues) => 
    {
      this.state.StateOfPopup.rsiBuySellSignals = buySellSignalValues;
      this.setState(this.state.StateOfPopup);  

      let annotationList = [];
      //let color = None;
      for(let entrySignalKey in buySellSignalValues)
      {
        let entrySignal = buySellSignalValues[entrySignalKey];

        if(entrySignal.SignalType == 'Buy')
        {
          annotationList.push(
            {
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: 0,
              yMax: 100,
              backgroundColor: 'rgba(102, 170, 78, 0.3)',
              borderWidth: 0
            });
        }
        else if(entrySignal.SignalType == 'Sell')
        {          
          annotationList.push(
            {
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: 0,
              yMax: 100,
              backgroundColor: 'rgba(215, 60, 100, 0.3)',
              borderWidth: 0
            });
        }
      }
      
      //Rsi overbought/oversold box
      annotationList.push({
        // Indicates the type of annotation
        type: 'box',
        yMin: this.state.rsiAttributes.overSoldThreshold,
        yMax: this.state.rsiAttributes.overBoughtThreshold,
        xMin: 0,
        xMax: this.state.newLabelValues.length-1,
        backgroundColor: 'rgba(70, 120, 248, 0.2)',
        borderWidth: 2
      });

      this.state.StateOfPopup.RsiAnnotations = annotationList;
      this.setState(this.state.StateOfPopup);  
    }


    setStateOfHistogramMacdBuySellSignals = (buySellSignalValues) => 
    {
      this.state.StateOfPopup.histogramMacdBuySellSignals = buySellSignalValues;

      let annotationList = [];

      let tempDifferenceValues = [...buySellSignalValues];
      tempDifferenceValues.sort(function(a, b){return a - b});
      let minSignalValue = tempDifferenceValues[0];
      let maxSignalValue = tempDifferenceValues[tempDifferenceValues.length - 1];

      for(let entrySignalKey in buySellSignalValues)
      {
        let entrySignal = buySellSignalValues[entrySignalKey];

        if(entrySignal.SignalType == 'Buy')
        {
          annotationList.push(
            {
              // Indicates the type of annotation
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: minSignalValue,
              yMax: maxSignalValue,
              backgroundColor: 'rgba(102, 170, 78, 0.55)',
              borderWidth: 0
            });
        }
        else if(entrySignal.SignalType == 'Sell')
        {          
          annotationList.push(
            {
              // Indicates the type of annotation
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: minSignalValue,
              yMax: maxSignalValue,
              backgroundColor: 'rgba(215, 60, 100, 0.4)',
              borderWidth: 0
            });
        }
      }
      this.state.StateOfPopup.HistogramMacdAnnotations = annotationList;
      this.setState(this.state.StateOfPopup); 
    }

    
    setStateOfNewHistogramMacdSignalDifference = (differenceValues) => 
    {
      //************************************************************************************************** */
      //TODO: MAKE THE LIGHTER SHADES THE WANING DIFFERENCES, AND THE DARKER SHADES THE INCREASING DIFFERENCES
      //************************************************************************************************** */
      this.state.StateOfPopup.histogramMacdSignalDifference = differenceValues;

      let annotationList = [];
      let index = 0;

      //Get the min and max differences from the sorted list
      let tempDifferenceValues = [...differenceValues];
      tempDifferenceValues.sort(function(a, b){return a - b});
      let minSignalValue = tempDifferenceValues[0];
      let maxSignalValue = tempDifferenceValues[tempDifferenceValues.length - 1];

      for(let entrySignalKey in differenceValues)
      {
        let entrySignal = differenceValues[entrySignalKey];
        let previousDifference = index > 0 ? differenceValues[entrySignalKey - 1] : entrySignal;

        if(entrySignal > 0)
        {
          let shadedColor = previousDifference < entrySignal ? 'rgba(0, 125, 0, 0.75)' : 'rgba(75, 210, 75, 0.55';
          annotationList.push(
            {
              // Indicates the type of annotation
              backgroundColor: shadedColor,
              type: 'box',
              xMin: index,
              xMax: index + 1,
              yMin: 0,
              yMax: entrySignal,
              borderWidth: 0,
            });
        }
        else if(entrySignal <= 0)
        {          
          let shadedColor = previousDifference > entrySignal ? 'rgba(204, 0, 75, 0.75)': 'rgba(225, 75, 90, 0.55)';
          annotationList.push(
            {
              // Indicates the type of annotation
              backgroundColor: shadedColor,
              type: 'box',
              xMin: index,
              xMax: index + 1,
              yMin: entrySignal,
              yMax: 0,
              borderWidth: 0,
            });
        }

        index++;
      }
      this.state.StateOfPopup.HistogramMacdSignalDifferenceAnnotations = annotationList;
      this.setState(this.state.StateOfPopup); 
    }


    setStateOfSingleTrendRsiBuySellSignals = (buySellSignalValues) => 
    {
      this.state.StateOfPopup.singleTrendRsiBuySellSignals = buySellSignalValues;

      let annotationList = [];
      //let color = None;
      for(let entrySignalKey in buySellSignalValues)
      {
        let entrySignal = buySellSignalValues[entrySignalKey];

        if(entrySignal.SignalType == 'Buy')
        {
          annotationList.push(
            {
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: 0,
              yMax: 100,
              backgroundColor: 'rgba(102, 170, 78, 0.3)',
              borderWidth: 0
            });
        }
        else if(entrySignal.SignalType == 'Sell')
        {          
          annotationList.push(
            {
              type: 'box',
              xMin: entrySignal.IndexOfSignal,
              xMax: entrySignal.IndexOfSignal + 1,
              yMin: 0,
              yMax: 100,
              backgroundColor: 'rgba(215, 60, 100, 0.3)',
              borderWidth: 0
            });
        }
      }
      
      //Rsi overbought/oversold box
      annotationList.push({
        // Indicates the type of annotation
        type: 'box',
        yMin: this.state.singleTrendRsiAttributes.overSoldThreshold,
        yMax: this.state.singleTrendRsiAttributes.overBoughtThreshold,
        xMin: 0,
        xMax: this.state.newLabelValues.length-1,
        backgroundColor: 'rgba(70, 120, 248, 0.8)',
        borderWidth: 3
      });

      this.state.StateOfPopup.SingleTrendRsiAnnotations = annotationList;
      this.setState(this.state.StateOfPopup);  
    }


    //TODO: calculate based on the current selected user on the chart view screen, or sum all if the user selected all data to be shown
    setStateBuyOrderSignals = (orderDataList) => 
    {
      this.state.StateOfPopup.BuyOrderAnnotations = []

      for(let orderDataKey in orderDataList)
      {        
        let orderData = orderDataList[orderDataKey];
        if(orderData.OrderTag == 'Bought Completed' || orderData.OrderTag == 'Sold Order')
        { 
          let annotationColor = '';
          if (orderData.OrderTag == 'Bought Completed')
          {
            annotationColor = 'rgba(26, 145, 61, 0.705)';
          }
          else
          {
            annotationColor = 'rgba(18, 42, 75, 0.705)';
          } 
          //console.log ('orderDataKey Time: ' + orderData.OrderFilledTime + 'orderData.OrderTag: ' + orderData.OrderTag + ' annotationColor: ' + annotationColor)

          let overrideMacdAnnotationWithOrderData = this.state.macdBuySellSignals.find(element => element.DateTimeOfSignal === orderData.OrderFilledTime)
          let overrideRsiAnnotationWithOrderData = this.state.rsiBuySellSignals.find(element => element.DateTimeOfSignal === orderData.OrderFilledTime)
          let overrideHistogramMacdAnnotationWithOrderData = this.state.histogramMacdBuySellSignals.find(element => element.DateTimeOfSignal === orderData.OrderFilledTime)
          let overrideSingleTrendRsiAnnotationWithOrderData = this.state.singleTrendRsiBuySellSignals.find(element => element.DateTimeOfSignal === orderData.OrderFilledTime)
          //console.log('Rsi BuySell Signal: ' + this.state.rsiBuySellSignals.DateTimeOfSignal + ' backgroundColor: ' + this.state.rsiBuySellSignals.backgroundColor 
          //+ ' overrideRsiAnnotationWithOrderData: ' + overrideRsiAnnotationWithOrderData) 
          //let overrideAnnotationIndex = this.state.StateOfPopup.MacdAnnotations.find(element => element.xMin === overrideAnnotationWithOrderData.IndexOfSignal)
          if (overrideMacdAnnotationWithOrderData !== undefined)
          {
            var indexOfMacdAnnotationToOverride = this.state.StateOfPopup.MacdAnnotations.map(function(e) { return e.xMin; }).indexOf(overrideMacdAnnotationWithOrderData.IndexOfSignal);
			
            this.state.StateOfPopup.MacdAnnotations[indexOfMacdAnnotationToOverride].backgroundColor = annotationColor;
            let orderAnnotation = {
              type: 'line',
              xMin: this.state.StateOfPopup.MacdAnnotations[indexOfMacdAnnotationToOverride].xMin,
              xMax: this.state.StateOfPopup.MacdAnnotations[indexOfMacdAnnotationToOverride].xMin,
              yMin: 0,
              yMax: 100,          
              borderColor: annotationColor,
              borderWidth: 4
            }
            this.state.StateOfPopup.BuyOrderAnnotations.push(orderAnnotation);
          }
          if (overrideRsiAnnotationWithOrderData !== undefined)
          {			          
            var indexOfRsiAnnotationToOverride = this.state.StateOfPopup.RsiAnnotations.map(function(e) { return e.xMin; }).indexOf(overrideRsiAnnotationWithOrderData.IndexOfSignal);
            this.state.StateOfPopup.RsiAnnotations[indexOfRsiAnnotationToOverride].backgroundColor = annotationColor;

            let orderAnnotation = {
              type: 'line',
              xMin: this.state.StateOfPopup.RsiAnnotations[indexOfRsiAnnotationToOverride].xMin,
              xMax: this.state.StateOfPopup.RsiAnnotations[indexOfRsiAnnotationToOverride].xMin,
              yMin: 0,
              yMax: 100,          
              borderColor: annotationColor,
              borderWidth: 4
              }
              this.state.StateOfPopup.BuyOrderAnnotations.push(orderAnnotation);
              
          }
          if(overrideHistogramMacdAnnotationWithOrderData !== undefined)
          {
            var indexOfHistogramMacdAnnotationToOverride = this.state.StateOfPopup.HistogramMacdAnnotations.map(function(e) { return e.xMin; }).indexOf(overrideHistogramMacdAnnotationWithOrderData.IndexOfSignal);
			
            if (orderData.OrderTag == 'Bought Completed')
            {
              annotationColor = 'rgba(26, 145, 61, 0.39)';
            }
            else
            {
              annotationColor = 'rgba(18, 42, 75, 0.39)';
            } 

            this.state.StateOfPopup.HistogramMacdAnnotations[indexOfHistogramMacdAnnotationToOverride].backgroundColor = annotationColor;
            let orderAnnotation = {
              type: 'line',
              xMin: this.state.StateOfPopup.HistogramMacdAnnotations[indexOfHistogramMacdAnnotationToOverride].xMin,
              xMax: this.state.StateOfPopup.HistogramMacdAnnotations[indexOfHistogramMacdAnnotationToOverride].xMin,
              yMin: 0,
              yMax: 100,          
              borderColor: annotationColor,
              borderWidth: 4
            }
            this.state.StateOfPopup.BuyOrderAnnotations.push(orderAnnotation);
          }
          if(overrideSingleTrendRsiAnnotationWithOrderData !== undefined)
          {
            var indexOfSingleTrendRsiAnnotationToOverride = this.state.StateOfPopup.SingleTrendRsiAnnotations.map(function(e) { return e.xMin; }).indexOf(overrideSingleTrendRsiAnnotationWithOrderData.IndexOfSignal);
			
            if (orderData.OrderTag == 'Bought Completed')
            {
              annotationColor = 'rgba(26, 145, 61, 0.39)';
            }
            else
            {
              annotationColor = 'rgba(18, 42, 75, 0.39)';
            } 

            this.state.StateOfPopup.SingleTrendRsiAnnotations[indexOfSingleTrendRsiAnnotationToOverride].backgroundColor = annotationColor;
            let orderAnnotation = {
              type: 'line',
              xMin: this.state.StateOfPopup.SingleTrendRsiAnnotations[indexOfSingleTrendRsiAnnotationToOverride].xMin,
              xMax: this.state.StateOfPopup.SingleTrendRsiAnnotations[indexOfSingleTrendRsiAnnotationToOverride].xMin,
              yMin: 0,
              yMax: 100,          
              borderColor: annotationColor,
              borderWidth: 4
            }
            this.state.StateOfPopup.BuyOrderAnnotations.push(orderAnnotation);
          }
        }
      }
      this.setState(this.state.StateOfPopup);  
    }   

    
    setStateStrategiesFilterVisible = (valueOfComponent) => {
      this.state.StateOfStrategyDataFilterTable.StrategiesFilterVisible = valueOfComponent;
      this.setState(this.state.StateOfStrategyDataFilterTable, () => {
      });  
    }   


    setStateLandingPageShowingTutorial = (valueOfComponent) => 
    {
      this.setState({landingPageShowingTutorial: valueOfComponent});       
    }

    setStateSearchBoxTestRunName = (valueOfComponent) => {
      this.setState({searchBoxTestRunName: valueOfComponent}); 
    }   

    setStateSearchBoxRating = (valueOfComponent) => {
      this.setState({searchBoxRating: valueOfComponent}); 
    }
    
    setStateSearchBoxLifeTime = (valueOfComponent) => {
      this.setState({searchBoxLifeTime: valueOfComponent}); 
    }   

    setStateSearchBoxProfit = (valueOfComponent) => {
      this.setState({searchBoxProfit: valueOfComponent}); 
    }   

    setStateSearchBoxWinLossRate = (valueOfComponent) => {
      this.setState({searchBoxWinLossRate: valueOfComponent}); 
    }   

    setStateSearchBoxNumberOfTrades = (valueOfComponent) => {
      this.setState({searchBoxNumberOfTrades: valueOfComponent}); 
    }   

    setStateOperationForRating = (valueOfComponent) => {
      this.setState({operationForRating: valueOfComponent}); 
    }  
    
    setStateOperationForLifeTime = (valueOfComponent) => {
      this.setState({operationForLifeTime: valueOfComponent}); 
    }   

    setStateOperationForProfit = (valueOfComponent) => {
      this.setState({operationForProfit: valueOfComponent}); 
    }   

    setStateOperationForWinLossRate = (valueOfComponent) => {
      this.setState({operationForWinLossRate: valueOfComponent}); 
    }   

    setStateOperationForNumberOfTrades = (valueOfComponent) => {
      this.setState({operationForNumberOfTrades: valueOfComponent}); 
    }   

    setStateSearchBoxTestRunName = (valueOfComponent) => {
      this.setState({searchBoxTestRunName: valueOfComponent}); 
    }  

    setStateExtendedRowTestRunData = (valueOfComponent) => {
      if(this.state.StateOfStrategyDataFilterTable.extendedRowTestRunData == valueOfComponent)        
        this.state.StateOfStrategyDataFilterTable.extendedRowTestRunData = ''; //Close extended data when same row selected again
      else
        this.state.StateOfStrategyDataFilterTable.extendedRowTestRunData = valueOfComponent;
      this.setState(this.state.StateOfStrategyDataFilterTable);  
    }


    setStateExtendedRowUserStrategyTestRunId = async(valueOfComponent) => {  
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending(this.state.StateOfUserProfilePopup.StrategyType)
      let assetMetricsUrl = urlString + 'Users/GetAssetMetrics/' + userId + '/' + valueOfComponent;

      const {data} = await axios.get(assetMetricsUrl);
      this.setCurrentStrategyAssetMetrics(data);

      if(this.state.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId == valueOfComponent)        
        this.state.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId = ''; //Close extended data when same row selected again
      else
        this.state.StateOfUserProfilePopup.ExtendedRowUserStrategyTestRunId = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup);  
    }
    

    setStateExtendedRowUserAsset = async(valueOfComponent) => {  
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending(this.state.StateOfUserProfilePopup.StrategyType)
      let assetMetricsUrl = urlString + 'Users/GetAssetMetrics/' + userId + '/' + valueOfComponent;

      const {data} = await axios.get(assetMetricsUrl);
      this.setCurrentAssetMetricsByStrategy(data);

      if(this.state.StateOfUserProfilePopup.ExtendedRowUserAsset == valueOfComponent)        
        this.state.StateOfUserProfilePopup.ExtendedRowUserAsset = ''; //Close extended data when same row selected again
      else
        this.state.StateOfUserProfilePopup.ExtendedRowUserAsset = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup);  
    }


    setStateExtendedRowUserIndicator = async(valueOfComponent) => {  
      let urlString = this.GetUrlPrefix()
      let userId = this.ModifyUserIdBeforeSending(this.state.StateOfUserProfilePopup.StrategyType)
      let indicatorMetricsUrl = urlString + 'Users/GetIndicatorMetrics/' + userId + '/' + valueOfComponent;

      const {data} = await axios.get(indicatorMetricsUrl);
      this.setCurrentIndicatorMetricsByStrategy(data);

      if(this.state.StateOfUserProfilePopup.ExtendedRowUserIndicator == valueOfComponent)        
        this.state.StateOfUserProfilePopup.ExtendedRowUserIndicator = ''; //Close extended data when same row selected again
      else
        this.state.StateOfUserProfilePopup.ExtendedRowUserIndicator = valueOfComponent;
      this.setState(this.state.StateOfUserProfilePopup);  
    }
        

    setCurrentStrategyAssetMetrics = (valueOfComponenet) =>
    {
      this.state.StateOfUserProfilePopup.currentStrategyAssetMetrics = valueOfComponenet
    }

    setCurrentAssetMetricsByStrategy = (valueOfComponenet) =>
    {
      this.state.StateOfUserProfilePopup.currentAssetMetricsByStrategy = valueOfComponenet
    }

    setCurrentIndicatorMetricsByStrategy = (valueOfComponenet) =>
    {
      this.state.StateOfUserProfilePopup.currentIndicatorMetricsByStrategy = valueOfComponenet
    }


    setStateSortTestRunName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortTestRunName == 'descending'
          ? this.setState({sortTestRunName: 'ascending'})
          : this.setState({sortTestRunName: 'descending'})          
      }
      else
      {
        this.setState({sortTestRunName: valueOfComponent}); 
      }
    }   

    setStateSortRating = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortRating == 'descending'
          ? this.setState({sortRating: 'ascending'})
          : this.setState({sortRating: 'descending'})          
      }
      else
      {
        this.setState({sortRating: valueOfComponent}); 
      }
    }
    
    setStateSortLifeTime = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortLifeTime == 'descending'
          ? this.setState({sortLifeTime: 'ascending'})
          : this.setState({sortLifeTime: 'descending'})          
      }
      else
      {
        this.setState({sortLifeTime: valueOfComponent}); 
      }
    }   

    setStateSortProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortProfit == 'descending'
          ? this.setState({sortProfit: 'ascending'})
          : this.setState({sortProfit: 'descending'})          
      }
      else
      {
        this.setState({sortProfit: valueOfComponent}); 
      }
    }   

    setStateSortWinLossRate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortWinLossRate == 'descending'
          ? this.setState({sortWinLossRate: 'ascending'})
          : this.setState({sortWinLossRate: 'descending'})          
      }
      else
      {
        this.setState({sortWinLossRate: valueOfComponent}); 
      }
    }   


    setStateSortNumberOfTrades = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.sortNumberOfTrades == 'descending'
          ? this.setState({sortNumberOfTrades: 'ascending'})
          : this.setState({sortNumberOfTrades: 'descending'})          
      }
      else
      {
        this.setState({sortNumberOfTrades: valueOfComponent}); 
      }
    }     


    setStateSortUserPromptTestRunName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortStrategyTestRunName == 'descending'
          ? this.state.StateOfUserProfilePopup.sortStrategyTestRunName = 'ascending'
          : this.state.StateOfUserProfilePopup.sortStrategyTestRunName = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortStrategyTestRunName = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   

    
    setStateSortUserPromptStartedInvestingDate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortStartedInvestingDate == 'descending'
          ? this.state.StateOfUserProfilePopup.sortStartedInvestingDate = 'ascending'
          : this.state.StateOfUserProfilePopup.sortStartedInvestingDate = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortStartedInvestingDate = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptEndedInvestingDate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortEndedInvestingDate == 'descending'
          ? this.state.StateOfUserProfilePopup.sortEndedInvestingDate = 'ascending'
          : this.state.StateOfUserProfilePopup.sortEndedInvestingDate = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortEndedInvestingDate = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptInvested = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortInvested == 'descending'
          ? this.state.StateOfUserProfilePopup.sortInvested = 'ascending'
          : this.state.StateOfUserProfilePopup.sortInvested = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortInvested = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }     


    setStateSortUserPromptProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortProfit == 'descending'
          ? this.state.StateOfUserProfilePopup.sortProfit = 'ascending'
          : this.state.StateOfUserProfilePopup.sortProfit = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortProfit = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptWinRate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortWinRate == 'descending'
          ? this.state.StateOfUserProfilePopup.sortWinRate = 'ascending'
          : this.state.StateOfUserProfilePopup.sortWinRate = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortWinRate = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptStrategyType = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortStrategyType == 'descending'
          ? this.state.StateOfUserProfilePopup.sortStrategyType = 'ascending'
          : this.state.StateOfUserProfilePopup.sortStrategyType = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortStrategyType = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptPercentageOfWholeStrategy = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortPercentageOfWholeStrategy == 'descending'
          ? this.state.StateOfUserProfilePopup.sortPercentageOfWholeStrategy = 'ascending'
          : this.state.StateOfUserProfilePopup.sortPercentageOfWholeStrategy = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortPercentageOfWholeStrategy = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptIndicators = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicators == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicators = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicators = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicators = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }   


    setStateSortUserPromptOrderName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderName == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderName = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderName = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderName = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderTestRunName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderTestRunName == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderTestRunName = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderTestRunName = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderTestRunName = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderTime = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderTime == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderTime = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderTime = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderTime = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderType = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderType == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderType = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderType = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderType = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderSymbol = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderSymbol == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderSymbol = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderSymbol = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderSymbol = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderQuantity = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderQuantity == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderQuantity = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderQuantity = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderQuantity = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderAveragePrice = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderAveragePrice == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderAveragePrice = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderAveragePrice = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderAveragePrice = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderRequestedPrice = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderRequestedPrice == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderRequestedPrice = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderRequestedPrice = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderRequestedPrice = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderProfit == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderProfit = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderProfit = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderProfit = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderProfitPercentage = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderProfitPercentage == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderProfitPercentage = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderProfitPercentage = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderProfitPercentage = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    setStateSortUserPromptOrderDesiredProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderDesiredProfit == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderDesiredProfit = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderDesiredProfit = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderDesiredProfit = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptOrderStopLoss = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortOrderStopLoss == 'descending'
          ? this.state.StateOfUserProfilePopup.sortOrderStopLoss = 'ascending'
          : this.state.StateOfUserProfilePopup.sortOrderStopLoss = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortOrderStopLoss = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptAssetName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetName == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetName = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetName = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetName = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    setStateSortUserPromptAssetType = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetType == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetType = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetType = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetType = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptAssetInvested = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetInvested == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetInvested = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetInvested = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetInvested = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptAssetProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetProfit == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetProfit = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetProfit = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetProfit = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    setStateSortuserPromptAssetWinRate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetWinRate == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetWinRate = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetWinRate = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetWinRate = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptAssetWins = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetWins == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetWins = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetWins = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetWins = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptAssetLosses = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetLosses == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetLosses = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetLosses = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetLosses = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    
    setStateSortUserPromptAssetNumberOfTrades = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortAssetNumberOfTrades == 'descending'
          ? this.state.StateOfUserProfilePopup.sortAssetNumberOfTrades = 'ascending'
          : this.state.StateOfUserProfilePopup.sortAssetNumberOfTrades = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortAssetNumberOfTrades = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptIndicatorName = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorName == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorName = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorName = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorName = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    setStateSortUserPromptIndicatorType = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorType == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorType = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorType = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorType = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptIndicatorInvested = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorInvested == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorInvested = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorInvested = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorInvested = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortUserPromptIndicatorProfit = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorProfit == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorProfit = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorProfit = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorProfit = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    setStateSortuserPromptIndicatorWinRate = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorWinRate == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorWinRate = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorWinRate = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorWinRate = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptIndicatorWins = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorWins == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorWins = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorWins = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorWins = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 


    setStateSortuserPromptIndicatorLosses = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorLosses == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorLosses = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorLosses = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorLosses = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 

    
    setStateSortUserPromptIndicatorNumberOfTrades = (valueOfComponent) => {
      if(valueOfComponent == '')
      {
        this.state.StateOfUserProfilePopup.sortIndicatorNumberOfTrades == 'descending'
          ? this.state.StateOfUserProfilePopup.sortIndicatorNumberOfTrades = 'ascending'
          : this.state.StateOfUserProfilePopup.sortIndicatorNumberOfTrades = 'descending'
          
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.sortIndicatorNumberOfTrades = valueOfComponent
        this.setState(this.state.StateOfUserProfilePopup)
      }
    } 



    SortStrategiesOnColumnDirectionChange = (data) => {
      if(this.state.needToInitialize)
        return;

      switch(data.type){
        case 'CHANGE_SORT':
            data.column == 'testRunValue' 
              ? this.setStateSortTestRunName('')
              : this.setStateSortTestRunName(null)
            data.column == 'rating' 
              ? this.setStateSortRating('')
              : this.setStateSortRating(null)
            data.column == 'totalLifeTime' 
              ? this.setStateSortLifeTime('')
              : this.setStateSortLifeTime(null)
            data.column == 'testRunProfit' 
                ? this.setStateSortProfit('')
                : this.setStateSortProfit(null)
            data.column == 'removeDecimalWinLossFormat' 
              ? this.setStateSortWinLossRate('')
              : this.setStateSortWinLossRate(null)
            data.column == 'totalNumberOfOrders' 
              ? this.setStateSortNumberOfTrades('')
              : this.setStateSortNumberOfTrades(null)
            break;
            default:
              throw new Error('Sorting Called but without type of sort.')
          }    
    }

          
    SortUserPromptStrategiesOnColumnDirectionChange = (data) => {
      if(this.state.needToInitialize)
        return;

      switch(data.type){
        case 'CHANGE_SORT':
            data.column == 'userPromptTestRunName' 
              ? this.setStateSortUserPromptTestRunName('')
              : this.setStateSortUserPromptTestRunName(null)
            data.column == 'userPromptStartedInvestingDate' 
              ? this.setStateSortUserPromptStartedInvestingDate('')
              : this.setStateSortUserPromptStartedInvestingDate(null)
            data.column == 'userPromptEndedInvestingDate' 
              ? this.setStateSortUserPromptEndedInvestingDate('')
              : this.setStateSortUserPromptEndedInvestingDate(null)
            data.column == 'userPromptInvested' 
              ? this.setStateSortUserPromptInvested('')
              : this.setStateSortUserPromptInvested(null)
            data.column == 'userPromptProfit' 
              ? this.setStateSortUserPromptProfit('')
              : this.setStateSortUserPromptProfit(null)
            data.column == 'userPromptWinRate' 
              ? this.setStateSortUserPromptWinRate('')
              : this.setStateSortUserPromptWinRate(null)
            data.column == 'userPromptStrategyType' 
              ? this.setStateSortUserPromptStrategyType('')
              : this.setStateSortUserPromptStrategyType(null)
            data.column == 'userPromptPercentageOfWholeStrategy' 
              ? this.setStateSortUserPromptPercentageOfWholeStrategy('')
              : this.setStateSortUserPromptPercentageOfWholeStrategy(null)
            data.column == 'userPromptIndicators' 
              ? this.setStateSortUserPromptIndicators('')
              : this.setStateSortUserPromptIndicators(null)
            break;
            default:
              throw new Error('Sorting Called but without type of sort.')
          }    
    }


    SortUserPromptOrdersOnColumnDirectionChange = (data) =>
    {
      if(this.state.needToInitialize)
        return;

      switch(data.type){
        case 'CHANGE_SORT':
            data.column == 'userPromptOrderName' 
              ? this.setStateSortUserPromptOrderName('')
              : this.setStateSortUserPromptOrderName(null)
            data.column == 'userPromptOrderTestRunName' 
              ? this.setStateSortUserPromptOrderTestRunName('')
              : this.setStateSortUserPromptOrderTestRunName(null)
            data.column == 'userPromptOrderTime' 
              ? this.setStateSortUserPromptOrderTime('')
              : this.setStateSortUserPromptOrderTime(null)
            data.column == 'userPromptOrderType' 
              ? this.setStateSortUserPromptOrderType('')
              : this.setStateSortUserPromptOrderType(null)
            data.column == 'userPromptOrderSymbol' 
              ? this.setStateSortUserPromptOrderSymbol('')
              : this.setStateSortUserPromptOrderSymbol(null)
            data.column == 'userPromptOrderQuantity' 
              ? this.setStateSortUserPromptOrderQuantity('')
              : this.setStateSortUserPromptOrderQuantity(null)
            data.column == 'userPromptOrderAveragePrice' 
              ? this.setStateSortUserPromptOrderAveragePrice('')
              : this.setStateSortUserPromptOrderAveragePrice(null)
            data.column == 'userPromptOrderRequestedPrice' 
              ? this.setStateSortUserPromptOrderRequestedPrice('')
              : this.setStateSortUserPromptOrderRequestedPrice(null)
            data.column == 'userPromptOrderProfit' 
              ? this.setStateSortUserPromptOrderProfit('')
              : this.setStateSortUserPromptOrderProfit(null)
            data.column == 'userPromptOrderProfitPercentage' 
              ? this.setStateSortUserPromptOrderProfitPercentage('')
              : this.setStateSortUserPromptOrderProfitPercentage(null)
            data.column == 'userPromptOrderDesiredProfit' 
              ? this.setStateSortUserPromptOrderDesiredProfit('')
              : this.setStateSortUserPromptOrderDesiredProfit(null)
              data.column == 'userPromptOrderStopLoss' 
                ? this.setStateSortUserPromptOrderStopLoss('')
                : this.setStateSortUserPromptOrderStopLoss(null)
            break;
            default:
              throw new Error('Sorting Called but without type of sort.')
          }  
    }

    SortUserPromptAssetsOnColumnDirectionChange = (data) =>
    {
      if(this.state.needToInitialize)
        return;

      switch(data.type){
        case 'CHANGE_SORT':
            data.column == 'userPromptAssetName' 
              ? this.setStateSortUserPromptAssetName('')
              : this.setStateSortUserPromptAssetName(null)
            data.column == 'userPromptAssetType' 
              ? this.setStateSortUserPromptAssetType('')
              : this.setStateSortUserPromptAssetType(null)
            data.column == 'userPromptAssetInvested' 
              ? this.setStateSortuserPromptAssetInvested('')
              : this.setStateSortuserPromptAssetInvested(null)
              data.column == 'userPromptAssetProfit' 
                ? this.setStateSortUserPromptAssetProfit('')
                : this.setStateSortUserPromptAssetProfit(null)
            data.column == 'userPromptAssetWinRate' 
              ? this.setStateSortuserPromptAssetWinRate('')
              : this.setStateSortuserPromptAssetWinRate(null)
            data.column == 'userPromptAssetWins' 
              ? this.setStateSortuserPromptAssetWins('')
              : this.setStateSortuserPromptAssetWins(null)
              data.column == 'userPromptAssetLosses' 
                ? this.setStateSortuserPromptAssetLosses('')
                : this.setStateSortuserPromptAssetLosses(null)
            data.column == 'userPromptAssetNumberOfTrades' 
              ? this.setStateSortUserPromptAssetNumberOfTrades('')
              : this.setStateSortUserPromptAssetNumberOfTrades(null)
            break;
            default:
              throw new Error('Sorting Called but without type of sort.')
          }  
    }

    SortUserPromptIndicatorsOnColumnDirectionChange = (data) =>
    {
      if(this.state.needToInitialize)
        return;

      switch(data.type){
        case 'CHANGE_SORT':
            data.column == 'userPromptIndicatorName' 
              ? this.setStateSortUserPromptIndicatorName('')
              : this.setStateSortUserPromptIndicatorName(null)
            data.column == 'userPromptIndicatorType' 
              ? this.setStateSortUserPromptIndicatorType('')
              : this.setStateSortUserPromptIndicatorType(null)
            data.column == 'userPromptIndicatorInvested' 
              ? this.setStateSortuserPromptIndicatorInvested('')
              : this.setStateSortuserPromptIndicatorInvested(null)
              data.column == 'userPromptIndicatorProfit' 
                ? this.setStateSortUserPromptIndicatorProfit('')
                : this.setStateSortUserPromptIndicatorProfit(null)
            data.column == 'userPromptIndicatorWinRate' 
              ? this.setStateSortuserPromptIndicatorWinRate('')
              : this.setStateSortuserPromptIndicatorWinRate(null)
            data.column == 'userPromptIndicatorWins' 
              ? this.setStateSortuserPromptIndicatorWins('')
              : this.setStateSortuserPromptIndicatorWins(null)
              data.column == 'userPromptIndicatorLosses' 
                ? this.setStateSortuserPromptIndicatorLosses('')
                : this.setStateSortuserPromptIndicatorLosses(null)
            data.column == 'userPromptIndicatorNumberOfTrades' 
              ? this.setStateSortUserPromptIndicatorNumberOfTrades('')
              : this.setStateSortUserPromptIndicatorNumberOfTrades(null)
            break;
            default:
              throw new Error('Sorting Called but without type of sort.')
          }  
      }


    FilterValuesPerSelectedOperation (valueToCheck, thresholdValue, operation){      
      if(operation == 'Equals')
      {
        return valueToCheck == thresholdValue
      }
      if(operation == 'Not Equals')
      {
        return valueToCheck != thresholdValue
      }
      if(operation == 'Greater Than')
      {
        return valueToCheck > thresholdValue
      }
      if(operation == 'Less Than')
      {
        return valueToCheck < thresholdValue
      }
      if(operation == 'Greater Than or Equal')
      {
        return valueToCheck >= thresholdValue
      }
      if(operation == 'Less Than or Equal')
      {
        return valueToCheck <= thresholdValue
      }
      return true; //Default To return the row if no operations match
    }


    UserPromptInputOrderOperatorOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptOrderNameList':
          this.setStateOperationForUserPromptOrderName(data.value);
          break;
        case 'userPromptOrderTestRunNameList':
          this.setStateOperationForUserPromptOrderTestRunName(data.value);
          break;
        case 'userPromptOrderTimeList':
          this.setStateOperationForUserPromptOrderTime(data.value);
          break;
        case 'userPromptOrderTypeList':
            this.setStateOperationForUserPromptOrderType(data.value);
          break;
        case 'userPromptOrderSymbolList':
          this.setStateOperationForUserPromptOrderSymbol(data.value);
          break;
        case 'userPromptOrderQuantityList':
          this.setStateOperationForUserPromptOrderQuantity(data.value);
          break;
        case 'userPromptOrderAveragePriceList':
          this.setStateOperationForUserPromptOrderAveragePrice(data.value);
          break;
        case 'userPromptOrderRequestedPriceList':
          this.setStateOperationForUserPromptOrderRequestedPrice(data.value);
          break;
        case 'userPromptOrderTagList':
          this.setStateOperationForUserPromptOrderTag(data.value);
          break;
        case 'userPromptOrderProfitList':
          this.setStateOperationForUserPromptOrderProfit(data.value);
          break;
        case 'userPromptOrderProfitPercentageList':
          this.setStateOperationForUserPromptOrderProfitPercentage(data.value);
          break;
        case 'userPromptOrderDesiredProfitList':
          this.setStateOperationForUserPromptOrderDesiredProfit(data.value);
          break;
        case 'userPromptOrderStopLossList':
          this.setStateOperationForUserPromptOrderStopLoss(data.value);
          break;
      }
    }     
    

    UserPromptInputAssetOperatorOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptAssetNameList':
          this.setStateOperationForUserPromptAssetName(data.value);
          break;
        case 'userPromptAssetTestRunNameList':
          this.setStateOperationForUserPromptAssetTestRunName(data.value);
          break;
        case 'userPromptAssetTimeList':
          this.setStateOperationForUserPromptAssetTime(data.value);
          break;
        case 'userPromptAssetTypeList':
            this.setStateOperationForUserPromptAssetType(data.value);
          break;
        case 'userPromptAssetInvestedList':
          this.setStateOperationForuserPromptAssetInvested(data.value);
          break;
        case 'userPromptAssetQuantityList':
          this.setStateOperationForUserPromptAssetQuantity(data.value);
          break;
        case 'userPromptAssetWinRateList':
          this.setStateOperationForuserPromptAssetWinRate(data.value);
          break;
        case 'userPromptAssetWinsList':
          this.setStateOperationForuserPromptAssetWins(data.value);
          break;
        case 'userPromptAssetLossesList':
          this.setStateOperationForUserPromptAssetLosses(data.value);
          break;
        case 'userPromptAssetProfitList':
          this.setStateOperationForUserPromptAssetProfit(data.value);
          break;
        case 'userPromptAssetNumberOfTradesList':
          this.setStateOperationForUserPromptAssetNumberOfTrades(data.value);
          break;
        case 'userPromptAssetDesiredProfitList':
          this.setStateOperationForUserPromptAssetDesiredProfit(data.value);
          break;
        case 'userPromptAssetStopLossList':
          this.setStateOperationForUserPromptAssetStopLoss(data.value);
          break;
      }
    } 

    
    UserPromptInputIndicatorOperatorOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptIndicatorNameList':
          this.setStateOperationForUserPromptIndicatorName(data.value);
          break;
        case 'userPromptIndicatorTestRunNameList':
          this.setStateOperationForUserPromptIndicatorTestRunName(data.value);
          break;
        case 'userPromptIndicatorTimeList':
          this.setStateOperationForUserPromptIndicatorTime(data.value);
          break;
        case 'userPromptIndicatorTypeList':
            this.setStateOperationForUserPromptIndicatorType(data.value);
          break;
        case 'userPromptIndicatorInvestedList':
          this.setStateOperationForuserPromptIndicatorInvested(data.value);
          break;
        case 'userPromptIndicatorQuantityList':
          this.setStateOperationForUserPromptIndicatorQuantity(data.value);
          break;
        case 'userPromptIndicatorWinRateList':
          this.setStateOperationForuserPromptIndicatorWinRate(data.value);
          break;
        case 'userPromptIndicatorWinsList':
          this.setStateOperationForuserPromptIndicatorWins(data.value);
          break;
        case 'userPromptIndicatorLossesList':
          this.setStateOperationForUserPromptIndicatorLosses(data.value);
          break;
        case 'userPromptIndicatorProfitList':
          this.setStateOperationForUserPromptIndicatorProfit(data.value);
          break;
        case 'userPromptIndicatorNumberOfTradesList':
          this.setStateOperationForUserPromptIndicatorNumberOfTrades(data.value);
          break;
        case 'userPromptIndicatorDesiredProfitList':
          this.setStateOperationForUserPromptIndicatorDesiredProfit(data.value);
          break;
        case 'userPromptIndicatorStopLossList':
          this.setStateOperationForUserPromptIndicatorStopLoss(data.value);
          break;
      }
    } 


    UserPromptInputOperatorOnChange = (event, data) => {      
      switch(data.list){
        case 'userPromptTestRunNameList':
          this.setStateOperationForUserPromptTestRunName(data.value);
          break;
        case 'userPromptStartedInvestingDateList':
          this.setStateOperationForUserPromptStartedInvestingDate(data.value);
          break;
        case 'userPromptEndedInvestingDateList':
          this.setStateOperationForUserPromptEndedInvestingDate(data.value);
          break;
        case 'userPromptInvestedList':
          this.setStateOperationForUserPromptInvested(data.value);
          break;
        case 'userPromptProfitList':
          this.setStateOperationForUserPromptProfit(data.value);
          break;
        case 'userPromptWinRateList':
          this.setStateOperationForUserPromptWinRate(data.value);
          break;
        case 'userPromptStrategyTypeList':
          this.setStateOperationForUserPromptStrategyType(data.value);
          break;
        case 'userPromptPercentageOfWholeStrategyList':
          this.setStateOperationForUserPromptPercentageOfWholeStrategy(data.value);
          break;
        case 'userPromptIndicatorsList':
          this.setStateOperationForUserPromptIndicators(data.value);
          break;
      }
    }


    setStateOperationForUserPromptTestRunName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForStartedInvestingDate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptStartedInvestingDate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForStartedInvestingDate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptEndedInvestingDate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForEndedInvestingDate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForInvested = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForProfit = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    } 
	
    setStateOperationForUserPromptWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForWinRate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptStartedInvesting = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForStartedInvesting = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptStrategyType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForStrategyType = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptPercentageOfWholeStrategy = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForPercentageOfWholeStrategy = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptIndicators = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicators = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }   

    setStateOperationForUserPromptOrderName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderName = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderTestRunName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderTestRunName = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderTime = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderTime = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderType = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderSymbol = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderSymbol = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderQuantity = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderQuantity = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderAveragePrice = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderAveragePrice = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderRequestedPrice = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderRequestedPrice = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderTag = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderTag = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderProfit = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderProfitPercentage = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderProfitPercentage = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderDesiredProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderDesiredProfit = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderStopLoss = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderStopLoss = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptAssetName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetName = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptAssetType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetType = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptAssetInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetInvested = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptAssetProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetProfit = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptAssetWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetWinRate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptAssetWins = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetWins = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptAssetLosses = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetLosses = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForAssetNumberOfTrades = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForAssetNumberOfTrades = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptIndicatorName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorName = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptIndicatorType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorType = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptIndicatorInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorInvested = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptIndicatorProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorProfit = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptIndicatorWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorWinRate = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForuserPromptIndicatorWins = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorWins = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptIndicatorLosses = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorLosses = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  

    setStateOperationForUserPromptOrderQuantity = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForOrderQuantity = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  
    
    setStateOperationForIndicatorNumberOfTrades = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.operationForIndicatorNumberOfTrades = valueOfComponent
      this.setState(this.state.StateOfUserProfilePopup); 
    }  


    UserPromptInputDataListOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptTestRunNameList':
          this.setStateUserPromptSearchBoxTestRunName(data.value);
          break;
        case 'userPromptStartedInvestingDateList':
          this.setStateUserPromptSearchBoxStartedInvestingDate(data.value);
          break;
        case 'userPromptEndedInvestingDateList':
          this.setStateUserPromptSearchBoxEndedInvestingDate(data.value);
          break;
        case 'userPromptInvestedList':
            let invested = parseFloat(data.value);
            this.setStateUserPromptSearchBoxInvested(invested);
          break;
        case 'userPromptProfitList':
          let profit = Number(data.value.replace(/[^0-9\.-]+/g,""));
          this.setStateUserPromptSearchBoxProfit(profit);
          break;
        case 'userPromptWinRateList':
          let winRate = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxWinRate(winRate);
          break;
        case 'userPromptStrategyTypeList':
          this.setStateUserPromptSearchBoxStrategyType(data.value);
          break;
        case 'userPromptPercentageOfWholeStrategyList':
          let percentageOwned = parseFloat(data.value);
          this.setStateUserPromptSearchBoxPercentageOfWholeStrategy(percentageOwned);
          break;
        case 'userPromptIndicatorsList':
          this. setStateUserPromptSearchBoxIndicators(data.value);
          break; 
      }
    } 


    UserPromptInputOrderDataListOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptOrderNameList':
          this.setStateUserPromptSearchBoxOrderName(data.value);
          break;
        case 'userPromptOrderTestRunNameList':
          this.setStateUserPromptSearchBoxOrderTestRunName(data.value);
          break;
        case 'userPromptOrderTimeList':
          this.setStateUserPromptSearchBoxOrderTime(data.value);
          break;
        case 'userPromptOrderTypeList':
            this.setStateUserPromptSearchBoxOrderType(data.value);
          break;
        case 'userPromptOrderSymbolList':
          this.setStateUserPromptSearchBoxOrderSymbol(data.value);
          break;
        case 'userPromptOrderQuantityList':
          let quantity = Number(data.value.replace(/[^0-9\.-]+/g,""));
          this.setStateUserPromptSearchBoxOrderQuantity(quantity);
          break;
        case 'userPromptOrderAveragePriceList':
          let avgPrice = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxOrderAveragePrice(avgPrice);
          break;
        case 'userPromptOrderRequestedPriceList':
          let requestedPrice = parseFloat(data.value);
          this.setStateUserPromptSearchBoxOrderRequestedPrice(requestedPrice);
          break;
        case 'userPromptOrderTagList':
          this.setStateUserPromptSearchBoxOrderTag(data.value);
          break;
        case 'userPromptOrderProfitList':
          this.setStateUserPromptSearchBoxOrderProfit(data.value);
          break;
        case 'userPromptOrderProfitPercentageList':
          this.setStateUserPromptSearchBoxOrderProfitPercentage(data.value);
          break;
        case 'userPromptOrderDesiredProfitList':
          this.setStateUserPromptSearchBoxOrderDesiredProfit(data.value);
          break;
        case 'userPromptOrderStopLossList':
          this.setStateUserPromptSearchBoxOrderStopLoss(data.value);
          break;
      }
    } 

    
    UserPromptInputAssetDataListOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptAssetNameList':
          this.setStateUserPromptSearchBoxAssetName(data.value);
          break;
        case 'userPromptAssetTypeList':
            this.setStateUserPromptSearchBoxAssetType(data.value);
          break;
        case 'userPromptAssetInvestedList':
          let amountInvested = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxAssetInvested(amountInvested);
          break;
          case 'userPromptAssetProfitList':
            let amountProfit = parseFloat(data.value); 
            this.setStateUserPromptSearchBoxAssetProfit(amountProfit);
            break;
        case 'userPromptAssetWinRateList':
          let avgWinRate = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxAssetWinRate(avgWinRate);
          break;
        case 'userPromptAssetWinsList':
          this.setStateUserPromptSearchBoxAssetWins(data.value);
          break;
        case 'userPromptAssetLossesList':
          this.setStateUserPromptSearchBoxAssetLosses(data.value);
          break;
        case 'UserPromptAssetNumberOfTradesList':
          this.setStateUserPromptSearchBoxAssetNumberOfTrades(data.value);
          break;
      }
    } 


    UserPromptInputIndicatorDataListOnChange = (event, data) => {
      switch(data.list){
        case 'userPromptIndicatorNameList':
          this.setStateUserPromptSearchBoxIndicatorName(data.value);
          break;
        case 'userPromptIndicatorTypeList':
            this.setStateUserPromptSearchBoxIndicatorType(data.value);
          break;
        case 'userPromptIndicatorInvestedList':
          let amountInvested = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxIndicatorInvested(amountInvested);
          break;
          case 'userPromptIndicatorProfitList':
            let amountProfit = parseFloat(data.value); 
            this.setStateUserPromptSearchBoxIndicatorProfit(amountProfit);
            break;
        case 'userPromptIndicatorWinRateList':
          let avgWinRate = parseFloat(data.value); 
          this.setStateUserPromptSearchBoxIndicatorWinRate(avgWinRate);
          break;
        case 'userPromptIndicatorWinsList':
          this.setStateUserPromptSearchBoxIndicatorWins(data.value);
          break;
        case 'userPromptIndicatorLossesList':
          this.setStateUserPromptSearchBoxIndicatorLosses(data.value);
          break;
        case 'UserPromptIndicatorNumberOfTradesList':
          this.setStateUserPromptSearchBoxIndicatorNumberOfTrades(data.value);
          break;
      }
    } 


    setStateUserPromptSearchBoxTestRunName = (valueOfComponent) => {
        this.state.StateOfUserProfilePopup.searchBoxUserStrategyTestRunName = valueOfComponent      
        this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptSearchBoxStartedInvestingDate = (valueOfComponent) => {
      // if (valueOfComponent != '')
      // {
      //   this.state.StateOfUserProfilePopup.searchBoxStartedInvestingDate = valueOfComponent      
      //   this.setState(this.state.StateOfUserProfilePopup)
      // }
      // else
      // {
      //   this.state.StateOfUserProfilePopup.searchBoxStartedInvestingDate = ''      
      //   this.setState(this.state.StateOfUserProfilePopup)
      // }
      this.state.StateOfUserProfilePopup.searchBoxStartedInvestingDate = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptSearchBoxEndedInvestingDate = (valueOfComponent) => {
      if (valueOfComponent != '')
      {
        this.state.StateOfUserProfilePopup.searchBoxEndedInvestingDate = valueOfComponent      
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.searchBoxEndedInvestingDate = ''      
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }    

    setStateUserPromptSearchBoxInvested = (valueOfComponent) => {
        this.state.StateOfUserProfilePopup.searchBoxInvested = valueOfComponent      
        this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptSearchBoxProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptSearchBoxWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxWinRate = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptSearchBoxStrategyType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxStrategyType = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxPercentageOfWholeStrategy = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxPercentageOfWholeStrategy = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }  

    setStateUserPromptSearchBoxIndicators = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicators = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }

    setStateUserPromptIndicators = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicators = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderName = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderTestRunName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderTestRunName = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderTime = (valueOfComponent) => {
      if (valueOfComponent != '')
      {
        this.state.StateOfUserProfilePopup.searchBoxOrderTime = valueOfComponent      
        this.setState(this.state.StateOfUserProfilePopup)
      }
      else
      {
        this.state.StateOfUserProfilePopup.searchBoxOrderTime = ''      
        this.setState(this.state.StateOfUserProfilePopup)
      }
    }    

    setStateUserPromptSearchBoxOrderType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderType = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderSymbol = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderSymbol = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderQuantity = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderQuantity = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    

    setStateUserPromptSearchBoxOrderAveragePrice = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderAveragePrice = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }        

    setStateUserPromptSearchBoxOrderRequestedPrice = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderRequestedPrice = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    } 

    setStateUserPromptSearchBoxOrderTag = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderTag = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }        

    setStateUserPromptSearchBoxOrderProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    } 

    setStateUserPromptSearchBoxOrderProfitPercentage = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderProfitPercentage = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    } 

    setStateUserPromptSearchBoxOrderDesiredProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderDesiredProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    } 

    setStateUserPromptSearchBoxOrderStopLoss = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxOrderStopLoss = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }
    
    
    setStateUserPromptSearchBoxAssetName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetName = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetType = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetInvested = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetWinRate = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetWins = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetWins = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetLosses = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetLosses = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetNumberOfTrades = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetNumberOfTrades = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    
 
    setStateUserPromptSearchBoxAssetName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetName = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetType = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetInvested = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetWinRate = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetWins = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetWins = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetLosses = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetLosses = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxAssetNumberOfTrades = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxAssetNumberOfTrades = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    
 
    setStateUserPromptSearchBoxIndicatorName = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorName = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorType = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorType = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorInvested = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorInvested = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorProfit = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorProfit = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorWinRate = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorWinRate = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorWins = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorWins = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorLosses = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorLosses = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }   
    
    setStateUserPromptSearchBoxIndicatorNumberOfTrades = (valueOfComponent) => {
      this.state.StateOfUserProfilePopup.searchBoxIndicatorNumberOfTrades = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)
    }    


    handleConfigureStrategyStartDateChange = (event) =>
    {
        let x = 0;
    }
    
    GetOrderedValues = (currentSort, valueA, valueB) => {
      if(currentSort == 'ascending')
      {
        if (valueB > valueA) 
        {
          return 1;
        }
        else if (valueB < valueA)
        {
          return -1;
        }
      }
      else
      {
        if (valueB > valueA)
        {
          return -1;
        } 
        else if (valueB < valueA)
        {
          return 1;                          
        }
      }
      return 0;
    }


    
    rnd() {
      return { // taken and modified from charts samples: utils.js
        _seed: Date.now(),
    
        num: function(min, max) {
          this._seed = (this._seed * 9301 + 49297) % 233280;
          return min + (this._seed / 233280) * (max - min);
        },
    
        numbers: function(count, min, max) {
          var data = [];
          var value;
          for (var i = 0; i < count; ++i)
            data.push(Math.round(this.num(min, max)));
          return data;
        },
    
        timePoints: function(count, min, max) {
          var y = this.numbers(count, min, max);
          var points = [];
          var date = Date.now();
          var xStep = 60 * 60 * 1000;
          for (var i = y.length - 1; i >= 0; i--) {
            points.push({
              x: date -= xStep,
              y: y[i]
            });
          }
          return points;
        }
      };
    }
    

    filterData(chart, maxRenderedPointsX, context) {
      var datasets = chart.data.datasets;
      var dataLabels = chart.data.labels;
      var startIndex = 0;
      var endIndex = 0;
      if (!chart.data.origDatasetsData) {
        chart.data.origDatasetsData = [];
        chart.data.originalLabelDataList = [];
        for (var i in datasets) {
          chart.data.origDatasetsData.push(datasets[i].data);
        }
        chart.data.originalLabelDataList.push(dataLabels);
      }
      var originalDatasetsData = chart.data.origDatasetsData;
      var originalLabelDataList = chart.data.labels;

      //var chartOptions =  chart.options.scales.xAxes[0];
      var startX = chart.options.scales.x.min;
      var endX = chart.options.scales.x.max;
    
      if (startX && typeof startX === 'object')
        startX = startX._d.getTime();
      if (endX && typeof endX === 'object')
        endX = endX._d.getTime();
    
      for (var i = 0; i < originalDatasetsData.length; i++) {
        var originalData = originalDatasetsData[i];
        if (!originalData.length)
          continue;
    
        var firstElement = {
          index: 0,
          time: null
        };
        var lastElement = {
          index: originalData.length - 1,
          time: null
        };
    
        for (var j = 0; j < originalData.length; j++) {
          var time = originalData[j].x;
          if (time >= startX && (firstElement.time === null || time < firstElement.time)) {
            firstElement.index = j;
            firstElement.time = time;
          }
          if (time <= endX && (lastElement.time === null || time > lastElement.time)) {
            lastElement.index = j;
            lastElement.time = time;
          }
        }
        startIndex = firstElement.index <= lastElement.index ? firstElement.index : lastElement.index;
        endIndex = firstElement.index >= lastElement.index ? firstElement.index : lastElement.index;
        // datasets[i].data = context.reduce(originalData.slice(startIndex, endIndex + 1), maxRenderedPointsX, context);
        datasets[i].data = context.reduce(context, originalData.slice(startIndex, endIndex + 1), maxRenderedPointsX, true);
      }
      dataLabels = context.reduce(context, originalLabelDataList.slice(startIndex, endIndex + 1), maxRenderedPointsX, false);
      context.setStateMaxMinYValues(datasets[i].data)
    }
    
    // returns a reduced version of the data array, averaging x and y values
    reduce(context, data, maxCount, useAverage) {
      if (data.length <= maxCount)
        return data;
      var blockSize = data.length / maxCount;
      var reduced = [];
      for (var i = 0; i < data.length;) {
        var chunk = data.slice(i, (i += blockSize) + 1);
        useAverage ? reduced.push(context.average(chunk)) : reduced.push(chunk)
      }
      return reduced;
    }
    
    average(chunk) {
      var x = 0;
      for (var i = 0; i < chunk.length; i++) {
        x += chunk[i];
      }
      return {
        x: Math.round(x / chunk.length)
      };
    }


    onScrollHandler = (valueOfComponent) => {              
      this.state.StateOfPopup.scrollX = valueOfComponent;
      this.setState(this.state.StateOfPopup);  

      //console.log('setCurrentNodeName ' + this.state.StateOfPopup.nameOfNode);  
    }


    setUserMetaDataFromCurrentProfile = (valueOfComponent) =>
    {
      this.state.userMetaData.UserId = valueOfComponent.UserId
      this.state.userMetaData.alpaca_authorized = valueOfComponent.alpaca_authorized
      // this.state.userMetaData.alpaca_client_id = valueOfComponent.alpaca_client_id
      // this.state.userMetaData.client_available_funds = valueOfComponent.client_available_funds
      // this.state.userMetaData.client_total_invested = valueOfComponent.client_total_invested
      // this.state.userMetaData.client_total_profit = valueOfComponent.client_total_profit
      // this.state.userMetaData.client_total_wins = valueOfComponent.client_total_wins
      // this.state.userMetaData.client_total_losses = valueOfComponent.client_total_losses
      // this.state.userMetaData.client_total_winrate = valueOfComponent.client_total_winrate
      // this.state.userMetaData.investing_active = valueOfComponent.investing_active
      this.setState(this.state.userMetaData);       
    }


    ConfiguredSliderInvestAmountValueChanged = (event,
      valueOfComponent) =>
    {

      if (valueOfComponent === undefined)
      {
        valueOfComponent = ''
      }
      this.state.StateOfUserProfilePopup.ConfiguredInvestAmountValue = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)    
    }


    ConfiguredStartDateValueChanged = (valueOfComponent) =>
    {
      let dateString = ''
      if (valueOfComponent != undefined)
      {
        dateString = new Date(valueOfComponent);
        this.state.StateOfUserProfilePopup.ConfiguredStartDateValue = dateString      
        this.setState(this.state.StateOfUserProfilePopup)   
      } 
    }


    ConfiguredEndDateValueChanged = (valueOfComponent) =>
    {
      let dateString = ''
      if (valueOfComponent != undefined)
      {
        dateString = new Date(valueOfComponent);
        this.state.StateOfUserProfilePopup.ConfiguredEndDateValue = dateString      
        this.setState(this.state.StateOfUserProfilePopup)   
      } 
    }


    ConfiguredInputInvestValueChanged = (event) =>
    {
      if (event.target.value != undefined)
      {
        let valueNumber = parseFloat(event.target.value);
        if (valueNumber != NaN) {
          if(valueNumber <= this.state.StateOfUserProfilePopup.client_free_cash) //Ensure user can't input value greater than the available free cash
          {
            if(event.target.value.includes('.') && !valueNumber.toString().includes('.')) //If the user inputs a . then ensure the . is added to te end of the valueNumber, as parseFloat removes . if that is the end of the user input
            {
              valueNumber += '.'
              if(event.target.value.includes('.0')) //If the user inputs a .0 then ensure the 0 is added to te end of the valueNumber, as parseFloat removes .0 if that is the end of the user input
              {
                valueNumber += '0'
              }
            }
            this.state.StateOfUserProfilePopup.ConfiguredInvestAmountValue = valueNumber 
          }
          else
          {
            this.state.StateOfUserProfilePopup.ConfiguredInvestAmountValue = this.state.StateOfUserProfilePopup.client_free_cash 
          }
          this.setState(this.state.StateOfUserProfilePopup) 
        }
      }       
    }


    HandleLiveTradeButtonClick = (valueOfComponenet) => 
    {
      let strategyType= 'Live'
      this.RequestStrategy(strategyType)
    }


    HandleSimulateStrategyButtonClick = (valueOfComponenet) => {
      let strategyType= 'Simulated'
      this.RequestStrategy(strategyType)
    }


    async RequestStrategy (strategyType)
    {
      let userId = 'G' + this.state.StateOfUserProfilePopup.userId 
      let userData = {UserId: userId, TestRunId: this.state.currentTestRunName, StrategyType: strategyType, start_date: new Date(this.state.StateOfUserProfilePopup.ConfiguredStartDateValue), end_date: new Date(this.state.StateOfUserProfilePopup.ConfiguredEndDateValue),
        amount_to_invest: this.state.StateOfUserProfilePopup.ConfiguredInvestAmountValue}

      let urlString = ''
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      }
      await axios({
        method:'post',
        url: urlString + 'StrategyRequest',
        headers: {'content-type': 'application/json'},
        data: userData
      }) 
      .then(res => { 
          console.log(res);
      });
    }

    setStateIsDecidingToInvest = (valueOfComponent) =>
    {
      this.state.StateOfUserProfilePopup.IsDecidingToInvest = valueOfComponent      
      this.setState(this.state.StateOfUserProfilePopup)    
    }


    HandleLiveTradeStrategyButtonClick = (valueOfComponenet) => 
    {
      this.setStateIsDecidingToInvest(!this.state.StateOfUserProfilePopup.IsDecidingToInvest)
    }

    setStateUserSelectedTableButtonClick = (valueOfComponenet) =>
    {
      this.ResetUserPromptChartValues()
      this.state.StateOfUserProfilePopup.CurrentUserSelectedTable = valueOfComponenet
      this.setState(this.state.StateOfUserProfilePopup)
    }

    GetAndSetUserStrategies = async (urlString, userId) => 
    {
      userId = this.FormatUserId(userId)
      const userStrategiesRaw = await axios.get(urlString + 'Users/CustomSelector/UserStrategies/' + userId)
      let userStrategiesData = userStrategiesRaw.data
      return userStrategiesData;
    };     

    GetAndSetUserOrders = async (urlString, userId) => {
      userId = this.FormatUserId(userId)
      const userOrdersRaw = await axios.get(urlString + 'Users/CustomSelector/UserOrders/' + userId)
      let userOrdersData = userOrdersRaw.data
      return userOrdersData;
    };   

    GetAndSetUserAssets = async (urlString, userId) => {
      userId = this.FormatUserId(userId)
      const userAssetsRaw = await axios.get(urlString + 'Users/GetAssetMetrics/' + userId)
      let userAssetsData = userAssetsRaw.data
      return userAssetsData;
    };

    GetAndSetUserIndicators = async (urlString, userId) => {
      userId = this.FormatUserId(userId)
      const userIndicatorsRaw = await axios.get(urlString + 'Users/GetIndicatorMetrics/' + userId)
      let userIndicatorsData = userIndicatorsRaw.data
      return userIndicatorsData;
    };

    FormatUserId = (userId) =>
    {
      let noPrefixUsers = ['Always Buy 1','$1 Investor','$10 Investor','$100 Investor','$1000 Investor','$10000 Investor']
      if (!noPrefixUsers.includes(userId))
      {
        userId = 'G' + userId
      }
      return userId
    }
    
    UserPromptStrategyTypeChanged = (event, data) =>
    {
      //Check if user table was just reloaded, so no need to update any values
      if (this.state.StateOfUserProfilePopup.StrategyType == 'My Strategies' && 'My Strategies' == data.value) 
      {
        return
      }

      this.state.StateOfUserProfilePopup.StrategyType = data.value       
      let urlString = ''
      let userId = 'My Strategies' == data.value ? this.state.StateOfUserProfilePopup.userId : data.value
      if(this.state.connectionType == "Test")
      {
        urlString = 'api/values/'
      }
      else
      {
        urlString = 'NeuralTradingNetwork/api/values/'
      } 

      this.GetAndSetUserStrategies(urlString, userId).then(userStrategies => 
      {
        this.setStateUserStrategies(userStrategies)
      })          

      this.GetAndSetUserOrders(urlString, userId).then(userOrders => 
      {
        this.setStateUserOrders(userOrders)
      })

      this.GetAndSetUserAssets(urlString, userId).then(userAssets => 
        {
          this.setStateUserAssets(userAssets)
        })

        this.GetAndSetUserIndicators(urlString, userId).then(userIndicators => 
          {
            this.setStateUserIndicators(userIndicators)
          })
      
      this.setState(this.state.StateOfUserProfilePopup)    
    }

    render() {
      let priorityStateTemp = this.props.value;
      if (priorityStateTemp === undefined )
      {
        return (
          <TestRunAndTickerRepoContext.Provider value={{
              statePriority: this.state.statePriority,
              stateTestRun: this.state.stateTestRun,
              stateTicker: this.state.stateTicker,
              TestRunAndTickerDictionary: this.state.TestRunAndTickerDictionary,
              CurrentValues: this.state.CurrentValues,
              newLabelValues: this.state.newLabelValues,
              priceDataList: this.state.priceDataList,
              macdDataList: this.state.macdDataList,
              histogramMacdDataList: this.state.histogramMacdDataList,
              pointsArray: this.state.pointsArray,
              mousePoint: this.state.mousePoint,
              showModalPopup: this.state.showModalPopup,
              StateOfCurrentStrategy: this.state.StateOfCurrentStrategy,
              StateOfPopup: this.state.StateOfPopup,
              needToInitialize: this.state.needToInitialize,
              showLanding: this.state.showLanding,
              isCurrentTestRunAndTickerProfitable: this.state.isCurrentTestRunAndTickerProfitable,
              orderDataList: this.orderDataList,
              MacdAnnotations: this.MacdAnnotations,
              macdBuySellSignals: this.macdBuySellSignals,
              rsiDataList: this.state.rsiDataList,
              rsiAttributes: this.state.rsiAttributes,
              RsiAnnotations: this.state.RsiAnnotations,
              currentXMin: this.state.currentXMin,
              currentXMax: this.state.currentXMax,
              singleTrendRsiDataList: this.state.singleTrendRsiDataList,
              singleTrendAttributes: this.state.singleTrendAttributes,
              StateOfStrategyDataFilterTable: this.state.StateOfStrategyDataFilterTable,
              searchBoxTestRunName: this.state.searchBoxTestRunName,
              searchBoxRating: this.state.searchBoxRating,
              searchBoxProfit: this.state.searchBoxProfit,
              searchBoxLifeTime: this.state.searchBoxLifeTime,
              searchBoxWinLossRate: this.state.searchBoxWinLossRate,
              searchBoxNumberOfTrades: this.state.searchBoxNumberOfTrades,
              operationForRating: this.state.operationForRating,
              operationForLifeTime: this.state.operationForLifeTime,
              operationForProfit: this.state.operationForProfit,
              operationForWinLossRate: this.state.operationForWinLossRate,
              operationForNumberOfTrades: this.state.operationForNumberOfTrades,
              sortTestRunName: this.state.sortTestRunName,
              sortRating: this.state.sortRating,
              sortLifeTime: this.state.sortLifeTime,
              sortProfit: this.state.sortProfit,
              sortWinLossRate: this.state.sortWinLossRate,
              sortNumberOfTrades: this.state.sortNumberOfTrades,    
              landingPageShowingTutorial: this.state.landingPageShowingTutorial,
              connectionType: this.state.connectionType,
              scrollX: this.state.scrollX,
              userMetaData: this.state.userMetaData,
              StateOfUserProfilePopup: this.state.StateOfUserProfilePopup,
              SelectedStrategy: this.state.SelectedStrategy,
              setStateOfComponentPriority: this.setStateOfComponentPriority,
              setStateOfComponentTestRun: this.setStateOfComponentTestRun,
              setStateOfComponentTicker: this.setStateOfComponentTicker,
              handlePriorityInputChange: this.handlePriorityInputChange,
              handleTestRunInputChange: this.handleTestRunInputChange,
              handlePriorityInputChange: this.handlePriorityInputChange,
              handleTickerInputChange: this.handleTickerInputChange,              
              UpdateTestRunAndTickerDictionary: this.UpdateTestRunAndTickerDictionary,
              UpdateCurrentValues: this.UpdateCurrentValues,
              setStateOfTestRunDictionary: this.setStateOfTestRunDictionary,
              handleGetTestRunAndTickerButtonClick: this.handleGetTestRunAndTickerButtonClick,
              HandleSimulateStrategyButtonClick: this.HandleSimulateStrategyButtonClick,
              handlePointArrayUpdated: this.handlePointArrayUpdated,
              handleMousePointUpdated: this.handleMousePointUpdated,
              showReport: this.showReport,
              setShowLanding: this.setShowLanding,
              setIsInitializing: this.setIsInitializing,
              setCurrentNodeName: this.setCurrentNodeName,
              GetTestRunData: this.GetTestRunData,              
              setStateOfNewLabelValues: this.setStateOfNewLabelValues,
              setStateOfNewShortEmaValues: this.setStateOfNewShortEmaValues,
              setStateOfNewLongEmaValues: this.setStateOfNewLongEmaValues,
              setStateOfNewPriceValues: this.setStateOfNewPriceValues,
              setStateOfNewMacdValues: this.setStateOfNewMacdValues,
              setStateOfNewMacdSignalValues: this.setStateOfNewMacdSignalValues,
              setTestRunAndTicker: this.setTestRunAndTicker,
              setMainPageHasRerendered: this.setMainPageHasRerendered,
              setIsCurrentTestRunAndTickerProfitable: this.setIsCurrentTestRunAndTickerProfitable,
              setOrderDataList: this.setOrderDataList,
              setTestRunExecutionTracking: this.setTestRunExecutionTracking,
              setStateOfMacdBuySellSignals: this.setStateOfMacdBuySellSignals,
              setStateOfNewRsiValues: this.setStateOfNewRsiValues,
              setStateOfNewRsiAttributesValues: this.setStateOfNewRsiAttributesValues,
              setStateOfRsiBuySellSignals: this.setStateOfRsiBuySellSignals,
              setStateBuyOrderSignals: this.setStateBuyOrderSignals,
              setStateXAxis: this.setStateXAxis,
              sortTickersInTestRunByCurrentPriority: this.sortTickersInTestRunByCurrentPriority,
              sortTestRunByCurrentPriority: this.sortTestRunByCurrentPriority,
              setStateOfNewHistogramMacdValues: this.setStateOfNewHistogramMacdValues,
              setStateOfNewHistogramMacdSignalValues: this.setStateOfNewHistogramMacdSignalValues,
              setStateOfHistogramMacdBuySellSignals: this.setStateOfHistogramMacdBuySellSignals,
              setStateOfNewHistogramMacdSignalDifference: this.setStateOfNewHistogramMacdSignalDifference,
              setStateOfSingleTrendRsiBuySellSignals: this.setStateOfSingleTrendRsiBuySellSignals,
              setStateOfNewSingleTrendRsiValues: this.setStateOfNewSingleTrendRsiValues,
              setStateOfNewSingleTrendRsiAttributesValues: this.setStateOfNewSingleTrendRsiAttributesValues,
              setStateStrategiesFilterVisible: this.setStateStrategiesFilterVisible,
              setStateSearchBoxTestRunName: this.setStateSearchBoxTestRunName,
              setStateSearchBoxRating: this.setStateSearchBoxRating,
              setStateSearchBoxLifeTime: this.setStateSearchBoxLifeTime,
              setStateSearchBoxProfit: this.setStateSearchBoxProfit,
              setStateSearchBoxWinLossRate: this.setStateSearchBoxWinLossRate,
              setStateSearchBoxNumberOfTrades: this.setStateSearchBoxNumberOfTrades,
              setStateExtendedRowTestRunData: this.setStateExtendedRowTestRunData,
              setStateOperationForRating: this.setStateOperationForRating,
              setStateOperationForLifeTime: this.setStateOperationForLifeTime,
              setStateOperationForProfit: this.setStateOperationForProfit,
              setStateOperationForWinLossRate: this.setStateOperationForWinLossRate,
              setStateOperationForNumberOfTrades: this.setStateOperationForNumberOfTrades,
              setStateSortTestRunName: this.setStateSortTestRunName,
              setStateSortRating: this.setStateSortRating,
              setStateSortLifeTime: this.setStateSortLifeTime,
              setStateSortProfit: this.setStateSortProfit,
              setStateSortWinLossRate: this.setStateSortWinLossRate,
              setStateSortNumberOfTrades: this.setStateSortNumberOfTrades,
              SortStrategiesOnColumnDirectionChange: this.SortStrategiesOnColumnDirectionChange,
              GetOrderedValues: this.GetOrderedValues,
              filterData: this.filterData,
              reduce: this.reduce,
              average: this.average,
              setStateLandingPageShowingTutorial: this.setStateLandingPageShowingTutorial,
              onScrollHandler: this.onScrollHandler,
              setUserMetaDataFromCurrentProfile: this.setUserMetaDataFromCurrentProfile,
              setUserFunds: this.setUserFunds,
              setUserProfilePopupDisplaying: this.setUserProfilePopupDisplaying,
              setUserName: this.setUserName,
              setUserId: this.setUserId,
              setUserAlpacaAuthorized: this.setUserAlpacaAuthorized,
              UserPromptInputDataListOnChange: this.UserPromptInputDataListOnChange,
              UserPromptInputOperatorOnChange: this.UserPromptInputOperatorOnChange,
              UserPromptInputOrderDataListOnChange: this.UserPromptInputOrderDataListOnChange,
              UserPromptInputOrderOperatorOnChange: this.UserPromptInputOrderOperatorOnChange,
              FilterValuesPerSelectedOperation: this.FilterValuesPerSelectedOperation,
              CheckStrategyValidPerOperation: this.CheckStrategyValidPerOperation,
              setStateExtendedRowUserStrategyTestRunId: this.setStateExtendedRowUserStrategyTestRunId,
              setStateExtendedRowUserAsset: this.setStateExtendedRowUserAsset,
              setStateExtendedRowUserIndicator: this.setStateExtendedRowUserIndicator,
              setStateUserStrategies: this.setStateUserStrategies,
              setStateUserOrders: this.setStateUserOrders,
              SortUserPromptStrategiesOnColumnDirectionChange: this.SortUserPromptStrategiesOnColumnDirectionChange,
              setStateOperationForUserPromptTestRunName: this.setStateOperationForUserPromptTestRunName,
              setStateOperationForUserPromptStartedInvestingDate: this.setStateOperationForUserPromptStartedInvestingDate,
              setStateOperationForUserPromptEndedInvestingDate: this.setStateOperationForUserPromptEndedInvestingDate,
              setStateOperationForUserPromptInvested: this.setStateOperationForUserPromptInvested,
              setStateOperationForUserPromptProfit: this.setStateOperationForUserPromptProfit,
              setStateOperationForUserPromptStrategyType: this.setStateOperationForUserPromptStrategyType,
              setStateOperationForUserPromptPercentageOfWholeStrategy: this.setStateOperationForUserPromptPercentageOfWholeStrategy, 
              setStateOperationForUserPromptIndicators: this.setStateOperationForUserPromptIndicators, 
              SortUserPromptOrdersOnColumnDirectionChange: this.SortUserPromptOrdersOnColumnDirectionChange,
              setStateOperationForUserPromptOrderName: this.setStateOperationForUserPromptOrderName,
              setStateOperationForUserPromptOrderTestRunName: this.setStateOperationForUserPromptOrderTestRunName,
              setStateOperationForUserPromptOrderTime: this.setStateOperationForUserPromptOrderTime,
              setStateOperationForUserPromptOrderType: this.setStateOperationForUserPromptOrderType,
              setStateOperationForUserPromptOrderSymbol: this.setStateOperationForUserPromptOrderSymbol,
              setStateOperationForUserPromptOrderQuantity: this.setStateOperationForUserPromptOrderQuantity,
              setStateOperationForUserPromptOrderAveragePrice: this.setStateOperationForUserPromptOrderAveragePrice,
              setStateOperationForUserPromptOrderRequestedPrice: this.setStateOperationForUserPromptOrderRequestedPrice,
              setStateOperationForUserPromptOrderTag: this.setStateOperationForUserPromptOrderTag,
              setStateOperationForUserPromptOrderProfit: this.setStateOperationForUserPromptOrderProfit,
              setStateOperationForUserPromptOrderProfitProfitPercentage: this.setStateOperationForUserPromptOrderProfitPercentage,
              setStateOperationForUserPromptOrderDesiredProfit: this.setStateOperationForUserPromptOrderDesiredProfit,
              setStateOperationForUserPromptOrderStopLoss: this.setStateOperationForUserPromptOrderStopLoss,
              setStateSortUserPromptOrderProfit: this.setStateSortUserPromptOrderProfit,
              setStateSortUserPromptOrderProfitPercentage: this.setStateSortUserPromptOrderProfitPercentage,
              setStateSortUserPromptOrderDesiredProfit: this.setStateSortUserPromptOrderDesiredProfit,
              setStateSortUserPromptOrderStopLoss: this.setStateSortUserPromptOrderStopLoss,
              SortUserPromptAssetsOnColumnDirectionChange: this.SortUserPromptAssetsOnColumnDirectionChange,
              UserPromptInputAssetOperatorOnChange: this.UserPromptInputAssetOperatorOnChange,
              setStateUserAssets: this.setStateUserAssets,
              setStateOperationForUserPromptAssetName: this.setStateOperationForUserPromptAssetName,
              setStateOperationForUserPromptAssetType: this.setStateOperationForUserPromptAssetType,
              setStateOperationForuserPromptAssetInvested: this.setStateOperationForuserPromptAssetInvested,
              setStateOperationForuserPromptAssetWinRate: this.setStateOperationForuserPromptAssetWinRate,
              setStateOperationForuserPromptAssetWins: this.setStateOperationForuserPromptAssetWins,
              setStateOperationForUserPromptAssetLosses: this.setStateOperationForUserPromptAssetLosses,
              setStateOperationForUserPromptAssetProfit: this.setStateOperationForUserPromptAssetProfit,
              setStateOperationForUserPromptAssetProfitProfitPercentage: this.setStateOperationForNumberOfTrades,
              setStateSortUserPromptAssetProfit: this.setStateSortUserPromptAssetProfit,
              setStateSortUserPromptAssetNumberOfTrades: this.setStateSortUserPromptAssetNumberOfTrades,
              UserPromptInputAssetDataListOnChange: this.UserPromptInputAssetDataListOnChange,
              GetAndSetUserAssets: this.GetAndSetUserAssets,
              SetStateCurrentSelectedAsset: this.SetStateCurrentSelectedAsset,     
              setStateOperationForAssetNumberOfTrades: this.setStateOperationForAssetNumberOfTrades,         
              SortUserPromptIndicatorsOnColumnDirectionChange: this.SortUserPromptIndicatorsOnColumnDirectionChange,
              UserPromptInputIndicatorOperatorOnChange: this.UserPromptInputIndicatorOperatorOnChange,
              setStateUserIndicators: this.setStateUserIndicators,
              setStateOperationForUserPromptIndicatorName: this.setStateOperationForUserPromptIndicatorName,
              setStateOperationForUserPromptIndicatorType: this.setStateOperationForUserPromptIndicatorType,
              setStateOperationForuserPromptIndicatorInvested: this.setStateOperationForuserPromptIndicatorInvested,
              setStateOperationForuserPromptIndicatorWinRate: this.setStateOperationForuserPromptIndicatorWinRate,
              setStateOperationForuserPromptIndicatorWins: this.setStateOperationForuserPromptIndicatorWins,
              setStateOperationForUserPromptIndicatorLosses: this.setStateOperationForUserPromptIndicatorLosses,
              setStateOperationForUserPromptIndicatorProfit: this.setStateOperationForUserPromptIndicatorProfit,
              setStateOperationForUserPromptIndicatorProfitProfitPercentage: this.setStateOperationForNumberOfTrades,
              setStateSortUserPromptIndicatorProfit: this.setStateSortUserPromptIndicatorProfit,
              setStateSortUserPromptIndicatorNumberOfTrades: this.setStateSortUserPromptIndicatorNumberOfTrades,
              UserPromptInputIndicatorDataListOnChange: this.UserPromptInputIndicatorDataListOnChange,
              GetAndSetUserIndicators: this.GetAndSetUserIndicators,
              SetStateCurrentSelectedIndicator: this.SetStateCurrentSelectedIndicator,
              setCurrentIndicatorMetricsByStrategy: this.setCurrentIndicatorMetricsByStrategy,
              setStateOperationIndicatorForNumberOfTrades: this.setStateOperationForIndicatorNumberOfTrades,
              handleConfigureStrategyStartDateChange: this.handleConfigureStrategyStartDateChange,
              setStateIsDecidingToInvest: this.setStateIsDecidingToInvest,
              HandleLiveTradeStrategyButtonClick: this.HandleLiveTradeStrategyButtonClick,
              ConfiguredSliderInvestAmountValueChanged: this.ConfiguredSliderInvestAmountValueChanged,
              ConfiguredInputInvestValueChanged : this.ConfiguredInputInvestValueChanged,
              ConfiguredStartDateValueChanged: this.ConfiguredStartDateValueChanged,
              ConfiguredEndDateValueChanged: this.ConfiguredEndDateValueChanged,
              HandleLiveTradeButtonClick: this.HandleLiveTradeButtonClick,
              setStateUserSelectedTableButtonClick: this.setStateUserSelectedTableButtonClick,
              ResetUserPromptChartValues: this.ResetUserPromptChartValues,
              UserPromptStrategyTypeChanged: this.UserPromptStrategyTypeChanged,
              GetAndSetUserStrategies: this.GetAndSetUserStrategies,
              GetAndSetUserOrders: this.GetAndSetUserOrders,
              SetStateCurrentSelectedStrategy: this.SetStateCurrentSelectedStrategy,
              UpdateChartHiddenDataSets: this.UpdateChartHiddenDataSets,
              setCurrentStrategyAssetMetrics: this.setCurrentStrategyAssetMetrics,
              setCurrentAssetMetricsByStrategy: this.setCurrentAssetMetricsByStrategy,
              ModifyUserIdBeforeSending: this.ModifyUserIdBeforeSending, 
              GetAllTestRunAndTickerOrderDataUrl: this.GetAllTestRunAndTickerOrderDataUrl,
              setStateMaxMinYValues: this.setStateMaxMinYValues,
            }}>
            {this.props.children}
          </TestRunAndTickerRepoContext.Provider>
        );
      }
      else
      {               
        return (
          <TestRunAndTickerRepoContext.Provider value={{
              statePriority: this.setStateOfComponentPriority(this.props.value.statePriority),
              stateTestRun: this.setStateOfComponentTestRun(this.props.value.stateTestRun),
              stateTicker: this.setStateOfComponentTicker(this.props.value.stateTicker),
              TestRunAndTickerDictionary: this.UpdateTestRunAndTickerDictionary(this.props.value.TestRunAndTickerDictionary),
              CurrentValues: this.UpdateCurrentValues (''),              
              newLabelValues: this.newLabelValues,
              priceDataList: this.state.priceDataList,
              macdDataList: this.state.macdDataList,
              histogramMacdDataList: this.state.histogramMacdDataList,
              pointsArray: this.state.pointsArray,
              mousePoint: this.state.mousePoint,
              showModalPopup: this.state.showModalPopup,
              StateOfCurrentStrategy: this.state.StateOfCurrentStrategy,
              StateOfPopup: this.state.StateOfPopup,
              needToInitialize: this.state.needToInitialize,
              showLanding: this.state.showLanding,
              isCurrentTestRunAndTickerProfitable: this.state.isCurrentTestRunAndTickerProfitable,
              orderDataList: this.orderDataList,
              MacdAnnotations: this.MacdAnnotations,
              macdBuySellSignals: this.macdBuySellSignals,
              rsiDataList: this.state.rsiDataList,
              rsiAttributes: this.state.rsiAttributes,
              RsiAnnotations: this.state.RsiAnnotations,
              currentXMin: this.state.currentXMin,
              currentXMax: this.state.currentXMax,
              singleTrendRsiDataList: this.state.singleTrendRsiDataList,
              singleTrendAttributes: this.state.singleTrendAttributes,
              StateOfStrategyDataFilterTable: this.state.StateOfStrategyDataFilterTable,
              searchBoxTestRunName: this.state.searchBoxTestRunName,
              searchBoxRating: this.state.searchBoxRating,
              searchBoxLifeTime: this.state.searchBoxLifeTime,
              searchBoxProfit: this.state.searchBoxProfit,
              searchBoxWinLossRate: this.state.searchBoxWinLossRate,
              searchBoxNumberOfTrades: this.state.searchBoxNumberOfTrades,      
              operationForRating: this.state.operationForRating,
              operationForLifeTime: this.state.operationForLifeTime,
              operationForProfit: this.state.operationForProfit,
              operationForWinLossRate: this.state.operationForWinLossRate,
              operationForNumberOfTrades: this.state.operationForNumberOfTrades,
              sortTestRunName: this.state.sortTestRunName,
              sortRating: this.state.sortRating,
              sortLifeTime: this.state.sortLifeTime,
              sortProfit: this.state.sortProfit,
              sortWinLossRate: this.state.sortWinLossRate,
              sortNumberOfTrades: this.state.sortNumberOfTrades,    
              landingPageShowingTutorial: this.state.landingPageShowingTutorial,
              connectionType: this.state.connectionType,
              scrollX: this.state.scrollX,
              userMetaData: this.state.userMetaData,
              StateOfUserProfilePopup: this.state.StateOfUserProfilePopup,
              SelectedStrategy: this.state.SelectedStrategy,
              setStateOfComponentPriority: this.setStateOfComponentPriority,
              setStateOfComponentTestRun: this.setStateOfComponentTestRun,
              setStateOfComponentTicker: this.setStateOfComponentTicker,
              handleTestRunInputChange: this.handleTestRunInputChange,
              handlePriorityInputChange: this.handlePriorityInputChange,
              handleTickerInputChange: this.handleTickerInputChange,
              UpdateTestRunAndTickerDictionary: this.UpdateTestRunAndTickerDictionary,
              UpdateCurrentValues: this.UpdateCurrentValues,
              setStateOfTestRunDictionary: this.setStateOfTestRunDictionary,
              handleGetTestRunAndTickerButtonClick: this.handleGetTestRunAndTickerButtonClick,
              HandleSimulateStrategyButtonClick: this.HandleSimulateStrategyButtonClick,
              handlePointArrayUpdated: this.handlePointArrayUpdated,
              handleMousePointUpdated: this.handleMousePointUpdated,
              showReport: this.showReport,
              setIsInitializing: this.setIsInitializing,
              setShowLanding: this.setShowLanding,
              setCurrentNodeName: this.setCurrentNodeName,
              GetTestRunData: this.GetTestRunData,
              setStateOfNewLabelValues: this.setStateOfNewLabelValues,
              setStateOfNewShortEmaValues: this.setStateOfNewShortEmaValues,
              setStateOfNewLongEmaValues: this.setStateOfNewLongEmaValues,
              setStateOfNewPriceValues: this.setStateOfNewPriceValues,
              setStateOfNewMacdValues: this.setStateOfNewMacdValues,
              setStateOfNewMacdSignalValues: this.setStateOfNewMacdSignalValues,
              setTestRunAndTicker: this.setTestRunAndTicker,
              setMainPageHasRerendered: this.setMainPageHasRerendered,
              setIsCurrentTestRunAndTickerProfitable: this.setIsCurrentTestRunAndTickerProfitable,
              setOrderDataList: this.setOrderDataList,
              setTestRunExecutionTracking: this.setTestRunExecutionTracking,
              setStateOfMacdBuySellSignals: this.setStateOfMacdBuySellSignals,
              setStateOfNewRsiValues: this.setStateOfNewRsiValues,
              setStateOfNewRsiAttributesValues: this.setStateOfNewRsiAttributesValues,
              setStateOfRsiBuySellSignals: this.setStateOfRsiBuySellSignals,
              setStateBuyOrderSignals: this.setStateBuyOrderSignals,
              setStateXAxis: this.setStateXAxis,
              sortTickersInTestRunByCurrentPriority: this.sortTickersInTestRunByCurrentPriority,
              sortTestRunByCurrentPriority: this.sortTestRunByCurrentPriority,
              setStateOfNewHistogramMacdValues: this.setStateOfNewHistogramMacdValues,
              setStateOfNewHistogramMacdSignalValues: this.setStateOfNewHistogramMacdSignalValues,
              setStateOfHistogramMacdBuySellSignals: this.setStateOfHistogramMacdBuySellSignals,
              setStateOfNewHistogramMacdSignalDifference: this.setStateOfNewHistogramMacdSignalDifference,
              setStateOfSingleTrendRsiBuySellSignals: this.setStateOfSingleTrendRsiBuySellSignals,
              setStateOfNewSingleTrendRsiValues: this.setStateOfNewSingleTrendRsiValues,
              setStateOfNewSingleTrendRsiAttributesValues: this.setStateOfNewSingleTrendRsiAttributesValues,
              setStateStrategiesFilterVisible: this.setStateStrategiesFilterVisible,
              setStateSearchBoxTestRunName: this.setStateSearchBoxTestRunName,
              setStateSearchBoxRating: this.setStateSearchBoxRating,
              setStateSearchBoxLifeTime: this.setStateSearchBoxLifeTime,
              setStateSearchBoxProfit: this.setStateSearchBoxProfit,
              setStateSearchBoxWinLossRate: this.setStateSearchBoxWinLossRate,
              setStateSearchBoxNumberOfTrades: this.setStateSearchBoxNumberOfTrades,
              setStateExtendedRowTestRunData: this.setStateExtendedRowTestRunData,
              setStateOperationForRating: this.setStateOperationForRating,
              setStateOperationForLifeTime: this.setStateOperationForLifeTime,
              setStateOperationForProfit: this.setStateOperationForProfit,
              setStateOperationForWinLossRate: this.setStateOperationForWinLossRate,
              setStateOperationForNumberOfTrades: this.setStateOperationForNumberOfTrades,
              setStateSortTestRunName: this.setStateSortTestRunName,
              setStateSortRating: this.setStateSortRating,
              setStateSortLifeTime: this.setStateSortLifeTime,
              setStateSortProfit: this.setStateSortProfit,
              setStateSortWinLossRate: this.setStateSortWinLossRate,
              setStateSortNumberOfTrades: this.setStateSortNumberOfTrades,
              SortStrategiesOnColumnDirectionChange: this.SortStrategiesOnColumnDirectionChange,
              GetOrderedValues: this.GetOrderedValues,
              filterData: this.filterData,
              reduce: this.reduce,
              average: this.average,
              setStateLandingPageShowingTutorial: this.setStateLandingPageShowingTutorial,
              onScrollHandler: this.onScrollHandler,
              setUserMetaDataFromCurrentProfile: this.setUserMetaDataFromCurrentProfile,
              setUserFunds: this.setUserFunds,
              setUserProfilePopupDisplaying: this.setUserProfilePopupDisplaying,
              setUserName: this.setUserName,
              setUserId: this.setUserId,
              setUserAlpacaAuthorized: this.setUserAlpacaAuthorized,
              UserPromptInputDataListOnChange: this.UserPromptInputDataListOnChange,
              UserPromptInputOperatorOnChange: this.UserPromptInputOperatorOnChange,
              UserPromptInputOrderDataListOnChange: this.UserPromptInputOrderDataListOnChange,
              UserPromptInputOrderOperatorOnChange: this.UserPromptInputOrderOperatorOnChange,
              FilterValuesPerSelectedOperation: this.FilterValuesPerSelectedOperation,
              CheckStrategyValidPerOperation: this.CheckStrategyValidPerOperation,
              setStateExtendedRowUserStrategyTestRunId: this.setStateExtendedRowUserStrategyTestRunId,
              setStateUserStrategies: this.setStateUserStrategies,
              setStateUserOrders: this.setStateUserOrders,
              SortUserPromptStrategiesOnColumnDirectionChange: this.SortUserPromptStrategiesOnColumnDirectionChange,
              setStateOperationForUserPromptTestRunName: this.setStateOperationForUserPromptTestRunName,
              setStateOperationForUserPromptStartedInvestingDate: this.setStateOperationForUserPromptStartedInvestingDate,
              setStateOperationForUserPromptEndedInvestingDate: this.setStateOperationForUserPromptEndedInvestingDate,
              setStateOperationForUserPromptInvested: this.setStateOperationForUserPromptInvested,
              setStateOperationForUserPromptProfit: this.setStateOperationForUserPromptProfit,
              setStateOperationForUserPromptStrategyType: this.setStateOperationForUserPromptStrategyType,
              setStateOperationForUserPromptPercentageOfWholeStrategy: this.setStateOperationForUserPromptPercentageOfWholeStrategy, 
              setStateOperationForUserPromptIndicators: this.setStateOperationForUserPromptIndicators, 
              SortUserPromptOrdersOnColumnDirectionChange: this.SortUserPromptOrdersOnColumnDirectionChange,
              setStateOperationForUserPromptOrderName: this.setStateOperationForUserPromptOrderName,
              setStateOperationForUserPromptOrderTestRunName: this.setStateOperationForUserPromptOrderTestRunName,
              setStateOperationForUserPromptOrderTime: this.setStateOperationForUserPromptOrderTime,
              setStateOperationForUserPromptOrderType: this.setStateOperationForUserPromptOrderType,
              setStateOperationForUserPromptOrderSymbol: this.setStateOperationForUserPromptOrderSymbol,
              setStateOperationForUserPromptOrderQuantity: this.setStateOperationForUserPromptOrderQuantity,
              setStateOperationForUserPromptOrderAveragePrice: this.setStateOperationForUserPromptOrderAveragePrice,
              setStateOperationForUserPromptOrderRequestedPrice: this.setStateOperationForUserPromptOrderRequestedPrice,
              setStateOperationForUserPromptOrderTag: this.setStateOperationForUserPromptOrderTag,
              setStateOperationForUserPromptOrderProfit: this.setStateOperationForUserPromptOrderProfit,
              setStateOperationForUserPromptOrderProfitProfitPercentage: this.setStateOperationForUserPromptOrderProfitPercentage,
              setStateOperationForUserPromptOrderDesiredProfit: this.setStateOperationForUserPromptOrderDesiredProfit,
              setStateOperationForUserPromptOrderStopLoss: this.setStateOperationForUserPromptOrderStopLoss,
              setStateSortUserPromptOrderProfit: this.setStateSortUserPromptOrderProfit,
              setStateSortUserPromptOrderProfitPercentage: this.setStateSortUserPromptOrderProfitPercentage,
              setStateSortUserPromptOrderDesiredProfit: this.setStateSortUserPromptOrderDesiredProfit,
              setStateSortUserPromptOrderStopLoss: this.setStateSortUserPromptOrderStopLoss,
              SortUserPromptAssetsOnColumnDirectionChange: this.SortUserPromptAssetsOnColumnDirectionChange,
              UserPromptInputAssetOperatorOnChange: this.UserPromptInputAssetOperatorOnChange,
              setStateUserAssets: this.setStateUserAssets,
              setStateOperationForUserPromptAssetName: this.setStateOperationForUserPromptAssetName,
              setStateOperationForUserPromptAssetType: this.setStateOperationForUserPromptAssetType,
              setStateOperationForuserPromptAssetInvested: this.setStateOperationForuserPromptAssetInvested,
              setStateOperationForuserPromptAssetWinRate: this.setStateOperationForuserPromptAssetWinRate,
              setStateOperationForuserPromptAssetWins: this.setStateOperationForuserPromptAssetWins,
              setStateOperationForUserPromptAssetLosses: this.setStateOperationForUserPromptAssetLosses,
              setStateOperationForUserPromptAssetProfit: this.setStateOperationForUserPromptAssetProfit,
              setStateOperationForAssetNumberOfTrades: this.setStateOperationForAssetNumberOfTrades,
              setStateSortUserPromptAssetProfit: this.setStateSortUserPromptAssetProfit,
              setStateSortUserPromptAssetNumberOfTrades: this.setStateSortUserPromptAssetNumberOfTrades,
              UserPromptInputAssetDataListOnChange: this.UserPromptInputAssetDataListOnChange,
              setStateUserIndicators: this.setStateUserIndicators,
              setStateExtendedRowUserAsset: this.setStateExtendedRowUserAsset,              
              SetStateCurrentSelectedAsset: this.SetStateCurrentSelectedAsset,   
              setStateOperationForUserPromptIndicatorName: this.setStateOperationForUserPromptIndicatorName,
              setStateOperationForUserPromptIndicatorType: this.setStateOperationForUserPromptIndicatorType,
              setStateOperationForuserPromptIndicatorInvested: this.setStateOperationForuserPromptIndicatorInvested,
              setStateOperationForuserPromptIndicatorWinRate: this.setStateOperationForuserPromptIndicatorWinRate,
              setStateOperationForuserPromptIndicatorWins: this.setStateOperationForuserPromptIndicatorWins,
              setStateOperationForUserPromptIndicatorLosses: this.setStateOperationForUserPromptIndicatorLosses,
              setStateOperationForUserPromptIndicatorProfit: this.setStateOperationForUserPromptIndicatorProfit,
              setStateOperationIndicatorForNumberOfTrades: this.setStateOperationForIndicatorNumberOfTrades,
              setStateSortUserPromptIndicatorProfit: this.setStateSortUserPromptIndicatorProfit,
              setStateSortUserPromptIndicatorNumberOfTrades: this.setStateSortUserPromptIndicatorNumberOfTrades,
              UserPromptInputIndicatorDataListOnChange: this.UserPromptInputIndicatorDataListOnChange,
              setCurrentIndicatorMetricsByStrategy: this.setCurrentIndicatorMetricsByStrategy,
              GetAndSetUserIndicators: this.GetAndSetUserIndicators,
              setStateExtendedRowUserIndicator: this.setStateExtendedRowUserIndicator,
              SetStateCurrentSelectedIndicator: this.SetStateCurrentSelectedIndicator, 
              handleConfigureStrategyStartDateChange: this.handleConfigureStrategyStartDateChange,
              setStateIsDecidingToInvest: this.setStateIsDecidingToInvest,
              HandleLiveTradeStrategyButtonClick: this.HandleLiveTradeStrategyButtonClick,
              ConfiguredSliderInvestAmountValueChanged: this.ConfiguredSliderInvestAmountValueChanged,
              ConfiguredInputInvestValueChanged : this.ConfiguredInputInvestValueChanged,
              ConfiguredStartDateValueChanged: this.ConfiguredStartDateValueChanged,
              ConfiguredEndDateValueChanged: this.ConfiguredEndDateValueChanged,
              HandleLiveTradeButtonClick: this.HandleLiveTradeButtonClick,
              setStateUserSelectedTableButtonClick: this.setStateUserSelectedTableButtonClick,
              ResetUserPromptChartValues: this.ResetUserPromptChartValues,
              UserPromptStrategyTypeChanged: this.UserPromptStrategyTypeChanged,
              GetAndSetUserStrategies: this.GetAndSetUserStrategies,
              GetAndSetUserOrders: this.GetAndSetUserOrders,
              SetStateCurrentSelectedStrategy: this.SetStateCurrentSelectedStrategy,
              UpdateChartHiddenDataSets: this.UpdateChartHiddenDataSets,
              setCurrentStrategyAssetMetrics: this.setCurrentStrategyAssetMetrics,
              setCurrentAssetMetricsByStrategy: this.setCurrentAssetMetricsByStrategy,
              ModifyUserIdBeforeSending: this.ModifyUserIdBeforeSending, 
              GetAllTestRunAndTickerOrderDataUrl: this.GetAllTestRunAndTickerOrderDataUrl,
              setStateMaxMinYValues: this.setStateMaxMinYValues,
            }}>
            {this.props.children}
          </TestRunAndTickerRepoContext.Provider>
        );
      }
    }
  }

export const TestRunAndTickerRepoConsumer = TestRunAndTickerRepoContext.Consumer;
export { TestRunAndTickerRepoContext };
export { TestRunAndTickerRepoContextProvider };