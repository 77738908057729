import React, {useState, Component}from 'react'
import {
  Checkbox,
  Grid,
  Button,
  Header,
  Icon,
  Input,
  Menu,
  Rating,
  Search,
  Segment,
  Sidebar,
  Table,
  Dropdown
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import Neurons from "./Neurons.js";
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
// import FilterableColumnsTable from "components/FilterableColumnsTable";
import NeuronImageOfCube from "../images/cube.png";
import NeuronImageOfGreenCube from "../images/GreenCube.png";
import NeuronImageOfRedCube from "../images/RedCube.png";
import NeuronImageOfBlueCube from "../images/blueCube.png";
import NeuronImageOfGrayCube from "../images/grayCube.png";
import NeuronImageOfYellowCube from "../images/yellowCube.png";
import NeuronImageOfPurpleCube from "../images/purpleCube.png";
// import TableExampleApprove from "TableExampleApprove";
// import { Hidden } from '@material-ui/core';
// import context from 'react-bootstrap/esm/AccordionContext';
const axios = require('axios');
// axios.defaults.baseURL = '//localhost:51728';
axios.defaults.baseURL = '//neuraltradingnetwork.com';

var NeuronImageToLoad2 = new Image();
var SelectedNeuronImageToLoad2 = new Image();
var protectedNeuronImageToLoad= new Image();
var eliminatedNeuronImageToLoad= new Image();
var SelectedLosingNeuronImageToLoad = new Image();
var V2NeuronImageToLoad = new Image();
var V3NeuronImageToLoad = new Image();
var offSetNeuronDraw = 0;

var start = null;
var delta = 0;
var lasttimestamp = null;
var particles = [];
var contextConsumer = null;
var settings = {
    pointDensity: 8,
    connections: 2,
    sizeVariation: 2,
    velocity: 0.000007,
    //Shake Nueron
    //sizeVariation: .01,
    //velocity: 1.0323,
    maxMovement: 15,
    attractionRange: 200,
    attractionFactor: 0.16,
    imagePath: "images/cube.png",
    imgWidth: 20,
    imgHeight: 18,
    lineColor: "rgb(185, 185, 185)",
    particleDensity: 0.2,
    particleChance: 0.2,
    particleVelocity: 50,
    particleColor: "rgba(255, 255, 255, 0.795)",
    particleLength: 10,
    flashRadius: 45,
    flashOpacity: 0.9,
    flashDecay: .3
};


var friendOptions = [
    {
      key: '(All)',
      text: '(All)',
      value: '(All)',
    },
    {
      key: 'Equals',
      text: 'Equals',
      value: 'Equals',
    },
    {
      key: 'Not Equals',
      text: 'Not Equals',
      value: 'Not Equals',   
    },
    {
      key: 'Greater Than',
      text: 'Greater Than',
      value: 'Greater Than',
    //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
    },
    {
      key: 'Less Than',
      text: 'Less Than',
      value: 'Less Than',
    },
    {
      key: 'Greater Than or Equal',
      text: 'Greater Than or Equal',
      value: 'Greater Than or Equal',
    },
    {
      key: 'Less Than or Equal',
      text: 'Less Than or Equal',
      value: 'Less Than or Equal',
    }
  ];

class SidebarController extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            count: 0
            };
        }

        // anotherSomething = TestRunAndTickerRepoConsumer;
        static contextType = TestRunAndTickerRepoContext;
        start = null;
        delta = 0;
        lasttimestamp = null;
        particles = [];                
        somethingelse = TestRunAndTickerRepoConsumer;
        s = 0
        somethingels1e = TestRunAndTickerRepoConsumer._context;
        GetConsumer = () => {            
          return this.context;
            // return (
            //   <TestRunAndTickerRepoConsumer>
            //     {context => {     
            
                       
            //     }}
            //   </TestRunAndTickerRepoConsumer>
            // ); 
          }
        
          
        componentDidMount() {
            //contextConsumer = this.GetConsumer()._self.context
            contextConsumer = this.context
        }

        // contextConsumer = this.GetConsumer()
        // anotherOne = 0
        // contextConsumer = contextConsumer._self.context;
        // componentDidMount() {            
        //     contextConsumer = this.GetConsumer()
        //     let anotherOne = 0
        //     contextConsumer = contextConsumer._self.context;
        // }

        HandleMouseMove = (event) => {
            contextConsumer.handleMousePointUpdated(event.offsetX, event.offsetY)
            //console.log(mousePoint.x, mousePoint.y);
          }
          
        HandleMouseOver = (event) => {
            //console.log(mousePoint.x, mousePoint.y);
          }
    
        HandleMouseClick = (event) => {
            var closestPoint = -1;
            var closestDistance;
            
    
            for(var i = 0; i < contextConsumer.pointsArray.length; i++) {
              var point = contextConsumer.pointsArray[i];
              var distanceToMouse = this.getDistance({x: event.offsetX, y: event.offsetY}, 
                {x: point.x, y: point.y });
    
              if(distanceToMouse < 30)
              {
                if(closestPoint == -1 || closestDistance == undefined || distanceToMouse < closestDistance)
                {
                  closestPoint = i;
                  closestDistance = distanceToMouse;
                }
              }
            }
    
            if(closestPoint != -1)
            {
              contextConsumer.pointsArray[closestPoint].selected = true
              if (!contextConsumer.StateOfPopup.isPropDisplaying)
              {
                let tickerDictionary = contextConsumer.TestRunAndTickerDictionary[contextConsumer.pointsArray[closestPoint].testRun].TickerDataDictionary;
                let tickerValues = Object.values(tickerDictionary);
     
    
                let tickerNameList = [];
                for(let tickerData in tickerValues)
                {
                    let testRunAndTickerValue = tickerValues[tickerData]; 
                    tickerNameList.push(testRunAndTickerValue.Ticker);
                }
                
                contextConsumer.sortTestRunByCurrentPriority(Object.values(contextConsumer.TestRunAndTickerDictionary));
                contextConsumer.sortTickersInTestRunByCurrentPriority(tickerValues);
                contextConsumer.setStateOfComponentTicker(tickerValues);
                contextConsumer.setTestRunAndTicker (contextConsumer.pointsArray[closestPoint].testRun, tickerValues[0].Ticker);
                contextConsumer.setIsCurrentTestRunAndTickerProfitable();            
                contextConsumer.setCurrentNodeName( contextConsumer.pointsArray[closestPoint].name);   
                //contextConsumer.blinkNeuron(contextConsumer.pointsArray[closestPoint].name)
                contextConsumer.showReport();         
                this.setState({ count: this.state.count + 1 }) 
              }
            }
            
          }

        getDistance = (p1, p2) => {
            return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
        }

        animate = (ctx, frameCount, timestamp, img) => {
            if (contextConsumer.userMetaData.UserId == "")
            {
              return;
            }

            // Calculate frametime
            if (!start) {
            start = timestamp;
            lasttimestamp = timestamp;
            }
            var elapsed = timestamp - start,
            delta = (timestamp - lasttimestamp)/100;
            lasttimestamp = timestamp;

            // Move points around
            for (var i = 0; i < contextConsumer.pointsArray.length; i++) {
            var point = contextConsumer.pointsArray [i];

            var attractionOffset = {x: 0, y: 0};
            var distanceToMouse = this.getDistance({x: point.originX, y: point.originY}, contextConsumer.mousePoint);
            if(distanceToMouse < 10)
            {
                let p = 0;
                //console.log(point.name + " " + point.x + " " + point.y + " " + contextConsumer.mousePoint.x + " " + contextConsumer.mousePoint.y);
            }
            if (distanceToMouse <= settings.attractionRange) {
                let displacementFactor = (Math.cos(distanceToMouse / settings.attractionRange * Math.PI) + 1) / 2 * settings.attractionFactor;

                attractionOffset.x = displacementFactor * (contextConsumer.mousePoint.x - point.x);
                attractionOffset.y = displacementFactor * (contextConsumer.mousePoint.y - point.y);
            }

            point.x = point.originX + Math.sin(elapsed*settings.velocity+point.animOffset)*settings.maxMovement*point.sizeMod+attractionOffset.x;
            point.y = point.originY - Math.cos(elapsed*settings.velocity+point.animOffset)*settings.maxMovement*point.sizeMod+attractionOffset.y;
            point.flashOpacity = Math.max(0, point.flashOpacity - settings.flashDecay * delta);
            }

            // Move particles
            for (var i = 0; i < particles.length; i++) {
            var particle = particles[i];

            var origin = contextConsumer.pointsArray[particle.origin];
            var target = origin.closest[particle.target];

            var distance = this.getDistance({x: origin.x, y: origin.y}, {x: target.x, y: target.y});
            var direction = {x: (target.x - origin.x) / distance, y: (target.y - origin.y) / distance};

            particle.traveled += settings.particleVelocity * delta;
            particle.direction = direction;

            particle.x = origin.x + direction.x * particle.traveled;
            particle.y = origin.y + direction.y * particle.traveled;

            if (!this.between(origin, {x: particle.x}, target)) {
                particles.splice(i, 1);
                i--;
            }

            }

            // Spawn new particles
            for (var i = 0; i < settings.particleDensity * contextConsumer.pointsArray.length; i++) {
            if (Math.random() < settings.particleChance * delta) {
                var pOriginNum = Math.floor(Math.random()*contextConsumer.pointsArray.length);
                var pOrigin = contextConsumer.pointsArray[pOriginNum];
                var pTargetNum = Math.floor(Math.random()*pOrigin.closest.length);
                var px = pOrigin.x;
                var py = pOrigin.y;
                var p = {origin: pOriginNum, target: pTargetNum, x: px, y: py, traveled: 0, direction: {x: 0, y: 0}};
                particles.push(p);
                pOrigin.flashOpacity = settings.flashOpacity;
            }
            }

            this.draw(ctx, frameCount, img);

            //window.requestAnimationFrame(ctx, animate);

        }

        between = (p1, p2, t) => {
                return (p1.x - p2.x) * (p2.x - t.x) > 0;
        }

        contains = (a, obj) => {
            if (a !== undefined) {
            for (var i = 0; i < a.length; i++) {
                if (a[i] === obj) {
                return true;
                }
            }
            }
            return false;
        }


        drawLines = (ctx, p) => {
            for(var i in p.closest) {
            ctx.beginPath();
            ctx.moveTo(p.x, p.y);
            ctx.lineTo(p.closest[i].x, p.closest[i].y);
            ctx.strokeStyle = settings.lineColor;
            ctx.stroke();
            }
        }




        createPoints = (ctx, testRunData) => {
        let testData = Object.values(testRunData);
        let testKeys = Object.keys(testRunData);
        let testRunSortedByMostProfitable = testData.sort(function(a, b){return a.TestRunProfit - b.TestRunProfit});
        
        let leastProfit = testRunSortedByMostProfitable[0].TestRunProfit;
        let mostProfit = testRunSortedByMostProfitable[testRunSortedByMostProfitable.length-1].TestRunProfit;
        //Find the least profitable test run that is above the elimination line
        // for(let i = 0; i < testRunSortedByMostProfitable.length; i++)
        // {
        //     let sortedTestData = testRunSortedByMostProfitable[i];
        //     if(sortedTestData.TestRunProfit > -700 && sortedTestData.WinLossRate >= .2)
        //     {
        //         leastProfit = sortedTestData.TestRunProfit - 50;
        //         break;
        //     }
        // }

        // //Find the most profitable test run that is below the protection line
        // for(let i = testRunSortedByMostProfitable.length-1; i >0; i--)
        // {
        //     let sortedTestData = testRunSortedByMostProfitable[i];
        //     if(sortedTestData.TestRunProfit < 700)
        //     {
        //         mostProfit = sortedTestData.TestRunProfit + 40;
        //         break;
        //     }
        // }
        // for(var x = 0 - 100; x < ctx.canvas.width + 100; x = x + 1000/settings.pointDensity) {
        //   for(var y = 0 - 100; y < ctx.canvas.height + 100; y = y + 1000/settings.pointDensity) {
        //     var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
        //     var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
        //     var pSizeMod = Math.random()*settings.sizeVariation+1
        //     var pw = settings.imgWidth*pSizeMod;
        //     var ph = settings.imgHeight*pSizeMod;
        //     var pAnimOffset = Math.random()*2*Math.PI;
        //     var p = {name: "Neuron " + contextConsumer.pointsArray.length, x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
        //     animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false};
        //     contextConsumer.handlePointArrayUpdated(p);
        //   }
        // }

        let numberRecreated = 0;
        for(var testResultIndex in testData) {
            let testResult = testData[testResultIndex]

            //Get the percentage difference of the current profit to the most profit of all test runs (max profit test run will return 1)
            let profitScale = testResult.TestRunProfit - leastProfit
            let maxProfitScale = mostProfit - leastProfit
            let currentProfitScale = profitScale / maxProfitScale
            if (currentProfitScale < .05)
            {
              currentProfitScale = .05
            }
            else if (currentProfitScale > .95)
            {
              currentProfitScale = .95
            }
            var px = ctx.canvas.width * currentProfitScale;
            let yOffset = Math.random()*.01;
            let randomOffset = Math.random() *.2;
            let actualHeightcalculation = (1 - (testResult.WinLossRate == 1 ? (.99 + (yOffset)) : testResult.WinLossRate > .5 ? testResult.WinLossRate + randomOffset : testResult.WinLossRate - randomOffset))
            if (actualHeightcalculation < .05)
            {
              actualHeightcalculation = .05
            }
            else if(actualHeightcalculation > .95)
            {
              actualHeightcalculation = .95
            }
            
            var py = ctx.canvas.height * actualHeightcalculation;

            if(testResult.WinLossRate > .8)
            {
                py = ctx.canvas.height * (.1 * testResult.WinLossRate)
            }
            // var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
            // var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
            var pSizeMod = Math.random()*settings.sizeVariation;
            var pw = settings.imgWidth*pSizeMod;
            var ph = settings.imgHeight*pSizeMod;
            var pAnimOffset = Math.random()*2*Math.PI;
            var randomSizeMult = settings.flashRadius/(2); //settings.flashRadius/(2 + Math.random()); //Random size of neurons
            // if(testResult.TestRunProfit > 700)
            // {
            // // px = ctx.canvas.width * (.01 + yOffset)
            // pAnimOffset = 0
            // pw = 0
            // ph = 0
            // py = ctx.canvas.height * (.9 + (.15 * testResult.WinLossRate))
            // }
            // if(testResult.TestRunProfit < -700 || testResult.WinLossRate == 0)
            // {
            // // px = ctx.canvas.width * (.01 + yOffset)
            // pAnimOffset = 0
            // pw = 0
            // ph = 0
            // py = ctx.canvas.height * (.9 + (.15 * testResult.WinLossRate))
            // }
            // if(-30 < py && py < screenOffset)
            // {
            //   py = screenOffset + 60 + (Math.random()*100);
            // }
            var p = {name: testResult.TestRunId + " " + numberRecreated, testRun: testResult.TestRunId, x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
            animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false, sizeMultiplier: randomSizeMult};
            contextConsumer.handlePointArrayUpdated(p);
            // for(var i = 0; i < testRunNames.length; i++) {
            //     var x = ctx.canvas.width * Math.random();
            //     var y = ctx.canvas.height * Math.random();
            //     var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
            //     var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
            //     var pSizeMod = Math.random()*settings.sizeVariation+1;
            //     var pw = settings.imgWidth*pSizeMod;
            //     var ph = settings.imgHeight*pSizeMod;
            //     var pAnimOffset = Math.random()*2*Math.PI;
            //     var p = {name: testRunNames[i] + " " + numberRecreated + i, testRun: testRunNames[i], x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
            //     animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false};
            //     contextConsumer.handlePointArrayUpdated(p);
            // }
            numberRecreated++
        }
        

        for(var i = 0; i < contextConsumer.pointsArray.length; i++) {
            var closest = [];
            var p1 = contextConsumer.pointsArray[i];
            for(var j = 0; j < contextConsumer.pointsArray.length; j++) {
            var p2 = contextConsumer.pointsArray[j]
            if(!this.contains(p2.closest, p1) && p1 != p2) {
                var placed = false;
                for(var k = 0; k < settings.connections; k++) {
                if(!placed && closest[k] == undefined) {
                    closest[k] = p2;
                    placed = true;
                }
                }

                for(var k = 0; k < settings.connections; k++) {
                if(!placed && this.getDistance(p1, p2) < this.getDistance(p1, closest[k])) {
                    closest[k] = p2;
                    placed = true;
                }
                }
            }
            }
            p1.closest = closest;
        }
        }

        draw = (ctx, frameCount, img) => {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

          if (contextConsumer.needToInitialize)
          {
            contextConsumer.needToInitialize = false;
            let urlString = ''
            if(contextConsumer.connectionType == "Test")
            {
              urlString = 'api/values/5'
            }
            else
            {
              urlString = 'NeuralTradingNetwork/api/values/5'
            }
            //axios.get('http://localhost:51722/api/values/5')
            axios({
              method:'get',
              url: urlString
            })
                .then(res => {
                    contextConsumer.UpdateTestRunAndTickerDictionary(res.data);

                    let orderTestRunIdsBy = ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average'];
                    
                    let finalTestRunDataList = contextConsumer.sortTestRunByCurrentPriority(Object.values(contextConsumer.TestRunAndTickerDictionary));

                    let allTestRuns = Object.keys(contextConsumer.TestRunAndTickerDictionary);
                    //contextConsumer.setStateOfComponentTestRun({dataValues:  contextConsumer.TestRunAndTickerDictionary})
                    contextConsumer.setStateOfComponentTestRun({dataValues:  finalTestRunDataList})

                    // console.log('allTestRuns: ' + allTestRuns);
                    // console.log('Object.values(allTestRuns): ' + Object.values(allTestRuns));
                    // console.log('Object.keys(allTestRuns): ' + Object.keys(allTestRuns));
                    for (let value in Object.values(allTestRuns)) {         
                    var testRunName = allTestRuns[value];
                    var TestRunData = res.data[testRunName];

                    var tickerList = [];
                    //Object.values(testRunAndTicker[key].TickerDataDictionary)
                    for(let tickerDataInfo in TestRunData.TickerDataDictionary) {     
                        let tickerValue = TestRunData.TickerDataDictionary[tickerDataInfo];
                        tickerList.push(tickerValue);     
                    }
                    }

                    // contextConsumer.sortTickersInTestRunByCurrentPriority(tickerList);
                    contextConsumer.setStateOfComponentTicker(Object.values(contextConsumer.CurrentValues))
                    contextConsumer.setStateOfComponentPriority({dataValues:  orderTestRunIdsBy})
                    this.createPoints(ctx, contextConsumer.TestRunAndTickerDictionary);
                    contextConsumer.setIsInitializing();
                });
                
                //createPoints(ctx);
        }
        else if(contextConsumer.pointsArray.length != 0)
        {
        for (var i = 0; i < contextConsumer.pointsArray.length; i++) {
            var point = contextConsumer.pointsArray [i];
            this.drawLines(ctx, point);
            ctx.beginPath();
            //let NeuronImageToLoad2 = NeuronImageOfCube
            // You'll get security error if you do.
            // This loop gets every pixels on the image and

            // ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
        //    NeuronImageToLoad2.onload = function() {
        //     ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
        // //   ctx.rect(point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
        // //   var gradient1 = ctx.createRadialGradient(point.x, point.y, settings.flashRadius, point.x, point.y, 1);
        // //   if(point.selected)
        // //   {
        // //     gradient1.addColorStop(0, "rgba(150, 250, 100, 0.80)");
        // //   }
        // //   else
        // //   {
        // //     gradient1.addColorStop(0, "rgba(255,255,255,0.8)");
        // //   }

        // //   ctx.fillStyle = gradient1;
        // //   ctx.fill();           
        //         setCount(prevCount => prevCount + 1)     
        //  }
        
        // if(NeuronImageToLoad2.src == '')
        // {
        //   NeuronImageToLoad2.src = NeuronImageOfCube;
        // }


        var testRunData = contextConsumer.TestRunAndTickerDictionary[point.testRun];
        if(point.selected){
            // var imageData=ctx.getImageData(point.x, point.y, point.sizeMultiplier, point.sizeMultiplier);
            // var cubeImageData = imageData.data;

        
            // for (var j=0; j<imageData.height; j++)
            // {
            //   for (var k=0; k<imageData.width; k++)
            //   {
            //     //Only convert the non transparent pixels
            //     var index=(k)*imageData.width+(j);
            //     if(imageData.data[index+3] > 200)
            //     {
            //       var red=50;
            //       var green=200;
            //       var blue=50;
            //       var alpha=imageData.data[index+3];
            //       var average=(red+green+blue)/3;
            //       imageData.data[index]=red;
            //       imageData.data[index+1]=green;
            //       imageData.data[index+2]= blue;

            //     }
                
            //   }
            // }
            // ctx.putImageData(imageData,point.x,point.y);
              if(testRunData.TestRunId.startsWith("Live-V1-"))
              {
                ctx.drawImage(V3NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);              
              }
              else if(testRunData.TestRunId.startsWith("V2-"))
              {
                ctx.drawImage(V2NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);              
              }
              else if(testRunData.TestRunProfit > 0)
              {
                if(testRunData.TestRunProfit > 1000)
                {
                    ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
                }
                else
                {
                    ctx.drawImage(SelectedNeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
                }
              }
              else
              {    
              // ctx.save();
              // var p = currentBlinkTransparency - .01;
              // ctx.global = p;
              //currentBlinkTransparency = p;

              let totalOrders = testRunData.TestRunLosses + testRunData.TestRunWins;
              if(totalOrders == 0 && testRunData.TestRunProfit == 0)
              {
                continue               
              }
              else if(testRunData.TestRunProfit < -700)
              {
                  ctx.drawImage(eliminatedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
              }
              else
              {
                  ctx.drawImage(SelectedLosingNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);

              }
            }
        }
        else
        {                      
            let totalOrders = testRunData.TestRunLosses + testRunData.TestRunWins;
            if(testRunData.TestRunId.startsWith("Live-V1-"))
            {
              ctx.drawImage(V3NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);              
            }
            else if(testRunData.TestRunId.startsWith("V2-"))
            {
              ctx.drawImage(V2NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);              
            }
            else if(totalOrders == 0 && testRunData.TestRunProfit == 0)
            {
              continue               
            }    
            // else if(testRunData.TestRunProfit > 1000)
            // {
            // ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
            // }           
            // else if(testRunData.TestRunProfit < -700)
            // {
            // ctx.drawImage(eliminatedNeuronImageToLoad, point.x - this.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
            // }
            else
            {
            // ctx.globalAlpha = 0.5
            ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier); 
            }
            let pass = 0
        }
        }

        for (var i = 0; i < particles.length; i++) {
            var particle = particles[i];
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(particle.x - particle.direction.x * settings.particleLength, particle.y - particle.direction.y * settings.particleLength);
            ctx.strokeStyle = settings.particleColor;
            ctx.stroke();
        }

        for (var i = 0; i < contextConsumer.pointsArray.length; i++) {
            var point = contextConsumer.pointsArray [i];
            if (point.flashOpacity > 0) {
              ctx.beginPath();
              // var neuronImageToLoad = document.getElementById("NeuronImageCube");
              
              //ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
              NeuronImageToLoad2.onload = function() {
                  ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
                      //   ctx.rect(point.x - settings.flashRadius, point.y - settings.flashRadius, settings.flashRadius * 2, settings.flashRadius * 2);
                      //   var gradient = ctx.createRadialGradient(point.x, point.y, settings.flashRadius, point.x, point.y, 1);
                      //   gradient.addColorStop(0, "rgba(255, 255, 255, 0)");
                      //   gradient.addColorStop(1, "rgba(255, 255, 255, " + point.flashOpacity + ")");
                      //   ctx.fillStyle = gradient;
                      //   ctx.fill();    
                          
                  //setCount(prevCount => prevCount + 1)
              }

              SelectedNeuronImageToLoad2.onload = function() {
                  ctx.drawImage(SelectedNeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);          
              }

              SelectedLosingNeuronImageToLoad.onload = function () {
                  ctx.drawImage(SelectedLosingNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              protectedNeuronImageToLoad.onload = function () {
                  ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              eliminatedNeuronImageToLoad.onload = function () {
                  ctx.drawImage(eliminatedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              V2NeuronImageToLoad.onload = function () {
                ctx.drawImage(V2NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              V3NeuronImageToLoad.onload = function () {
                ctx.drawImage(V3NeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }
            }
            
            if(NeuronImageToLoad2.src == '')
            {
            NeuronImageToLoad2.src = NeuronImageOfCube;
            }
            
            if(SelectedNeuronImageToLoad2.src == '')
            {
            SelectedNeuronImageToLoad2.src = NeuronImageOfGreenCube;
            }

            if(SelectedLosingNeuronImageToLoad.src == '')
            {
            SelectedLosingNeuronImageToLoad.src = NeuronImageOfRedCube;
            }

            if(protectedNeuronImageToLoad.src == '')
            {
            protectedNeuronImageToLoad.src = NeuronImageOfBlueCube;
            }

            if(eliminatedNeuronImageToLoad.src == '')
            {
            eliminatedNeuronImageToLoad.src = NeuronImageOfGrayCube;
            }

            if(V2NeuronImageToLoad.src == '')
            {
              V2NeuronImageToLoad.src = NeuronImageOfYellowCube;
            }

            if(V3NeuronImageToLoad.src == '')
            {
              V3NeuronImageToLoad.src = NeuronImageOfPurpleCube
            }
        }
    }
    }


    renderTableData(prop, sortStrategy, sortDirection) {      
        return prop.map((row, index) => {
          const { testRunValue, rating, totalLifeTime, rawTotalLifeTime, testRunProfit, testRunProfitRaw, removeDecimalWinLossFormat, rawTestRunWinLossRate, totalNumberOfOrders } = row //destructuring          
          const { Ticker, TickerProfits, TickerWinLossRatio, TickerWins, TickerLosses, tickerTotalLifeTime, tickerRawTotalLifeTime } = row //destructuring

          let modifiedWinLossFormat = this.ConvertWinLossFormat(TickerWinLossRatio)
          let modifiedProfitString = Math.round(TickerProfits * 100) / 100

          if (testRunValue != undefined)
          {          
            return (
            <Table.Row onClick={(event) => this.handledRowClicked(event, testRunValue)}>
              <Table.Cell collapsing>
              <Checkbox slider id={testRunValue} onChange={this.CheckBoxChanged}/>
              </Table.Cell>
              <Table.Cell>{testRunValue}</Table.Cell>
              <Table.Cell>
                  <Rating icon='star' defaultRating={rating} maxRating={5} />
              </Table.Cell>      
              {
                    rawTotalLifeTime > 4320000 ? 
                    <Table.Cell positive>{totalLifeTime}</Table.Cell>
                    :
                    rawTotalLifeTime < 432000 ? 
                    <Table.Cell negative>{totalLifeTime}</Table.Cell>
                    :                  
                    <Table.Cell>{totalLifeTime}</Table.Cell>
              }
              {
                    testRunProfitRaw > 1000 ?                    
                    <Table.Cell positive>${testRunProfit}</Table.Cell>
                    : 
                    <Table.Cell>${testRunProfit}</Table.Cell>
              }
              {
                    rawTestRunWinLossRate > .7 ? 
                    <Table.Cell positive>{removeDecimalWinLossFormat}%</Table.Cell>
                    :    
                    rawTestRunWinLossRate < .33 ?                 
                    <Table.Cell negative>{removeDecimalWinLossFormat}%</Table.Cell>
                    :
                    <Table.Cell>{removeDecimalWinLossFormat}%</Table.Cell>                    
              }
              <Table.Cell>{totalNumberOfOrders}</Table.Cell>
            </Table.Row>                  
          ) 
          }
          else
          {
              return (
                <Table.Row active={true}>
                  <Table.Cell collapsing>
                  <Checkbox slider id={Ticker} onChange={this.CheckBoxChanged}/>
                  </Table.Cell>
                  <Table.Cell>{Ticker}</Table.Cell>
                  <Table.Cell>
                      <Rating icon='star' defaultRating={3} maxRating={5} />
                  </Table.Cell >      
                  {
                        tickerRawTotalLifeTime > 4320000 ? 
                        <Table.Cell positive>{tickerTotalLifeTime}</Table.Cell>
                        :
                        tickerRawTotalLifeTime < 432000 ? 
                        <Table.Cell negative>{tickerTotalLifeTime}</Table.Cell>
                        :                  
                        <Table.Cell>{tickerTotalLifeTime}</Table.Cell>
                  }
                  {
                        TickerProfits > 10 ?                    
                        <Table.Cell positive>${modifiedProfitString}</Table.Cell>
                        : 
                        TickerProfits < -5 ?                    
                        <Table.Cell negative>${modifiedProfitString}</Table.Cell>
                        : 
                        <Table.Cell>${modifiedProfitString}</Table.Cell>
                  }
                  {
                        TickerWins + TickerLosses > 5 && TickerWinLossRatio > .7 ? 
                        <Table.Cell positive>{modifiedWinLossFormat}%</Table.Cell>
                        :    
                        TickerWins + TickerLosses > 5 && TickerWinLossRatio < .33 ?                 
                        <Table.Cell negative>{modifiedWinLossFormat}%</Table.Cell>
                        :
                        <Table.Cell>{modifiedWinLossFormat}%</Table.Cell>                    
                  }
                  <Table.Cell>{TickerWins + TickerLosses}</Table.Cell>
                </Table.Row>  
              )
          }          
        })
      }
      



    ShowPopupDataCollector(testRunValue) {    
      contextConsumer.setStateStrategiesFilterVisible(false);
      var pointData = contextConsumer.pointsArray.find(obj => {
          return obj.testRun === testRunValue
        });
        pointData.selected = true

        let tickerDictionary = contextConsumer.TestRunAndTickerDictionary[pointData.testRun].TickerDataDictionary;
        let tickerValues = Object.values(tickerDictionary);

        let tickerNameList = [];
        for(let tickerData in tickerValues)
        {
            let testRunAndTickerValue = tickerValues[tickerData]; 
            tickerNameList.push(testRunAndTickerValue.Ticker);
        }
        
        contextConsumer.sortTestRunByCurrentPriority(Object.values(contextConsumer.TestRunAndTickerDictionary));
        contextConsumer.sortTickersInTestRunByCurrentPriority(tickerValues);
        contextConsumer.setStateOfComponentTicker(tickerValues);
        contextConsumer.setTestRunAndTicker (pointData.testRun, tickerValues[0].Ticker);
        contextConsumer.setIsCurrentTestRunAndTickerProfitable();            
        contextConsumer.setCurrentNodeName( pointData.name);   
        //testRunAndTickerConsumerContext.blinkNeuron(testRunAndTickerConsumerContext.pointsArray[closestPoint].name)       
        contextConsumer.showReport();          
        this.forceUpdate();     
    }

    CheckBoxChanged = (event, data) => {
      this.ShowPopupDataCollector(data.id);
    }

    
    InputOperatorOnChange = (event, data) => {
      switch(data.list){
        case 'datalist':
          contextConsumer.setStateOperationForTestRunName(data.value);
          break;
        case 'profitDataList':
          contextConsumer.setStateOperationForProfit(data.value);
          break;
        case 'lifeTimeDataList':
          contextConsumer.setStateOperationForLifeTime(data.value);
          break;
          case 'winLossDataList':
          contextConsumer.setStateOperationForWinLossRate(data.value);
            break;
        case 'totalOrderCountDataList':
          contextConsumer.setStateOperationForNumberOfTrades(data.value);
          break;
      }
    }


    InputDataListOnChange = (event, data) => {
      switch(data.list){
        case 'datalist':
          contextConsumer.setStateSearchBoxTestRunName(data.value);
          break;
        case 'profitDataList':
          let profit = Number(data.value.replace(/[^0-9\.-]+/g,""));
          contextConsumer.setStateSearchBoxProfit(profit);
          break;
        case 'lifeTimeDataList':
          let days = parseInt(data.value);
          contextConsumer.setStateSearchBoxLifeTime(days);
          break;
          case 'winLossDataList':
            let winRate = parseInt(data.value);
            contextConsumer.setStateSearchBoxWinLossRate(winRate);
            break;
        case 'totalOrderCountDataList':
          let orderCount = parseInt(data.value);
          contextConsumer.setStateSearchBoxNumberOfTrades(orderCount);
          break;
      }
    }
    

    CheckStrategyValidPerOperation (valueToCheck, thresholdValue, operation){      
      if(operation == 'Equals')
      {
        return valueToCheck == thresholdValue
      }
      if(operation == 'Not Equals')
      {
        return valueToCheck != thresholdValue
      }
      if(operation == 'Greater Than')
      {
        return valueToCheck > thresholdValue
      }
      if(operation == 'Less Than')
      {
        return valueToCheck < thresholdValue
      }
      if(operation == 'Greater Than or Equal')
      {
        return valueToCheck >= thresholdValue
      }
      if(operation == 'Less Than or Equal')
      {
        return valueToCheck <= thresholdValue
      }
      return true; //Default To return the row if no operations match
    }
    

    handleSliderClosed = () => {
      contextConsumer.setStateStrategiesFilterVisible(false)
      this.props.SidebarControllerProp('Strategy_Selected');
    };


    handledRowClicked = (event, valueOfTestRun) => {
      contextConsumer.setStateExtendedRowTestRunData(valueOfTestRun)
    };


    ConvertWinLossFormat(valueToConvert)
    {
      let modifiedWinLossFormatFormat = Math.abs((Math.round(valueToConvert * 100) / 100)).toFixed(2);
      let removeDecimalWinLossFormat = '';
      if(valueToConvert == 1)
      {
        removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('.', '');
      }
      else if(valueToConvert < .1) 
      {
        removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.0', '');
      }
      else
      {
        removeDecimalWinLossFormat = modifiedWinLossFormatFormat.replace('0.', '');
      }
      return removeDecimalWinLossFormat;
    }


    ConvertTimeValueFormat(valueToConvert)
    {
      if(valueToConvert <= 60){
        return (valueToConvert).toFixed(0) + ' seconds'             
      } else if(valueToConvert <= 3600){
        return (valueToConvert  / 60).toFixed(0) + ' minutes'        
      } else if(valueToConvert <= 86400){
        return (valueToConvert / 60 / 60).toFixed(0) + ' hours'
      } else{
        return (valueToConvert  / 60 / 60 / 24).toFixed(0) + ' days'      
      }  
    }

    render() {  
    //   setStateSearchBoxTestRunName
    //   setStateSearchBoxRating
    //   searchBoxLifeTime
    //  setStateSearchBoxProfit
    //  setStateSearchBoxWinLossRate
      return (
          <TestRunAndTickerRepoConsumer>
            {context => {  
        let testRunValue = 'Back-Again-Mustard'
        let testRunProfit = '0'
        let testRunProfitRaw = 0
        let totalNumberOfOrders = 0
        let totalLifeTime = 0
        let removeDecimalWinLossFormat = ''
        let rawTestRunWinLossRate = 0
        let durationString = ''
        let testRunWinLossRate = ''
        let rating = 2
        let firstElement = ''
        let tableRows = ''
        let props =  []
        let testRunIdsString = '<datalist id=\'TestRunOptionsElement\'>'
        let testRunProfitString = '<profitDataList id=\'TestRunProfitOptionsElement\'>'
        let testRunDurationString = '<lifeTimeDataList id=\'TestRunDurationOptionsElement\'>'
        let testRunWinLossString = '<winLossDataList id=\'TestRunWinLossOptionsElement\'>'
        let testRunTotalOrderCountString = '<totalOrderCountDataList id=\'TestRunTotalOrderCountOptionsElement\'>'
        let dl = document.getElementById('datalist');
        let profitDataList = document.getElementById('profitDataList');
        let lifeTimeDataList = document.getElementById('lifeTimeDataList');
        let winLossDataList = document.getElementById('winLossDataList');
        let totalOrderCountDataList = document.getElementById('totalOrderCountDataList');
         
        // let testRunNameOptions = document.createElement('TestRunOptionsElement');   
        if(context != undefined && context.TestRunAndTickerDictionary != undefined && Object.keys(context.TestRunAndTickerDictionary).length > 0)
        {
            let p = 0
            let k = 0
            let modifiedWinLossFormatFormat = 0;
            let tableRow = ''
            let filterNumberOfTradesTestRuns = []
            for (let key in context.TestRunAndTickerDictionary) {
                //Total Order Filter              
                totalLifeTime = context.TestRunAndTickerDictionary[key].TotalExecutionTime;
                testRunValue =  context.TestRunAndTickerDictionary[key].TestRunId;
                totalNumberOfOrders =  context.TestRunAndTickerDictionary[key].TestRunLosses + context.TestRunAndTickerDictionary[key].TestRunWins;
                testRunProfitRaw = context.TestRunAndTickerDictionary[key].TestRunProfit;
                testRunWinLossRate = context.TestRunAndTickerDictionary[key].WinLossRate;
                // let q = testRunValue.lastIndexOf(context.searchBoxTestRunName, 0);
                if(context.searchBoxTestRunName != '' && !testRunValue.lastIndexOf(context.searchBoxTestRunName, 0) == 0)
                {
                  continue;
                }
                if(context.searchBoxNumberOfTrades != '' && !this.CheckStrategyValidPerOperation(totalNumberOfOrders, context.searchBoxNumberOfTrades, context.operationForNumberOfTrades))
                {
                  continue;
                }

                if(context.searchBoxProfit != '' && !this.CheckStrategyValidPerOperation(testRunProfitRaw, context.searchBoxProfit, context.operationForProfit))
                {
                  continue;
                }

                // if(context.searchBoxWinLossRate != '' && testRunWinLossRate < (context.searchBoxWinLossRate / 100))
                // {
                //   continue;
                // }
                if(context.searchBoxWinLossRate != '' && !this.CheckStrategyValidPerOperation(Math.round(testRunWinLossRate * 100), Math.round(context.searchBoxWinLossRate * 100) / 100, context.operationForWinLossRate))
                {
                  continue;
                }
                
                if (testRunProfitRaw > 1000)                
                    rating = 5;
                else if (testRunProfitRaw > 500)                
                    rating = 4;
                else if (testRunProfitRaw > 200)                
                    rating = 3;
                else if (testRunProfitRaw > 50)                
                    rating = 2;
                else rating = 1;

                testRunProfit = testRunProfitRaw.toFixed(2);
                let duration = 0

                durationString = this.ConvertTimeValueFormat(totalLifeTime);
                  
                duration = (totalLifeTime  / 60 / 60 / 24) //Only set duration for days until dynamic times can be integrated.

                if(context.searchBoxLifeTime != '' && !this.CheckStrategyValidPerOperation(parseInt(duration), context.searchBoxLifeTime, context.operationForLifeTime))
                {
                  continue;
                }
                
                removeDecimalWinLossFormat = this.ConvertWinLossFormat(testRunWinLossRate);
                
                let prop = {testRunValue: testRunValue, rating: rating, testRunProfit: testRunProfit, testRunProfitRaw: testRunProfitRaw, removeDecimalWinLossFormat: removeDecimalWinLossFormat, 
                  rawTestRunWinLossRate: testRunWinLossRate, totalNumberOfOrders: totalNumberOfOrders, totalLifeTime: durationString, rawTotalLifeTime: totalLifeTime}
                props.push(prop);
                filterNumberOfTradesTestRuns.push(context.TestRunAndTickerDictionary[key])
            }
            
            let sortStrategy = ''
            let sortDirection = ''
            if(props.length > 0)
            {
                props.sort(function(a, b){
                    if(context.sortTestRunName != null)
                    {
                      sortStrategy = 'sortTestRunName';
                      sortDirection = context.sortTestRunName;
                      return context.GetOrderedValues(context.sortTestRunName, a.testRunValue, b.testRunValue);
                    }
                    else if(context.sortRating != null)
                    {     
                      sortStrategy = 'sortRating';
                      sortDirection = context.sortRating;
                      return context.GetOrderedValues(context.sortRating, a.rating, b.rating);       
                    }
                    else if(context.sortProfit != null)
                    {
                      sortStrategy = 'sortProfit';
                      sortDirection = context.sortProfit;
                      return context.GetOrderedValues(context.sortProfit, a.testRunProfitRaw, b.testRunProfitRaw);   
                    }
                    else if(context.sortLifeTime != null)
                    {
                      sortStrategy = 'sortTestRunName';
                      sortDirection = context.sortTestRunName;
                      return context.GetOrderedValues(context.sortTestRunName, a.rawTotalLifeTime, b.rawTotalLifeTime);   
                    }
                    else if(context.sortWinLossRate != null)
                    {
                      sortStrategy = 'sortWinLossRate';
                      sortDirection = context.sortWinLossRate;
                      return context.GetOrderedValues(context.sortWinLossRate, a.rawTestRunWinLossRate, b.rawTestRunWinLossRate);   
                    }
                    else if(context.sortNumberOfTrades != null)
                    {
                      sortStrategy = 'sortNumberOfTrades';
                      sortDirection = context.sortNumberOfTrades;
                      return context.GetOrderedValues(context.sortNumberOfTrades, a.totalNumberOfOrders, b.totalNumberOfOrders);   
                    }
                    else
                        return 0;
                });
                
                if(contextConsumer != null && contextConsumer.StateOfStrategyDataFilterTable.extendedRowTestRunData != '')
                {
                    let extendedTickerData = 
                      Object.values(contextConsumer.TestRunAndTickerDictionary[contextConsumer.StateOfStrategyDataFilterTable.extendedRowTestRunData].TickerDataDictionary);

                    extendedTickerData.sort(function(a, b){
                      if(sortStrategy == 'sortTestRunName')
                      {
                        return contextConsumer.GetOrderedValues(sortDirection, a.Ticker, b.Ticker);
                      }
                    //  else if(contextConsumer.sortRating != null)
                    //  {     
                    //    return contextConsumer.GetOrderedValues(contextConsumer.sortRating, a.rating, b.rating);       
                    //  }
                      else if(sortStrategy == 'sortProfit')
                      {
                        return contextConsumer.GetOrderedValues(sortDirection, a.TickerProfits, b.TickerProfits);   
                      }
                      // else if(sortStrategy == 'sortLifeTime')
                      // {
                      //   return contextConsumer.GetOrderedValues(sortDirection, a.rawTotalLifeTime, b.rawTotalLifeTime);   
                      // }
                      else if(sortStrategy == 'sortWinLossRate')
                      {
                        return contextConsumer.GetOrderedValues(sortDirection, a.TickerWinLossRatio, b.TickerWinLossRatio);   
                      }
                      else if(sortStrategy == 'sortNumberOfTrades')
                      {
                        return contextConsumer.GetOrderedValues(sortDirection, a.TickerWins + a.TickerLosses, b.TickerWins + b.TickerLosses);   
                      }
                      else
                          return 0;
                      });

                    var indexOfExtendedData = props.findIndex(item => item.testRunValue === contextConsumer.StateOfStrategyDataFilterTable.extendedRowTestRunData); 

                    extendedTickerData.map( x => {
                      x.tickerTotalLifeTime = durationString;
                      x.tickerRawTotalLifeTime = totalLifeTime;
                      return x;
                      })

                    props.splice.apply(props, [indexOfExtendedData +1, 0].concat(extendedTickerData)); //Insert extended data list into the original metrics data list
                } 

                
                tableRows = this.renderTableData(props, sortStrategy, sortDirection)
                // this.renderTableData(props);
            } 
        
            
            let durationElapsedTimeSort = []
            let winLossRateSort = []
            let testRunIdSort = []
            let profitSort = []
            let orderCountSort = []
            let durationStringGroupByCheck = []
            let winLossStringGroupByCheck = []
            let profitGroupByCheck = []
            let orderCountGroupByCheck = []
            for (var indexOfTotalTradesFilter in filterNumberOfTradesTestRuns){
              let v = filterNumberOfTradesTestRuns[indexOfTotalTradesFilter]
              testRunIdSort.push(v.TestRunId);
              profitSort.push(v.TestRunProfit);
              durationElapsedTimeSort.push(v.TotalExecutionTime);
              winLossRateSort.push(v.WinLossRate);
              orderCountSort.push(v.TestRunWins + v.TestRunLosses)
            };

            testRunIdSort.sort();
            durationElapsedTimeSort.sort(function(a, b) {
              return b - a;
            });
            winLossRateSort.sort(function(a, b) {
              return b - a;
            });
            profitSort.sort(function(a, b) {
              return b - a;
            });
            orderCountSort.sort(function(a, b) {
              return b - a;
            });

            for(var orderedDurationStringIndex in durationElapsedTimeSort)
            {
              let orderedDurationString = durationElapsedTimeSort[orderedDurationStringIndex];         

              durationString = this.ConvertTimeValueFormat(orderedDurationString);

              if(!durationStringGroupByCheck.includes(durationString))
              {
                durationStringGroupByCheck.push(durationString);
              }
            }
            for(var orderedWinLossStringIndex in winLossRateSort)
            {
              let orderedWinLossRate = winLossRateSort[orderedWinLossStringIndex];
              let modifiedWinLossFormatFormat = this.ConvertWinLossFormat(orderedWinLossRate);
              
              if(!winLossStringGroupByCheck.includes(modifiedWinLossFormatFormat))
              {
                winLossStringGroupByCheck.push(modifiedWinLossFormatFormat);
              }
            }
            for(var profitStringIndex in profitSort)
            {
              let orderedprofit = profitSort[profitStringIndex];
              if(!profitGroupByCheck.includes(orderedprofit))
              {
                profitGroupByCheck.push(orderedprofit);
              }
            }
            for(var orderCountStringIndex in orderCountSort)
            {
              let orderedOrderCount = orderCountSort[orderCountStringIndex];
              if(!orderCountGroupByCheck.includes(orderedOrderCount))
              {
                orderCountGroupByCheck.push(orderedOrderCount);
              }
            }
            
            for(var testRunIdSortedIndex in testRunIdSort)
            {
              let testRunIdSorted = testRunIdSort[testRunIdSortedIndex];
              testRunIdsString += '<option value=\'' + testRunIdSorted + '\'>' + testRunIdSorted + '</option>';
            }
            for(var orderedDurationStringIndex in durationStringGroupByCheck)
            {
              let orderedDurationString = durationStringGroupByCheck[orderedDurationStringIndex];
              testRunDurationString += '<option value=\'' + orderedDurationString + '\'>' + orderedDurationString + '</option>';
            }
            for(var orderedWinLossStringIndex in winLossStringGroupByCheck)
            {
              let orderedWinLossString = winLossStringGroupByCheck[orderedWinLossStringIndex];
              testRunWinLossString += '<option value=\'' + orderedWinLossString + '%\'>' + orderedWinLossString + '%</option>';
            }
            for(var profitIndex in profitGroupByCheck)
            {
              let profitString = profitGroupByCheck[profitIndex];
              testRunProfitString += '<option value=\'$' + Math.round(profitString * 100) / 100 + '\'>$' + Math.round(profitString * 100) / 100 + '</option>';
            }
            for(var orderedOrderCount in orderCountGroupByCheck)
            {
              let orderedWinLossString = orderCountGroupByCheck[orderedOrderCount];
              testRunTotalOrderCountString += '<option value=\'' + orderedWinLossString + '\'>' + orderedWinLossString + '</option>';
            }

            testRunIdsString += '</datalist>';
            testRunProfitString += '</profitDataList>';
            testRunDurationString += '</lifeTimeDataList>';
            testRunWinLossString += '</winLossDataList>';
            testRunTotalOrderCountString += '</totalOrderCountDataList>';
            dl.innerHTML = testRunIdsString;
            profitDataList.innerHTML = testRunProfitString;
            lifeTimeDataList.innerHTML = testRunDurationString;
            winLossDataList.innerHTML = testRunWinLossString;
            totalOrderCountDataList.innerHTML = testRunTotalOrderCountString;
        }       
                            return(<Grid columns={1}>
                            <div className="ToggleSidebarController">
                                <Grid.Column>
                                    <Checkbox slider icon='share'
                                    disabled={false}
                                    checked={context.StateOfStrategyDataFilterTable.StrategiesFilterVisible}
                                    id={{ children: <code>Filter Constellation (Beta)</code> }}
                                    onChange={(e, data) => context.setStateStrategiesFilterVisible(data.checked)}
                                    />
                                </Grid.Column>
                            </div>
                          <Grid.Column>
                            <Sidebar.Pushable as={Segment}>
            
                            <div className='NeuronControlPanel'>
                              <Sidebar
                                as={Table}
                                animation='overlay'
                                icon='labeled'
                                inverted
                                onHide={this.handleSliderClosed}
                                vertical
                                visible={context.StateOfStrategyDataFilterTable.StrategiesFilterVisible}
                                width='thin'
                              >
                                
                              <div className='FilteredTableView'><Table celled inverted selectable>
                                        <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell colSpan='6' font-size='1.9em' lineColor={'black'} textAlign={'center'}>Filter Constellation</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell 
                                                sorted={context.sortTestRunName} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'testRunValue'})}
                                            >Name</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={context.sortRating} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'rating'})}
                                            >Rating</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={context.sortLifeTime} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'totalLifeTime'})}
                                            >Lifetime</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={context.sortProfit} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'testRunProfit'})}
                                            >Profit</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={context.sortWinLossRate} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'removeDecimalWinLossFormat'})}
                                            >Win Percentage</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={context.sortNumberOfTrades} 
                                                onClick={() => context.SortStrategiesOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'totalNumberOfOrders'})}
                                            >Number Of Trades</Table.HeaderCell>
                                        </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        <Table.Row height='100px'>
                                            <Table.Cell />
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    placeholder='Equals'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className='InputWithDataSet' placeholder='(Strategy Name)'>                                
                                              <Input onChange={this.InputDataListOnChange} list='datalist'/>
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    placeholder='(All)'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                />
                                            </div>                              
                                            <Search placeholder='(Rating)'/>
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    placeholder='(All)'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                    onChange={this.InputOperatorOnChange} list='lifeTimeDataList'
                                                />
                                            </div>
                                            <div className='InputWithDataSet'>                                
                                              <Input onChange={this.InputDataListOnChange} list='lifeTimeDataList' placeholder='(Lifetime)'/>
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    placeholder='(All)'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                    onChange={this.InputOperatorOnChange} list='profitDataList'
                                                />
                                            </div>
                                            <div className='InputWithDataSet'>                                
                                              <Input onChange={this.InputDataListOnChange} list='profitDataList' placeholder='(Profit)'/>
                                              </div>  
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    placeholder='(All)'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                    onChange={this.InputOperatorOnChange} list='winLossDataList'
                                                />
                                            </div>
                                            <div className='InputWithDataSet'>                                
                                              <Input onChange={this.InputDataListOnChange} list='winLossDataList' placeholder='(Win Percentage)'/>
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                            <div className='conditionals-dropdown-content'>
                                            <Dropdown                
                                                    defaultValue='Greater Than'
                                                    fluid
                                                    selection
                                                    options={friendOptions}
                                                    onChange={this.InputOperatorOnChange} list='totalOrderCountDataList'
                                                />
                                            </div>
                                            <div className='InputWithDataSet'>                                
                                              <Input onChange={this.InputDataListOnChange} list='totalOrderCountDataList' placeholder='5'/>
                                              </div>
                                            </Table.Cell>
                                        </Table.Row>
                                        {tableRows}
                                        </Table.Body>
            
                                        <Table.Footer fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell colSpan='6'>
                                            <Button
                                                floated='right'
                                                icon
                                                labelPosition='left'
                                                primary
                                                size='small'
                                            >
                                                <Icon name='user' /> Add User
                                            </Button>
                                            <Button size='small'>Approve</Button>
                                            <Button disabled size='small'>
                                                Approve All
                                            </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </div>            
                              </Sidebar>
                            </div>
                    
                            <Sidebar.Pusher dimmed={context.StateOfStrategyDataFilterTable.StrategiesFilterVisible}>
                                <Segment basic>
                                    <div className='NueronsView'>   
                                        <Neurons  animate={this.animate} />
                                    </div>  
                                </Segment>
                              </Sidebar.Pusher>
                            </Sidebar.Pushable>
                          </Grid.Column>
                        </Grid>);
                        }
                      }
            </TestRunAndTickerRepoConsumer>
          );  
          
      }  

}
  
export default SidebarController;