import React from 'react'
import {   Checkbox,
  Grid,
  Button,
  Header,
  Icon,
  Input,
  Menu,
  Rating,
  Search,
  Segment,
  Sidebar,
  Table,
  Dropdown } from 'semantic-ui-react'
  import 'semantic-ui-css/semantic.min.css'
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";


class IndicatorsTable extends React.Component {    

static contextType = TestRunAndTickerRepoContext;


GetConsumer = () => {
    return (
      <TestRunAndTickerRepoConsumer>
        {context => {     
    
               
        }}
      </TestRunAndTickerRepoConsumer>
    ); 
  }

  
  renderIndicatorsData(prop, context)
  {
    let hasAddedExtendedHeader = false
    return prop.map((row, index) => {
      const { IndicatorName, IndicatorInvested, IndicatorProfit, IndicatorWinRate, IndicatorWins, IndicatorLosses, IndicatorNumberOfTrades} = row //destructuring
      const { TestRunName, userStrategyInvested, userStrategyProfit, userStrategyWinRate, Wins, Losses, NumberOfTrades} = row //destructuring

      if (IndicatorName != undefined)
      {
        return (
        // <Table.Row onClick={(event) => this.handledUserIndicatorRowClicked(event, TestRunId)}>
        <Table.Row onClick={(event) => this.handledIndicatorRowClicked(event, IndicatorName, context)}>
          <Table.Cell>{IndicatorName}</Table.Cell> 
          <Table.Cell>{'Crypto'}</Table.Cell> 
          <Table.Cell>{IndicatorInvested}</Table.Cell> 
          <Table.Cell>{IndicatorProfit}</Table.Cell> 
          <Table.Cell>{IndicatorWinRate}</Table.Cell> 
          <Table.Cell>{IndicatorWins}</Table.Cell> 
          <Table.Cell>{IndicatorLosses}</Table.Cell> 
          <Table.Cell>{IndicatorNumberOfTrades}</Table.Cell> 
        </Table.Row>                  
         ) 
       }
       else
       {
      
        let firstHyphenIndexOffset = TestRunName.indexOf("-") + 1
        let indexOfStrategyTypeEnd = TestRunName.indexOf("-", firstHyphenIndexOffset) //Finds the second occurance of the hypthen (-)
        let strategyTypeUnmodified = TestRunName.substring(0, indexOfStrategyTypeEnd)
        let strategyType = strategyTypeUnmodified.replace("-", " ")
  
        if(hasAddedExtendedHeader)
        {       
          return (
          <Table.Row active={true} onClick={(event) => this.handledIndicatorStrategyRowClicked(event, TestRunName, context)}>
              <Table.Cell>{TestRunName}</Table.Cell> 
              <Table.Cell>{strategyType}</Table.Cell> 
              <Table.Cell>{userStrategyInvested}</Table.Cell> 
              <Table.Cell>{userStrategyProfit}</Table.Cell> 
              <Table.Cell>{userStrategyWinRate}</Table.Cell> 
              <Table.Cell>{Wins}</Table.Cell> 
              <Table.Cell>{Losses}</Table.Cell> 
              <Table.Cell>{NumberOfTrades}</Table.Cell> 
            </Table.Row>
          )
        }
        else{        
          hasAddedExtendedHeader = true
          return [this.IndicatorStrategyTableRows(context),
            <Table.Row active={true} onClick={(event) => this.handledIndicatorStrategyRowClicked(event, TestRunName, context)}>
              <Table.Cell>{TestRunName}</Table.Cell> 
              <Table.Cell>{strategyType}</Table.Cell> 
              <Table.Cell>{userStrategyInvested}</Table.Cell> 
              <Table.Cell>{userStrategyProfit}</Table.Cell> 
              <Table.Cell>{userStrategyWinRate}</Table.Cell> 
              <Table.Cell>{Wins}</Table.Cell> 
              <Table.Cell>{Losses}</Table.Cell> 
              <Table.Cell>{NumberOfTrades}</Table.Cell> 
            </Table.Row>]  
            }
          }   
        }              
      )
    }


  handledIndicatorRowClicked = (event, userIndicatorName, context) => {
  if(context.StateOfUserProfilePopup.SelectedIndicator == null || context.StateOfUserProfilePopup.SelectedIndicator != userIndicatorName)
  {
    context.SetStateCurrentSelectedIndicator(event, userIndicatorName)
  }
  else
  {
    context.setStateExtendedRowUserIndicator(userIndicatorName)
  }
};


handledIndicatorStrategyRowClicked = (event, userStrategyName, context) => {
  let x = 0;
};


IndicatorStrategyTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortIndicatorName} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorName'})}
  >Strategy Name</Table.HeaderCell>  
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorType} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorType'})}
  >Strategy Update Period</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorInvested} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorProfit} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWinRate} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWins} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWins'})}
  >Wins</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorLosses} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorLosses'})}
  >Losses</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorNumberOfTrades'})}
  >Number Of Trades</Table.HeaderCell>
</Table.Row>
}

IndicatorTableRows (context) {
  return <Table.Row>
  <Table.HeaderCell     
      sorted={context.StateOfUserProfilePopup.sortIndicatorName} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorName'})}
  >Indicator Name</Table.HeaderCell>  
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorType} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorType'})}
  >Indicator Type</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorInvested} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorInvested'})}
  >Invested</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorProfit} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorProfit'})}
  >Profit</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWinRate} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWinRate'})}
  >Win Rate</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorWins} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorWins'})}
  >Wins</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorLosses} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorLosses'})}
  >Losses</Table.HeaderCell>
  <Table.HeaderCell
      sorted={context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades} 
      onClick={() => context.SortUserPromptIndicatorsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptIndicatorNumberOfTrades'})}
  >Number Of Trades</Table.HeaderCell>
</Table.Row>
}


IndicatorTableDataRows (context, conditionalOptions, userIndicators)
{   
    return (
      <Table.Body>
       <Table.Row height='100px'>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown      
                value='Equals'          
                fluid
                selection
                options={conditionalOptions}
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet' >                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorName} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorNameList' placeholder='(Indicator Name)'/>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value='Equals'
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorTypeList'
                disabled={true}
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorType} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorTypeList' placeholder='(Indicator Type)'/>
          </div>  
        </Table.Cell>
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                
                value={context.StateOfUserProfilePopup.operationForIndicatorInvested}  
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorInvestedList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorInvested} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorInvestedList' placeholder='(Invested)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForIndicatorProfit}                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorProfitList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorProfit} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorProfitList' placeholder='(Profit)'/>
          </div>
        </Table.Cell>

        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown         
                value={context.StateOfUserProfilePopup.operationForIndicatorWinRate}                
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorWinRateList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorWinRate} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorWinRateList' placeholder='(Win Rate)'/>
          </div>
        </Table.Cell>

        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown           
                value={context.StateOfUserProfilePopup.operationForIndicatorWins}                            
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorWinsList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorWins} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorWinsList' placeholder='(Wins)'/>
          </div>
        </Table.Cell>
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForIndicatorLosses}                          
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorLossesList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorLosses} onChange={context.UserPromptInputIndicatorDataListOnChange} list='userPromptIndicatorLossesList' placeholder='(Losses)'/>
          </div>
        </Table.Cell>
        
        
        <Table.Cell collapsing>
        <div className='conditionals-dropdown-content'>
        <Dropdown                      
                value={context.StateOfUserProfilePopup.operationForIndicatorNumberOfTrades}                  
                fluid
                selection
                options={conditionalOptions}
                onChange={context.UserPromptInputIndicatorOperatorOnChange} list='userPromptIndicatorNumberOfTradesList'
            />
        </div>
        <div className='InputWithDataSet'>                                
          <Input fluid value={context.StateOfUserProfilePopup.searchBoxIndicatorNumberOfTrades} onChange={context.UserPromptInputIndicatorDataListOnChange} 
                list='userPromptIndicatorNumberOfTradesList' placeholder='(Number Of Trades)'/>
          </div>
        </Table.Cell>

    </Table.Row>
    {userIndicators}
    </Table.Body>
  )
}



SetUserIndicatorsProp (context)
{
  let userPromptFilterIndicators = []
  let props =  []

  let userPromptIndicatorNamesString = '<userPromptIndicatorNameList id=\'UserPromptIndicatorNameOptionsElement\'>'
  let userPromptIndicatorTypeString = '<userPromptIndicatorTypeList id=\'UserPromptIndicatorTypeOptionsElement\'>'
  let userPromptIndicatorProfitString = '<userPromptIndicatorProfitList id=\'UserPromptIndicatorProfitOptionsElement\'>'
  let userPromptIndicatorInvestedString = '<userPromptIndicatorInvestedList id=\'userPromptIndicatorInvestedOptionsElement\'>'
  let userPromptIndicatorWinRateString = '<userPromptIndicatorWinRateList id=\'userPromptIndicatorWinRateOptionsElement\'>'
  let userPromptIndicatorWinsString = '<userPromptIndicatorWinsList id=\'userPromptIndicatorWinsOptionsElement\'>'
  let userPromptIndicatorLossestring = '<userPromptIndicatorLossesList id=\'UserPromptIndicatorLossesOptionsElement\'>'
  let userPromptIndicatorNumberOfTradesString = '<userPromptIndicatorNumberOfTradesList id=\'userPromptIndicatorNumberOfTradesOptionsElement\'>'

  let userPromptIndicatorNameListDataList = document.getElementById('userPromptIndicatorNameList');
  let userPromptIndicatorTypeListDataList = document.getElementById('userPromptIndicatorTypeList');
  let userPromptIndicatorInvestedDataList = document.getElementById('userPromptIndicatorInvestedList');
  let userPromptIndicatorProfitDataList = document.getElementById('userPromptIndicatorProfitList');
  let userPromptIndicatorWinRateListDataList = document.getElementById('userPromptIndicatorWinRateList');
  let userPromptIndicatorWinsDataList = document.getElementById('userPromptIndicatorWinsList');  
  let userPromptIndicatorLossesDataList = document.getElementById('userPromptIndicatorLossesList');   
  let userPromptIndicatorNumberOfTradesDataList = document.getElementById('userPromptIndicatorNumberOfTradesList');   



  for (let key in context.StateOfUserProfilePopup.userIndicators) 
    {
      let userIndicator = context.StateOfUserProfilePopup.userIndicators[key]
      
      if(context.StateOfUserProfilePopup.searchBoxIndicatorName != '' && !userIndicator.userStrategyRowName.lastIndexOf(context.StateOfUserProfilePopup.searchBoxIndicatorName, 0) == 0)
      {
        continue;
      }   
      if(context.StateOfUserProfilePopup.searchBoxIndicatorType != '' && !userIndicator.IndicatorType.lastIndexOf(context.StateOfUserProfilePopup.searchBoxIndicatorType, 0) == 0)
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorInvested != '' && !context.CheckStrategyValidPerOperation(userIndicator.userStrategyInvested, context.StateOfUserProfilePopup.searchBoxIndicatorInvested, context.StateOfUserProfilePopup.operationForIndicatorInvested))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorProfit != '' && !context.CheckStrategyValidPerOperation(userIndicator.userStrategyProfit, context.StateOfUserProfilePopup.searchBoxIndicatorProfit, context.StateOfUserProfilePopup.operationForIndicatorProfit))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorWinRate != '' && !context.CheckStrategyValidPerOperation(userIndicator.userStrategyWinRate, context.StateOfUserProfilePopup.searchBoxIndicatorWinRate, context.StateOfUserProfilePopup.operationForIndicatorWinRate))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorWins != '' && !context.CheckStrategyValidPerOperation(userIndicator.Wins, context.StateOfUserProfilePopup.searchBoxIndicatorWins, context.StateOfUserProfilePopup.operationForIndicatorWins))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorLosses != '' && !context.CheckStrategyValidPerOperation(userIndicator.Losses, context.StateOfUserProfilePopup.searchBoxIndicatorLosses, context.StateOfUserProfilePopup.operationForIndicatorLosses))
      {
        continue;
      }
      if(context.StateOfUserProfilePopup.searchBoxIndicatorNumberOfTrades != '' && (userIndicator.NumberOfTrades == null || !context.CheckStrategyValidPerOperation(userIndicator.NumberOfTrades, context.StateOfUserProfilePopup.searchBoxIndicatorNumberOfTrades, context.StateOfUserProfilePopup.operationForIndicatorNumberOfTrades)))
      {
        continue;
      }
                     
      
      let IndicatorProp = {
        IndicatorName: userIndicator.userStrategyRowName, 
        IndicatorType: 'Crypto',
        IndicatorInvested: userIndicator.userStrategyInvested, 
        IndicatorProfit: userIndicator.userStrategyProfit,
        IndicatorWinRate: userIndicator.userStrategyWinRate,
        IndicatorWins: userIndicator.Wins,
        IndicatorLosses: userIndicator.Losses,
        IndicatorNumberOfTrades: userIndicator.NumberOfTrades, 
      }
      props.push(IndicatorProp);
      userPromptFilterIndicators.push(context.StateOfUserProfilePopup.userIndicators[key])
    } 

    let sortStrategy = ''
    let sortDirection = ''
    if(props.length > 0)
    {
        props.sort(function(a, b){
            if(context.StateOfUserProfilePopup.sortIndicatorName != null)
            {
              sortStrategy = 'sortIndicatorName';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorName;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorName, a.IndicatorName, b.IndicatorName);
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorType != null)
            {
              sortStrategy = 'sortIndicatorType';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorType;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorType, a.IndicatorType, b.IndicatorType);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorInvested != null)
            {
              sortStrategy = 'sortIndicatorInvested';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorInvested;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorInvested, a.IndicatorInvested, b.IndicatorInvested);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorProfit != null)
            {
              sortStrategy = 'sortIndicatorProfit';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorProfit;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorProfit, a.IndicatorProfit, b.IndicatorProfit);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorWinRate != null)
            {
              sortStrategy = 'sortIndicatorWinRate';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorWinRate;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorWinRate, a.IndicatorWinRate, b.IndicatorWinRate);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorWins != null)
            {
              sortStrategy = 'sortIndicatorWins';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorWins;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorWins, a.IndicatorWins, b.IndicatorWins);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorLosses != null)
            {
              sortStrategy = 'sortIndicatorLosses';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorLosses;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorLosses, a.IndicatorLosses, b.IndicatorLosses);   
            }
            else if(context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades != null)
            {
              sortStrategy = 'sortIndicatorNumberOfTrades';
              sortDirection = context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades;
              return context.GetOrderedValues(context.StateOfUserProfilePopup.sortIndicatorNumberOfTrades, a.IndicatorNumberOfTrades, b.IndicatorNumberOfTrades);   
            }
            else
                return 0;
        });

        if(context != null && context.StateOfUserProfilePopup.ExtendedRowUserIndicator != '' && context.StateOfUserProfilePopup.currentIndicatorMetricsByStrategy.length > 0)
        {
            let userStrategyParentData = context.StateOfUserProfilePopup.userStrategies[context.StateOfUserProfilePopup.ExtendedRowUserIndicator]
            let extendedData = context.StateOfUserProfilePopup.currentIndicatorMetricsByStrategy              
            
            extendedData.sort(function(a, b){
              if(sortStrategy == 'sortIndicatorName')
              {
                return context.GetOrderedValues(sortDirection, a.TestRunName, b.TestRunName);
              }
              // else if(sortStrategy == 'sortIndicatorType')
              // {
              //   return context.GetOrderedValues(sortDirection, a.IndicatorType, b.IndicatorType);
              // }
              else if(sortStrategy == 'sortIndicatorInvested')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyInvested, b.userStrategyInvested);   
              }
              else if(sortStrategy == 'sortIndicatorProfit')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyProfit, b.userStrategyProfit);   
              }
              else if(sortStrategy == 'sortIndicatorWinRate')
              {
                return context.GetOrderedValues(sortDirection, a.userStrategyWinRate, b.userStrategyWinRate);   
              }
              else if(sortStrategy == 'sortIndicatorWins')
              {
                return context.GetOrderedValues(sortDirection, a.Wins, b.Wins);   
              }
              else if(sortStrategy == 'sortIndicatorLosses')
              {
                return context.GetOrderedValues(sortDirection, a.Losses, b.Losses);   
              }
              else if(sortStrategy == 'sortIndicatorNumberOfTrades')
              {
                return context.GetOrderedValues(sortDirection, a.NumberOfTrades, b.NumberOfTrades);   
              }
              else
                  return 0;
              });

            var indexOfExtendedData = props.findIndex(item => item.IndicatorName === context.StateOfUserProfilePopup.ExtendedRowUserIndicator); 
            
            if(indexOfExtendedData >= 0)
            {
              // extendedData.map( x => {
              //     x.IndicatorName = x.TestRunName; 
              //     x.IndicatorType = 'Crypto';
              //     x.IndicatorInvested = x.userStrategyInvested; 
              //     x.IndicatorProfit = x.userStrategyProfit;
              //     x.IndicatorWinRate = x.userStrategyWinRate;
              //     x.IndicatorWins = x.Wins;
              //     x.IndicatorLosses = x.Losses;
              //     x.IndicatorNumberOfTrades = x.NumberOfTrades;
              //   return x;
              //   })  
              props.splice.apply(props, [indexOfExtendedData +1, 0].concat(extendedData));
            }
          }     
    } 

    let userPromptIndicatorNameSort = []
    let userPromptIndicatorTypeSort = []
    let userPromptIndicatorInvestedSort = []
    let userPromptIndicatorProfitSort = []
    let userPromptIndicatorWinRateSort = []
    let userPromptIndicatorWinsSort = []
    let userPromptIndicatorLossesSort = []
    let userPromptIndicatorNumberOfTradesSort = []
    
    let userPromptIndicatorTimeGroupByCheck= []
    let userPromptIndicatorTypeGroupByCheck = []
    let userPromptIndicatorInvestedGroupByCheck = []
    let userPromptIndicatorProfitGroupByCheck = []
    let userPromptIndicatorWinRateGroupByCheck = []
    let userPromptIndicatorWinsGroupByCheck = []
    let userPromptIndicatorLossesGroupByCheck = []
    let userPromptIndicatorNumberOfTradesGroupByCheck = []
    
    for (var index in userPromptFilterIndicators){
      let userPromptIndicatorData = userPromptFilterIndicators[index]
      userPromptIndicatorNameSort.push(userPromptIndicatorData.userStrategyRowName);
      userPromptIndicatorTypeSort.push('Crypto');
      //userPromptIndicatorTypeSort.push(userPromptIndicatorData.IndicatorType);
      userPromptIndicatorInvestedSort.push(userPromptIndicatorData.userStrategyInvested);
      userPromptIndicatorProfitSort.push(userPromptIndicatorData.userStrategyProfit);
      userPromptIndicatorWinRateSort.push(userPromptIndicatorData.userStrategyWinRate);
      userPromptIndicatorWinsSort.push(userPromptIndicatorData.Wins);
      userPromptIndicatorLossesSort.push(userPromptIndicatorData.Losses);
      userPromptIndicatorNumberOfTradesSort.push(userPromptIndicatorData.NumberOfTrades);
    };

    userPromptIndicatorNameSort.sort();
    userPromptIndicatorTypeSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorInvestedSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorProfitSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorWinRateSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorWinsSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorLossesSort.sort(function(a, b) {
      return b - a;
    });
    userPromptIndicatorNumberOfTradesSort.sort(function(a, b) {
      return b - a;
    });

    for(var index in userPromptIndicatorTypeSort)
    {
      let IndicatoredIndicatorType = userPromptIndicatorTypeSort[index];
      if(!userPromptIndicatorTypeGroupByCheck.includes(IndicatoredIndicatorType))
      {
        userPromptIndicatorTypeGroupByCheck.push(IndicatoredIndicatorType);
      }
    }
    for(var index in userPromptIndicatorInvestedSort)
    {
      let IndicatorInvested = userPromptIndicatorInvestedSort[index];
      if(!userPromptIndicatorInvestedGroupByCheck.includes(IndicatorInvested))
      {
        userPromptIndicatorInvestedGroupByCheck.push(IndicatorInvested);
      }
    }
    for(var index in userPromptIndicatorProfitSort)
    {
      let IndicatoredIndicatorProfit = userPromptIndicatorProfitSort[index];
      if(!userPromptIndicatorProfitGroupByCheck.includes(IndicatoredIndicatorProfit))
      {
        userPromptIndicatorProfitGroupByCheck.push(IndicatoredIndicatorProfit);
      }
    }
    for(var index in userPromptIndicatorWinRateSort)
    {
      let IndicatoredAveragePrice = userPromptIndicatorWinRateSort[index];
      if(!userPromptIndicatorWinRateGroupByCheck.includes(IndicatoredAveragePrice))
      {
        userPromptIndicatorWinRateGroupByCheck.push(IndicatoredAveragePrice);
      }
    }
    for(var index in userPromptIndicatorWinsSort)
    {
      let IndicatoredIndicatorRequestedPrice = userPromptIndicatorWinsSort[index];
      if(!userPromptIndicatorWinsGroupByCheck.includes(IndicatoredIndicatorRequestedPrice))
      {
        userPromptIndicatorWinsGroupByCheck.push(IndicatoredIndicatorRequestedPrice);
      }
    }
    for(var index in userPromptIndicatorLossesSort)
    {
      let IndicatorLosses = userPromptIndicatorLossesSort[index];
      if(!userPromptIndicatorLossesGroupByCheck.includes(IndicatorLosses))
      {
        userPromptIndicatorLossesGroupByCheck.push(IndicatorLosses);
      }
    }
    for(var index in userPromptIndicatorNumberOfTradesSort)
    {
      let numberOfTrades = userPromptIndicatorNumberOfTradesSort[index];
      if(!userPromptIndicatorNumberOfTradesGroupByCheck.includes(numberOfTrades))
      {
        userPromptIndicatorNumberOfTradesGroupByCheck.push(numberOfTrades);
      }
    }


    for(var userPromptIndicatorNameSortedIndex in userPromptIndicatorNameSort)
    {
      let IndicatorNameSorted = userPromptIndicatorNameSort[userPromptIndicatorNameSortedIndex];
      userPromptIndicatorNamesString += '<option value=\'' + IndicatorNameSorted + '\'>' + IndicatorNameSorted + '</option>';
    }
    for(var userPromptIndicatorTypeKey in userPromptIndicatorTypeGroupByCheck)
    {
      let IndicatorTypeString = userPromptIndicatorTypeGroupByCheck[userPromptIndicatorTypeKey];
      userPromptIndicatorTypeString += '<option value=\'' + IndicatorTypeString + '\'>' + IndicatorTypeString + '</option>';
    }
    for(var userPromptIndicatorInvestedIndex in userPromptIndicatorInvestedGroupByCheck)
    {
      let IndicatorInvested = userPromptIndicatorInvestedGroupByCheck[userPromptIndicatorInvestedIndex];
      userPromptIndicatorInvestedString += '<option value=\'' + IndicatorInvested + '\'>' + IndicatorInvested + '</option>';
    }
    for(var userPromptIndicatorProfitSortedIndex in userPromptIndicatorProfitGroupByCheck)
    {
      let IndicatorProfit = userPromptIndicatorProfitGroupByCheck[userPromptIndicatorProfitSortedIndex];
      userPromptIndicatorProfitString += '<option value=\'' + IndicatorProfit + '\'>' + IndicatorProfit + '</option>';
    }   
    for(var IndicatorWinRateIndex in userPromptIndicatorWinRateGroupByCheck)
    {
      let IndicatorWinRate = userPromptIndicatorWinRateGroupByCheck[IndicatorWinRateIndex];
      userPromptIndicatorWinRateString += '<option value=\'' + Math.round(IndicatorWinRate * 100) / 100 + '\'>' + Math.round(IndicatorWinRate * 100) / 100 + '</option>';
    }
    for(var IndicatorWinsKey in userPromptIndicatorWinsGroupByCheck)
    {
      let IndicatorWinsSort = userPromptIndicatorWinsGroupByCheck[IndicatorWinsKey];
      userPromptIndicatorWinsString += '<option value=\'' + IndicatorWinsSort + '\'>' + IndicatorWinsSort + '</option>';
    }
    for(var userPromptIndicatorLossesSortedIndex in userPromptIndicatorLossesGroupByCheck)
    {
      let IndicatorLossessSort = userPromptIndicatorLossesGroupByCheck[userPromptIndicatorLossesSortedIndex];
      userPromptIndicatorLossestring += '<option value=\'' + IndicatorLossessSort + '\'>' + IndicatorLossessSort + '</option>';
    }
    for(var userPromptIndicatorNumberOfTradesSortedIndex in userPromptIndicatorNumberOfTradesGroupByCheck)
    {
      let IndicatorNumberOfTradesSort = userPromptIndicatorNumberOfTradesSort[userPromptIndicatorNumberOfTradesSortedIndex];
      userPromptIndicatorNumberOfTradesString += '<option value=\'' + IndicatorNumberOfTradesSort + '\'>' + IndicatorNumberOfTradesSort + '</option>';
    }

    userPromptIndicatorNamesString += '</userPromptIndicatorNameList>';
    userPromptIndicatorTypeString += '</userPromptIndicatorTypeList>';
    userPromptIndicatorInvestedString += '</userPromptIndicatorInvestedList>';
    userPromptIndicatorProfitString += '</userPromptIndicatorProfitList>';    
    userPromptIndicatorWinRateString += '</userPromptIndicatorWinRateList>';
    userPromptIndicatorWinsString += '</userPromptIndicatorWinsList>';    
    userPromptIndicatorLossestring += '</userPromptIndicatorLossesList>';    
    userPromptIndicatorNumberOfTradesString += '</userPromptIndicatorNumberOfTradesList>';     

    userPromptIndicatorNameListDataList.innerHTML = userPromptIndicatorNamesString;
    userPromptIndicatorTypeListDataList.innerHTML = userPromptIndicatorTypeString;
    userPromptIndicatorInvestedDataList.innerHTML = userPromptIndicatorInvestedString;
    userPromptIndicatorProfitDataList.innerHTML = userPromptIndicatorProfitString;  
    userPromptIndicatorWinRateListDataList.innerHTML = userPromptIndicatorWinRateString;
    userPromptIndicatorWinsDataList.innerHTML = userPromptIndicatorWinsString;
    userPromptIndicatorLossesDataList.innerHTML = userPromptIndicatorLossestring;  
    userPromptIndicatorNumberOfTradesDataList.innerHTML = userPromptIndicatorNumberOfTradesString;  
    return {userIndicatorsProp: props, sortStrategy: sortStrategy, sortDirection: sortDirection}
}


render(){ 
    
    let userIndicators = ''
    let userSortIndicators = ''
    let userSortIndicatorsDirection = ''
    let userIndicatorsProp =  []
        
    let conditionalOptions = [
      {
        key: '(All)',
        text: '(All)',
        value: '(All)',
      },
      {
        key: 'Equals',
        text: 'Equals',
        value: 'Equals',
      },
      {
        key: 'Not Equals',
        text: 'Not Equals',
        value: 'Not Equals',   
      },
      {
        key: 'Greater Than',
        text: 'Greater Than',
        value: 'Greater Than',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: 'Less Than',
        text: 'Less Than',
        value: 'Less Than',
      },
      {
        key: 'Greater Than or Equal',
        text: 'Greater Than or Equal',
        value: 'Greater Than or Equal',
      },
      {
        key: 'Less Than or Equal',
        text: 'Less Than or Equal',
        value: 'Less Than or Equal',
      }
    ];


    let strategyTypesDropDownOptions = [
      {
        key: 'My Strategies',
        text: 'My Strategies',
        value: 'My Strategies',
      },
      {
        key: 'Always Buy 1',
        text: '(View) Always Buy 1',
        value: 'Always Buy 1',
      },
      {
        key: '$1 Investor',
        text: '(View) $1 Investor',
        value: '$1 Investor',   
      },
      {
        key: '$10 Investor',
        text: '(View) $10 Investor',
        value: '$10 Investor',
      //   image: { avatar: true, src: 'images/equations/GreaterThan.png' },
      },
      {
        key: '$100 Investor',
        text: '(View) $100 Investor',
        value: '$100 Investor',
      },
      {
        key: '$1000 Investor',
        text: '(View) $1000 Investor',
        value: '$1000 Investor',
      },
      {
        key: '$10000 Investor',
        text: '(View) $10000 Investor',
        value: '$10000 Investor',
      }
    ];


    return (
        <TestRunAndTickerRepoConsumer>
          {
          context => {      
            let headerColumnSpan = 9
            
            if(context != undefined && context.StateOfUserProfilePopup.userIndicators != undefined && Object.keys(context.StateOfUserProfilePopup.userIndicators).length > 0)
            {
              let userIndicatorDict = this.SetUserIndicatorsProp(context)
              userSortIndicators = userIndicatorDict.sortStrategy
              userSortIndicatorsDirection = userIndicatorDict.sortDirection
              userIndicatorsProp = userIndicatorDict.userIndicatorsProp
            } 
            if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Strategies Table')
            {
              headerColumnSpan = 10
            }
            else if(context.StateOfUserProfilePopup.CurrentUserSelectedTable == 'Indicators Table')
            {
              headerColumnSpan = 13
            }
            
            userIndicators = this.renderIndicatorsData(userIndicatorsProp, context)

              return (     this.IndicatorTableDataRows(context, conditionalOptions, userIndicators)
              );     
          }
        }  
        </TestRunAndTickerRepoConsumer>
      ); 
}
}
export default IndicatorsTable