import React, { useEffect, useState } from "react";
import { Button, Spinner } from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import AsyncConfirmationPrompt from "./AsyncConfirmationPrompt.js";
import UserProfilePrompt from "./UserProfilePrompt.js";
import crypto from "crypto";

const Profile = (refreshScreen) => {
    let testRunAndTickerConsumerContext = TestRunAndTickerRepoConsumer._currentValue;
    var contextType = TestRunAndTickerRepoContext;

    const requestIsValid = (inital_state, returned_state) => { return inital_state === returned_state }
    const [isLoading, setLoading] = useState(true);
    const [checkedIfAlpacaAuthorized, setCheckedIfAlpacaAuthorized] = useState(false);
    const [showAlpacaAuthorizationPrompt, setShowAlpacaAuthorizationPrompt] = useState(true);
    const [isAlpacaAccountAuthorized, setAlpacaAccountAuthorized] = useState(false);
    const [currentUserProfile, setCurrentUserProfile] = useState();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);
    const axios = require("axios").default;
    // axios.defaults.baseURL = '//localhost:51728';
    axios.defaults.baseURL = '//neuraltradingnetwork.com';
    var accessToken = "";

    useEffect(() => {
        const getUserMetadata = async () => {
          const domain = "dev-3d529kxe.us.auth0.com";
      
          try {
            let authorizationAccessToken = await getAccessTokenSilently({
              audience: `https://${domain}/api/v2/`,
              scope: "read:current_user",
            });
      
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${authorizationAccessToken}`,
              },
            });
      
            const { user_metadata } = await metadataResponse.json();
      
            setUserMetadata(user_metadata);
            //testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(user_metadata)
          } catch (e) {
            console.log(e.message);
          }
        };            

        getUserMetadata();
      }, [getAccessTokenSilently, user?.sub]);
      

      useEffect(() => {
        const GetAndSetAlpacaAuthorized = async (urlString, userId) => {          
          // if (window.location.href.includes('&state='))
          // {
          //   let clientId = GetStateIdentifier()
          //   const userData = await axios.get(urlString + 'Users/CustomSelector/' + clientId)
          //   let userIdCheck = userData.data.substring(1)
          //   if (userIdCheck == userIdParam)
          //   {
          //     isAlpacaAuthorized = true
          //     await UpdateUser(userIdParam, 'alpaca_authorized', isAlpacaAuthorized)
          //     setAlpacaAuthorized = {UserId: userIdParam, alpaca_authorized: isAlpacaAuthorized}
          //     testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(setAlpacaAuthorized)
          //     setAlpacaAccountAuthorized(isAlpacaAuthorized)
          //     setCheckedIfAlpacaAuthorized(true)
          //     setLoading(false);
          //   }
          // }
          // else
          // {
          //   isAlpacaAuthorizedResponse = await axios.get(urlString + 'Users/G' + userIdParam + '/alpaca_authorized')
          //   isAlpacaAuthorized = isAlpacaAuthorizedResponse.data == 1
          //   setAlpacaAuthorized = {UserId: userIdParam, alpaca_authorized: isAlpacaAuthorized}
          //   testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(setAlpacaAuthorized)
          //   setAlpacaAccountAuthorized(isAlpacaAuthorized)
          //   setCheckedIfAlpacaAuthorized(true)
          //   setLoading(false);
          // }          
          const isUserAccountDataResponse = await axios.get(urlString + 'Users/G' + userId + '/client_free_cash&client_accrued_fees&client_equity&alpaca_authorized')
          let userAccountData = isUserAccountDataResponse.data
          return userAccountData;
          
          //setRedirectingToAlpaca(false);
        };

        // const GetAndSetUserStrategies = async (urlString, userId) => {
        //   const userStrategiesRaw = await axios.get(urlString + 'Users/CustomSelector/UserStrategies/G' + userId)
        //   let userStrategiesData = userStrategiesRaw.data
        //   return userStrategiesData;
        // };     

        // const GetAndSetUserOrders = async (urlString, userId) => {
        //   const userOrdersRaw = await axios.get(urlString + 'Users/CustomSelector/UserOrders/G' + userId)
        //   let userOrdersData = userOrdersRaw.data
        //   return userOrdersData;
        // };
     

        let urlString = ''
        if(user != null)
        {
          let userId = user.sub.split('|')[1]   
          let isAlpacaAuthorized = false
          let setAlpacaAuthorized = ''
  
          
          if(testRunAndTickerConsumerContext.connectionType == "Test")
          {
            urlString = 'api/values/'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/'
          } 

          testRunAndTickerConsumerContext.GetAndSetUserStrategies(urlString, userId).then(userStrategies => 
          {
            testRunAndTickerConsumerContext.setStateUserStrategies(userStrategies)
          })          

          testRunAndTickerConsumerContext.GetAndSetUserOrders(urlString, userId).then(userOrders => 
          {
            testRunAndTickerConsumerContext.setStateUserOrders(userOrders)
          })

          testRunAndTickerConsumerContext.GetAndSetUserAssets(urlString, userId).then(userAssets => 
          {
            testRunAndTickerConsumerContext.setStateUserAssets(userAssets)
          })

          testRunAndTickerConsumerContext.GetAndSetUserIndicators(urlString, userId).then(userIndicators => 
          {
            testRunAndTickerConsumerContext.setStateUserIndicators(userIndicators)
          })



          GetAndSetAlpacaAuthorized(urlString, userId).then(userAccountData => 
            {
              let alpacaAuthorizedForUser = userAccountData.alpaca_authorized == 1
              setAlpacaAuthorized = {UserId: userId, alpaca_authorized: alpacaAuthorizedForUser}
              testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(setAlpacaAuthorized)
              testRunAndTickerConsumerContext.setUserName(user.nickname)
              testRunAndTickerConsumerContext.setUserId(userId)
              testRunAndTickerConsumerContext.setUserAlpacaAuthorized(alpacaAuthorizedForUser)
              testRunAndTickerConsumerContext.setUserFunds(userAccountData)
              setAlpacaAccountAuthorized(alpacaAuthorizedForUser);
              setCheckedIfAlpacaAuthorized(true);
              setShowAlpacaAuthorizationPrompt(!alpacaAuthorizedForUser) // Don't show the prompt if already authorized
              setLoading(false);
            })
        }

      }, []);




    // useEffect(() => {
    //   const RedirectingToAlpacaCheck = async () => {RedirectToAlpacaAuthorization()};

    //   if(redirectingToAlpaca)
    //   {
    //     RedirectingToAlpacaCheck();
    //   }
    // })

    // const onConfirm = (confirmString) =>
    // {
    //   console.log("Confirm String: " + confirmString)
    //   //setRedirectingToAlpaca(true)
    //   const RedirectingToAlpaca = async () => {RedirectToAlpacaAuthorization()};

    //   (async () => {

    //     await RedirectingToAlpaca();
    //     })();          
    // }

    async function onConfirm() {
      console.log("Confirm String: ")
      
      console.log('Redirecting To Alpaca')
      let clientId = crypto.randomBytes(16).toString("hex") //'8e02c9c6a3484f4daaf8c1fb1df290e1'
      await SetClientStateIdentifier(clientId)
      await RedirectToAlpacaAuthorization(clientId)
      setShowAlpacaAuthorizationPrompt(false)
      //setRedirectingToAlpaca(true)
      // const RedirectingToAlpaca = async () => {RedirectToAlpacaAuthorization()};

      // (async () => {

      //   await RedirectingToAlpaca();
      //   })();   
    }

    async function onCancel ()
    {
        console.log("Cancel Alpaca Authorization")
        let clientId = crypto.randomBytes(16).toString("hex") //'8e02c9c6a3484f4daaf8c1fb1df290e1'
        await SetClientStateIdentifier(clientId)
        setShowAlpacaAuthorizationPrompt(false)
    }

    const RedirectToAlpacaAuthorization = async (clientId) => {
        //TODO Update alpaca_client_id HERE in AUTH0
        let redirectUri = 'https://app.alpaca.markets/oauth/authorize?response_type=code&client_id=61ff72974b4b02df7305087233ae8693&redirect_uri=https://neuraltradingnetwork.com/&state=' + clientId + '&scope=account:write%20trading'
        
        try 
        {
          const { code, state } = await openAlpacaPopUp(redirectUri)
          if (!requestIsValid(clientId, state)) 
          {
            throw new Error("Alpaca Authentication Invalid")
          }
          console.log('Alpaca Authorization Successful')
          console.log(code)
          console.log(state)
          let isAlpacaAuthorized = code != undefined
          let userId = user.sub.split('|')[1] 
          await UpdateUser(userId, clientId, isAlpacaAuthorized, code)
          let setAlpacaAuthorized = {UserId: userId, alpaca_authorized: isAlpacaAuthorized}
          testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(setAlpacaAuthorized)
          setAlpacaAccountAuthorized(isAlpacaAuthorized)
        }
        catch (e) 
        {
          const error = {}
          error.message = e.message
          console.log(error)

        }
        
        //window.location.href = redirectUri;
        //console.log('Alpaca Authorization Successful')
    }
    
    const SetClientStateIdentifier = async (clientId) => 
    {
        let userId = user.sub.split('|')[1] 
        let userData = {UserId: userId, alpaca_authorized: false, alpaca_client_id: clientId, client_equity: 0, client_total_invested: 0, client_total_profit: 0, 
          client_total_wins: 0, client_total_losses: 0, client_total_winrate: 0, investing_active: false}

          let urlString = ''
          if(testRunAndTickerConsumerContext.connectionType == "Test")
          {
            urlString = 'api/values/'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/'
          }

          userData.UserId = 'G' + userData.UserId // Force a string for the id
          await axios({
            method:'post',
            url: urlString + 'InsertUser',
            headers: {'content-type': 'application/json'},
            data: userData
          }) 
          .then(res => { 
              console.log("Successfully called InsertUser " + userData.UserId)
              userData.UserId.substring(1) // Remove G prefix
              console.log("Removed G Prefix InsertUser " + userData.UserId)
              testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(userData)
              setCurrentUserProfile(userData)
              console.log(res);
          });
    }


    // const SetAlpacaAuthorized = () => 
    // {
    //     let clientId = GetStateIdentifier()
    //     let urlString = ''
    //     let userId = ''
    //     if(testRunAndTickerConsumerContext.connectionType == "Test")
    //     {
    //       urlString = 'api/values/'
    //     }
    //     else
    //     {
    //       urlString = 'NeuralTradingNetwork/api/values/'
    //     }
    //     (async () => {
    //       const userData = await axios.get(urlString + 'Users/CustomSelector/' + clientId)
    //       userId = userData.data.substring(1)
    //       // let isAlpacaAuthorized = await axios.get(urlString + 'Users/' + userId + '/alpaca_authorized')

    //       // if (isAlpacaAuthorized.data == 0)
    //       // {
    //       //   //Only update the value if alpaca_authorized was previously false
    //       //   await UpdateUser(userId, 'alpaca_authorized', true)
    //       // }
          
    //       await UpdateUser(userId, 'alpaca_authorized', true)
    //       let SetAlpacaAuthorized = {UserId: userId, alpaca_authorized: true}
    //       testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(SetAlpacaAuthorized)
    //       setAlpacaAccountAuthorized(true)
    //       })();
    //   }

      // const CheckIfAlpacaAuthorized = () => {
      //   let urlString = ''
      //   let userId = user.sub.split('|')[1] 
      //   let isAlpacaAuthorized = false
      //   if(testRunAndTickerConsumerContext.connectionType == "Test")
      //   {
      //     urlString = 'api/values/'
      //   }
      //   else
      //   {
      //     urlString = 'NeuralTradingNetwork/api/values/'
      //   }

      //   let isAlpacaAuthorizedResponse = await axios.get(urlString + 'Users/' + userId + '/alpaca_authorized')
      //   isAlpacaAuthorized = isAlpacaAuthorizedResponse.data == 1              
      //   let setAlpacaAuthorized = {UserId: userId, alpaca_authorized: isAlpacaAuthorized}
      //   testRunAndTickerConsumerContext.setUserMetaDataFromCurrentProfile(setAlpacaAuthorized)
      //   return isAlpacaAuthorized  
      // }



    const UpdateUser = async(userId, alpaca_client_idValue, alpaca_authorizedValue, codeValue) => 
    {
        let urlString = ''
        if(codeValue === undefined)
        {
          console.log('Alpaca canceled Authorization. User will need to Re-Authorize before live trading allowed.')
          codeValue = ''
        }
        if(testRunAndTickerConsumerContext.connectionType == "Test")
        {
          urlString = 'api/values/'
        }
        else
        {
          urlString = 'NeuralTradingNetwork/api/values/'
        }
  
        await axios({
          method:'patch',
          url: urlString + 'UpdateUserData',
          data: {
            UserId: 'G' + userId,
            alpaca_client_id: alpaca_client_idValue,
            alpaca_authorized: alpaca_authorizedValue,
            alpaca_code: codeValue
          }
          //baseURL: 'https://archives.neuraltradingnetwork.com/',
        }) 
        .then(res => { 
          console.log(res)
        });
     }
  


    const GetStateIdentifier = () => {
        let currentUrl = window.location.href;
        let startIndex = currentUrl.indexOf("&state=") + 7;
        let endIndex = currentUrl.length;
        let stateIdentifier = currentUrl.substring(startIndex, endIndex)       
        return stateIdentifier 
    }


    const openAlpacaPopUp = (uri) => {
        return new Promise((resolve, reject) => {
            const authWindow = window.open(uri);
            let snippet = uri | null;

            const interval = setInterval(async () => {
                try {
                    snippet = authWindow && authWindow.location && authWindow.location.search;
                } catch (e) { }
                if (snippet) {
                    const rawCode = snippet.substring(1);
                    const code = JSON.parse('{"' + rawCode.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
                    authWindow.close();
                    resolve(code);
                    clearInterval(interval)
                }
            }, 100);
        });
    };

    async function userPromptOnCancel ()
    {
        console.log("Save and Close User Prompt")
        // await UpdateUserConfigurableValues(clientId)
        testRunAndTickerConsumerContext.setUserProfilePopupDisplaying()
    }

    async function userPromptOnConfirm ()
    {
        console.log("Refresh User Data")
        let urlString = ''
        if(user != null)
        {
          let userId = user.sub.split('|')[1]     
          
          if(testRunAndTickerConsumerContext.connectionType == "Test")
          {
            urlString = 'api/values/'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/'
          } 
          
          const userAccountData = await axios.get(urlString + 'Users/G' + userId + '/client_free_cash&client_accrued_fees&client_equity&alpaca_authorized')
          testRunAndTickerConsumerContext.setUserFunds(userAccountData.data)
        }
        
    }

    return (
      <TestRunAndTickerRepoConsumer>
      {context => { 
          return(     
                isAuthenticated && user != null ? 
                context.StateOfUserProfilePopup.isPropDisplaying
                  ? 
                  <div className="UserPromptStyle">             
                    <UserProfilePrompt refreshScreen={refreshScreen} onConfirm={userPromptOnConfirm} onCancel={userPromptOnCancel} animation="border" role="status" />
                  </div> 
                  : 
                  <div >    
                        {
                            checkedIfAlpacaAuthorized && !isAlpacaAccountAuthorized && !isLoading && showAlpacaAuthorizationPrompt//&& !userMetadata.alpaca_authorized //testRunAndTickerConsumerContext.userMetadata != undefined && testRunAndTickerConsumerContext.userMetadata != {} // && !testRunAndTickerConsumerContext.userMetadata.alpaca_authorized
                            ? <div className="NavigateToAlpacaPrompt">
                            {
                              <AsyncConfirmationPrompt onConfirm={onConfirm} onCancel={onCancel} firstMessage={'For Live Stock and Crypto Trading, you will need an account with the trading brokerage AlpacaDB.'} secondMessage={'Would you like to create/link your Alpaca account now?'}  />                                              
                            }
                        </div>
                        : showAlpacaAuthorizationPrompt 
                          ? <div>             
                              <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div> 
                          : <div></div>
                        }     
                    </div>    
                : <div/>
              )
            }
          }
          </TestRunAndTickerRepoConsumer>);
};


export default Profile;