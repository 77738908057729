import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBCardFooter, MDBContainer, MDBTable, MDBListGroup, MDBListGroupItem } from "mdbreact";
import styles  from  '../App.css'

const PanelPage = (props) => {
return (
  <div>
<div class="PanelPageClass">
<h1>{props.panelType}</h1>
<MDBContainer style={{styles}}>
    <MDBCard border="success" className="m-3" style={{ maxWidth: "18rem" }}>
      <MDBCardHeader tag="h3">{props.panelTitle}</MDBCardHeader>
      <MDBCardBody className="PanelCardBody">
        <MDBCardTitle tag="h1">{props.profit}</MDBCardTitle>
        <MDBCardText>{props.winLossText}</MDBCardText>
        <MDBCardText color="green"> {props.summaryText}
        </MDBCardText>
      </MDBCardBody>
        <MDBCardFooter color="success-color">{props.duration}</MDBCardFooter>
    </MDBCard>
</MDBContainer> 
</div>
</div>
);
};

export default PanelPage;