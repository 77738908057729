import TextLoop from "react-text-loop";
import cxs from "cxs/component";

const Example = cxs("div")({
    fontSize: "22px"
  });
  
  const Title = cxs("div")({
    marginBottom: "5px",
    fontSize: "10px",
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#aaa"
  });
  
  const Section = cxs("div")({
    marginRight: "1rem",
    fontSize: "1.25rem",
    textDecoration: "none",
    whiteSpace: "nowrap",
    marginBottom: "2rem",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif'
  });
  
  const StyledTextLoop = cxs(TextLoop)({
    display: "block"
  });

const RollingText = () => {
    return (
        <Section>
          <Example>   
            <TextLoop springConfig={{  stiffness: 70, damping: 31 }} 
        adjustingSpeed={1500} interval={6000}>
              <span className="GoodNewsUpdate">Welcome To Neural Trading Network (NTN)</span>
              <span className="GoodNewsUpdate">Current Release: Pre-Alpha</span>
              <span className="GoodNewsUpdate">User Accounts Added (View, Simulate and Trade)</span>
              <span className="GoodNewsUpdate">Automatically Buy and Sell 17 Different Cryptos</span>
              <span className="GoodNewsUpdate">24/7 Price Movement Monitoring</span>
              <span className="GoodNewsUpdate">Metrics Tailored Toward your Investment History</span>
              <span className="GoodNewsUpdate">6 Variations Of Indicators Available To Use</span>
              <span className="GoodNewsUpdate">Stop Loss Coming Soon!</span>
              <span className="GoodNewsUpdate">Tutorial Coming Soon!</span>
              <span className="GoodNewsUpdate">Private Beta Testing Results Coming Soon!</span>
              <span className="GoodNewsUpdate">Multiple Graphical Improvements Coming Soon!</span>
            </TextLoop>{"   "}
            |        Updates Every 5 Minutes  
          </Example>
        </Section>
        );
    };


export default RollingText