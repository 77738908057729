import React from 'react'

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";

const axios = require('axios');
  // axios.defaults.baseURL = '//localhost:51728';
axios.defaults.baseURL = '//neuraltradingnetwork.com';
class ComboDropDown extends React.Component {    
  //static contextType = Consumer
  TestRunAndTickerDictionary = {}; 

  static contextType = TestRunAndTickerRepoContext;

  state = {
    componentName: ''
  };


  constructor(props) {
    super(props);
    this.state.componentName = props.labelValue;
  }

  GetConsumer = () => {
    return (
      <TestRunAndTickerRepoConsumer>
        {context => {     
    
               
        }}
      </TestRunAndTickerRepoConsumer>
    ); 
  }

  componentDidMount() {
    // Runs after the first render() lifecycle
    // Trigger update
    const orderTestRunIdsBy = ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average'];

    let contextConsumer = this.context;
    if(Object.keys(contextConsumer.TestRunAndTickerDictionary).length === 0)
    {
          let urlString = ''
          if(contextConsumer.connectionType == "Test")
          {
            urlString = 'api/values/5'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/5'
          }

           axios({
            method:'get',
            url: urlString,
            //baseURL: 'https://archives.neuraltradingnetwork.com/',
           })
            .then(res => {
                contextConsumer.UpdateTestRunAndTickerDictionary(res.data);
                for (let key in res.data) {
                  contextConsumer.TestRunAndTickerDictionary[key] = res.data[key];
                }
                
                if(this.state.componentName == 'Test Run Id')
                {
                  contextConsumer.sortTestRunByCurrentPriority(contextConsumer.TestRunAndTickerDictionary);
                  contextConsumer.setStateOfComponentTestRun({dataValues:  Object.keys(contextConsumer.TestRunAndTickerDictionary)});
                  // TestRunAndTickerRepo.stateTestRun.setState({
                  //   dataValues: Object.keys(TestRunAndTickerRepo.GetTestRunAndTickerDictionary())})
                }
                else if(this.state.componentName == 'Stock Result Data')
                {     
                  
                  contextConsumer.setStateOfComponentTicker(Object.values(contextConsumer.CurrentValues))
      
                  // TestRunAndTickerRepo.stateTicker.setState({
                  //   dataValues: TestRunAndTickerRepo.GetCurrentValues()})
                } else if(this.state.componentName == 'Order Test Run By')
                {
                  contextConsumer.setStateOfComponentPriority({dataValues:  orderTestRunIdsBy})
                  //contextConsumer.setStateOfComponentPriority({dataValues: orderTestRunIdsBy})
                }
                else 
                {
                  contextConsumer.setStateOfComponentPriority({dataValues:  orderTestRunIdsBy})
                  //contextConsumer.setStateOfComponentPriority({dataValues: orderTestRunIdsBy})
                }
            })
          }        
  };

  
  render() {  
    if(this.state.componentName == 'Test Run Id')
    {        
      return (
        <TestRunAndTickerRepoConsumer>
          {context => {
			let popupStateTestRun = ''
			if(context.StateOfPopup != undefined)
			{
				popupStateTestRun = context.StateOfPopup.currentTestRunName
			}
            return (
              <div style={{marginLeft:'10%'}}>
                <h3>{context.stateTestRun.comboBoxHeader}</h3>
                <Autocomplete
				          value={popupStateTestRun}
                  options={context.stateTestRun.dataValues}
                  style={{ width: 300 }}
                  onInputChange={context.handleTestRunInputChange}
                  renderInput={(params) =>
                    <TextField {...params} label={context.stateTestRun.labelValue} variant="outlined" />}
                />          
              </div>
            );
          }}
        </TestRunAndTickerRepoConsumer>
      );      
    }
    else if(this.state.componentName == 'Stock Result Data')
    {    
      return (
        <TestRunAndTickerRepoConsumer>
          {context => {
			let popupStateTicker = ''
			if(context.StateOfPopup != undefined)
			{
				popupStateTicker = context.StateOfPopup.currentTickerName
			}
      console.log('Trying to change ticker with datavalues: ' + context.stateTicker.dataValues);
            return (
              <div style={{marginLeft:'10%'}}>
                <h3>{context.stateTicker.comboBoxHeader}</h3>
                <Autocomplete
				          value={popupStateTicker}
                  options={context.stateTicker.dataValues}
                  style={{ width: 300 }}
                  onInputChange={context.handleTickerInputChange}
                  renderInput={(params) =>
                    <TextField {...params} label={context.stateTicker.labelValue} variant="outlined" />}
                />          
              </div>
            );
          }}
        </TestRunAndTickerRepoConsumer>
      );         
    }
    else 
    { 
      return (
        <TestRunAndTickerRepoConsumer>
          {context => { 
            let popupStatePriority = ''
            if(context.StateOfPopup != undefined)
            {
              popupStatePriority = context.StateOfPopup.currentPriority
            }           
            return (
              <div style={{marginLeft:'10%'}}>
                <h3>{context.statePriority.comboBoxHeader}</h3>
                <Autocomplete
				          value={popupStatePriority}
                  options={context.statePriority.dataValues}
                  style={{ width: 300 }}
                  onInputChange={context.handlePriorityInputChange}
                  renderInput={(params) =>
                    <TextField {...params} label={context.statePriority.labelValue} variant="outlined" />}
                />          
              </div>
            );
          }}
        </TestRunAndTickerRepoConsumer>
      );    
    }    
  }
}



export default ComboDropDown