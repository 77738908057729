import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
import { Button, Spinner } from 'react-bootstrap'

import {  Table} from 'semantic-ui-react'

import { MDBContainer, MDBCard, MDBCardText, MDBCardFooter, MDBCardTitle, MDBCardBody, MDBCardHeader} from "mdbreact";
import TableExampleApprove from './TableExampleApprove';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import UserChartsPrompt from './UserChartsPrompt';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ComboDropDown from "./ComboDropDown.js";
import LineChart from './LineChart.js';
import React from 'react';
import '../styles/style.css';


const axios = require('axios');
// // import LineChart from 'components/LineChart.js';
// import LineChart from './LineChart.js';
// import { Button } from '@material-ui/core'

// import { TestRunAndTickerRepoConsumer } from "./TestRunAndTickerRepository.js";
// import { TestRunAndTickerRepoContext } from "./TestRunAndTickerRepository.js";
// import ComboDropDown from './ComboDropDown';
// import StrategyConfigurationPrompt from './StrategyConfigurationPrompt'
// // import ComboDropDown from './components/ComboDropDown.js';
// // import SlideBar from 'components/SlideBar';
// import PanalPage from './PanalPage.js';
// // import NeuronAnimation from 'images/NeuronPresentation.gif'
// // import TextField from '@material-ui/core/TextField';

// const axios = require('axios');
  // axios.defaults.baseURL = '//localhost:51728';
axios.defaults.baseURL = '//neuraltradingnetwork.com';

// let classes = useStyles();

class UserProfilePrompt extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
        count: 0,
        key: Date.now() 
        };
    }

    static contextType = TestRunAndTickerRepoContext;

    
    xLabels = ['00:00:00 3-17-2021', '04:00:00 3-17-2021', '08:00:00 3-17-2021', 
    '12:00:00 3-17-2021', '16:00:00 3-17-2021', '20:00:00 3-17-2021'];
  

    priceDataList = [{
      label: 'SlowEma',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: 'orange',
      borderColor: 'orange',
      borderWidth: 2
      },
      {
        label: 'FastEma',
        data: [11, 22, 1, 3, 2, 5],
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2
      },
      {
          label: 'Price',
          data: [47, 52, 67, 59, 80, 50],
          backgroundColor: 'blue',
          borderColor: 'green',
          borderWidth: 2
      }
      ];

     macdDataList = [{
        label: 'Macd',
        data: [],
        backgroundColor: 'red',
        borderColor: 'red',
        borderWidth: 2
        },
        {
          label: 'Signal',
          data: [],
          backgroundColor: 'blue',
          borderColor: 'blue',
          borderWidth: 2
        }] 


      rsiDataList = [{
          label: 'RSI',
          data: [],
          backgroundColor: 'blue',
          borderColor: 'blue',
          borderWidth: 2
        }
      ]

        GetConsumer = () => {
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {     
          
                     
              }}
            </TestRunAndTickerRepoConsumer>
          ); 
        }
        
        componentDidMount() {
          // let tempTestRunName = '5-MINUTE-rich-masterful-horse'
          let tempTicker = 'LTCUSD'
          let contextConsumer = this.context;
          let orderedStrategiesByProfit = null;
          let props = []
          for (let userStrategyId in contextConsumer.StateOfUserProfilePopup.userStrategies)
          {
            let userStrategy = contextConsumer.StateOfUserProfilePopup.userStrategies[userStrategyId]
            let prop = {              
              userStrategyRowName: userStrategy.TestRunId, 
              userStrategyStartedInvestingDate: userStrategy.StartedInvestingDate, 
              userStrategyEndedInvestingDate: userStrategy.EndedInvestingDate, 
              userStrategyInvested: userStrategy.Invested, 
              userStrategyProfit: userStrategy.Profit, 
              userStrategyWinRate: userStrategy.WinRate, 
              userStrategyStrategyType: userStrategy.StrategyType, 
              userStrategyPercentageOfWholeStrategy: userStrategy.PercentageOfWholeStrategy,
              userStrategyIndicators: userStrategy.ActiveIndicators
            }
            props.push(prop);
          }
          //Default to using the highest profitable strategy first
          props.sort(function(a, b){
            return contextConsumer.GetOrderedValues('ascending', a.userStrategyProfit, b.userStrategyProfit)
          });
          if(props.length > 0)
          {
            let chosenTestRunName = props[0].userStrategyRowName
            // let chosenTicker = props[0].user
            this.updateUserPromptStrategyOverviewChart(chosenTestRunName, tempTicker)
          }
        }

        // componentDidUpdate(prevProps, prevState) {
        //   let contextConsumer = this.context;
        //   if(prevState.count == this.state.count)
        //   {
        //     this.props.refreshScreen.ProfileControllerProp('Strategy_Selected')
        //     this.setState({ count: this.state.count + 1 }) 
        //     this.setState({ key: Date.now() }) 
        //   }
        // }
      


        ModifyUserIdBeforeSending(context)
        {
          let userId = ''
          if(context.StateOfUserProfilePopup.StrategyType == 'My Strategies')
          {
            if(context.StateOfUserProfilePopup.userId == '')
            {              
              userId = 'MainUser'
            }
            else
            {
              userId = 'G' + context.StateOfUserProfilePopup.userId
            }
          }
          else if(context.StateOfUserProfilePopup.StrategyType != 'MainUser')
          {
            userId = context.StateOfUserProfilePopup.StrategyType
          }
          return userId
        }
    
        GetUrlPrefix(context)
        {
          let urlString = ''
          let userId = ''
          if(context.connectionType == "Test")
          {
            urlString = 'api/values/'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/'
          }
          return urlString
        }


        updateUserPromptStrategyOverviewChart(testRunId, ticker)
        {
          let contextConsumer = this.context;
          let urlPrefix = this.GetUrlPrefix(contextConsumer)
          let modifiedUserId = this.ModifyUserIdBeforeSending(contextConsumer)
          let tempLabels = []
          let GetTestRunOrderDataUrl = ''      
          if(modifiedUserId == '')
          {
            GetTestRunOrderDataUrl = urlPrefix + '8/' + testRunId;
          }
          else
          {
            GetTestRunOrderDataUrl = urlPrefix + 'Users/AdjustEquityProfitData/' + modifiedUserId + '/' + testRunId;
          }

          // let GetTestRunExecutionData = contextConsumer.GetAllTestRunAndTickerOrderDataUrl(testRunId)
          
          axios({
            method:'get',
            url: GetTestRunOrderDataUrl,
            //baseURL: 'https://archives.neuraltradingnetwork.com/',
            })
          .then(res => {                         
            // for(let i = 0; i < res.data.length; i++)
            // {
            //   tempLabels.push(res.data[i])
            // }
            // contextConsumer.setStateOfNewLabelValues(tempLabels);
            contextConsumer.setTestRunExecutionTracking(res.data);
          });
          // let urlString = ''
          // if(contextConsumer.connectionType == "Test")
          // {
          //   urlString = 'api/values/'
          // }
          // else
          // {
          //   urlString = 'NeuralTradingNetwork/api/values/'
          // }
          // let GetTestRunAndTickerData = urlString + '6/' + testRunId + '/' + ticker;
          // let GetAllTestRunAndTickerOrderData = urlString + '7/' + testRunId + '/' + ticker;
          
          // contextConsumer.setOrderDataList([]);      
          // axios({
          //   method:'get',
          //   url: GetTestRunAndTickerData,
          //   //baseURL: 'https://archives.neuraltradingnetwork.com/',
          //  })
          //       .then(res => {
          //         this.newLabelValues = [] 
          //         contextConsumer.macdBuySellSignals = []
          //         contextConsumer.MacdAnnotations = []

          //         this.priceDataList = [
          //           {
          //             label: 'SlowEma',
          //             data: [],
          //             backgroundColor: 'orange',
          //             borderColor: 'orange',
          //             borderWidth: 2
          //           },
          //           {
          //             label: 'FastEma',
          //             data: [],
          //             backgroundColor: 'purple',
          //             borderColor: 'purple',
          //             borderWidth: 2
          //           },
          //           {
          //             label: 'Price',
          //             data: [],
          //             backgroundColor: 'blue',
          //             borderColor: 'green',
          //             borderWidth: 2
          //           }
          //         ]

          //         this.macdDataList = [
          //           {
          //             label: 'Macd',
          //             data: [],
          //             backgroundColor: 'red',
          //             borderColor: 'red',
          //             borderWidth: 2
          //           },
          //           {
          //             label: 'Signal',
          //             data: [],
          //             backgroundColor: 'blue',
          //             borderColor: 'blue',
          //             borderWidth: 2
          //           }
          //         ]
                  
          //         this.rsiDataList = [                    
          //           {
          //             label: 'RSI',
          //             data: [],
          //             backgroundColor: 'blue',
          //             borderColor: 'blue',
          //             borderWidth: 2
          //           }
          //         ]

          //         let tempLabelValues = []       
          //         let tempShortEmaValues = []    
          //         let tempLongEmaValues = []    
          //         let tempPriceValues = []    
          //         let tempMacdValues = []    
          //         let tempMacdFastValues = []    
          //         let tempMacdSlowValues = []    
          //         let tempSignalValues = []    
                  
          //         let tempHistogramMacdValues = []    
          //         let tempHistogramMacdFastValues = []    
          //         let tempHistogramMacdSlowValues = []    
          //         let tempHistogramSignalValues = []   
          //         let tempHistogramMacdSignalDifference = []   

          //         let tempMacdBuySellSignalValues = []    
          //         let tempHistogramMacdBuySellSignalValues = []    
          //         let tempRsiValues = []
          //         let tempRsiBuySellSignalValues = []    
          //         let index = 0
          //         let rsiAttributeObject = {
          //           rsiOverBoughtThreshold: 0, 
          //           rsiOverSoldThreshold: 0}

          //           let tempSingleTrendRsiValues = []    
          //           let tempSingleTrendRsiBuySellSignalValues = []
          //           let singleTrendRsiAttributeObject = {
          //                                           OverBoughtThreshold: 0, 
          //                                           OverSoldThreshold: 0};   

          //         contextConsumer.setStateOfNewLabelValues(tempLabelValues);
          //         contextConsumer.setStateOfNewShortEmaValues(tempShortEmaValues);
          //         contextConsumer.setStateOfNewLongEmaValues(tempLongEmaValues);
          //         contextConsumer.setStateOfNewPriceValues(tempPriceValues);
          //         contextConsumer.setStateOfNewMacdValues(tempMacdValues);
          //         contextConsumer.setStateOfNewMacdSignalValues(tempSignalValues);
          //         contextConsumer.setStateOfNewRsiValues(tempRsiValues);
          //         contextConsumer.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);
          //         contextConsumer.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);
          //         contextConsumer.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);
          //         contextConsumer.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);
                  

          //         for (let key in res.data) {     
          //           let testRunAndTickerValue = res.data[key];       
          //           let testRunAndTickerActiveIndicatorList = testRunAndTickerValue.ActiveIndicatorList;           
          //           tempLabelValues.push(testRunAndTickerValue.Modified);
          //           tempShortEmaValues.push(testRunAndTickerValue.ShortEmaValue);
          //           tempLongEmaValues.push(testRunAndTickerValue.LongEmaValue);
          //           tempPriceValues.push(testRunAndTickerValue.Price);

          //           if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'MacdIndicator'))
          //           {
          //               let macdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'MacdIndicator');
          //               tempMacdValues.push(macdElement.MacdValue);
          //               tempMacdFastValues.push(macdElement.Fast.CurrentValue);
          //               tempMacdSlowValues.push(macdElement.Slow.CurrentValue);
          //               tempSignalValues.push(macdElement.SignalValue.CurrentValue);
                        
          //               if(macdElement.IsDesiredBuy)
          //               {
          //                 tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'})
          //               }
          //               else if(macdElement.IsDesiredSell)
          //               {
          //                 tempMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
          //               }
          //           }
          //           if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'RsiIndicator'))
          //           {
          //               let rsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'RsiIndicator')
          //               tempRsiValues.push(rsiElement.RSI);       

          //               rsiAttributeObject = {
          //                 OverBoughtThreshold: rsiElement.OverBoughtThreshold, 
          //                 OverSoldThreshold: rsiElement.OverSoldThreshold};          

          //               if(rsiElement.IsDesiredBuy)
          //               {
          //                 tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
          //               }
          //               else if(rsiElement.IsDesiredSell)
          //               {
          //                 tempRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
          //               }
          //           }
          //           if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'HistogramMacdIndicator'))
          //           {
          //               let histogramMacdElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'HistogramMacdIndicator');                  
          //               tempHistogramMacdValues.push(histogramMacdElement.MacdValue);
          //               tempHistogramMacdFastValues.push(histogramMacdElement.Fast.CurrentValue);
          //               tempHistogramMacdSlowValues.push(histogramMacdElement.Slow.CurrentValue);
          //               tempHistogramSignalValues.push(histogramMacdElement.SignalValue.CurrentValue);    
          //               tempHistogramMacdSignalDifference.push(histogramMacdElement.DifferenceOfMacdAndSignal);        
                        
          //               if(histogramMacdElement.IsDesiredBuy)
          //               {
          //                 tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
          //               }
          //               else if(histogramMacdElement.IsDesiredSell)
          //               {
          //                 tempHistogramMacdBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
          //               }
          //           }
          //           if(testRunAndTickerActiveIndicatorList.some(e => e.IndicatorName === 'SingleTrendRsiIndicator'))
          //           {
          //               let singleTrendRsiElement = testRunAndTickerActiveIndicatorList.find(e => e.IndicatorName === 'SingleTrendRsiIndicator')
          //               tempSingleTrendRsiValues.push(singleTrendRsiElement.RSI);       
    
          //               singleTrendRsiAttributeObject = {
          //                 OverBoughtThreshold: singleTrendRsiElement.OverBoughtThreshold, 
          //                 OverSoldThreshold: singleTrendRsiElement.OverSoldThreshold};          
    
          //               if(singleTrendRsiElement.IsDesiredBuy)
          //               {
          //                 tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Buy'});
          //               }
          //               else if(singleTrendRsiElement.IsDesiredSell)
          //               {
          //                 tempSingleTrendRsiBuySellSignalValues.push({DateTimeOfSignal: testRunAndTickerValue.Modified, IndexOfSignal: index, SignalType: 'Sell'});
          //               }
          //           }
                    
          //           index++;
          //         }
                  
                  
          //         contextConsumer.setStateOfNewSingleTrendRsiAttributesValues(singleTrendRsiAttributeObject);
          //         contextConsumer.setStateOfNewRsiValues(tempRsiValues);
          //         contextConsumer.setStateOfNewRsiAttributesValues(rsiAttributeObject);
          //         contextConsumer.setStateOfNewLabelValues(tempLabelValues);
          //         contextConsumer.setStateOfNewShortEmaValues(tempShortEmaValues);
          //         contextConsumer.setStateOfNewLongEmaValues(tempLongEmaValues);
          //         contextConsumer.setStateOfNewPriceValues(tempPriceValues);
          //         contextConsumer.setStateOfNewMacdValues(tempMacdValues);
          //         contextConsumer.setStateOfNewMacdSignalValues(tempSignalValues);
          //         contextConsumer.setStateOfNewHistogramMacdValues(tempHistogramMacdValues);
          //         contextConsumer.setStateOfNewHistogramMacdSignalValues(tempHistogramSignalValues);
          //         contextConsumer.setStateOfMacdBuySellSignals(tempMacdBuySellSignalValues);
          //         contextConsumer.setStateOfRsiBuySellSignals(tempRsiBuySellSignalValues);
          //         contextConsumer.setStateOfHistogramMacdBuySellSignals(tempHistogramMacdBuySellSignalValues);
          //         contextConsumer.setStateOfNewHistogramMacdSignalDifference(tempHistogramMacdSignalDifference);
          //         contextConsumer.setStateOfNewSingleTrendRsiValues(tempSingleTrendRsiValues);
          //         contextConsumer.setStateOfSingleTrendRsiBuySellSignals(tempSingleTrendRsiBuySellSignalValues);
                  
                  
          //         axios({
          //           method:'get',
          //           url: GetAllTestRunAndTickerOrderData,
          //           //baseURL: 'https://archives.neuraltradingnetwork.com/',
          //           })
          //           .then(res => { 
          //             contextConsumer.setOrderDataList(res.data);
          //           });            
          //       });
        }

        
      AssetTableRows (context) {
        return <Table  celled inverted selectable striped><Table.Row>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetName} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetName'})}
          >Asset Name</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetType} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetType'})}
          >Asset Type</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetInvested} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetInvested'})}
          >Invested</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetProfit} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetProfit'})}
          >Profit</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetWinRate} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWinRate'})}
          >Win Rate</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetWins} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetWins'})}
          >Wins</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetLosses} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetLosses'})}
          >Losses</Table.HeaderCell>
          <Table.HeaderCell 
              sorted={context.StateOfUserProfilePopup.sortAssetNumberOfTrades} 
              onClick={() => context.SortUserPromptAssetsOnColumnDirectionChange({ type: 'CHANGE_SORT', column: 'userPromptAssetNumberOfTrades'})}
          >Number of Trades</Table.HeaderCell>
        </Table.Row>
      </Table>
      }

        render() {
          
          return (
            <TestRunAndTickerRepoConsumer>
              {context => {          
                
// xs={12} sm={12} direction={"row"}>      
                return (     
                  <div className="UserProfilePrompt">         
                      <MDBContainer >
                          <MDBCard border="success" className="m-3" >
                              <div  className="UserGridPrompt" style={{ padding: '10px'}} >
                              <Grid container direction={"row"} justifyContent={"flex-start"} >
                                <Grid container spacing={3}>     
                                
                              <Grid item  xs sm style={{ marginTop: "35px" }}>
                                  <Grid item xs sm>
                                    <Paper className={'paper'}>Name: {context.StateOfUserProfilePopup.nameOfUser}</Paper>
                                  </Grid>
                                  <Grid item xs sm style={{ marginTop: "5px" }}>
                                    <Paper className={'paper'}>Can Simulate Trades: Yes</Paper>
                                  </Grid>
                                  <Grid item xs sm style={{ marginTop: "5px" }}>
                                    <Paper className={'paper'}>Can Live Trade: {context.StateOfUserProfilePopup.alpaca_authorized ? "Yes" : "No"}</Paper>
                                  </Grid>
                                  <Grid item xs sm  style={{ marginTop: "5px" }}>
                                    <Paper className={'paper'}>Available Funds: ${context.StateOfUserProfilePopup.client_free_cash}</Paper>
                                  </Grid>
                                  <Grid item xs sm style={{ marginTop: "5px" }}>
                                    <Paper className={'paper'}>Total Equity: ${context.StateOfUserProfilePopup.client_equity}</Paper>
                                  </Grid>
                                  <Grid item xs sm style={{ marginTop: "5px" }}>
                                    <Paper className={'paper'}>Accrued Fees: ${context.StateOfUserProfilePopup.client_accrued_fees}</Paper>
                                  </Grid>
                                </Grid>       
                                  <Grid item xs={4} sm={7}> 
                                        <h3 className='ChartHeader'>Strategy Overview</h3>
                                        <LineChart              
                                            xAxisValues={context.StateOfCurrentStrategy.testRunExecutionTracking.xLabelExecutionTime}
                                            dataValuesList={context.StateOfCurrentStrategy.testRunExecutionTracking.data}                 
                                            labelValue='Evaluations Chart'
                                            // yAxesId='userPromptYAxis'
                                        />
                                  </Grid>                  
                                  <Grid item xs={6} sm={3}>
                                    Strategy Investments (Mock Data) 
                                    <ParentSize>
                                        {({ width, height }) => <UserChartsPrompt 
                                                                  width={width} 
                                                                  height={height*.9} 
                                                                  outerCircleMessage={'Outer Ring - Total Invested per Strategy '}
                                                                  innerCircleMessage={'Inner Pie Chart - Profits per Strategy'}/>}
                                    </ParentSize>
                                  </Grid>     
                                     
                                  {/* <Grid item xs={4} sm={4}>
                                    Profit Per Asset (Mock Data) 
                                    <ParentSize>
                                        {({ width, height }) => <UserChartsPrompt 
                                                                  width={width} 
                                                                  height={400} 
                                                                  outerCircleMessage={'Outer Ring - Top 5 Profiting Assets'}
                                                                  innerCircleMessage={'Inner Pie Chart - Highest Profiting Strategy per Top 5 Assets'}/>}
                                    </ParentSize>
                                  </Grid>         
                                  <Grid item xs={4} sm={4}>
                                    Profit Per Indicator (Mock Data) 
                                    <ParentSize>
                                        {({ width, height }) => <UserChartsPrompt 
                                                                  width={width} 
                                                                  height={400} 
                                                                  outerCircleMessage={'Outer Ring - Top 5 Most Profitable Indicators '}
                                                                  innerCircleMessage={'Inner Pie Chart - Highest Profiting Assets per Top 5 Indicators'}/>}
                                    </ParentSize>
                                  </Grid>                  */}
                                </Grid>
                                <Grid item xs={12} sm={12}  style={{ marginTop: "30px" }} direction={"row"} justifyContent={"flex-end"}>
                                  <div className="UserStrategyTable">
                                      <TableExampleApprove />
                                  </div>
                                </Grid>
                              </Grid>
                                {/* <Grid container spacing={1} direction={"column"} alignItems={"flex-start"} >
                                  <Grid item xs={12} >
                                    <Paper className={classes.paper}>
                                        Name: {context.StateOfUserProfilePopup.nameOfUser}
                                    </Paper>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <Paper className={classes.paper}>Can Simulate Trades: Yes</Paper>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <Paper className={classes.paper}>Can Live Trade: {context.StateOfUserProfilePopup.alpaca_authorized ? "Yes" : "No"}</Paper>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Paper className={classes.paper}>Available Funds: ${context.StateOfUserProfilePopup.client_free_cash}</Paper>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <Paper className={classes.paper}>Total Equity: ${context.StateOfUserProfilePopup.client_equity}</Paper>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Paper className={classes.paper}>Accrued Fees: ${context.StateOfUserProfilePopup.client_accrued_fees}</Paper>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Paper className={classes.paper}>1/2 Size</Paper>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Paper className={classes.paper}>Full Size</Paper>
                                  </Grid>
                                </Grid> */}
                              </div>
                              <div className="UserProfilePromptButtons">
                                  <Button onClick=
                                  {
                                      () => this.props.onConfirm()
                                  }>
                                      Refresh
                                  </Button>              
                              </div>
                              <div className="UserProfilePromptButtons">
                                  <Button onClick={() => this.props.onCancel()}>
                                      Save And Close
                                  </Button>                
                              </div>  
                          </MDBCard>
                      </MDBContainer>        
                  </div>   
              );          
              }}
            </TestRunAndTickerRepoConsumer>
          );
        }
}

export default UserProfilePrompt;


// const UserProfilePrompt = (props) =>
// {
//     const classes = useStyles();
    
//     return (
//         <TestRunAndTickerRepoConsumer>
//         {context => { 
//                   return (     
//                     <div className="UserProfilePrompt">         
//                         <MDBContainer >
//                             <MDBCard border="success" className="m-3" >
//                                 <div  className="UserGridPrompt" style={{ padding: '10px'}} >
//                                 <Grid container direction={"row"} justifyContent={"flex-start"} >
//                                   <Grid item xs={6} sm={3} style={{ marginTop: "5px" }}>
//                                     <Grid item xs={6} sm={6}>
//                                       <Paper className={classes.paper}>Name: {context.StateOfUserProfilePopup.nameOfUser}</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6} style={{ marginTop: "5px" }}>
//                                       <Paper className={classes.paper}>Can Simulate Trades: Yes</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6} style={{ marginTop: "5px" }}>
//                                       <Paper className={classes.paper}>Can Live Trade: {context.StateOfUserProfilePopup.alpaca_authorized ? "Yes" : "No"}</Paper>
//                                     </Grid>
//                                     <Grid item xs={12} sm={6} style={{ marginTop: "5px" }}>
//                                       <Paper className={classes.paper}>Available Funds: ${context.StateOfUserProfilePopup.client_free_cash}</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6} style={{ marginTop: "5px" }}>
//                                       <Paper className={classes.paper}>Total Equity: ${context.StateOfUserProfilePopup.client_equity}</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6} style={{ marginTop: "5px" }}>
//                                       <Paper className={classes.paper}>Accrued Fees: ${context.StateOfUserProfilePopup.client_accrued_fees}</Paper>
//                                     </Grid>
//                                   </Grid>
//                                   <Grid container xs={12} sm={9} direction={"row"}>                 
//                                     <Grid item xs={4} sm={8}> 
//                                           <h3 className='ChartHeader'>Strategy Overview</h3>
//                                           <LineChart              
//                                               xAxisValues={context.StateOfCurrentStrategy.testRunExecutionTracking.xLabelExecutionTime}
//                                               dataValuesList={context.StateOfCurrentStrategy.testRunExecutionTracking.data}                
//                                               labelValue='Evaluations Chart'
//                                           />
//                                     </Grid>                  
//                                     <Grid item xs={4} sm={4}>
//                                       Strategy Investments (Mock Data) 
//                                       <ParentSize>
//                                           {({ width, height }) => <UserChartsPrompt 
//                                                                     width={width} 
//                                                                     height={400} 
//                                                                     outerCircleMessage={'Outer Ring - Total Invested per Strategy '}
//                                                                     innerCircleMessage={'Inner Pie Chart - Profits per Strategy'}/>}
//                                       </ParentSize>
//                                     </Grid>          
//                                     {/* <Grid item xs={4} sm={4}>
//                                       Profit Per Asset (Mock Data) 
//                                       <ParentSize>
//                                           {({ width, height }) => <UserChartsPrompt 
//                                                                     width={width} 
//                                                                     height={400} 
//                                                                     outerCircleMessage={'Outer Ring - Top 5 Profiting Assets'}
//                                                                     innerCircleMessage={'Inner Pie Chart - Highest Profiting Strategy per Top 5 Assets'}/>}
//                                       </ParentSize>
//                                     </Grid>         
//                                     <Grid item xs={4} sm={4}>
//                                       Profit Per Indicator (Mock Data) 
//                                       <ParentSize>
//                                           {({ width, height }) => <UserChartsPrompt 
//                                                                     width={width} 
//                                                                     height={400} 
//                                                                     outerCircleMessage={'Outer Ring - Top 5 Most Profitable Indicators '}
//                                                                     innerCircleMessage={'Inner Pie Chart - Highest Profiting Assets per Top 5 Indicators'}/>}
//                                       </ParentSize>
//                                     </Grid>                  */}
//                                   </Grid>
//                                   <Grid item xs={12} sm={12} style={{ marginTop: "30px" }} direction={"row"} justifyContent={"flex-end"}>
//                                     <div className="UserStrategyTable">
//                                         <TableExampleApprove />
//                                     </div>
//                                   </Grid>
//                                 </Grid>
//                                   {/* <Grid container spacing={1} direction={"column"} alignItems={"flex-start"} >
//                                     <Grid item xs={12} >
//                                       <Paper className={classes.paper}>
//                                           Name: {context.StateOfUserProfilePopup.nameOfUser}
//                                       </Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6}>
//                                       <Paper className={classes.paper}>Can Simulate Trades: Yes</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6}>
//                                       <Paper className={classes.paper}>Can Live Trade: {context.StateOfUserProfilePopup.alpaca_authorized ? "Yes" : "No"}</Paper>
//                                     </Grid>
//                                     <Grid item xs={12} sm={6}>
//                                       <Paper className={classes.paper}>Available Funds: ${context.StateOfUserProfilePopup.client_free_cash}</Paper>
//                                     </Grid>
//                                     <Grid item xs={6} sm={6}>
//                                       <Paper className={classes.paper}>Total Equity: ${context.StateOfUserProfilePopup.client_equity}</Paper>
//                                     </Grid>
//                                     <Grid item xs={12} sm={6}>
//                                       <Paper className={classes.paper}>Accrued Fees: ${context.StateOfUserProfilePopup.client_accrued_fees}</Paper>
//                                     </Grid>
//                                     <Grid item xs={12} sm={6}>
//                                       <Paper className={classes.paper}>1/2 Size</Paper>
//                                     </Grid>
//                                     <Grid item xs={12}>
//                                       <Paper className={classes.paper}>Full Size</Paper>
//                                     </Grid>
//                                   </Grid> */}
//                                 </div>
//                                 <div className="UserProfilePromptButtons">
//                                     <Button onClick=
//                                     {
//                                         () => props.onConfirm()
//                                     }>
//                                         Refresh
//                                     </Button>              
//                                 </div>
//                                 <div className="UserProfilePromptButtons">
//                                     <Button onClick={() => props.onCancel()}>
//                                         Save And Close
//                                     </Button>                
//                                 </div>  
//                             </MDBCard>
//                         </MDBContainer>        
//                     </div>   
//                 );  
//               }
//             }
//             </TestRunAndTickerRepoConsumer>);
    
// };
    
// export default UserProfilePrompt;