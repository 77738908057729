import './App.css';
import './styles/navBar.css';
import React, {useState}from 'react'
// import Popup from 'reactjs-popup';
// import {Line} from 'react-chartjs-2'
// import { Button } from '@material-ui/core'
import { TestRunAndTickerRepoConsumer } from "./components/TestRunAndTickerRepository.js";
import { TestRunAndTickerRepoContext } from "./components/TestRunAndTickerRepository.js";
// import { TestRunAndTickerRepoContextProvider } from "components/TestRunAndTickerRepository.js";

// import Neurons from "components/Neurons.js";
import SidebarController from "./components/SidebarController.js";
import Profile from "./components/Profile.js";
import NeuronImageOfCube from "./images/cube.png";
import NeuronImageOfGreenCube from "./images/GreenCube.png";
import NeuronImageOfRedCube from "./images/RedCube.png";
import NeuronImageOfBlueCube from "./images/blueCube.png";
import NeuronImageOfGrayCube from "./images/grayCube.png";
import Popup from './components/Popup.js'; 
// import PanalPage from 'components/PanalPage.js'; 
// import LineChart from 'components/LineChart.js'
// import ComboDropDown from 'components/ComboDropDown.js'
// import SelectInput from '@material-ui/core/Select/SelectInput.js';
//import 'bootstrap/dist/css/bootstrap.min.css';

var cors = require('cors')

// const GetRequest = require('components/GetRequest.js');
const axios = require('axios');
axios.defaults.baseURL = '//localhost:51728';
// axios.defaults.baseURL = '//neuraltradingnetwork.com';
var NeuronImageToLoad2 = new Image();
var SelectedNeuronImageToLoad2 = new Image();
var protectedNeuronImageToLoad= new Image();
var eliminatedNeuronImageToLoad= new Image();
var SelectedLosingNeuronImageToLoad = new Image();
var offSetNeuronDraw = 0;
var screenOffset = 55;
var currentBlinkTransparency = 1;
var contextType = TestRunAndTickerRepoContext;
var contextConsumer = null;

async function GetTestRunAndTickerValues() {
  let urlString = ''
  if(contextConsumer.connectionType == "Test")
  {
    urlString = 'api/values/5'
  }
  else
  {
    urlString = 'NeuralTradingNetwork/api/values/5'
  }

  let res = await axios({
    method:'get',
    url: urlString,
    //url:'NeuralTradingNetwork/api/values/5',
    //baseURL: 'https://archives.neuraltradingnetwork.com/',
   })
  //let res = await axios.get('/NeuralTradingNetwork/api/values/5');  
  return res;
}


const App = (user) => {     
    
    let testRunAndTickerConsumerContext = TestRunAndTickerRepoConsumer._currentValue;
    //let testRunAndTickerConsumerContext = TestRunAndTickerRepoConsumer._context._currentValue;
    const [count, setCount] = useState(0)

    var start = null,
		lasttimestamp = null;

    let particles = [];

      var settings = {
        pointDensity: 8,
        connections: 2,
        sizeVariation: 2,
        velocity: 0.000007,
        //Shake Nueron
        //sizeVariation: .01,
        //velocity: 1.0323,
        maxMovement: 15,
        attractionRange: 200,
        attractionFactor: 0.16,
        imagePath: "images/cube.png",
        imgWidth: 20,
        imgHeight: 18,
        lineColor: "rgb(185, 185, 185)",
        particleDensity: 0.2,
        particleChance: 0.2,
        particleVelocity: 50,
        particleColor: "rgba(255, 255, 255, 0.795)",
        particleLength: 10,
        flashRadius: 45,
        flashOpacity: 0.9,
        flashDecay: .3
      }

      

      const Modal = () => (
        <Popup trigger={HandleMouseClick} modal>
          <span> Modal content </span>
        </Popup>
      );
      
      const HandleMouseMove = (event) => {
        testRunAndTickerConsumerContext.handleMousePointUpdated(event.pageX, event.pageY) 

        //console.log(mousePoint.x, mousePoint.y);
      }
      
      const HandleMouseOver = (event) => {
        //console.log(mousePoint.x, mousePoint.y);
      }

      const HandleMouseClick = (event) => {    
        if (testRunAndTickerConsumerContext.StateOfStrategyDataFilterTable.StrategiesFilterVisible || testRunAndTickerConsumerContext.StateOfPopup.isPropDisplaying)
        {     
          return;
        }
        var closestPoint = -1;
        var closestDistance;
        var previousClosestPoint = -1;
        var previousClosestPointName = "";

        for(var i = 0; i < testRunAndTickerConsumerContext.pointsArray.length; i++) {
          var point = testRunAndTickerConsumerContext.pointsArray[i];
          var distanceToMouse = getDistance(
            {x: event.offsetX, y: event.offsetY}, 
            {x: point.x, y: point.y });

          
          if(distanceToMouse < 30)
          {
            if(closestPoint == -1 || closestDistance == undefined || distanceToMouse < closestDistance)
            {
              closestPoint = i;
              closestDistance = distanceToMouse;
            }
          }
          if(distanceToMouse < previousClosestPoint || previousClosestPoint == -1)
          {
            previousClosestPoint = distanceToMouse;
            previousClosestPointName = testRunAndTickerConsumerContext.pointsArray[i].testRun;
          }
          if(previousClosestPoint < 50)
          {
            var something = "breakpoint here";

          }
        }

        if(previousClosestPoint > -1)
        {
            var another = "breakpoint here";
        }

        if(closestPoint != -1)
        {
          testRunAndTickerConsumerContext.pointsArray[closestPoint].selected = true
          if (!testRunAndTickerConsumerContext.StateOfPopup.isPropDisplaying)
          {
            let tickerDictionary = testRunAndTickerConsumerContext.TestRunAndTickerDictionary[testRunAndTickerConsumerContext.pointsArray[closestPoint].testRun].TickerDataDictionary;
            let tickerValues = Object.values(tickerDictionary);
 

            let tickerNameList = [];
            for(let tickerData in tickerValues)
            {
                let testRunAndTickerValue = tickerValues[tickerData]; 
                tickerNameList.push(testRunAndTickerValue.Ticker);
            }
            
            testRunAndTickerConsumerContext.sortTestRunByCurrentPriority(Object.values(testRunAndTickerConsumerContext.TestRunAndTickerDictionary));
            testRunAndTickerConsumerContext.sortTickersInTestRunByCurrentPriority(tickerValues);
            testRunAndTickerConsumerContext.setStateOfComponentTicker(tickerValues);
            testRunAndTickerConsumerContext.setTestRunAndTicker (testRunAndTickerConsumerContext.pointsArray[closestPoint].testRun, tickerValues[0].Ticker);
            testRunAndTickerConsumerContext.setIsCurrentTestRunAndTickerProfitable();            
            testRunAndTickerConsumerContext.setCurrentNodeName( testRunAndTickerConsumerContext.pointsArray[closestPoint].name);   
            //testRunAndTickerConsumerContext.blinkNeuron(testRunAndTickerConsumerContext.pointsArray[closestPoint].name)
            testRunAndTickerConsumerContext.showReport();          
            setCount(prevCount => prevCount + 1)
          }
        }
        
      }

      const drawLines = (ctx, p) => {
          for(var i in p.closest) {
            ctx.beginPath();
            ctx.moveTo(p.x, p.y);
            ctx.lineTo(p.closest[i].x, p.closest[i].y);
            ctx.strokeStyle = settings.lineColor;
            ctx.stroke();
          }
        }


      const getDistance = (p1, p2) => {
        		return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
      }


      const createPoints = (ctx, testRunData) => {
        let testData = Object.values(testRunData);
        let testKeys = Object.keys(testRunData);
        let testRunSortedByMostProfitable = testData.sort(function(a, b){return a.TestRunProfit - b.TestRunProfit});

        let leastProfit = testRunSortedByMostProfitable[0].TestRunProfit;
        let mostProfit = testRunSortedByMostProfitable[testRunSortedByMostProfitable.length-1].TestRunProfit;

        //TODO: Removed max/min price limitations
        //Find the least profitable test run that is above the elimination line
        // for(let i = 0; i < testRunSortedByMostProfitable.length; i++)
        // {
        //   let sortedTestData = testRunSortedByMostProfitable[i];
        //   if(sortedTestData.TestRunProfit > -700 && sortedTestData.WinLossRate >= .2)
        //   {
        //       leastProfit = sortedTestData.TestRunProfit - 50;
        //       break;
        //   }
        // }

        // //Find the most profitable test run that is below the protection line
        // for(let i = testRunSortedByMostProfitable.length-1; i >0; i--)
        // {
        //   let sortedTestData = testRunSortedByMostProfitable[i];          
        //   mostProfit = sortedTestData.TestRunProfit + 40;
        //   // if(sortedTestData.TestRunProfit < 700)
        //   // {
        //   //     mostProfit = sortedTestData.TestRunProfit + 40;
        //   //     break;
        //   // }
        // }
        // for(var x = 0 - 100; x < ctx.canvas.width + 100; x = x + 1000/settings.pointDensity) {
        //   for(var y = 0 - 100; y < ctx.canvas.height + 100; y = y + 1000/settings.pointDensity) {
        //     var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
        //     var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
        //     var pSizeMod = Math.random()*settings.sizeVariation+1
        //     var pw = settings.imgWidth*pSizeMod;
        //     var ph = settings.imgHeight*pSizeMod;
        //     var pAnimOffset = Math.random()*2*Math.PI;
        //     var p = {name: "Neuron " + testRunAndTickerConsumerContext.pointsArray.length, x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
        //     animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false};
        //     testRunAndTickerConsumerContext.handlePointArrayUpdated(p);
        //   }
        // }
    
        let numberRecreated = 0;
        for(var testResultIndex in testData) {
          let testResult = testData[testResultIndex]

          //Get the percentage difference of the current profit to the most profit of all test runs (max profit test run will return 1)
          let profitScale = testResult.TestRunProfit - leastProfit
          let maxProfitScale = mostProfit - leastProfit
          let currentProfitScale = profitScale / maxProfitScale
          var px = ctx.canvas.width * currentProfitScale;
          let yOffset = Math.random()*.01;
          let randomOffset = Math.random() *.02;
          var py = ctx.canvas.height * (1 - (testResult.WinLossRate == 1 ? (.92 + (yOffset)) : testResult.WinLossRate > .5 ? testResult.WinLossRate + randomOffset : testResult.WinLossRate - randomOffset));

          // var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
          // var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
          var pSizeMod = Math.random()*settings.sizeVariation;
          var pw = settings.imgWidth*pSizeMod;
          var ph = settings.imgHeight*pSizeMod;
          var pAnimOffset = Math.random()*2*Math.PI;
          var randomSizeMult = settings.flashRadius/(2); //settings.flashRadius/(2 + Math.random()); //Random size of neurons
          if(testResult.TestRunProfit > 700)
          {
            // px = ctx.canvas.width 
            pAnimOffset = 0
            pw = 0
            ph = 0
            if(testResult.WinLossRate > .9)
            {
              py = ctx.canvas.height * (.15 + yOffset)
            }
          }
          if(testResult.TestRunProfit < -700 || testResult.WinLossRate == 0)
          {
            // px = ctx.canvas.width 
            pAnimOffset = 0
            pw = 0
            ph = 0
            py = ctx.canvas.height * (.9 + (.15 * testResult.WinLossRate))
          }
          // if(-30 < py && py < screenOffset)
          // {
          //   py = screenOffset + 60 + (Math.random()*100);
          // }
          var p = {name: testResult.TestRunId + " " + numberRecreated, testRun: testResult.TestRunId, x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
          animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false, sizeMultiplier: randomSizeMult};
          testRunAndTickerConsumerContext.handlePointArrayUpdated(p);
          // for(var i = 0; i < testRunNames.length; i++) {
          //     var x = ctx.canvas.width * Math.random();
          //     var y = ctx.canvas.height * Math.random();
          //     var px = Math.floor(x + Math.random()*1000/settings.pointDensity);
          //     var py = Math.floor(y + Math.random()*1000/settings.pointDensity);
          //     var pSizeMod = Math.random()*settings.sizeVariation+1;
          //     var pw = settings.imgWidth*pSizeMod;
          //     var ph = settings.imgHeight*pSizeMod;
          //     var pAnimOffset = Math.random()*2*Math.PI;
          //     var p = {name: testRunNames[i] + " " + numberRecreated + i, testRun: testRunNames[i], x: px, originX: px, y: py, originY: py, w: pw, h: ph, sizeMod: pSizeMod, 
          //     animOffset: pAnimOffset, attraction: 0, flashOpacity: settings.flashOpacity, selected: false};
          //     testRunAndTickerConsumerContext.handlePointArrayUpdated(p);
          // }
          numberRecreated++
        }
        

        // for each point find the closest points. From https://tympanus.net/codrops/2014/09/23/animated-background-headers/
        for(var i = 0; i < testRunAndTickerConsumerContext.pointsArray.length; i++) {
          var closest = [];
          var p1 = testRunAndTickerConsumerContext.pointsArray[i];
          for(var j = 0; j < testRunAndTickerConsumerContext.pointsArray.length; j++) {
            var p2 = testRunAndTickerConsumerContext.pointsArray[j]
            if(!contains(p2.closest, p1) && p1 != p2) {
              var placed = false;
              for(var k = 0; k < settings.connections; k++) {
                if(!placed && closest[k] == undefined) {
                  closest[k] = p2;
                  placed = true;
                }
              }
    
              for(var k = 0; k < settings.connections; k++) {
                if(!placed && getDistance(p1, p2) < getDistance(p1, closest[k])) {
                  closest[k] = p2;
                  placed = true;
                }
              }
            }
          }
          p1.closest = closest;
        }
      }


      const closePopUp = () => 
      {
        let tempLabelValues = []       
        let tempShortEmaValues = []    
        let tempLongEmaValues = []    
        let tempPriceValues = []    
        let tempMacdValues = []    
        let tempMacdFastValues = []    
        let tempMacdSlowValues = []    
        let tempSignalValues = []    
        let tempRsiValues = []    

        testRunAndTickerConsumerContext.showReport();  

        testRunAndTickerConsumerContext.setStateOfNewLabelValues(tempLabelValues);
        testRunAndTickerConsumerContext.setStateOfNewShortEmaValues(tempShortEmaValues);
        testRunAndTickerConsumerContext.setStateOfNewLongEmaValues(tempLongEmaValues);
        testRunAndTickerConsumerContext.setStateOfNewPriceValues(tempPriceValues);
        testRunAndTickerConsumerContext.setStateOfNewMacdValues(tempMacdValues);
        testRunAndTickerConsumerContext.setStateOfNewMacdSignalValues(tempSignalValues);
        testRunAndTickerConsumerContext.setStateOfNewRsiValues(tempRsiValues);
        
        setCount(prevCount => prevCount + 1);
      }

      const animate = (ctx, frameCount, timestamp, img) => {
        // Calculate frametime
        if (!start) {
          start = timestamp;
          lasttimestamp = timestamp;
        }
        var elapsed = timestamp - start,
          delta = (timestamp - lasttimestamp)/100;
        lasttimestamp = timestamp;

        // Move points around

        if(testRunAndTickerConsumerContext)
        
        for (var i = 0; i < testRunAndTickerConsumerContext.pointsArray.length; i++) {
          var point = testRunAndTickerConsumerContext.pointsArray [i];

          var attractionOffset = {x: 0, y: 0};
          var distanceToMouse = getDistance({x: point.originX, y: point.originY}, testRunAndTickerConsumerContext.mousePoint);
          if(distanceToMouse < 10)
          {
            let p = 0;
            //console.log(point.name + " " + point.x + " " + point.y + " " + testRunAndTickerConsumerContext.mousePoint.x + " " + testRunAndTickerConsumerContext.mousePoint.y);
          }
          if (distanceToMouse <= settings.attractionRange) {
            let displacementFactor = (Math.cos(distanceToMouse / settings.attractionRange * Math.PI) + 1) / 2 * settings.attractionFactor;

            attractionOffset.x = displacementFactor * (testRunAndTickerConsumerContext.mousePoint.x - point.x);
            attractionOffset.y = displacementFactor * (testRunAndTickerConsumerContext.mousePoint.y - point.y);
          }

          point.x = point.originX + Math.sin(elapsed*settings.velocity+point.animOffset)*settings.maxMovement*point.sizeMod+attractionOffset.x;
          point.y = point.originY - Math.cos(elapsed*settings.velocity+point.animOffset)*settings.maxMovement*point.sizeMod+attractionOffset.y;
          point.flashOpacity = Math.max(0, point.flashOpacity - settings.flashDecay * delta);
        }

        // Move particles
        for (var i = 0; i < particles.length; i++) {
          var particle = particles[i];

          var origin = testRunAndTickerConsumerContext.pointsArray[particle.origin];
          var target = origin.closest[particle.target];

          var distance = getDistance({x: origin.x, y: origin.y}, {x: target.x, y: target.y});
          var direction = {x: (target.x - origin.x) / distance, y: (target.y - origin.y) / distance};

          particle.traveled += settings.particleVelocity * delta;
          particle.direction = direction;

          particle.x = origin.x + direction.x * particle.traveled;
          particle.y = origin.y + direction.y * particle.traveled;

          if (!between(origin, {x: particle.x}, target)) {
            particles.splice(i, 1);
            i--;
          }

        }

        // Spawn new particles
        for (var i = 0; i < settings.particleDensity * testRunAndTickerConsumerContext.pointsArray.length; i++) {
          if (Math.random() < settings.particleChance * delta) {
            var pOriginNum = Math.floor(Math.random()*testRunAndTickerConsumerContext.pointsArray.length);
            var pOrigin = testRunAndTickerConsumerContext.pointsArray[pOriginNum];
            var pTargetNum = Math.floor(Math.random()*pOrigin.closest.length);
            var px = pOrigin.x;
            var py = pOrigin.y;
            var p = {origin: pOriginNum, target: pTargetNum, x: px, y: py, traveled: 0, direction: {x: 0, y: 0}};
            particles.push(p);
            pOrigin.flashOpacity = settings.flashOpacity;
          }
        }

        draw(ctx, frameCount, img);

        //window.requestAnimationFrame(ctx, animate);

      }

      const between = (p1, p2, t) => {
       		return (p1.x - p2.x) * (p2.x - t.x) > 0;
      }

      const contains = (a, obj) => {
        if (a !== undefined) {
          for (var i = 0; i < a.length; i++) {
            if (a[i] === obj) {
              return true;
            }
          }
        }
        return false;
      }

      const draw = (ctx, frameCount, img) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        if (testRunAndTickerConsumerContext.needToInitialize)
        {
          testRunAndTickerConsumerContext.needToInitialize = false;
          let urlString = ''
          if (testRunAndTickerConsumerContext.connectionType == "Test")
          {
            urlString = 'api/values/5'
          }
          else
          {
            urlString = 'NeuralTradingNetwork/api/values/5'
          }

           axios({
            method:'get',
            url: urlString,
            //baseURL: 'https://archives.neuraltradingnetwork.com/',
           })
            .then(res => {
                testRunAndTickerConsumerContext.UpdateTestRunAndTickerDictionary(res.data);

                let orderTestRunIdsBy = ['Most Profitable', 'Least Profitable', 'Highest Win Rate', 'Lowest Win Rate', 'Highest Winning Average', 'Highest Losing Average'];

                
                let finalTestRunDataList = testRunAndTickerConsumerContext.sortTestRunByCurrentPriority(Object.values(testRunAndTickerConsumerContext.TestRunAndTickerDictionary));


                let allTestRuns = Object.keys(testRunAndTickerConsumerContext.TestRunAndTickerDictionary);
                //testRunAndTickerConsumerContext.setStateOfComponentTestRun({dataValues:  testRunAndTickerConsumerContext.TestRunAndTickerDictionary})
                testRunAndTickerConsumerContext.setStateOfComponentTestRun({dataValues:  finalTestRunDataList})

                // console.log('allTestRuns: ' + allTestRuns);
                // console.log('Object.values(allTestRuns): ' + Object.values(allTestRuns));
                // console.log('Object.keys(allTestRuns): ' + Object.keys(allTestRuns));
                for (let value in Object.values(allTestRuns)) {         
                  var testRunName = allTestRuns[value];
                  var TestRunData = res.data[testRunName];

                  var tickerList = [];
                  //Object.values(testRunAndTicker[key].TickerDataDictionary)
                  for(let tickerDataInfo in TestRunData.TickerDataDictionary) {     
                    let tickerValue = TestRunData.TickerDataDictionary[tickerDataInfo];
                    tickerList.push(tickerValue);     
                  }
                }

                testRunAndTickerConsumerContext.sortTickersInTestRunByCurrentPriority(tickerList);
                testRunAndTickerConsumerContext.setStateOfComponentTicker(Object.values(testRunAndTickerConsumerContext.CurrentValues))
                testRunAndTickerConsumerContext.setStateOfComponentPriority({dataValues:  orderTestRunIdsBy})
                createPoints(ctx, res.data);
                testRunAndTickerConsumerContext.setIsInitializing();
            });
            
            //createPoints(ctx);
        }
        else if(testRunAndTickerConsumerContext.pointsArray.length != 0)
        {
          for (var i = 0; i < testRunAndTickerConsumerContext.pointsArray.length; i++) {
            var point = testRunAndTickerConsumerContext.pointsArray [i];
            drawLines(ctx, point);
            ctx.beginPath();
            //let NeuronImageToLoad2 = NeuronImageOfCube
              // You'll get security error if you do.
            // This loop gets every pixels on the image and

            // ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
          //    NeuronImageToLoad2.onload = function() {
          //     ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
          // //   ctx.rect(point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
          // //   var gradient1 = ctx.createRadialGradient(point.x, point.y, settings.flashRadius, point.x, point.y, 1);
          // //   if(point.selected)
          // //   {
          // //     gradient1.addColorStop(0, "rgba(150, 250, 100, 0.80)");
          // //   }
          // //   else
          // //   {
          // //     gradient1.addColorStop(0, "rgba(255,255,255,0.8)");
          // //   }
  
          // //   ctx.fillStyle = gradient1;
          // //   ctx.fill();           
          //         setCount(prevCount => prevCount + 1)     
          //  }
          
          // if(NeuronImageToLoad2.src == '')
          // {
          //   NeuronImageToLoad2.src = NeuronImageOfCube;
          // }


          var testRunData = testRunAndTickerConsumerContext.TestRunAndTickerDictionary[point.testRun];
          if(point.selected){
            // var imageData=ctx.getImageData(point.x, point.y, point.sizeMultiplier, point.sizeMultiplier);
            // var cubeImageData = imageData.data;

          
            // for (var j=0; j<imageData.height; j++)
            // {
            //   for (var k=0; k<imageData.width; k++)
            //   {
            //     //Only convert the non transparent pixels
            //     var index=(k)*imageData.width+(j);
            //     if(imageData.data[index+3] > 200)
            //     {
            //       var red=50;
            //       var green=200;
            //       var blue=50;
            //       var alpha=imageData.data[index+3];
            //       var average=(red+green+blue)/3;
            //       imageData.data[index]=red;
            //       imageData.data[index+1]=green;
            //       imageData.data[index+2]= blue;

            //     }
                
            //   }
            // }
            // ctx.putImageData(imageData,point.x,point.y);
            if(testRunData.TestRunProfit > 0)
            {
              if(testRunData.TestRunProfit > 700)
              {
                ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
              }
              else
              {
                ctx.drawImage(SelectedNeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }
            }
            else
            {    
              // ctx.save();
              // var p = currentBlinkTransparency - .01;
              // ctx.global = p;
              //currentBlinkTransparency = p;
              let totalOrders = testRunData.TestRunLosses + testRunData.TestRunWins;
              if(totalOrders == 0 && testRunData.TestRunProfit == 0)
              {
                continue               
              }
              else if(testRunData.TestRunProfit < -700)
              {
                ctx.drawImage(eliminatedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
              }
              else
              {
                ctx.drawImage(SelectedLosingNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);

              }
            }
          }
          else
          {       
            
            let totalOrders = testRunData.TestRunLosses + testRunData.TestRunWins;
            if(totalOrders == 0 && testRunData.TestRunProfit == 0)
            {
              continue               
            }
            else if(testRunData.TestRunProfit > 700)
            {
              ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
            }           
            else if(testRunData.TestRunProfit < -700)
            {
              ctx.drawImage(eliminatedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);                
            }
            else
            {
              if(testRunData.TestRunId == 'rebel-impressive-rottweiler')
              {
                let h = 0;
              }
              ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
            }
          }
        }
  
          for (var i = 0; i < particles.length; i++) {
            var particle = particles[i];
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(particle.x - particle.direction.x * settings.particleLength, particle.y - particle.direction.y * settings.particleLength);
            ctx.strokeStyle = settings.particleColor;
            ctx.stroke();
          }
  
          for (var i = 0; i < testRunAndTickerConsumerContext.pointsArray.length; i++) {
            var point = testRunAndTickerConsumerContext.pointsArray [i];
            if (point.flashOpacity > 0) {
              ctx.beginPath();
              // var neuronImageToLoad = document.getElementById("NeuronImageCube");
            
            //ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y - settings.flashRadius/4, point.sizeMultiplier, point.sizeMultiplier);
            NeuronImageToLoad2.onload = function() {
                  ctx.drawImage(NeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
                    //   ctx.rect(point.x - settings.flashRadius, point.y - settings.flashRadius, settings.flashRadius * 2, settings.flashRadius * 2);
                    //   var gradient = ctx.createRadialGradient(point.x, point.y, settings.flashRadius, point.x, point.y, 1);
                    //   gradient.addColorStop(0, "rgba(255, 255, 255, 0)");
                    //   gradient.addColorStop(1, "rgba(255, 255, 255, " + point.flashOpacity + ")");
                    //   ctx.fillStyle = gradient;
                    //   ctx.fill();    
                        
                  //setCount(prevCount => prevCount + 1)
               }

              SelectedNeuronImageToLoad2.onload = function() {
                ctx.drawImage(SelectedNeuronImageToLoad2, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
         
              }

              SelectedLosingNeuronImageToLoad.onload = function () {
                ctx.drawImage(SelectedLosingNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              protectedNeuronImageToLoad.onload = function () {
                ctx.drawImage(protectedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }

              eliminatedNeuronImageToLoad.onload = function () {
                ctx.drawImage(eliminatedNeuronImageToLoad, point.x - settings.flashRadius/4, point.y, point.sizeMultiplier, point.sizeMultiplier);
              }
            }
            
            if(NeuronImageToLoad2.src == '')
            {
              NeuronImageToLoad2.src = NeuronImageOfCube;
            }
            
            if(SelectedNeuronImageToLoad2.src == '')
            {
              SelectedNeuronImageToLoad2.src = NeuronImageOfGreenCube;
            }

            if(SelectedLosingNeuronImageToLoad.src == '')
            {
              SelectedLosingNeuronImageToLoad.src = NeuronImageOfRedCube;
            }

            if(protectedNeuronImageToLoad.src == '')
            {
              protectedNeuronImageToLoad.src = NeuronImageOfBlueCube;
            }

            if(eliminatedNeuronImageToLoad.src == '')
            {
              eliminatedNeuronImageToLoad.src = NeuronImageOfGrayCube;
            }
        }


          // try
          // {            
          //   var img = new Image();
          //   img.src = settings.imagePath;
            
            
          //   // img.onload = function () {
          //   //   ctx.drawImage(img, point.x-point.w/2, point.y-point.h/2, point.w, point.h);
          //   // };
            
          //   // img.onerror = function(e) {
          //   //   console.log("Not ok",e);
          //   // }
          // }
          // catch(e)
          // {
          //   console.log(e);
          // }
        }
	    }

      const RefreshScreen = (data) =>{
          switch (data) {
            case 'Strategy_Selected':
              setCount(prevCount => prevCount + 1)
            default:
              return;
          }
      }

      
      document.onmousemove = HandleMouseMove;
      document.onmouseover = HandleMouseOver;
      document.onmouseup = HandleMouseClick;

      return (
        <div className="App" >   
            {
              testRunAndTickerConsumerContext.StateOfPopup.isPropDisplaying == true ?
                  <Popup
                  text={testRunAndTickerConsumerContext.StateOfPopup.nameOfNode}
                  closePopup={closePopUp}
                  />
                : null
            }         
            <div className='SideBarController'>      
              <SidebarController SidebarControllerProp = {RefreshScreen}/> 
              <Profile ProfileControllerProp = {RefreshScreen} />  
              </div>                
        </div>
      );
}




export default App